import React from 'react'
import { PackageComboBox, PhenomInput, PhenomLabel, PhenomSelect, PhenomTextArea } from '../util/stateless';
import { createPhenomGuid, deGuidify, splitXmiType } from '../util/util';
import ChangeSetPicker from '../widget/ChangeSetPicker';
import { NodeHistory2 } from './node-history';
import { withPageLayout } from "./node-layout";
import PhenomId from "../../requests/phenom-id";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Button, Toolbar } from '@progress/kendo-react-buttons';
import DeletionConfirm2 from '../dialog/DeletionConfirm2';
import { logicalValueTypeList } from '../../global-constants';



export class BasisEntityManager extends React.Component {
  static defaultProps = {
    newNode: {
      name: "",
      xmiType: "conceptual:BasisEntity",
      description: "",
      parent: "",
      children: [],
      subModelId: undefined,
    },
  }

  constructor(props) {
    super(props);

    this.phenomId = new PhenomId("basis-entity", this.props.idCtx);
  }

  state = {
    ...this.props.newNode,
  }

  componentDidMount() {
    this.initNodeState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.node !== this.props.node) {
      this.initNodeState();
    }
  }

  initNodeState = () => {
    const { node } = this.props;
    this.setState({ ...node })
  }

  generateNode = () => {
    const data = {
      guid: this.state.guid,
      name: this.state.name,
      xmiType: this.state.xmiType,
      description: this.state.description,
      parent: this.state.parent || undefined,
      subModelId: this.state.subModelId,
    }

    return data;
  }
  
  render() {
    const { editable } = this.props;
    const original = this.props.node;

    return <div className="edit-form">
            <div className="p-row">
              <div className="p-col">
                <div className="p-row p-with-flex">
                  <PhenomInput label="Basis Entity"
                               value={this.state.name}
                               originalValue={original["name"]}
                               disabled={!editable}
                               autoFocus={true}
                               id={this.phenomId.genPageId("name")}
                               onChange={(e) => this.setState({ name: e.target.value })}
                               onClickResetIcon={() => this.setState({ name: original["name"] })} />

                  <PackageComboBox label="Parent Package"
                                    xmiType="face:ConceptualDataModel"
                                    placeholder="<Default>"
                                    nodeGuid={this.state.guid}
                                    selectedGuid={this.state.parent}
                                    originalGuid={original["parent"]}
                                    disabled={!editable}
                                    id={this.phenomId.genPageId("parent")}
                                    onChange={(parent) => this.setState({ parent: parent.guid })}
                                    onClickResetIcon={() => this.setState({ parent: original["parent"] })} />
                </div>

                <PhenomTextArea label="Description"
                                value={this.state.description}
                                originalValue={original["description"]}
                                disabled={!editable}
                                id={this.phenomId.genPageId("description")}
                                onChange={(e) => this.setState({ description: e.target.value })} />
              </div>

              <div className="edit-side">
                <NodeHistory2 guid={this.state.guid}
                              ref={ele => this.historyRef = ele} />
                <ChangeSetPicker label="Change Set"
                                 id={this.phenomId.genPageId()}
                                 disabled={!editable} />
              </div>
            </div>
          </div>
  }
}


export const EditBasisEntityManager = withPageLayout(BasisEntityManager);
