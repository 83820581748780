import React from 'react'
import $ from 'jquery'
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { KendoButtonWithDebounce, PhenomComboBox, PhenomInput, PhenomLabel } from '../../util/stateless';
import { fetchProjectsAndModels } from '../../../requests/sml-requests';
import { connect } from 'react-redux';
import { useState } from 'react';
import { BasicAlert } from '../../dialog/BasicAlert';
import { receiveLogs, receiveResponse, receiveWarnings } from '../../../requests/actionCreators';
import { useCallback } from 'react';
import { ModelLeaf, ProjectLeaf } from '../../tree/leaf/ModelLeaf';
import { BasicConfirm } from '../../dialog/BasicConfirm';
import { _ajax } from '../../../requests/sml-requests';


class PermissionTokens extends React.Component {

  state = {
    tokens: [],
    projectLeaves: [],
    modelLeaves: [],
    
    tokenCode: "",
    selectedLeaf: null,

    sortProjectConfig: [],
    sortModelConfig: [],
  }

  componentDidMount() {
    this.init();
  }

  init() {
    this.fetchTokenList();
    this.fetchTreeData();
  }

  fetchTokenList = () => {
    return $.ajax({
      url: "/index.php?r=/referencing-model/permission-token-list",
      method: "post",
    }).then(res => {
      const response = JSON.parse(res);
      let tokens = [];

      if (Array.isArray(response?.data?.tokens)) {
        tokens = response.data.tokens;
      }

      this.setState({ tokens });
    })
  }

  fetchTreeData = () => {
    const { username, activeProjectId } = this.props.userIdentity;
    
    return fetchProjectsAndModels(username, activeProjectId).then((data => {
      this.setState({ 
        projectLeaves: Object.values(data.projectIndex).filter(leaf => leaf.isOwner(username)),
        modelLeaves: Object.values(data.modelIndex).filter(leaf => leaf.isOwner(username)),
      });
    }))
  }

  activateToken = () => {
    const { tokenCode } = this.state;

    if (!tokenCode) {
      return receiveWarnings("Please enter a token and try again.");
    }

    _ajax({
      url: "/index.php?r=/referencing-model/activate-permission-token",
      method: "post",
      data: { 
        token: tokenCode,
      },
    }).then((res) => {
      const response = res;
      receiveResponse(response);
      
      if (response.error || response.errors) {
        return;
      }
      
      BasicAlert.show("Refreshing data");

      this.fetchTreeData().finally(() => {
        BasicAlert.hide();
      })
    })
  }

  generateToken = (leaf, dstUsername, role) => {
    let projectId, modelId;

    if (!dstUsername) {
      return receiveWarnings("Please enter a username and try again.")
    }

    if (leaf instanceof ProjectLeaf) {
      projectId = leaf.getId();

    } else if (leaf instanceof ModelLeaf) {
      modelId = leaf.getId();
    }
    
    return $.ajax({
      url: "/index.php?r=/referencing-model/create-permission-token",
      method: "post",
      data: { 
        projectId,
        modelId,
        dstUsername,
        role,
      },
    }).then((res) => {
      const response = JSON.parse(res);
      receiveResponse(response);

      if (response?.status === "success") {
        receiveLogs(`Token Generated: ${response.data?.token}`);
        this.fetchTokenList();
      }
    })
  }

  deleteToken = (token) => {
    return $.ajax({
      url: "/index.php?r=/referencing-model/delete-permission-tokens",
      method: "post",
      data: {
        tokens: [ token ],
      }
    }).then(() => {
      this.fetchTokenList();
    })
  }


  handleDeleteToken = (data) => {
    if (!data?.token) {
      return;
    }

    BasicConfirm.show(`You are about to delete the token for '${data.username}'. Are you sure?`, () => this.deleteToken(data.token));
  }

  sortLeaves = (leaves, configs=[]) => {
    const copy = [...leaves];

    for (let config of configs) {
      switch (config.dir) {
        case "asc":
          copy.sort((leaf1, leaf2) => leaf1.getName().localeCompare(leaf2.getName()));
          break;
        case "desc":
          copy.sort((leaf1, leaf2) => leaf2.getName().localeCompare(leaf1.getName()));
          break;

        default:
      }
    }

    return copy;
  }

  renderRole = (cellProps) => {
    const { role } = cellProps.dataItem;
    
    let role_text = "";
    if (/.*o.*/.test(role)) {
      role_text += "Owner / "
    }
    if (/.*a.*/.test(role)) {
      role_text += "Admin / "
    }
    if (/.*w.*/.test(role)) {
      role_text += "Write / "
    }
    if (/.*r.*/.test(role)) {
      role_text += "Read / "
    }
    role_text = role_text.slice(0, -3);

    return <td>{ role_text }</td>
  }

  renderCreationDate = (cellProps) => {
    const { creation_date } = cellProps.dataItem;

    if (!creation_date) {
      return <td />
    }

    const date = new Date(creation_date);
    return <td>
      {`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}
    </td>
  }

  renderDeleteCell = (cellProps) => {
    const data = cellProps.dataItem;

    return <td>
        <KendoButtonWithDebounce icon="trash"
                                onClick={() => this.handleDeleteToken(data)} />
    </td>
  }

  renderTarget(cellProps) {
    const { model_name, branch_ref_name } = cellProps.dataItem;

    return <td>
      {model_name || branch_ref_name || ""}
    </td>
  }

  renderTreeRow = (_, cellProps) => {
    return <PGridRow leaf={cellProps.dataItem}
                     generateToken={this.generateToken} />
  }

  render() {
    const { tokens=[], projectLeaves=[], modelLeaves=[], sortProjectConfig, sortModelConfig } = this.state;
    return <div className="phenom-content-wrapper">
            <nav className='sub-menu-actions' aria-label='form actions' />

            <div className="phenom-content-scrollable">
              <div style={{ display: "flex", height: "100%" }}>
                <div className="edit-form-container">
                  <div style={{ fontSize: "0.875rem", padding: 5, border: "1px solid lightgray" }}>
                    Sharing a project or model with a user outside your organization allows that user to see the username and organization of any users with permissions to the project or model.<br />
                    <span style={{ color: "red" }}>Granting another user admin permissions to this project or model means that that user can change or remove permissions on this project or model, including yours. Proceed with caution.</span>
                  </div>
                  <div className="fill-vertical overflow-hidden">
                    <PhenomLabel text="Projects" />
                    <Grid data={sortProjectConfig.length ? this.sortLeaves(projectLeaves, sortProjectConfig) : projectLeaves}
                          rowRender={this.renderTreeRow}
                          className="fill-vertical overflow-hidden"
                          sortable
                          sort={sortProjectConfig}
                          onSortChange={(e) => this.setState({ sortProjectConfig: e.sort })}>
                      <GridNoRecords>No data available</GridNoRecords>
                      <GridColumn title="Name" field="name" />
                      <GridColumn title="Username" />
                      <GridColumn title="Read" width="75px" />
                      <GridColumn title="Write" width="75px" />
                      <GridColumn title="Admin" width="75px" />
                      <GridColumn title="Share" width="60px" />
                    </Grid>
                  </div>

                  <div className="fill-vertical overflow-hidden">
                    <PhenomLabel text="Models" />
                    <Grid data={sortModelConfig.length ? this.sortLeaves(modelLeaves, sortModelConfig) : modelLeaves}
                          rowRender={this.renderTreeRow}
                          className="fill-vertical overflow-hidden"
                          sortable
                          sort={sortModelConfig}
                          onSortChange={(e) => this.setState({ sortModelConfig: e.sort })}>
                      <GridNoRecords>No data available</GridNoRecords>
                      <GridColumn title="Name" field="name" />
                      <GridColumn title="Username" />
                      <GridColumn title="Read" width="75px" />
                      <GridColumn title="Write" width="75px" />
                      <GridColumn title="Admin" width="75px" />
                      <GridColumn title="Share" width="60px" />
                    </Grid>
                  </div>
                </div>

                <div className="edit-form-container">
                  <div>
                    <PhenomInput label="Gain access to a project/model" 
                                 value={this.state.tokenCode}
                                 placeholder="Enter token"
                                 onChange={(e) => this.setState({ tokenCode: e.target.value })}>
                      <KendoButtonWithDebounce onClick={this.activateToken}>Submit</KendoButtonWithDebounce>
                    </PhenomInput>
                  </div>

                  <div>
                    <PhenomLabel text="Permission Tokens" />
                    <div style={{ fontSize: "0.875rem", padding: 5, marginBottom: 10, border: "1px solid lightgray" }}>
                    Tokens are valid for 24h and will only work for the user specified. Send this token to them so they can access the desired project or model.
                    </div>
                    <Grid data={tokens}>
                      <GridNoRecords>No data available</GridNoRecords>
                      <GridColumn title="Username" field='username' />
                      <GridColumn title="Token" field='token' />
                      <GridColumn title="Role" field='role' cell={this.renderRole} />
                      <GridColumn title="Project/Model" cell={this.renderTarget} />
                      <GridColumn title="Creation Date" field='creation_date' cell={this.renderCreationDate} />
                      <GridColumn title="Delete" width="65px" cell={this.renderDeleteCell} />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
      </div>
  }
}


const PGridRow = (props) => {
  const [ username, setUsername ] = useState("");
  const [ perm, setPerm ] = useState("");
  const { leaf } = props;

  const handleShare = useCallback(() => {
    props.generateToken(leaf, username, sortPerms(perm))
    setUsername("");
    setPerm("");
  }, [username, perm]);

  if (!leaf) {
    return null;
  }

  const handleEditPerms = (role) => {
    console.log(leaf)
    
    let newPerm = perm;
    if (newPerm.includes(role)) {
      newPerm = newPerm.replace(role, "");
    }
    else {
      newPerm += role;
    }

    if (!/.*r.*/.test(newPerm) && /.*[aw].*/.test(newPerm)) {
      newPerm += "r";
    }
    setPerm(newPerm);
  };

  const adminPerms = /.*a.*/.test(perm);
  const writePerms = /.*w.*/.test(perm);
  const readPerms = /.*r.*/.test(perm);

  const sortPerms = (role) => {
    let sorted = "";
    if (adminPerms) sorted += "a";
    if (writePerms) sorted += "w";
    if (readPerms) sorted += "r";
    return sorted;
  }

  return <tr>
    <td>{ leaf.getName() }</td>
    <td>
      <PhenomInput value={username}
                   onChange={(e) => setUsername(e.target.value)} />
    </td>
    <td>
      <input type="checkbox"
             checked={readPerms}
             onChange={(e) => handleEditPerms("r")}
             disabled={writePerms || adminPerms} />
    </td>
    <td>
      <input type="checkbox"
             checked={writePerms}
             onChange={(e) => handleEditPerms("w")} />
    </td>
    <td>
      <input type="checkbox"
             checked={adminPerms}
             onChange={(e) => handleEditPerms("a")} />
    </td>
    <td>
      <KendoButtonWithDebounce iconClass="fa fas fa-share-alt"
                               onClick={handleShare} />
    </td>
  </tr>
}

const msp = (state) => ({
  userIdentity: state.user.userIdentity,
  canEdit: state.user.canEdit,
})


export default connect(msp)(PermissionTokens);