import {Notifications} from "../edit/notifications";
import {CollapseHeader, LineLabel} from "../util/stateless";
import React, { Component } from "react";
import $ from "jquery";
import PhenomId from "../../requests/phenom-id";

export class RequestPasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
        };
    }

    launchResetRequest = () => {
        $.ajax({
            url: "/index.php?r=/site/request-password-reset",
            method: "post",
            data: {username: this.state.username},
        }).then((res) => {
            const response = JSON.parse(res);

            if (response.errors) {
                this.noticeRef.error(response.errors);
            } else if (response.response && response.response === true) {
                this.noticeRef.info("Your password reset request has been processed. Please check the email associated with your account for a message detailing next steps.");
            } else {
                this.noticeRef.error("An error occurred during the request process. Please contact Skayl Support for further help.");
            }
        });
        return false;
    };

    render() {
        const phenomId = new PhenomId("request-password-reset",this.props.idCtx);
        return (<section id="phenom-content" style={{backgroundColor: "#fff"}}>
            <Notifications ref={ele => this.noticeRef = ele}/>
            <div className="phenom-content-wrapper">
              <div className="phenom-content-scrollable">
                <CollapseHeader text="REQUEST PASSWORD RESET" id={phenomId.gen("")}/>
                <nav className="tab-nav" style={{height: 30}}></nav>
                <main style={{padding: 20, backgroundColor: "#fff", flex: 1}}>
                    <LineLabel text="Username" idCtx={phenomId.gen("","username")}/>
                    <input onChange={(e) => this.setState({username: e.target.value})}
                          placeholder="enter your account username" type="text" className="cadet-text-input"
                            idCtx={phenomId.gen("","username-input")} />
                    <button
                        onClick={this.launchResetRequest}
                        style={{marginTop: 5}}
                        disabled={!this.state.username.length}
                        className="filled-button"
                        id={phenomId.gen("","request-reset-button")}>
                        REQUEST RESET
                    </button>
                </main>
              </div>
            </div>
        </section>);
    }
}
