import {LineLabel} from "./stateless";
import React, {Component, Fragment} from "react";
import $ from "jquery";
import PhenomId from "../../requests/phenom-id";

export class PerformanceChecker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timingData: null,
            start: null,
            end: null
        };
    }

    launchTimingRequest = () => {
        this.setState({start: Date.now()});
        $.ajax({
            url: "/index.php?r=/site/performance-checker",
            method: "post",
        }).then((res) => {
            const response = JSON.parse(res);
            this.setState({timingData: response});
            this.setState({end: Date.now()});
        });
        return false;
    };
    
    componentDidMount() {
        // setTimeout(() => {
        //     this.launchTimingRequest();
        // }, (500));
    }

    render() {
        const phenomId = new PhenomId("performance-checker",this.props.idCtx);
        return (<Fragment style={{backgroundColor: "#fff"}} id={phenomId.gen("","wrapper")}>
            <nav className="tab-nav" style={{height: 30}}></nav>
            <main style={{padding: 20, backgroundColor: "#fff"}}>
                <LineLabel text={"JS Start Time: 0ms"} idCtx={phenomId.gen("","start-time")}/>
                <br/>
                <LineLabel text={"PHP Start Time: +" + (this.state.timingData?.data.phpStartTime - this.state.start) + "ms"} idCtx={phenomId.gen("","php-start-time")}/>
                <br/>
                <LineLabel text={"Skayl_Model_Library Time: +" + (this.state.timingData?.data.smlTime - this.state.start) + "ms"} idCtx={phenomId.gen("","sml-time")}/>
                <br/>
                <LineLabel text={"PHP End Time: +" + (this.state.timingData?.data.phpEndTime - this.state.start) + "ms"} idCtx={phenomId.gen("","php-end-time")}/>
                <br/>
                <LineLabel text={"JS End Time: +" + (this.state.end - this.state.start) + "ms"} idCtx={phenomId.gen("","js-end-time")}/>
                <br/>
                <button className="bordered-button" onClick={() => this.launchTimingRequest()}
                        style={{float: "left"}}
                        id={phenomId.gen("","close-button")}>LAUNCH
                </button>
            </main>
        </Fragment>);
    }
}
