import React from 'react'
import { PackageComboBox, PhenomInput, PhenomLabel, PhenomSelect, PhenomTextArea, PhenomToggle } from '../util/stateless';
import { deGuidify, isPhenomGuid, splitXmiType, validateNodeFields } from '../util/util';
import ChangeSetPicker from '../widget/ChangeSetPicker';
import { NodeHistory2 } from './node-history';
import { withPageLayout } from "./node-layout";
import PhenomId from "../../requests/phenom-id";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';



export class ConstraintManager2 extends React.Component {
  static defaultProps = {
    newNode: {
      name: "",
      xmiType: "platform:RegularExpressionConstraint",
      description: "",
      parent: "",
      expression: "",
      upperBound: "1",
      lowerBound: "1",
      upperBoundInclusive: "true",
      lowerBoundInclusive: "true",
      allowedValue: "",
      subModelId: undefined,
    },
  }

  constructor(props) {
    super(props);

    this.phenomId = new PhenomId("constraint", this.props.idCtx);
  }

  // ------------------------------------
  // State
  // ------------------------------------
  state = {
    ...this.props.newNode,
    allLabels: {},
    enumLabels: [],
    availableLabels: [],
    draggingGuid: null,
  };

  logicalTypeList = [
    {
      name: "Regular Expression Constraint",
      xmiType: "logical:RegularExpressionConstraint"
    },
    {
      name: "Real Range Constraint",
      xmiType: "logical:RealRangeConstraint"
    },
    {
      name: "Integer Range Constraint",
      xmiType: "logical:IntegerRangeConstraint"
    },
    {
      name: "Enumeration Constraint",
      xmiType: "logical:EnumerationConstraint"
    },
  ]

  platformTypeList = [
    {
      name: "Regular Expression Constraint",
      xmiType: "platform:RegularExpressionConstraint"
    },
    {
      name: "Real Range Constraint",
      xmiType: "platform:RealRangeConstraint"
    },
    {
      name: "Integer Range Constraint",
      xmiType: "platform:IntegerRangeConstraint"
    },
  ]

  // "required" checks for empty field as the input changes (user is typing)
  // "errorRef" checks for empty field after pressing the save button
  requiredFields = {
    name: {
      required: true,
      checkFirstChar: true,
      checkAllChars: true,
      errorRef: React.createRef(),
    },
    expression: {
      required: true,
      errorRef: React.createRef(),
    },
    lowerBound: {
      required: true,
      errorRef: React.createRef(),
    },
    upperBound: {
      required: true,
      errorRef: React.createRef(),
    }
  }

  // ------------------------------------
  // Life Cycle Methods
  // ------------------------------------
  componentDidMount() {
    this.initNodeState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.node !== this.props.node) {
      this.initNodeState();
    }
  }

  // ------------------------------------
  // Initial Setup
  // ------------------------------------
  initNodeState = () => {
    const { node } = this.props;

    const isLogical = this.props.isLogical || (typeof node.xmiType === 'string' && node.xmiType.split(":")[0] === "logical");
    const isEnumerated = node.xmiType === "logical:EnumerationConstraint";
    
    let xmiType = node.xmiType || "platform:RegularExpressionConstraint";

    // change xmiType because it defaults to platformType
    if (isLogical && isPhenomGuid(node.guid)) {
      xmiType = "logical:RegularExpressionConstraint";
      if (isEnumerated) xmiType = "logical:EnumerationConstraint";
    }

    return this.setState({
      ...node,
      xmiType,
      isLogical,
      isEnumerated,
      lowerBound: this.isInfinity(node.lowerBound) ? "Infinity" : node.lowerBound,
      upperBound: this.isInfinity(node.upperBound) ? "Infinity" : node.upperBound,
      lowerBoundInclusive: this.convertInclusiveStringToBoolean(node.lowerBoundInclusive),
      upperBoundInclusive: this.convertInclusiveStringToBoolean(node.upperBoundInclusive),
    }, () => {
      this.initAllLabels();
    })
  }

  initAllLabels = () => {
    const { isLogical, isEnumerated, parent } = this.state;
    const { parentValueType } = this.props;

    if (!isLogical || !isEnumerated || !parent) {
      return;
    }

    window["cachedRequest"]("newVTUS").then(data => {
      const valueTypeUnit = data.value_type_units[parent];    // <- can't use this when creating a new VTU
      const valueType = data.value_types[parentValueType];    // <- need to use this when creating a new VTU
      const vtLabels = valueType?.children || valueTypeUnit?.valueType?.children || [];

      this.setState({ allLabels: deGuidify(vtLabels) }, () => {
        this.initEnumLabels();
      })
    })
  }

  initEnumLabels = () => {
    const { isLogical, isEnumerated, allLabels, parent, allowedValue } = this.state;

    if (!isLogical || !isEnumerated || !parent) {
      return;
    }

    let enumLabels = [];
    const labelGuids = typeof allowedValue === 'string' ? allowedValue.split(" ") :
                       Array.isArray(allowedValue) ? allowedValue : [];
          labelGuids.forEach(labelGuid => allLabels[labelGuid] && enumLabels.push(allLabels[labelGuid]));

    const labelSetGuids = new Set(labelGuids);
    let availableLabels = Object.values(allLabels).filter(label => !labelSetGuids.has(label.guid));
        availableLabels.unshift({ guid: "", name: "--select one--", xmiType: "none"});
        availableLabels = availableLabels.sort((n1, n2) => n1.name.localeCompare(n2.name));

    this.setState({ enumLabels, availableLabels });
  }

  // ------------------------------------
  // Getters
  // ------------------------------------
  // the backend sends boolean values as "true" or "false" (string values)
  convertInclusiveStringToBoolean = (str) => {
    return str === "true";
  }

  isInfinity = (val) => {
    return ["+0", "-0", "Infinity", "-Infinity", "+Infinity"].includes(val);
  }

  // ------------------------------------
  // Setters
  // ------------------------------------
  updateDoubleValue = (key, value) => {
    const regex = new RegExp(/[-+]?\d+\.?\d*|Infinity/)
    const res = value.match(regex);
    if (!res) return;
    this.setState({ [key]: res[0] });
  }

  addLabel = (e) => {
    let enumLabels = [ ...this.state.enumLabels ];
    let availableLabels = [ ...this.state.availableLabels ];
    let removeIdx = availableLabels.findIndex(label => label.guid === e.target.value);

    if (removeIdx > -1) {
      const label = availableLabels[removeIdx];
      enumLabels.push(label);
      availableLabels.splice(removeIdx, 1);
    }

    this.setState({ enumLabels, availableLabels });
  }

  removeLabel = (cellProps) => {
    let enumLabels = [ ...this.state.enumLabels ];
    let availableLabels = [ ...this.state.availableLabels ];
    let removeIdx = enumLabels.findIndex(label => label.guid === cellProps.dataItem.guid);

    if (removeIdx > -1) {
      enumLabels.splice(removeIdx, 1);
      availableLabels.push(cellProps.dataItem);
    }

    availableLabels = availableLabels.sort((n1, n2) => n1.name.localeCompare(n2.name));
    this.setState({ enumLabels, availableLabels });
  }

  reorder = (label) => {
    if (this.state.draggingGuid === label.guid) return;
    let prevIndex = this.state.enumLabels.findIndex(ele => ele.guid === this.state.draggingGuid);
    let nextIndex = this.state.enumLabels.findIndex(ele => ele.guid === label.guid);

    if (prevIndex < 0 || nextIndex < 0) return;

    const splicedLabeled = this.state.enumLabels[prevIndex];
    this.state.enumLabels.splice(prevIndex, 1);
    this.state.enumLabels.splice(nextIndex, 0, splicedLabeled);
    this.setState({ draggingGuid: this.state.draggingGuid })
  }

  // ------------------------------------
  // Called by parent component
  // ------------------------------------
  validateNode = () => {
    return validateNodeFields(this.requiredFields, this.state);
  }

  generateNode = () => {
    const data = {
      guid: this.state.guid,
      name: this.state.name,
      xmiType: this.state.xmiType,
      description: this.state.description,
      parent: this.state.parent || undefined,
      expression: this.state.expression,
      upperBound: this.state.upperBound,
      lowerBound: this.state.lowerBound,
      upperBoundInclusive: this.state.upperBoundInclusive,
      lowerBoundInclusive: this.state.lowerBoundInclusive,
      allowedValue: this.state.enumLabels.map(label => label.guid),
      subModelId: this.state.subModelId,
    }

    const convertBound = (value) => {
      value = value.replace(/\s+/g, "");
      const calculation = value.match(/([+-]?\d+(e\d+)?[+\-*/^%])*([+-]?\d+(e\d+)?)/);
      if (calculation && calculation[0] === value) {
          return new Function(`return ${calculation[0]};`)();
      }
      return Number(value);
    };

    if (this.state.xmiType === "platform:RealRangeConstraint" ||
        this.state.xmiType === "logical:RealRangeConstraint") {
        data.lowerBound = convertBound(data.lowerBound);
        data.upperBound = convertBound(data.upperBound);
    }

    if (this.state.xmiType === "platform:IntegerRangeConstraint" ||
        this.state.xmiType === "logical:IntegerRangeConstraint") {
        data.lowerBound = Number(data.lowerBound);
        data.upperBound = Number(data.upperBound);
    }

    return data;
  }


  // ------------------------------------
  // Render methods
  // ------------------------------------
  renderExpressionSection() {
    if (splitXmiType(this.state.xmiType) !== "RegularExpressionConstraint") return null;
    const { editable } = this.props;
    const original = this.props.node;
    const phenomId = this.phenomId;

    return <PhenomInput label="Expression"
                        value={this.state.expression}
                        originalValue={original["expression"]}
                        disabled={!editable}
                        id={phenomId.gen("", "expression")}
                        onChange={(e) => this.setState({ expression: e.target.value })}
                        config={this.requiredFields["expression"]} />
  }

  renderRealRangeSection() {
    if (splitXmiType(this.state.xmiType) !== "RealRangeConstraint") return null;
    const { lowerBound, upperBound, lowerBoundInclusive, upperBoundInclusive } = this.state;
    const { editable } = this.props;
    const original = this.props.node;
    const phenomId = this.phenomId;

    return <div className="p-row">
              <div className="p-col p-col-6">
                <div>
                  <PhenomLabel text="Lower Bound" />
                  <div className="p-row">
                    <PhenomToggle checked={lowerBoundInclusive}
                                  data={["Exclusive", "Inclusive"]}
                                  disabled={!editable}
                                  id={phenomId.gen("", "lowerBoundInclusive")}
                                  onChange={(e) => this.setState({ lowerBoundInclusive: e.target.checked })} />
                    <PhenomInput value={lowerBound}
                                  originalValue={original["lowerBound"]}
                                  disabled={!editable}
                                  id={phenomId.gen("", "lowerBound")}
                                  onChange={(e) => this.updateDoubleValue("lowerBound", e.target.value)}
                                  config={this.requiredFields["lowerBound"]}
                                  containerProps={{
                                    style: {
                                      flex: 1,
                                    }
                                  }}>
                      <Button iconClass="fas fa-infinity"
                              disabled={!editable}
                              onClick={() => this.setState({ lowerBound: "Infinity" })} />
                    </PhenomInput>
                  </div>
                </div>
              </div>
              <div className="p-col p-col-6">
                <div>
                  <PhenomLabel text="Upper Bound" />
                  <div className="p-row">
                    <PhenomToggle checked={upperBoundInclusive}
                                  data={["Exclusive", "Inclusive"]}
                                  disabled={!editable}
                                  id={phenomId.gen("", "upperBoundInclusive")}
                                  onChange={(e) => this.setState({ upperBoundInclusive: e.target.checked })} />
                    <PhenomInput value={upperBound}
                                originalValue={original["upperBound"]}
                                disabled={!editable}
                                id={phenomId.gen("", "upperBound")}
                                onChange={(e) => this.updateDoubleValue("upperBound", e.target.value)}
                                config={this.requiredFields["upperBound"]}
                                containerProps={{
                                  style: {
                                    flex: 1,
                                  }
                                }}>
                      <Button iconClass="fas fa-infinity" 
                              disabled={!editable}
                              onClick={() => this.setState({ upperBound: "Infinity" })} />
                    </PhenomInput>
                  </div>
                </div>
              </div>
            </div>
  }


  renderIntegerRangeSection() {
    if (splitXmiType(this.state.xmiType) !== "IntegerRangeConstraint") return null;
    const { editable } = this.props;
    const original = this.props.node;
    const phenomId = this.phenomId;

    return <div className="p-row">
              <div className="p-col p-col-6">
                <PhenomInput label="Lower Bound"
                              value={this.state.lowerBound}
                              originalValue={original["lowerBound"]}
                              type="number"
                              disabled={!editable}
                              id={phenomId.gen("", "lowerBound")}
                              onChange={(e) => this.setState({ lowerBound: e.target.value })}
                              config={this.requiredFields["lowerBound"]}  />
              </div>
              <div className="p-col p-col-6">
                  <PhenomInput label="Upper Bound"
                              value={this.state.upperBound}
                              originalValue={original["upperBound"]}
                              type="number"
                              disabled={!editable}
                              id={phenomId.gen("", "upperBound")}
                              onChange={(e) => this.setState({ upperBound: e.target.value })}
                              config={this.requiredFields["upperBound"]} />
              </div>
            </div>
  }

  renderAllowedValueSection = () => {
    const { isEnumerated, enumLabels, availableLabels } = this.state;
    const { editable } = this.props;

    if (!isEnumerated) return null;

    return <div>
      <PhenomLabel text="Allowed Values" />

      <Grid data={enumLabels}
            rowRender={this.renderRowLabel}>
        <GridColumn title='Name' />
        <GridColumn title='Description' />
        <GridColumn title='Remove' width="75px" />
        
      </Grid>
      <div style={{ display: "flex", alignItems: "center", gap: 10, paddingTop: 10, fontSize: 12 }}>
        <label>Add label:</label>
        <PhenomSelect value=""
                      data={availableLabels}
                      dataItemKey="guid"
                      dataDisabled={[""]}
                      disabled={!editable}
                      onChange={this.addLabel}
                      containerProps={{
                        style: { maxWidth: 400 }
                      }} />
      </div>
    </div>
  }

  renderRowLabel = (_, cellProps) => {
    return <tr draggable
                onDragStart={(e) => {
                  this.setState({ draggingGuid: cellProps.dataItem.guid })
                  e.dataTransfer.setData("dragging", "");
                }}
                onDragOver={() => {
                  this.reorder(cellProps.dataItem);
                }}>
      <td>{ cellProps.dataItem.name }</td>
      <td>{ cellProps.dataItem.description }</td>
      { this.renderRemoveLabelCell(cellProps) }
    </tr>
  }

  renderRemoveLabelCell = (cellProps) => {
    const { editable } = this.props;

    return <td style={{textAlign:"center", padding:"0 5px"}}>
      <Button icon="close"
              look="bare"
              disabled={!editable}
              onClick={() => this.removeLabel(cellProps)} />
    </td>
  }

  render() {
    const { editable, lockXmiType } = this.props;
    const { isLogical, isEnumerated } = this.state;
    const phenomId = this.phenomId;
    const original = this.props.node;
    
    return <div className="edit-form">
            <div className="p-row">
              <div className="p-col">
                <PhenomInput label="Constraint"
                          value={this.state.name}
                          originalValue={original["name"]}
                          disabled={!editable}
                          autoFocus={true}
                          id={phenomId.gen("", "name")}
                          onChange={(e) => this.setState({ name: e.target.value })}
                          onClickResetIcon={() => this.setState({ name: original["name"] })}
                          config={this.requiredFields["name"]} />
                <div className="p-row p-with-flex">
                  <PhenomSelect label="Constraint Type"
                                value={this.state.xmiType}
                                data={isLogical ? this.logicalTypeList : this.platformTypeList}
                                dataItemKey="xmiType"
                                textField="name"
                                disabled={!editable || lockXmiType || isEnumerated}
                                id={phenomId.gen("", "type")}
                                onChange={(e) => this.setState({ xmiType: e.target.value })} />

                  {!isLogical &&
                  <PackageComboBox label="Package"
                                    xmiType="face:PlatformDataModel"
                                    placeholder="<Default>"
                                    nodeGuid={this.state.guid}
                                    selectedGuid={this.state.parent}
                                    originalGuid={original["parent"]}
                                    disabled={!editable}
                                    id={phenomId.gen("", "parent")}
                                    onChange={(parent) => this.setState({ parent: parent.guid })}
                                    onClickResetIcon={() => this.setState({ parent: original["parent"] })} /> }
                </div>

                <PhenomTextArea label="Description"
                                value={this.state.description}
                                originalValue={original["description"]}
                                disabled={!editable}
                                id={phenomId.gen("", "description")}
                                onChange={(e) => this.setState({ description: e.target.value })} />
              </div>

              <div className="edit-side">
                <NodeHistory2 guid={this.state.guid}
                              ref={ele => this.historyRef = ele} />
                <ChangeSetPicker label="Change Set"
                                 disabled={!editable} />
              </div>
            </div>

            { this.renderExpressionSection() }
            { this.renderRealRangeSection() }
            { this.renderIntegerRangeSection() }
            { this.renderAllowedValueSection() }
          </div>
  }
}


export const EditConstraintManager2 = withPageLayout(ConstraintManager2);
