import React, {Component} from "react";
import {Notifications} from "../notifications";
import {CadetInput, CadetTextArea, LineLabel, PackageComboBox} from "../../util/stateless";
import {NodeHistory2} from "../node-history";
import {NavLink} from "react-router-dom";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import {orderBy} from "@progress/kendo-data-query";
import $ from "jquery";
import {smmSaveNodes} from "../../../requests/sml-requests";
import PhenomId from "../../../requests/phenom-id";
import {withPageLayout} from "../node-layout";
import DeletionConfirm2 from "../../dialog/DeletionConfirm2";
import { createNodeUrl } from "../../../requests/type-to-path";
import { getActiveChangeSetId, receiveResponse } from "../../../requests/actionCreators";
import ChangeSetPicker from "../../widget/ChangeSetPicker";
import NavTree from "../../tree/NavTree";

export class ProcessingElementManager extends Component {
    constructor(props) {
        super(props);

        this.noticeRef = undefined;
        this.original = undefined;
        this.emptyValues = {
            guid: null,
            name: "",
            description: "",
            ipAddress: "",
            running: []
        };
    }

    state = {parent: null,};
    parentTypes = ["skayl:DeploymentModel"];

    componentDidMount() {
        this.loadData(this.props.match.params.guid);
        window.addEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    componentDidUpdate(prevProps) {
        const currGuid = this.props.match.params.guid;
        if (currGuid !== prevProps.match.params.guid) {
            this.loadData(currGuid);
        }
    }

    componentWillUnmount() {
      window.removeEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    loadData = (guid) => {
        if (guid !== "new") {
            $.ajax({
                url: "/index.php?r=/integration-processing-element/model-get-node",
                method: "get",
                data: {
                    guid: this.props.match.params.guid
                }
            }).then(res => {
                const response = JSON.parse(res);
                this.setStateFromResponse(response);
                this.original = response;
            }).fail((err) => {
                err.status === 500 && this.props.renderPageNotFound && this.props.renderPageNotFound();
            });
        } else {
            this.setStateFromResponse(this.emptyValues);
            this.original = this.emptyValues;
        }
    };

    setStateFromResponse = (resp) => {
        if (this.props.updateTemplateNode) {
            this.props.updateTemplateNode(resp);
        }

        this.setState({
            ...resp
        });
    };

    handleReset = () => {
        this.setStateFromResponse(this.original);
    };

    update = (e) => {
        const target = e.target.id.match("name|description|ipAddress")[0]
        this.setState({
            [target]: e.target.value
        });
    };

    handleSave = () => {
        const processingElement = {
            xmiType: "pedm:ProcessingElement",
            name: this.state.name,
            guid: this.state.guid,
            description: this.state.description,
            ipAddress: this.state.ipAddress,
            changeSetId: getActiveChangeSetId(),
            parent: this.state.parent || null,
        };

        return smmSaveNodes(processingElement).then((res) => {
            let resp = JSON.parse(res);

            if ("errors" in resp) {
                let errors = "";
                resp["errors"].forEach(x => errors = errors + x + " \n");
                return this.noticeRef.error(errors);
            }

            receiveResponse(resp);
            this.setStateFromResponse(resp);
            NavTree.addNodes([ resp ]);
            this.props.history.push( createNodeUrl(resp) );
        });
    };

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.name);
        return null;
    };

    mutateOriginalParentListener = (e) => {
      // invalid
      if (!this.state.guid) {
        return;
      }

      const leaf = NavTree.getLeafNode(this.state.guid);
      if (!leaf) {
        return;
      }

      const newParentGuid = leaf.getParentGuid();
      if (this.state.parent !== newParentGuid) {
        this.original["parent"] = newParentGuid;
      }
    }

    render() {
        const phenomId = new PhenomId("edit-processing-element");
        return (<div className="subview-wrapper" style={{position: "relative"}}>
            <Notifications ref={el => this.noticeRef = el} />
            {/* <EditTopButtons saveBtn={this.save} deleteBtn={this.delete} resetBtn={this.reset}
                expired={this.props.expired} canEdit={this.state.editable} /> */}
            <div className="flex-h" id={phenomId.gen("details","wrapper")}>
                <div className="flex-v" style={{flexGrow: 1}}>
                    <LineLabel text="Processing Element" idCtx={phenomId.gen("details","name")}/>
                    <CadetInput text={this.state.name} idCtx={phenomId.gen("details","name")} onChange={this.update}
                        style={{marginBottom: 0}} />
                    <LineLabel text="Description" style={{marginTop: 15}} idCtx={phenomId.gen("details","description")}/>
                    <CadetTextArea
                        text={this.state.description}
                        title={this.state.description}
                        idCtx={phenomId.gen("details","description")}
                        onChange={this.update}
                        style={{marginBottom: 0}}
                    />
                    <div style={{marginTop: 15}}>
                        <PackageComboBox id={phenomId.genPageId("parent")}
                                          label="Package"
                                          xmiType={this.parentTypes}
                                          placeholder="<Default>"
                                          nodeGuid={this.state.guid}
                                          selectedGuid={this.state.parent}
                                          disabled={!this.props.canEdit}
                                          onChange={(parent) => this.setState({ parent: parent.guid })}
                                          onClickCancelIcon={() => this.setState({ parent: undefined })} />
                    </div>
                    <LineLabel text="IP Address" style={{marginTop: 15}} idCtx={phenomId.gen("details","ipAddress")}/>
                    <CadetInput text={this.state.ipAddress} idCtx={phenomId.gen("details","ipAddress")} onChange={this.update}
                        style={{marginBottom: 0}} />
                    <LineLabel text="Running" style={{margin: "15px 0"}} idCtx={phenomId.gen("running","")}/>
                    <Grid
                        id={phenomId.gen("running","grid")}
                        data={orderBy(this.state["running"], this.state["runningSort"] || [])}
                        sortable
                        sort={this.state["runningSort"]}
                        className="editorTable"
                        resizable
                    >
                        <GridNoRecords>
                            No Data Is Available For This Table
                            </GridNoRecords>
                        <Column
                            title="NAME"
                            field="name"
                            cell={(props) => {
                                const {guid, name} = props.dataItem;
                                return (
                                    <td><NavLink className="cadet-anchor normal-anchor" id={phenomId.gen(["running",`${props.columnIndex}`],"link")}
                                        to={`/edit/details/main_program/${guid}`}>{name}</NavLink>
                                    </td>
                                );
                            }}
                        />
                        <Column title="DESCRIPTION" field="description" />
                    </Grid>
                </div>
                <div className="edit-side-bar">
                    <NodeHistory2 ref={ele => this.historyRef = ele} guid={this.state.guid} id={phenomId.genPageId()}/>
                    <ChangeSetPicker id={phenomId.genPageId()}
                                     label="Change Set" />
                </div>
            </div>
        </div>);
    }
}


export const EditProcessingElementManager = withPageLayout(ProcessingElementManager)
