import {CadetInput, ColorCollapsable, SkoogleResult} from "../util/stateless";
import React from "react";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import $ from "jquery";
import PhenomId from "../../requests/phenom-id";
import { cloneDeep } from "lodash";
import { stopBubbleUp } from "../util/util";

export class SkoogleBar extends React.Component {
    constructor(props) {
        super(props);
        this.defaultState = {
            previousQuery: "",
            query: "",
            results: [],
            showResultModal: false,
            resultsLoading: false,
            failedResponse: false,
            collections: {}
        };

        this.state = cloneDeep(this.defaultState);
    
        this.phenomId = new PhenomId("skoogle-bar",this.props.idCtx);
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeProjectId } = this.props;

        // reset state on project switch using activeProjectId prop
        if (prevProps.activeProjectId !== activeProjectId) {
            this.resetSkoogleBar();
        }
    }

    resetSkoogleBar = () => {
        this.setState({...cloneDeep(this.defaultState)});
    }

    submitQuery = e => {
        if (e) e.preventDefault();
        this.setState({ resultsLoading: true });

        if (this.state.query.trim() === this.state.previousQuery.trim()) {
            this.setState({resultsLoading: false});
            return;
        }

        this.setState({
            previousQuery: this.state.query
        });

        if (this.state.query.trim() === "") {
            this.setState({
                resultsLoading: false
            });
            return;
        }

        const execQuery = this.state.query;

        $.ajax({
            method: "get",
            url: "/index.php?r=/detail/skoogle-query",
            data: {query: execQuery}
        }).then((res) => {
            if (this.state.query.trim() !== execQuery) return;

            const response = JSON.parse(res);

            const collections = {};
            response.results.forEach(result => {
                let {xmiType} = result;
                xmiType = xmiType.split(":")[1];

                let newType;

                switch (xmiType) {
                    case "ConceptualDataModel":
                    case "LogicalDataModel":
                    case "PlatformDataModel":
                    case "UoPModel":
                    case "MessageDataModel":
                    case "DeploymentModel":
                        newType = "Package";
                        if (!collections[newType]) collections[newType] = [];
                        collections[newType].push(result);
                        break;
                    case "Composition":
                        newType = "EntityAttribute";
                        if (!collections[newType]) collections[newType] = [];
                        collections[newType].push(result);
                        break;
                    case "CharacteristicProjection":
                        newType = "ViewField";
                        if (!collections[newType]) collections[newType] = [];
                        collections[newType].push(result);
                        break;
                    case "Type":
                        newType = "Message";
                        if (!collections[newType]) collections[newType] = [];
                        collections[newType].push(result);
                        break;
                    case "AssociatedEntity":
                        newType = "Participant"
                        if (!collections[newType]) collections[newType] = [];
                        collections[newType].push(result);
                        break
                    default:
                        newType = xmiType;
                        if (!collections[newType]) collections[newType] = [];
                        collections[newType].push(result);
                        break;
                }
            });

            this.setState({
                previousQuery: execQuery,
                results: response.results,
                showResultModal: true,
                resultsLoading: false,
                failedResponse: false,
                collections
            });
        }).catch(err => {
            this.setState({
                resultsLoading: false,
                failedResponse: true
            });
        });
    };

    generateResultContent(header) {
        const phenomId = this.phenomId;
        const contentArr = this.state.collections[header];
        return <div id={header + "ResultList"}>
            {contentArr.map((result, idx) => {
                return (<SkoogleResult node={result} closeModal={this.closeResultModal} key={idx} idCtx={phenomId.gen("")} order={idx}/>);
            })}
        </div>;
    }

    closeResultModal = e => {
        if (e.target === e.currentTarget) {
            this.setState({
                showResultModal: false,
                resultsLoading: false
            });
        }
    };

    collapsableList = () => {
      const phenomId = this.phenomId;
      let collapsibleList = [];
      const colors = ["#436b99", "#4874a5", "#4e7db2"];
        if (this.state.results) {
          collapsibleList = Object.keys(this.state.collections).map((header, idx) => {
            const colorIdx = idx % colors.length;
            const formattedHeader = header.replace(/[A-Z][a-z]/g, match => ` ${match}`).trim();
            return (<ColorCollapsable
                idCtx={phenomId.gen(["results",idx],"")}
                content={this.generateResultContent(header,idx)}
                color={colors[colorIdx]}
                default={true}
                heading={formattedHeader}
                contentId={header + "ResultList"}
                vMargin={0}
                key={idx}/>);
            })
        }
    return collapsibleList;
    }

    renderResults() {
      const { resultsLoading, showResultModal, failedResponse, results, query, previousQuery } = this.state;

      if (resultsLoading) {
        return <img id={this.phenomId.gen("","loading-icon")}
                    style={{ display: "block", height: "140px" }}  src={loadingIcon}/>
      }

      if (showResultModal && results.length) {
        return this.collapsableList();
      }

      if (failedResponse) {
        return <div style={{padding: 10, background: "white"}}>
          "Search timed out. Try making your search more specific or searching for something else."
        </div>
      }
      
      if (showResultModal && !!query.trim().length && !!previousQuery.length && !results.length) {
        return <div style={{padding: 10, background: "white"}}>
          "No results could be found for your search."
        </div>
      }
      
      if (showResultModal) {
        return <div style={{padding: 10, background: "white"}}>
          "Press enter to search."
        </div>
      }

      return null;
    }

    render() {
        const phenomId = this.phenomId;
        let collapsableList = [];
        collapsableList = this.collapsableList();

        return (
            <div id="skoogle-bar"
                 className={(this.state.showResultModal ? "expanded" : null)}>
                <form className="skoogle-form"
                    onSubmit={this.submitQuery}>
                    <CadetInput
                        disabled={this.props.disabled}
                        text={this.state.query}
                        onChange={e => {
                            this.setState({query: e.target.value});
                        }}
                        onKeyDown={stopBubbleUp}
                        onFocus={() => this.setState({showResultModal: true})}
                        // onBlur={() => this.setState({showResultModal: false})}
                        placeholder="enter search query"
                        idCtx={phenomId.gen("","search-bar")}
                    />
                </form>

                {this.state.showResultModal ?
                    <div className="skoogle-backdrop"
                         onClick={this.closeResultModal}
                         id={phenomId.gen("","close-results-div")}>
                    </div> : ""}
                

                <div id={phenomId.gen(["init","results"])} className="skoogle-result">
                      { this.renderResults() }
                </div>
            </div>
        );
    }
}
