import React from 'react'
import { BranchLeaf, ModelLeaf } from '../../../tree/leaf/ModelLeaf';
import { InputWithShadow, Label, PhenomInput, PhenomLabel, PhenomTextArea } from '../../../util/stateless'
import { cloneDeep } from 'lodash'
import ModelTree from './ModelTree';
import { Upload } from '@progress/kendo-react-upload';
import { Button } from '@progress/kendo-react-buttons';
import { ImportCheckList, QueryCheckList } from './ManageDetail';
import { createPhenomGuid } from '../../../util/util';
import { BasicConfirm } from '../../../dialog/BasicConfirm';
import $ from 'jquery';
import { receiveLogs, receiveResponse } from '../../../../requests/actionCreators';
import InterModelDependencies from '../Dependencies';
import NavTree from '../../../tree/NavTree';
import PhenomdId from '../../../../requests/phenom-id';
import { MANAGE_OPTIONS } from '../model_manage';
import { Checkbox } from '@progress/kendo-react-inputs';
import { BasicAlert } from '../../../dialog/BasicAlert';


export class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = cloneDeep(this.defaultState);
  }

  defaultState = {
    name: "",
    description: "",
    copyPerms: false,
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const {leaf, createPublishProject, manage_option} = this.props;

    if (prevProps.leaf !== leaf) {
      this.init();
    }

    // append published to leaf name on publish ctx btn click
    if (prevProps.createPublishProject !== createPublishProject && createPublishProject === true) {
      this.setState({
        name: leaf.getName() + "_published",
      })
    } else if (prevProps.manage_option !== manage_option && manage_option === MANAGE_OPTIONS.newCopyProject) {
      this.setState({
        name: leaf.getName() + "_copy",
      })
    }
  }

  init() {
    const { leaf } = this.props;
    if (!leaf) {
      return this.setState( cloneDeep(this.defaultState) );
    };

    this.setState({
      name: leaf.getName(),
      description: leaf.getDescription(),
      copyPerms: false,
    });
  }

  isEdited = () => {
    const { leaf } = this.props;

    return leaf.getName() !== this.state.name ||
           leaf.getDescription() !== this.state.description;
  }

  serialize = () => {
    return {
      name: this.state.name,
      description: this.state.description,
      copyPerms: this.state.copyPerms,
    }
  }

  render() {
    const { leaf, createPublishProject, manage_option } = this.props;
    const phenomId = new PhenomdId("project-create");
    const isCopyProject = manage_option === MANAGE_OPTIONS.newCopyProject;

    let title = "Project Name";
    if (createPublishProject) {
      title = "New Published Project";
    } else if (isCopyProject) {
      title = "Copy Project";
    }

    return <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
              <PhenomInput label={title}
                           value={this.state.name}
                           onChange={(e) => this.setState({ name: e.target.value })} 
                           id={phenomId.gen("","name")}/>
              <PhenomTextArea label="Description"
                              value={this.state.description}
                              // disabled={!leaf.isNewLeaf()}
                              onChange={(e) => this.setState({ description: e.target.value })}
                              id={phenomId.gen("","description")}/>

              {(isCopyProject || createPublishProject) &&
                <div>
                  <Checkbox label="Copy all permissions"
                            checked={this.state.copyPerms}
                            onChange={(e) => {
                              this.setState({ copyPerms: e.value })
                            }} />
                </div> }
          </div>
  }
}


/**
 * Shows family models
 *    - allows user to switch model version
 */
export class ProjectDetailModelFamily extends React.Component {
  constructor(props) {
    super(props);
    this.state = cloneDeep(this.defaultState);
  }
  defaultState = {
    families: {},
    
    newBlankModel: null,
    newExistingModel: null,
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leaf !== this.props.leaf) {
      this.init();
    }
  }

  init() {
    const { leaf } = this.props;
    if (!leaf) {
      return this.setState( cloneDeep(this.defaultState ));
    };

    const families = {}
    leaf.getModelLeaves().forEach(modelLeaf => families[modelLeaf.getId()] = { show: false, newId: "" });

    this.setState({
      ...cloneDeep( this.defaultState ),
      families,
    })
  }

  serialize = () => {
    let data = {};

    // Redeclare Submodels - create array if it was edited
    if (Object.values(this.state.families).some(fam => fam.newId)) {
      data.redeclare = Object.entries(this.state.families).map(([modelId, fam]) => fam.newId || modelId );
    }

    // Add new model - need name
    if (this.state.newBlankModel) {
      data.blankModelName = this.state.newBlankModel.name;
    }

    // Add existing model - need id
    if (this.state.newExistingModel) {
      data.additionId = this.state.newExistingModel.id;
    }

    return data;
  }


  isActiveProject = () => {
    const { leaf , activeProjectId } = this.props;
    return leaf.getId() === activeProjectId;
  }

  handleAddPlaceholders = () => {
    const { leaf } = this.props;

    const message = <div className="flex-v" style={{width: "85%"}}>
        <p style={{marginBottom: 15}}>
            Phenom can add a set of placeholder nodes to help you in your modeling efforts. These nodes will be most useful when you are not completely sure what observable or entity best represents the semantic you are trying to document or what measurement best represents the one used by a particular interface. In these instances, you will be able to use or project to a placeholder node, coming back later to revise your project.
        <br></br><br></br>
        You will be able to easily find parts of the project you still have to review by using the Health Check feature in the Generate menu to find all of the usages of placeholder nodes. Upon export, Phenom will remove from the exported artifact any placeholder nodes which are not being used by any other parts of the project.
        <br></br><br></br>
        Importing placeholders will create a new model which will be included in your project workspace. You will not be able to edit placeholder nodes, though you will be allowed to incorporate the generated model into other project workspaces.
        <br></br><br></br>
        For more information about placeholders, their usage in Phenom, and best-practice workflows, please review the related <a className="cadet-anchor" style={{fontSize: "100%"}}>knowledgebase article</a>.
        <br></br><br></br>
        NOTE: If your model already contains a placeholder model, repeating the process will generate placeholder content for any new observables for which that content is missing.
    </p>
    </div>

    BasicConfirm.show(message, () => {
      BasicAlert.show("Adding placeholders...", "One moment", false);

      // if this project is active then send undefined
      let requestProjectId;
      if (!this.isActiveProject()) {
        requestProjectId = leaf.getId();
      }

      return $.post({
        url: "/index.php?r=/referencing-model/add-placeholders",
        data: { projectId: requestProjectId }
      }).then(res => {
          BasicAlert.hide();
          if(res === "#true") {
              receiveLogs("Placeholders helper nodes added to project.");
              this.props.refreshTrees(null, leaf.getId());
              NavTree.reset();
            } else {
              const response = JSON.parse(res);
              receiveResponse(response);
          }
      }).catch(() => {
        BasicAlert.hide();
      })
    })
  }





  renderFamily(modelLeaf) {
    const id = modelLeaf.getId();
    const data = this.state.families[id];
    if (!data || !this.isActiveProject() || modelLeaf.getFamilyIds().length < 1) return null;

    const versions = modelLeaf.findVersionOptions(this.props.modelIndex);
    if (!versions.length) {
      return null;
    }

    if (data.show) {
      return <>
              <div>
                <select className="cadet-select-single box-shadow"
                        value={data.newId}
                        onChange={(e) => this.setState((prevState) => ({
                          families: {
                            ...prevState.families,
                            [id]: { ...data, newId: e.target.value }
                          }
                        }))}>
                  <option value="" disabled>--Select alternate version--</option>
                  {versions.map(ver => {
                    return <option key={ver.getId()}
                                   value={ver.getId()}>
                                    {ver.getName()}
                          </option>
                  })}
                </select>
              </div>
              <button className="btn-main"
                      onClick={() => this.setState((prevState) => ({
                        families: {
                          ...prevState.families,
                          [id]: { show: false, newId: "" }
                        }
                      }))}>Cancel</button>
      </>
    } else {
        return <button className="btn-main"
                       style={{ whiteSpace: "nowrap" }}
                       onClick={() => this.setState((prevState) => ({
                        families: {
                          ...prevState.families,
                          [id]: { show: true, newId: "" }
                        }
                       }))}>Change Version</button>
    }
  }


  renderAddOptions() {
    const projectLeaf = this.props.leaf;
    const createPublishProject = this.props.createPublishProject;
    const createCopyProject = this.props.manage_option === MANAGE_OPTIONS.newCopyProject;
    const { newBlankModel, newExistingModel } = this.state;

    if (createPublishProject || createCopyProject) {
      return null;
    }

    if (!this.isActiveProject()) {
      return <div style={{ display: "flex", gap: "0.5em" }}>
              <button className="btn-main"
                      onClick={this.handleAddPlaceholders}>
                        Add Placeholders
              </button>
            </div>
    }


    if (newBlankModel) {
      return <div style={{ display: "flex", gap: "0.5em" }}>
        <PhenomInput value={newBlankModel.name}
                     placeholder="new model name"
                     onChange={(e) => this.setState({ newBlankModel: { name: e.target.value } })} />
        <button className="btn-main"
                onClick={() => this.setState({ newBlankModel: null })}>
                  Cancel
        </button>
      </div>

    } else if (newExistingModel) {
      const existingModels = [];
      const relatedIds = new Set();

      projectLeaf.getModelLeaves().forEach(modelLeaf => {
        relatedIds.add(modelLeaf.getId());
        modelLeaf.getFamilyIds().forEach(id => relatedIds.add(id));
      })

      for (let modelId in this.props.modelIndex) {
        const modelLeaf = this.props.modelIndex[modelId];
        if (relatedIds.has(parseInt(modelId)) || modelLeaf.getFamilyIds().some(memberId => relatedIds.has(memberId))) {
          continue;
        }
        existingModels.push(modelLeaf);
      }

      return <div style={{ display: "flex", gap: "0.5em" }}>
                <div>
                  <select className="cadet-select-single box-shadow"
                          value={this.state.newExistingModel.id}
                          onChange={(e) => this.setState({ newExistingModel: { id: e.target.value } })}>

                    <option value="" disabled>--Select existing model--</option>
                    {existingModels.map(existModel => {
                      return <option key={existModel.getId()}
                                     value={existModel.getId()}>
                                      {existModel.getName()}
                            </option>
                    })}
                  </select>
                </div>

                <button className="btn-main"
                        onClick={() => this.setState({ newExistingModel: null })}>
                          Cancel
                </button>
             </div>

    } else {
      return <div style={{ display: "flex", gap: "0.5em" }}>
              <button className="btn-main"
                      onClick={() => this.setState({ newExistingModel: { id: "" } })}>
                        Add Existing Model
              </button>
              <button className="btn-main"
                      onClick={this.handleAddPlaceholders}>
                        Add Placeholders
              </button>
            </div>
    }
  }

  handleDependencies = () => {
    if (this.isActiveProject()) {
      InterModelDependencies.show(this.props.projectIndex[this.props.activeProjectId].data);
    }
  }



  render() {
    const { leaf } = this.props;
    if (!leaf || leaf.isNewLeaf()) return null;

    return <>
              {leaf.isPublished() &&
                <div>
                  <PhenomLabel text="Publication Date" />
                  <div className="model-leaves-wrapper">
                    {leaf.getPublishedDate()}
                  </div>
                </div>
              }
              <InterModelDependencies/>
              <div>
                <div className={"flex-h"} style={{justifyContent: "space-between"}}>
                  <PhenomLabel text="Models" style={{flex:"9"}}/>
                  {this.isActiveProject() && leaf.getModelLeaves().length > 1 &&
                    <button className="btn-main"
                            style={{flex:"1", margin:"5px"}}
                            onClick={() => this.handleDependencies()}>
                        DEPENDENCIES
                      </button>}
                </div>
                <div className="model-leaves-wrapper">
                  {leaf.getModelLeaves().map(modelLeaf => {
                    return <div className="model-leaf"
                                key={modelLeaf.getId()}>
                              {modelLeaf.getName()}
                              { !leaf.isPublished() && this.renderFamily(modelLeaf) }
                          </div>
                  })}

                </div>
                
                { !leaf.isPublished() && this.renderAddOptions() }
              </div>
           </>
  }

}


/**
 * Only for new Projects
 *    - when creating a new project user can upload/create a new model at the same time
 */
export class ProjectDetailModelSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = cloneDeep(this.defaultState);
    this.containerRef = new React.createRef();
  }
  
  modelIndex = {}
  branchIndex = {}
  defaultState = {
    newModels: [],
    selectedLeaves: new Set(),
  }

  componentDidMount() {
    this.init();
    window.addEventListener("resize", this.handleContainerResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leaf !== this.props.leaf ||
        prevProps.branchIndex !== this.props.branchIndex) {
      this.init();
      this.handleContainerResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleContainerResize);
  }

  init() {
    const { leaf } = this.props;
    if (!leaf) {
      this.branchIndex = {};
      this.modelIndex = {};
      return this.setState( cloneDeep(this.defaultState) );
    };

    this.cloneIndices();
  }

  serialize = () => {
    const selectedSubmodelIds = [];
    const blankModels = [];
    const newModels = [];
    const newModelFiles = [];

    // Models selected from tree
    this.state.selectedLeaves.forEach(modelLeaf => {
      selectedSubmodelIds.push(modelLeaf.getId().toString());
    })

    // Models created manually
    this.state.newModels.forEach(nm => {
      if (!nm.ref.current) return;
      const data = nm.ref.current.serialize();
      
      // ignore if name doesn't exist
      // ignore if ele is supposed to have a file but does not
      // if (!data.name || (nm.uploadFile && !data.file)) return;

      if (nm.uploadFile) {
        newModels.push({
          name: data.name,
          description: data.description,
          checks: data.checks,
          retained_types: data.retained_types,
        });
        newModelFiles.push(data.file);

      } else {
        blankModels.push(data);
      }
    })

    return {
      selectedSubmodelIds,
      blankModels,
      newModels,
      newModelFiles,
    }
  }

  /**
   * For dynamic height to work, the dom element requires a real number for height (a non-percentage number for height)
   *    - This looks at the parent's attribute to calculate the height
   *    - This triggers on window resize through event listeners
   */
  handleContainerResize = (e) => {
    if (!this.containerRef.current) return;

    const container = this.containerRef.current;
    const containerBox = container.getBoundingClientRect();
    const parentBox = container.parentElement.getBoundingClientRect();

    const newHeight = Math.max(parentBox.bottom - containerBox.top, 0);
    container.style.height = newHeight + "px";
  }

  // NOTE: THIS CAN BE REFACTORED
  //    -> available-sub-models is much faster now and can be used to retrieve a fresh tree index
  //    -> It used to take forever so I relied on cloning the existing tree index
  //
  // create a deep copy of branchLeaf and modelLeaf because it affects the other trees
  cloneIndices = () => {
    const branchIndex = {};
    const modelIndex = {};

    // set branch index
    for (let guid in this.props.branchIndex) {
      const data = this.props.branchIndex[guid].getData();
      branchIndex[guid] = new BranchLeaf(data);
    }

    // set parent-child relationships for branch leaves
    for (let guid in this.props.branchIndex) {
      const prevBranchLeaf = this.props.branchIndex[guid];
      const newBranchLeaf = branchIndex[guid];

      prevBranchLeaf.getChildrenLeaves().forEach(child => {
        const newChildBranchLeaf = branchIndex[child.getId()];
        if (!newChildBranchLeaf) return;
        newBranchLeaf.setChildLeaf(newChildBranchLeaf);
        newChildBranchLeaf.setParentLeaf(newBranchLeaf);
      })
    }

    // set branch-model relationships and set model index
    for (let guid in this.props.modelIndex) {
      const prevModelLeaf = this.props.modelIndex[guid];
      const prevBranchLeaf = prevModelLeaf?.getBranchLeaf();
      if (!prevModelLeaf || !prevBranchLeaf) continue;

      const newModelLeaf = new ModelLeaf(prevModelLeaf.getData());
      const newBranchLeaf = branchIndex[prevBranchLeaf.getId()];
      
      modelIndex[guid] = newModelLeaf;
      newModelLeaf.setBranchLeaf(newBranchLeaf);
      newBranchLeaf.setModelLeaf(newModelLeaf);
    }

    this.modelIndex = modelIndex;
    this.branchIndex = branchIndex;
    this.setState({ selectedLeaves: new Set() });
  }

  /**
   * When project creation errors out, this is used to reselect the model.
   * 
   * @param {number} modelId 
   * @returns void
   */
   selectModelById = (modelId) => {
    const modelLeaf = this.modelIndex[modelId];
    if (!modelLeaf) return;
    modelLeaf.setExpanded(true);
    this.selectLeaf(modelLeaf);
  }

  /**
   * Toggle selection
   * 
   * @param {ModelLeaf} leaf 
   */
  selectLeaf = (leaf) => {
    if (!leaf || !leaf.isSelectable()) return;
    const selectedLeaves = new Set(this.state.selectedLeaves);

    // remove leaf
    if (selectedLeaves.has(leaf)) {
      leaf.setSelected(false);        // i know this is redundant - it was a mistake :(
      selectedLeaves.delete(leaf);

      // remove the strikethrough text
      for (let famId of leaf.getFamilyIds()) {
        const modelLeaf = this.modelIndex[famId];
        if (!modelLeaf) continue;        
        modelLeaf.setSelectable(true);
      }

    } else {
      // add selected leaf
      leaf.setSelected(true);
      selectedLeaves.add(leaf);

      // show strikethrough text
      for (let famId of leaf.getFamilyIds()) {
        const modelLeaf = this.modelIndex[famId];
        if (!modelLeaf || modelLeaf === leaf) continue;        
        modelLeaf.setSelectable(false);
      }
    }

    this.setState({ selectedLeaves });
  }

  /**
   * When project creation errors out, this is used to remove "new models" based on the name
   * 
   * @param {string} name 
   */
  removeNewModelByName = (name) => {
    this.removeNewModel( this.state.newModels.findIndex((newModel) => newModel.ref.current.getName() === name) );
  }

  removeNewModel = (idx) => {
    // 0 is falsey
    if (typeof parseInt(idx) !== 'number' || idx < 0) return;

    this.setState((prevState) => {
      const newModels = [...prevState.newModels];
      newModels.splice(idx, 1);
      return { newModels };
    })
  }

  render() {
    const { leaf } = this.props;
    if (!leaf.isNewLeaf()) return null;

    return <div className="new-model-selection" ref={this.containerRef}>
              <div>
                <ModelTree id="model-selection" 
                          treeType="models"
                          title="Include existing model"
                          index={this.branchIndex}
                          useCheckbox={true}
                          onSelect={this.selectLeaf}
                          onSearch={this.searchLeafByName} />
              </div>

              <div>
                <h2>Include new model</h2>
                <div style={{ display: "flex", flexDirection: "column", gap: "1em", padding: 5, overflow: "auto" }}>
                    {this.state.newModels.map((newModel, idx) => {
                      return <NewModelData key={newModel.uid}
                                           id={`new-model-${idx}`}
                                           idx={idx + 1}
                                           uploadFile={newModel.uploadFile}
                                           onDelete={() => this.removeNewModel(idx)}
                                           ref={newModel.ref} />
                    })}

                    <div style={{ display: "flex", gap: 5 }}>
                      {/* <Button onClick={() => this.setState((prevState) => {
                                const newModels = [...prevState.newModels];
                                newModels.push({ uid: createPhenomGuid(), uploadFile: false, ref: React.createRef() });
                                return { newModels }
                              })}>
                        New empty model
                      </Button> */}
                      <Button onClick={() => this.setState((prevState) => {
                                const newModels = [...prevState.newModels];
                                newModels.push({ uid: createPhenomGuid(), uploadFile: true, ref: React.createRef() });
                                return { newModels }
                              })}>
                        Import FACE model
                      </Button>
                    </div>
                </div>
              </div>
            </div>
  }
}





class NewModelData extends React.Component {
  importCheckRef = React.createRef();
  queryCheckRef = React.createRef();

  state = {
    name: "",
    description: "",
    files: [],
  }

  serialize = () => {
    const importData = this.importCheckRef.current?.state;
    const queryData = this.queryCheckRef.current?.getSerializeData();
    const checks = JSON.stringify({...importData, ...queryData[0]});
    const retainedTypes = JSON.stringify([...queryData[1]]);

    return {
      name: this.state.name,
      description: this.state.description,
      file: this.state.files[0]?.getRawFile(),
      checks: checks,
      retained_types: retainedTypes,
    }
  }

  getName = () => {
    return this.state.name;
  }

  handleStatusChange = (e) => {
    e.newState[0].status = 1;
    this.setState({ files: e.newState });
  }

  render() {
    return <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <Label>{`#${this.props.idx}`}</Label>
                <Button icon="close" look="bare" onClick={this.props.onDelete} />
              </div>
              <InputWithShadow id={this.props.id}
                               value={this.state.name}
                               placeholder={`Name of model #${this.props.idx}`}
                               onChange={(e) => this.setState({ name: e.target.value })} />

              <InputWithShadow id={this.props.id}
                               value={this.state.description}
                               placeholder={`Description of model #${this.props.idx}`}
                               onChange={(e) => this.setState({ description: e.target.value })} />

              {!this.props.createPublishProject && this.props.uploadFile && <>
                <Upload id={`${this.props.id}-upload`}
                        multiple={false}
                        files={this.state.files}
                        defaultFiles={[]}
                        onStatusChange={this.handleStatusChange}
                        restrictions={{
                          allowedExtensions: [".face", ".skayl"],
                          maxFileSize: 209715200
                        }} />

                <ImportCheckList ref={this.importCheckRef} />
                <QueryCheckList ref={this.queryCheckRef} />
              </>
              
              
              }

            </div>
  }
}
