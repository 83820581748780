import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import React from 'react'
import { modelNodesOfType, smmSaveNodes } from '../../requests/sml-requests';
import { BasicAlert } from '../dialog/BasicAlert';
import SkratchpadManager from "../navigate/diagrams/manager/SkratchpadManager";
import { receiveErrors } from '../../requests/actionCreators';
import { PhenomComboBox, PhenomToggle } from '../util/stateless';
import { Checkbox } from '@progress/kendo-react-inputs';
import { connect } from 'react-redux';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';



class ScratchpadExporter extends React.Component {
  managerRef = React.createRef();
  diagrams = [];
  emptyPackage = { guid: "", name: "<None>", xmiType: "skayl:DiagramModel" }


  state = {
    nodes: [],
    packages: [],
    selectedPackage: null,
  }

  componentDidMount() {
    this.setPackages();

    modelNodesOfType("skayl:DiagramContext").then(res => {
      const response = JSON.parse(res);

      if ("error" in response || "errors" in response) {
          return receiveErrors("Something went wrong. Failed to fetch diagram list");
      }

      this.diagrams = response.nodes.filter(node => !!node.image);
      this.diagrams.forEach((node) => {
        node.isSelected = true;
      })

      this.filterDiagramList();
    })
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.nodesOfType !== prevProps.nodesOfType) {
      this.setPackages();
    }
  }

  setPackages = () => {
    this.setState({ packages: this.props.nodesOfType["skayl:DiagramModel"] || [] });
  }

  filterDiagramList = () => {
    const { selectedPackage } = this.state;
    const packageGuid = selectedPackage?.guid;
    if (!packageGuid) {
      return this.setState({ nodes: this.diagrams });
    }

    this.setState({
      nodes: this.diagrams.filter(node => node.parent === packageGuid)
    })
  }

  changePackage = (parent) => {
    this.setState({ selectedPackage: parent }, () => {
      this.filterDiagramList();
    })
  }

  // removes data:image/png;base64, from the dataURL string
  getBase64String(dataURL) {
    var idx = dataURL.indexOf('base64,') + 'base64,'.length;
    return dataURL.substring(idx);
  }

  downloadZipFile = () => {
    const zip = new JSZip();

    for (let node of this.state.nodes) {
      if (!node.isSelected) continue;
      zip.file(node.name + ".png", this.getBase64String(node.image), { base64: true });
    }

    zip.generateAsync({ type: "blob" })
       .then(blob => {
          saveAs(blob, "Diagrams.zip");
       })
  }

  // NOTE: this only takes a screenshot.
  //    -> it does not call skratchpad's save diagram method because we're not regenerating the json content
  //    -> at the time of writing this, the generate page is turning off skratchpad's model picker
  generateAllImages = async () => {
    if (!this.managerRef.current) return;

    for (let context of this.state.nodes) {
      if (!context || !context.isSelected) continue;

      BasicAlert.show(`Generating ${context.name}. This can take a few minutes.`, "One moment please", false);
      const activeTabId = this.managerRef.current.getActiveTabId();
      await this.managerRef.current.loadContext(context.guid);

      const imgData = await this.managerRef.current.createScreenshotWithMode(0, context.mode);
      context.image = imgData;
      smmSaveNodes({ node: context });

      this.managerRef.current.removeTab(activeTabId);
    }

    BasicAlert.hide();
    this.forceUpdate();
  }

  // NOTE: this only takes a screenshot.
  //    -> it does not call skratchpad's save diagram method because we're not regenerating the json content
  //    -> at the time of writing this, the generate page is turning off skratchpad's model picker
  generateAndPreview = async (context) => {
    if (!context || !this.managerRef.current) return;

    BasicAlert.show(`Generating ${context.name}. This can take a few minutes. Preview will appear in a new browser tab.`, "One moment please", false);
    const activeTabId = this.managerRef.current.getActiveTabId();
    await this.managerRef.current.loadContext(context.guid)

    // note: window.open need to be triggered by the click action or else popup blockers can prevent it
    const win = window.open('', '_blank');
    if (win) {
      win.document.write('Loading image.  One moment please...')
      win.document.close();
    }

    const imgData = await this.managerRef.current.createScreenshotWithMode(0, context.mode)
    context.image = imgData;

    this.managerRef.current.removeTab(activeTabId);
    BasicAlert.hide();
    this.forceUpdate();

    smmSaveNodes({ node: context });

    const imageDom = new Image();
          imageDom.src = imgData;
    
    if (win === null) {
      return BasicAlert.show("Failed to open a new browser tab. You might have a popup blocker enabled.", "Preview", true);
    }

    win.document.write(`<html><head><title>${context.name}</title></head>
                              <body>${imageDom.outerHTML}</body></html>`);
    win.document.close();
  }

  previewImage = (node) => {
    if (!node) return;

    // note: window.open need to be triggered by the click action or else popup blockers can prevent it
    const win = window.open('', '_blank');
    if (win === null) {
      return BasicAlert.show("Failed to open a new browser tab. You might have a popup blocker enabled.", "Preview", true);
    }

    const imageDom = new Image();
          imageDom.src = node.image;
    win.document.write(`<html><head><title>${node.name}</title></head>
                              <body>${imageDom.outerHTML}</body></html>`)
  }

  downloadSingleImage = (node) => {
    if (!node) return;
    saveAs(node.image, `${node.name || "my-diagram"}.png`);
  }

  headerCellCheckbox = (props) => {
    const { nodes } = this.state;

    return <a style={{ display: "block", textAlign: "center" }}>
      <Checkbox label=''
                checked={nodes.every(node => node.isSelected)} 
                onClick={(e) => {
                  nodes.forEach(node => node.isSelected = e.target.checked);
                  this.forceUpdate();
                }} />
    </a>
  }

  cellCheckbox = (props) => {
    const { dataItem } = props;

    return <td style={{ textAlign: "center" }}>
      <Checkbox label=''
                checked={dataItem.isSelected} 
                onClick={(e) => {
                  dataItem.isSelected = e.target.checked;
                  this.forceUpdate();
                }} />
    </td>
  }

  cellPreview = (props) => {
    const { dataItem } = props;

    return <td style={{ textAlign: "center" }}>
      <Button icon='preview' onClick={() => this.previewImage(dataItem)} />
    </td>
  }

  cellGenerate = (props) => {
    const { dataItem } = props;

    return <td style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 5 }}>
      <Button icon='image-edit' onClick={() => this.generateAndPreview(dataItem)} />
      <PhenomToggle data={["projection", "modeling"]}
                    checked={dataItem.mode === "m"}
                    onChange={(e) => {
                      dataItem.mode = e.target.checked ? "m" : "p";
                      this.forceUpdate();
                    }} />
    </td>
  }

  cellDownload = (props) => {
    const { dataItem } = props;

    return <td style={{ textAlign: "center" }}>
      <Button icon='download' onClick={() => this.downloadSingleImage(dataItem)} />
    </td>
  }

  render() {
    const { nodes, selectedPackage, packages } = this.state;

    return <div className='flex-container'>
            <div className='p-row'>
              <div className='p-col p-col-6 p-col-fixed'>
                <PhenomComboBox label="Filter by package"
                                data={packages} 
                                value={selectedPackage}
                                dataItemKey="guid"
                                onChange={this.changePackage}
                                onClickCancelIcon={() => this.changePackage(null)} />
              </div>
            </div>

            <div className='p-row'>
              <Button icon='image-edit' onClick={this.generateAllImages}>Regenerate selected</Button>
              <Button icon='download' onClick={this.downloadZipFile}>Download selected</Button>
            </div>

            <div style={{ overflow: "hidden" }}>
              <Grid data={nodes}>
                <GridColumn title="" width="60px" cell={this.cellCheckbox} headerCell={this.headerCellCheckbox} />
                <GridColumn title="NAME" field="name" />
                <GridColumn title="PREVIEW" width="80px"  cell={this.cellPreview} />
                <GridColumn title="REGENERATE" width="160px" cell={this.cellGenerate} />
                <GridColumn title="DOWNLOAD" width="95px"  cell={this.cellDownload} />
              </Grid>

              <div style={{ position: "relative", flex: 1 }}>
                <SkratchpadManager ref={this.managerRef} doNotRenderPrompt={true} />
                <div style={{ position: "absolute", inset: "0 0 0 0", background: "white", zIndex: 5 }} />
              </div>
            </div>
    </div>
  }
}


const msp = (state) => ({
  nodesOfType: state.navTree.nodesOfType,
})

export default connect(msp)(ScratchpadExporter);