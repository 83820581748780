import React from "react";
import {Upload} from "@progress/kendo-react-upload";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {LineLabel, PhenomLabel} from "../../util/stateless";
import {BasicAlert} from "../../dialog/BasicAlert";
import {Notifications} from "../../edit/notifications";
import ChangeSetPicker from "../../widget/ChangeSetPicker";
import PhenomLoadButton from "../../widget/LoaderButton";
import $ from "jquery";
import PhenomId from "../../../requests/phenom-id";
import { getActiveChangeSetId } from "../../../requests/actionCreators";
import NavTree from "../../tree/NavTree";


class ImportCSV extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validImportTypes: [
                {val: "pkg-view-char", txt: "View-Characteristic"},
                {val: "view-char-update", txt: "View-Characteristic UPDATE"},
                {val: "enum-lit", txt: "Enum-Literal"},
                {val: "dsdm-element", txt: "Model-Content"},
                {val: "dsdm-element-update", txt: "Model-Content UPDATE"},
                {val: "constraint", txt: "Constraints"},
                {val: "message", txt: "Message Data Model"},
                {val: "platform-type", txt: "Platform Types"}
            ],
            importType: {val: "pkg-view-char", txt: "View-Characteristic"},
            files: [],
        };

        this.noticeRef = undefined;
        this.uploadEl = React.createRef();
    }

    handleTypeChange = event => {
        this.setState({
            importType: event.target.value
        });
    };

    fileStateChange = event => {
        const modifiedState = event.newState;
        modifiedState[0].status = 1;

        this.setState({
            files: modifiedState
        });
    };

    uploadFile = () => {
        if (this.uploadEl.current.state.files[0].status !== 1) {
            this.noticeRef.error("Please select a valid type of file to import.");
            return;
        }

        const data = new FormData();
        data.append("csvFile", this.state.files[0] ? this.state.files[0].getRawFile() : null);
        data.append("formData", JSON.stringify({
            type: this.state.importType.val,
            changeSetId: getActiveChangeSetId()
        }));

        return $.ajax({
            url: "/index.php?r=/import/import-file",
            method: "post",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                "X-CSRF-TOKEN": window["_csrf"]
            }
        }).then((resp) => {
            const res = JSON.parse(resp);

            if (res.errors) {
                const {importErrors} = res.errors;
                BasicAlert.show(importErrors, "Import errors");
            } else {
                BasicAlert.show("Import successfully completed.");
                NavTree.reset();
            }
        });
    };

    render() {
        const phenomId = new PhenomId("import-csv",this.props.idCtx);
        return (
            <div className="phenom-content-scrollable" style={{padding: "15px"}}>
                <div className="import-options">
                    <Notifications ref={ele => this.noticeRef = ele} />
                    <div className="flex-h">
                        <div className="flex-v" style={{flexGrow: 1, marginRight: 30}}>
                            <LineLabel text="Import Type" style={{marginBottom: 15}} idCtx={phenomId.gen("","import-type-label")}/>
                            <DropDownList
                                id={phenomId.gen("","import-type-drop-down-list")}
                                data={this.state.validImportTypes}
                                textField="txt"
                                dataItemKey="val"
                                style={{width: "100%"}}
                                value={this.state.importType}
                                onChange={this.handleTypeChange} />
                        </div>
                    </div>
                    <LineLabel text="Import File" style={{marginBottom: 15, marginTop: 15}} idCtx={phenomId.gen("","file-label")}/>
                    <Upload
                        idCtx={phenomId.gen("","file-upload")}
                        onStatusChange={this.fileStateChange}
                        multiple={false}
                        files={this.state.files}
                        defaultFiles={[]}
                        restrictions={{
                            allowedExtensions: [".face", ".csv"],
                            maxFileSize: 5000000
                        }}
                        ref={this.uploadEl}
                    />
                    <div className="flex-h" style={{marginTop: 25, gap: 20 }}>
                        <PhenomLoadButton
                            idCtx={phenomId.gen("")}
                            onClick={this.uploadFile}
                            text="IMPORT"/>
                        <div>
                          <PhenomLabel text="Change Set" style={{ textTransform: "none" }} />
                          <ChangeSetPicker id={phenomId.gen("")} />
                        </div>
                        <div style={{flex:1}}>
                            <PhenomLabel text="Instructions" style={{ textTransform: "none" }} />
                            <span className="phenom-span-text">
                                Please browse for a valid file that matches the selected Import Type before importing. Additonally, select which model should be the destination for the import.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportCSV;
