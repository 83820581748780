import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
import styled from "@emotion/styled";

import { getNodesOfType } from "../../../../requests/sml-requests";
import ModelPicker from "../../../edit/widgets/ModelPicker";
import { BasicAlert } from "../../../dialog/BasicAlert";
import blankDiagram from "../../../../images/blank_diagram.png";
import loadingIcon from "../../../../images/Palette Ring-1s-200px.gif";
import { Modal2 } from "../../../util/Modal";
import { cloneDeep } from "lodash";
import {DialogActionsBar} from "@progress/kendo-react-dialogs";
import PhenomId from "../../../../requests/phenom-id";
import SavePrompt from "./prompt_components/SavePrompt";
import SkratchpadSavePrompt from "./prompt_components/SkratchpadSavePrompt";
import { BasicConfirm } from "../../../dialog/BasicConfirm";



const Container = styled.div`
    background: white;
    position: relative;
    min-width: 400px;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
    background: #8aa5b2;
    color: white;

    h2 {
        font-size: 18px;
        margin: 0;
    }
`

const Content = styled.div`
    padding: 16px;
`

const Grid = styled.div`
    display: grid;
    width: 600px;
    column-gap: 16px;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr;
`

const GridConfirm = styled(Grid)`
    width: 400px;
    padding: 20px;
    grid-template-columns: 1fr;
`

const SelectionBox = styled.div`
    height: 200px;

    ul {
        overflow: scroll;
    }
`

const Row = styled.div`
    grid-column: 1 / 3;
`






// View = Menu
const Menu = styled.div`
    display: flex;
`

const MenuItem = styled.button`
    display: block;
    width: calc(100% / 2);
    padding: 20px;
    text-align: center;
    color: #121212;
    cursor: pointer;
    border: none;
    background: white;

    h3 {
        font-size: 18px;
        margin: 0 0 7px;
    }

    .menu-desc {
        color: gray;
        font-size: 12px;
    }

    &:hover {
        color: white;
        background: #88b1c5;

        .menu-desc {
            color: white;
        }
    }

    &:disabled {
        color: gray;
        background: gainsboro;

        &:hover .menu-desc {
            color: gray;
        }
    }
`

const File = styled.div`
    max-width: 120px;
    min-width: 60px;
    text-align: center;
    word-break: break-word;
    overflow: hidden;
    padding: 5px;
    cursor: pointer;
    background: ${(props) => props.highlight ? "#ac7a66" : null};
    color: ${(props) => props.highlight ? "white" : null};

    &:hover {
        color: white;
        background: #ac7a66;
    }
`

const Icon = styled.div`
    font-size: 18px;
    padding: 0 5px;
    font-family: "Font Awesome 6 Pro";

    &:before {
        content: ${(props) => props.save ? '"\\f0c7"' :
                              props.reset ? '"\\f0e2"' :
                              props.leave ? '"\\f08b"' :
                              props.commit ? '"\\f386"' :
                              props.file ? '"\\f15b"' :
                              props.saveAs ? '"\\e183"' : ""
    }
`

const loadingStyle = {
    width:30
}

function noop(){
}

/**
 * INDEX
 * ------------------------------------------------------------
 * 00. Lifecycle / helper
 * 01. Save
 * 02. Save As
 * 03. Commit
 * 04. Load
 * 05. Delete
 * 06. Confirm
 * 07. Menu
 * ------------------------------------------------------------
 */

export class LoadSavePrompt extends Component {
    defaultState = {
        visible: false,
        loading: false,
        
        selectedTabId: "",
        currDialogType: "",
        isSavePrompt: false,

        contexts: [],
        selectedContext: undefined,
        selectedPackage: undefined,

        // used for confirm dialog
        isConfirmDialog: false,
        isConfirmed: null,

        selectedGuids: new Set(),
        missingGuids: new Set(),
        confirmFunc: noop,
        cancelFunc: noop,
    }

    phenomId = new PhenomId("sp-prompt")
    state = {
        ...cloneDeep(this.defaultState),
    }


    // ------------------------------------------------------------
    // 00. Lifecycle / helper
    // ------------------------------------------------------------
    
    componentDidUpdate(prevProps, prevState) {
        const { selectedContext } = this.state;

        if (prevState.selectedContext !== selectedContext) {
            this.updatePackageSelection();
        }
    }


    show = (dialogType) => {
        const { activeTabId, tabData } = this.props;
        const selectedTab = tabData[activeTabId];

        this.setState({
            selectedTab,
            selectedTabId: activeTabId,
            currDialogType: dialogType,
            isSavePrompt: dialogType === "savePrompt",
            visible: true,
            loading: false,
        })

        if (dialogType !== "commit") {
            this.fetchDiagramFiles(dialogType);
        }
    }

    showCommitSingleNode = (guid, confirmFunc = noop, cancelFunc = noop) => {
        const { activeTabId, tabData } = this.props;
        const selectedTab = tabData[activeTabId];

        const selectedGuids = new Set([guid]);
        const missingGuids = this.props.manager.getMissingDependencies(guid);

        this.setState({
            selectedTab,
            selectedTabId: activeTabId,
            selectedGuids,
            missingGuids,
            confirmFunc,
            cancelFunc,
            currDialogType: "commitSingle",
            isSavePrompt: false,
            visible: true,
            loading: false,
        })
    }

    close = () => {
        this.setState({
            visible: false,
        }, () => setTimeout(() => this.setState({ ...this.defaultState }), 300));
    }

    goToDialog = (dialogType) => {
        this.setState({
            currDialogType: dialogType,
            loading: false,
        })
    }

    /**
     * Gets all diagram context nodes and sets response the state 'contexts'
     *  additionally, sets the initial input for saveAs dialog
     * 
     * @param {string} dialogType optional arg used specifically for SaveAs functionality in state callback
     * @returns {void}
     */
    fetchDiagramFiles(dialogType=String) {
        getNodesOfType("skayl:DiagramContext").then(res => {
            const response = JSON.parse(res);

            if ("error" in response || "errors" in response) {
                return;
            }

            const contexts = response.nodes.sort((a, b) => a.name.localeCompare(b.name));
            this.setState({ contexts, loading: false }, () => {
                if (dialogType === "saveAs" || dialogType === "savePrompt") {
                    this.initializeSelectedContext(contexts);
                }
            });
        })
    }


    // ------------------------------------------------------------
    // 01. Save
    // ------------------------------------------------------------

    saveDiagram = async () => {
        const { selectedTabId } = this.state;

        this.setState({ loading: true });
        await this.props.manager.saveDiagram(selectedTabId);
        this.fetchDiagramFiles();
    }

    
    // ------------------------------------------------------------
    // 02. Save As
    // ------------------------------------------------------------

    saveDiagramAs = async () => {
        const { selectedTabId, selectedContext, isSavePrompt, selectedPackage } = this.state;

        this.setState({ loading: true });
        await this.props.manager.saveDiagramAs(selectedTabId, selectedContext, selectedPackage);
        this.fetchDiagramFiles();

        BasicAlert.hide();
        if(isSavePrompt) {
            this.goToDialog("savePrompt");
        } else {
            this.close();
        }
    }

    handleSaveDiagramAs = () => {
        const { selectedContext,isConfirmDialog } = this.state;

        if (selectedContext.hasOwnProperty("guid") && isConfirmDialog === false) {
            this.setState({isConfirmDialog: true})
            return;
        }

        this.saveDiagramAs();
    }

    /**
     * Sets state 'selectedContext' to initialize <SkratchpadSavePrompt/> input field
     *  also checks if selectedTab.fileName + "_copy" is equal to any diagram names  <--- very rare edge case, still needs to be checked
     * 
     * @param {string} dialogType optional arg used specifically for SaveAs functionality in state callback
     * @returns {void}
     */
    initializeSelectedContext = (contexts=[]) => {
        const { activeTabId, tabData } = this.props;
        const selectedTab = tabData[activeTabId];
        const contextName = selectedTab?.fileName + "_copy";
        let flag = false;

        contexts.forEach(context => {
            if (context.name === contextName) {
                this.setState({selectedContext: context});
                flag = true;
                return;
            }
        })
        
        if (flag === false) {
            this.setState({selectedContext: {name: contextName}});
        }
    }

    updatePackageSelection = () => {
        const { selectedContext } = this.state;

        const contextParent = selectedContext?.parent;

        if (contextParent) {
            this.setState({ selectedPackage: contextParent })
        }
    }

    renderSaveAs = () => {
        const phenomId = new PhenomId("save-as");
        const { contexts, loading, selectedContext, selectedPackage } = this.state;

        return <SkratchpadSavePrompt 
                            phenomId={phenomId}
                            selectedFile={selectedContext}
                            selectedPackage={selectedPackage}
                            data={contexts}
                            dataGuidKey="guid"
                            dataNameKey="name"
                            loading={loading}
                            disabled={!selectedContext || (!selectedContext?.fileName?.length && !selectedContext?.name?.length)}
                            onSelect={(e) => this.setState({ selectedContext: e })}
                            onPackageSelect={(e) => this.setState({ selectedPackage: e})}
                            onSave={this.handleSaveDiagramAs}
                            />
    }


    // ------------------------------------------------------------
    // 03. Commit
    // ------------------------------------------------------------

    toggleCommitSelection = (guid) => {
        if(!guid) return;

        if(this.state.selectedGuids.has(guid)) {
            this.state.selectedGuids.delete(guid);
        } else {
            this.state.selectedGuids.add(guid);
        }

        this.forceUpdate();
    }

    validateRequest = (request) => {
        if(!request) return;
        const missingGuids = new Set(); 

        [...request].forEach(guid => {
            const dependencies = this.props.manager.getMissingDependencies(guid);
            dependencies.forEach(guid => {
                if(!request.has(guid)) missingGuids.add(guid);
            })
            // dependencies.missingGuids.forEach(missingGuids.add, missingGuids);
        })

        if(missingGuids.size) {
            this.setState({ missingGuids });
        } else {
            this.commitChanges(request);
        }
    }

    commitChanges = async (request) => {
        if(!request) request = new Set();

        this.setState({loading: true});
        await this.props.manager.commitChanges([...request], this.state.selectedTabId)
                        .then(() => {
                            if (this.state.isSavePrompt) {
                              this.goToDialog("savePrompt");
                            } else {
                              this.close();
                            }
                        })
                        .catch(() => {
                            this.setState({loading: false});
                        })
    }

    renderCommitNodes = () => {
        const domId = this.phenomId.genPageId("commit");
        const { tabData } = this.props;
        const { selectedTabId, selectedGuids, missingGuids, loading, isSavePrompt } = this.state;
        const activeTab = tabData[selectedTabId];
        const uncommittedGuids = activeTab.uncommitted;   // Set
        const hasDependencies = !!missingGuids.size;

        return(<Content>
            {isSavePrompt &&
            <Row style={{display:"flex", marginBottom:10}}>
                <Button id={domId + "-go-back-btn"}
                        icon="arrow-left" 
                        look="outline"
                        onClick={() => this.setState({currDialogType:"savePrompt"})}>Go Back</Button>
            </Row>}

            {hasDependencies && 
            <div style={{
                    padding:5,
                    border:"1px solid black",
                    marginBottom:10,
                    color:"black",
                    background:"#fff79c",
            }}>The highlighted nodes are missing dependencies and need to be saved as well.<br/>Please review the list below and Choose to commit or cancel.</div>}

            <Grid>
                <SelectionBox>
                    {hasDependencies ? (
                        <div style={{color:"var(--skayl-orange)"}}>Missing Dependencies!</div>
                    ) : (
                        <div>Select uncommitted Nodes:</div>
                    )}

                    <ul id={domId + "-node-list"} className="diagram-load-list" style={{overflow: "scroll"}}>
                        {hasDependencies ? (
                            [...selectedGuids, ...missingGuids].map((guid, idx) => {
                                const node = this.props.getOptionNode(guid);
                                if(!node) return null;

                                return (
                                    <li id={domId + "-node-list-item-" + idx}
                                        className="diagram-load-option"
                                        style={{backgroundColor: missingGuids.has(guid) ? "var(--skayl-orange)" : ""}}
                                        key={guid}>
                                            {node.name}
                                    </li>)
                            })
                        ) : (
                            [...uncommittedGuids].map((guid, idx) => {
                                const node = this.props.getOptionNode(guid);
                                if(!node) return null;
    
                                return (
                                    <li id={domId + "-node-list-item-" + idx}
                                        className="diagram-load-option"
                                        onClick={() => this.toggleCommitSelection(guid)}
                                        style={{backgroundColor: selectedGuids.has(guid) ? "rgba(3, 15, 222, 0.5)" : ""}}
                                        key={guid}>
                                            {node.name}
                                    </li>)
                            })
                        )}
                    </ul>
                </SelectionBox>

                <SelectionBox>
                    <img
                        id="dlg-diagram-image-tag"
                        className="dlg-diagram-image"
                        src={blankDiagram}/>
                </SelectionBox>

                {hasDependencies ? (
                    <div>
                        <Button id={domId + "-commit-btn"}
                                style={{marginRight:5}}
                                disabled={loading}
                                onClick={() => this.commitChanges([...selectedGuids, ...missingGuids])}>
                                        Commit changes</Button>
                        <Button id={domId + "-cancel-btn"}
                                disabled={loading}
                                onClick={() => this.setState({missingGuids: new Set()})}>
                                        Cancel</Button>
                        {loading &&
                            <img id={domId + "-loading-spinner"} style={loadingStyle} src={loadingIcon} />}
                    </div>
                ) : (
                    <div>
                        <Button id={domId + "-save-all-btn"}
                                style={{marginRight:5}}
                                disabled={loading}
                                onClick={() => this.validateRequest(uncommittedGuids)}>
                                      Commit all</Button>
                        <Button id={domId + "-save-selected-btn"}
                                disabled={loading}
                                onClick={() => this.validateRequest(selectedGuids)}>
                                      Commit selected nodes</Button>
                        {loading &&
                            <img id={domId + "-loading-spinner"} style={loadingStyle} src={loadingIcon} />}
                    </div>
                )}
            </Grid>
        </Content>)
    }

    renderCommitSingleNode = () => {
        const domId = this.phenomId.genPageId("commit");
        const { currDialogType, selectedGuids, missingGuids, loading, confirmFunc, cancelFunc } = this.state;

        const guid = selectedGuids.values().next().value;
        const node = this.props.manager.getOptionNode(guid);

        switch(currDialogType) {
            case "commitSingle":
                var message = <div>You have selected an <b>uncommitted {node.xmiType.split(":")[1]}.</b> A path can only go through committed nodes.<br/>Would you like to commit <b>{node.name}</b> now and continue with the path builder?</div>;
                break;
        }

        return(<GridConfirm>
            <div>{message}</div>

            {missingGuids.size > 0 &&
            <div>
                <div>{node.name} has dependencies so the following nodes will be committed as well:</div>
                <ul id={domId + "-dependency-list"} style={{overflow: "auto"}}>
                    {[...missingGuids].map((guid, idx) => {
                        const node = this.props.getOptionNode(guid);
                        if(!node) return null;
                        return <li key={guid} id={domId + "-dependency-list-item-" + idx}> {node.name} </li>
                    })}
                </ul>
            </div>}

            <div />

            <div style={{margin: "0 -20px -20px"}}>
                <DialogActionsBar>
                    <button id={domId + "-cancel-btn"}
                            className="k-button"
                            disabled={loading}
                            onClick={() => {
                                this.close();
                                cancelFunc();
                            }}>Cancel</button>
                    <button id={domId + "-save-btn"}
                            className="k-button k-primary"
                            disabled={loading}
                            onClick={async () => {
                                    await this.commitChanges([...selectedGuids, ...missingGuids]);
                                    confirmFunc();
                                }}>Commit</button>
                </DialogActionsBar>
            </div>
        </GridConfirm>)
    }


    // ------------------------------------------------------------
    // 04. Load
    // ------------------------------------------------------------

    loadDiagram = async () => {
        const { selectedContext } = this.state;
        if(!selectedContext) return;

        this.setState({loading: true});

        // scratchpad loads based on context now - needs to be refactored
        // viewtrace no longer uses this
        if (this.props.category === "s") {
            await this.props.manager.loadContextWithAlert(selectedContext.guid);
        }

        BasicAlert.hide();
        this.close();
    }

    renderLoadSaveDiagram = () => {
        const domId = this.phenomId.genPageId("load-save");
        const { canEdit } = this.props;
        const { selectedContext, contexts, currDialogType, loading, isSavePrompt } = this.state;
        const isSave = currDialogType === "save";
        const selectedContextGuid = selectedContext?.guid;

        return(<Content>
            {isSavePrompt &&
                <Row style={{display:"flex", marginBottom:10}}>
                    <Button id={domId + "-go-back-btn"}
                            icon="arrow-left" 
                            look="outline"
                            onClick={() => this.setState({currDialogType:"savePrompt"})}>Go Back</Button>
                </Row>}

            <Grid>
                <SelectionBox>
                    {isSave ? "Select an existing diagram" : "Select a diagram"}
                    <ul id={domId + "-diagram-list"} className="diagram-load-list" style={{overflow: "scroll"}}>
                        {contexts.map((context, idx) => {
                            return (
                                <li id={domId + "-diagram-list-item-" + idx}
                                    className="diagram-load-option"
                                    onClick={() => this.setState({ selectedContext: context })}
                                    style={{ backgroundColor: selectedContextGuid === context.guid ? "rgba(3, 15, 222, 0.5)" : "" }}
                                    key={context.guid}>
                                        {context.name}
                                </li>);
                        })}
                    </ul>
                </SelectionBox>
                <SelectionBox>
                    <img
                        id="dlg-diagram-image-tag"
                        className="dlg-diagram-image"
                        src={selectedContext?.image || blankDiagram}/>
                </SelectionBox>

                <div>
                    <div className="flex-h" style={{justifyContent: "center"}}>
                        {isSave ? <>
                            {loading &&
                                <img id={this.phenomId.genPageId("loading-spinner")} style={loadingStyle} src={loadingIcon} />}
                            </> : <>
                            <Button id={domId + "-load-btn"}
                                    style={{marginRight: 5}}
                                    disabled={!selectedContextGuid || loading}
                                    onClick={this.loadDiagram}>Load</Button>
                            <Button id={domId + "-delete-btn"}
                                    style={{marginRight: 5}}
                                    disabled={!selectedContextGuid || !canEdit || loading}
                                    onClick={this.deleteDiagram}>Delete</Button>
                            {loading &&
                                <img id={domId + "-loading-spinner"} style={loadingStyle} src={loadingIcon} />}
                        </>}
                    </div>
                </div>
            </Grid>
        </Content>)
    }


    // ------------------------------------------------------------
    // 05. Delete
    // ------------------------------------------------------------

    deleteDiagram = () => {
        const { selectedContext, contexts } = this.state;

        // scratchpad loads based on context now - needs to be refactored
        // viewtrace no longer uses this
        this.props.manager.deleteContext(selectedContext, () => {
            const newContexts = [...contexts];
            const removeIdx = newContexts.findIndex(c => c.guid === selectedContext?.guid);
            if (removeIdx > -1) {
                newContexts.splice(removeIdx, 1);
            }

            this.setState({
                selectedContext: undefined,
                contexts: newContexts,
            })
        });
    }


    // ------------------------------------------------------------
    // 06. Confirm
    // ------------------------------------------------------------
    renderConfirm = () => {
        const { selectedContext, selectedTabId, isConfirmDialog} = this.state;
        const { tabData } = this.props;

        if (!isConfirmDialog) return;
        
        // can add other cases, only used for SaveAs for now
        if (this.state.currDialogType === "saveAs") {
            const saveFromData = tabData[selectedTabId];

            if (!saveFromData) return;

            BasicConfirm.show('Are you sure you want to overwrite diagram:\n"' + selectedContext?.name + '"?', 
            () => {BasicConfirm.hide()
                    this.setState({isConfirmDialog: false})
                    this.saveDiagramAs()}, 
            () => {this.setState({isConfirmDialog: false})
                    BasicConfirm.hide()
                    })
        }
    }


    // ------------------------------------------------------------
    // 07. Menu
    // ------------------------------------------------------------

    handleContinueBtn = async () => {
        if(this.props.promptHistory && this.props.promptLocation) {
            await this.props.promptContinue();
            this.close();
            this.props.promptHistory.push(this.props.promptLocation);
        }
    }
    
    renderMenu = () => {
        const domId = this.phenomId.genPageId("menu");
        const { selectedTabId, loading } = this.state;
        const { tabData } = this.props;
        
        const selectedTab = tabData[selectedTabId];
        const selectedName = selectedTab?.fileName;

        if(selectedTab) {
            var disableSaveBtn = !loading && selectedTab.saved;
            var disableCommitBtn = !loading && !selectedTab.uncommitted.size;
        }

        return (<div>
            <div style={{padding:"5px 10px", fontSize:12, margin:"5px 0"}}>
                The following diagrams have unsaved changes. Select a diagram and choose to Save or Commit.
            </div>

            <div id={domId + "-file-list"} style={{display:"flex", justifyContent:"center", padding:"5px 10px", overflowX: "auto", minHeight:70, background:"beige", borderTop:"1px solid gray", borderBottom:"1px solid gray"}}>
                {Object.values(tabData).map((tab, idx) => {
                    if(!tab) return null;

                    return (
                        <File id={domId + "-file-item-" + idx}
                              highlight={selectedName === tab.fileName}
                              onClick={() => this.setState({ selectedTabId: tab.tabId })}>
                            <Icon file />
                            <span style={{fontSize:12}}>{tab.fileName}</span>
                        </File>
                    )
                })}
            </div>
        
            <Menu>
                <MenuItem id={domId + "-save-btn"} onClick={this.saveDiagram}
                          disabled={disableSaveBtn}>
                    <Icon save />
                    <h3>Save</h3>
                    <div className="menu-desc">Save your diagram.</div>
                </MenuItem>
                <MenuItem id={domId + "-save-as-btn"} onClick={() => this.setState({currDialogType: "saveAs"})}
                          disabled={disableSaveBtn}>
                    <Icon saveAs />
                    <h3>Save As</h3>
                    <div className="menu-desc">Save your diagram as another name.</div>
                </MenuItem>
                <MenuItem id={domId + "-commit-btn"} onClick={() => this.setState({currDialogType: "commit"})}
                            disabled={disableCommitBtn}>
                    <Icon commit />
                    <h3>Commit</h3>
                    <div className="menu-desc">Save uncommitted changes</div>
                </MenuItem>
                <MenuItem id={domId + "-continue-btn"} onClick={this.handleContinueBtn}>
                    <Icon leave />
                    <h3>Continue</h3>
                    <div className="menu-desc">Discard unsaved changes, and then exit.</div>
                </MenuItem>
                <MenuItem id={domId + "-cancel-btn"} onClick={this.close}>
                    <Icon reset />
                    <h3>Cancel</h3>
                    <div className="menu-desc">Go back.</div>
                </MenuItem>
            </Menu>
        </div>)
    }
    


    // ------------------------------------------------------------
    // MAIN RENDER METHOD
    // ------------------------------------------------------------

    render() {
        let title = "";
        let allowClose = true;

        switch(this.state.currDialogType) {
            case "load":
                title="Load Diagram";
                break;
            case "save":
                title="Save Diagram";
                break;
            case "commit":
                title="Commit Changes";
                break;
            case "commitSingle":
                title = "Path not allowed";
                allowClose = false;
                break;
            case "savePrompt":
                title="Unsaved Changes";
                break;
            case "saveAs":
                title="Save Diagram As";
                break;
        }

        return (
            <Modal2 show={this.state.visible}
                    onExitedCallback={() => this.setState({ ...cloneDeep(this.defaultState) })}>

                {this.state.isConfirmDialog ? 
                    this.renderConfirm()
                
                    :
                    <Container id={this.phenomId.genPageId()}>
                        <Header>
                            <h2 id={this.phenomId.genPageId("title")}>{title}</h2>

                            {allowClose &&
                                <Button id={this.phenomId.genPageId("close-btn")}
                                        icon="close"
                                        look="bare"
                                        onClick={this.close} />}
                        </Header>
                            {(this.state.currDialogType === "savePrompt") &&
                                this.renderMenu()}

                            {(this.state.currDialogType === "load") &&
                                this.renderLoadSaveDiagram()}

                            {(this.state.currDialogType === "commit") &&
                                this.renderCommitNodes()}

                            {(this.state.currDialogType === "commitSingle") &&
                                this.renderCommitSingleNode()}

                            {(this.state.currDialogType === "saveAs") &&
                                this.renderSaveAs()}
                    </Container>
                }
            </Modal2>
        )
    }
}
