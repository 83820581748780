import React from "react";
import { CadetInput } from "../util/stateless";
//import AEPathBuilder from "./ae-path-builder";
//import SpecializationBuilder from "./specialization-builder";
//import {deGuidify} from "../../util/util";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Button} from "@progress/kendo-react-buttons";
import PhenomId from "../../requests/phenom-id";


export class PlatformLiteral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guid: "",
            parent: "",
            name: "",
            realizes: null,
            labels: this.props.labels,
            deleted: false
        };

        this.originalRes = "";
    }

    componentDidMount() {
        this.originalRes = JSON.stringify(this.props.element);
        if (this.props.element) {
            this.setStateFromResponse(this.props.element);
        }
    }

    setStateFromResponse(response) {
        this.setState({
            guid: response.guid,
            parent: response.parent,
            name: response.name,
            realizes: response.realizes,
            deleted: false
        });
        this.originalRes = JSON.stringify(response);
    }

    getStateIfChanged = () => {
        const original = JSON.parse(this.originalRes);
        if (!this.state.deleted && (this.props.new || original.name !== this.state.name || original.realizes !== this.state.realizes)) {
            const childData = {
                guid: this.state.guid || undefined,
                name: this.state.name,
                xmiType: 'platform:EnumerationLiteral',
                realizes: this.state.realizes
            };
            return childData;
        } else {
            return false;
        }
    };

    getName = () => {
        return this.state.name;
    }
    getRealizes = () => {
        return this.state.realizes;
    }
    getXmiType = () => {
        return "platform:EnumerationLiteral";
    }
    getDeleted = () => {
        return this.state.deleted ? this.state.guid : false;
    }

    setRealizes = (realizee) => {
        this.setState({realizes: realizee});
    }

    render() {
        const dropdownValue = this.state.realizes ? this.props.labels.find(e => e.guid === this.state.realizes) : false;
        const emptyValue = {guid: undefined, name: ""};
        const rowColor = this.state.deleted ? "rgba(220,20,60,0.25)" : (this.props.new ? "rgba(152,251,152,0.25)" : false);
        const phenomId = new PhenomId("platform-literal",this.props.idCtx);
        return (
            <tr
                key={this.props.key}
                style={{height: 50, backgroundColor: rowColor}}
                draggable={this.props.isDraggable && this.props.editable}
                className={this.props.isDraggable ? "draggable" : ""}
                onDragOver={this.props.onDragOver}
                onDragStart={this.props.onDragStart}
                draggable={this.props.isDraggable && this.props.canEdit}>
                <td>
                    <CadetInput
                        text={this.state.name}
                        disabled={!this.props.canEdit}
                        idCtx={phenomId.gen("","name")}
                        // id={this.state.guid}
                        onChange={(e) => {
                            this.setState({name: e.target.value});
                        }}
                        style={{margin: 0, padding: 0, height: 28, fontSize:14}}
                    />
                </td>
                <td>
                    <DropDownList
                        id={phenomId.gen("","value-dropdown")}
                        data={this.state.labels} textField="name"
                        dataItemKey="guid" style={{width: "100%"}}
                        value={dropdownValue}
                        disabled={!this.props.canEdit}
                        onOpen={(e) => {
                            const unusedLabels = dropdownValue ? [emptyValue, dropdownValue, ...this.props.getUnusedElements()] : this.props.getUnusedElements();
                            this.setState({labels: unusedLabels});
                        }}
                        onChange={(e) => {
                            this.setState({realizes: e.value.guid});
                        }}/>
                </td>
                <td>
                    <Button
                        id={phenomId.gen("","delete-button")}
                        icon={this.state.deleted ? "check" : "close"}
                        look="bare"
                        disabled={!this.props.canEdit}
                        style={{width: "100%"}}
                        onClick={(e) => {
                            this.setState({deleted: !this.state.deleted});
                        }} />
                </td>
            </tr>
        );
    }
}
