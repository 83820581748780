import React, { useEffect, useState } from 'react';
import { Menu } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { modellingPortPosition } from "./node/DiagramNode"
import styled from "@emotion/styled";
import { sortNodesByName, LightenColor, createPhenomGuid, isPhenomGuid, capFirstChar, defaultBounds, createGhostElement } from "../../util/util";
import { BasicConfirm } from '../../dialog/BasicConfirm';
import { getDeletableStatus } from '../../../requests/sml-requests';
import { BasicAlert } from '../../dialog/BasicAlert';
import { CadetLink } from '../../util/stateless'
import { clone, cloneDeep } from 'lodash';
import { Toggle } from "../../util/stateless";
import StormData from './base/StormData';

// INDEX
// ------------------------------------------------------------
// # Diagram Properties
// # Diagram Methods
// # Diagram Toolbar
// # Diagram Side Panel
// # Diagram Stencils
// # Base Node
// # Scratchpad
// ## Path Builder
// # IDM Editor
// # Helper
// # Uncategorized / Deprecated
// ------------------------------------------------------------


// ------------------------------------------------------------
// 00. Diagram Properties
// -----------------------------------------------------------
/**
 * Initial Properties of Diagram Nodes
 */
 export const nodeProps = {
  width: 300,
  minWidth: 100,
  minHeight: 18,
  bgColor: "whitesmoke",
  uncommittedColor: "var(--skayl-orange)",
  deleteColor: "#fce0e5",
}



// ------------------------------------------------------------
// 01. Diagram Methods
// ------------------------------------------------------------
/**
 * Centers the diagram node after drag and drop
 * 
 * @param {number} x X coordinate
 * @param {number} y Y coordinate
 * @param {number} width diagram node's width
 * @returns adjusted x, y coordinates
 */
export const centerCoordinatesOnDragEnd = (x, y, width=nodeProps.width) => {
  return {
    x: x - (width / 2),
    y: y - nodeProps.minHeight,
  }
}

/**
 * Adjust x or y coordinate by a small amount
 *    in diagrams, you can click on names to add the node.
 *    this is used to provide extra spacing between the nodes so they're not directly next to each other.
 *  
 * @param {number} pos x or y coordinate
 * @returns adjusted x or y coordinate
 */
export const addGutter = (pos) => {
  return pos + 50;
}

/**
 * Generates a random rgb color
 *    the color is darkened by 10% to prevent white
 *  
 * @returns rgb color
 */
export const randomColor = () => {
  const hexColor = Math.floor(Math.random()*16777215).toString(16);
  // darkens the color by 10% to ensure its not too bright
  return LightenColor(hexColor, -10);
}


// ------------------------------------------------------------
// 10. Diagram Toolbar
// ------------------------------------------------------------
export const DiagramToolBar = ({ options=[] }) => {
  return <div className="diagram-toolbar">
            {options.map(option => <DiagramToolBarItem key={option.id} option={option} /> )}
         </div>
}

const DiagramToolBarItem = ({ option={} }) => {
  const { type, text, classNames=[], style, ...restProps } = option;

  switch (type) {
    case "section":
      return <div className="toolbar-section" data-title={text} style={style}>
                {option.items.map(item => <DiagramToolBarItem key={item.id} option={item} /> )}
             </div>
      
    case "separator":
      return <div key={option.id} className="toolbar-separator" />

    case "fa-button":
      return <button key={option.id} className={["toolbar-icon-btn", ...classNames].join(" ")} {...restProps}>
                {text}</button>
      
    case "text-button":
      return <button key={option.id} className="toolbar-text-btn" {...restProps}>
                {text}</button>
      
    case "toggle":
      return <DiagramToggleWrapper key={option.id} style={style} {...restProps} />
      
    case "zoom":
      return <DiagramZoomLevel key={option.id}
                                 {...restProps} />

    default:
      return null;
  }
}

/**
 * This wraps around the Toggle Component to keep track of the position value
 *   -> This logic sort of exist in the Toggle Component, but the it looks like "startingPosition" is what triggers any change.
 *   -> I would've updated Toggle Component but I didn't want to mess up its use cases.
 */
const DiagramToggleWrapper = ({ id, options, startingPosition, style, toggleFunction }) => {
  const [ position, setPosition ] = useState(startingPosition);

  return <Toggle id={id}
                 options={options}
                 startingPosition={position}
                 style={style}
                 toggleFunction={() => {
                   if (position) {
                     setPosition(0);
                     toggleFunction(false);
                   } else {
                     setPosition(1);
                     toggleFunction(true);
                   }
                 }} />
}

/**
 * This keeps track of the diagram's zoom level.
 */
export const DiagramZoomLevel = ({ zoomLevel, updateZoomLevel }) => {
  const [ value, setValue ] = useState(zoomLevel || 100);

  return <div className="toolbar-section toolbar-section-zoom" data-title="Zoom">
            <button className="toolbar-icon-btn fa fa-minus-circle"
                    onClick={() => {
                      const zoom = value - 5;
                      if (zoom < 20 || zoom > 500) return;
                      setValue(zoom);
                      updateZoomLevel(zoom);
                    }} />
            <input type="range" min="20" max="500" step="5" value={value}
                   onChange={(e) => {
                     const zoom = parseInt(e.target.value);
                     setValue(zoom);
                     updateZoomLevel(zoom);
                   }} />
            <output>{value}</output>
            <button className="toolbar-icon-btn fa fa-plus-circle"
                    onClick={() => {
                      const zoom = value + 5;
                      if (zoom < 20 || zoom > 500) return;
                      setValue(zoom);
                      updateZoomLevel(zoom);
                    }} />
          </div>
}


// ------------------------------------------------------------
// 11. Diagram Side Panel
// ------------------------------------------------------------
export const StormSideTabs = ({ phenomId, tabs=[], activeTab, onClick }) => {
  return <div className="storm-side-tabs">
            {tabs.map(name => {
              const classes = ["storm-side-tab-item"];

              const isActive = activeTab === name;
              if (isActive) {
                classes.push("active");
              }

              let id;
              if (phenomId) {
                id = phenomId.genPageId(`tab-${name}`);
              }

              return <span id={id} key={id} className={classes.join(" ")}
                           onClick={() => isActive ? onClick("") : onClick(name)}>
                              { capFirstChar(name) }
                     </span>
            })}
  </div>
}

// ------------------------------------------------------------
// # Diagram Stencils
// ------------------------------------------------------------
export const StencilItem = ({ id, text, xmiType, bgColor }) => {
  const data = { id, type: xmiType, name: text, bgColor };

  return <div id={id}
              className="stencil-item"
              draggable
              style={{ "--bg-color": bgColor }}
              onDragStart={(e) => {
                const { htmlElement, offset } = createGhostElement(data);
                e.dataTransfer.setDragImage(htmlElement, offset[0], offset[1]);
                e.dataTransfer.setData("stencil-item", xmiType)
                setTimeout(() => htmlElement.remove(), 0);
              }}>
            { text }
          </div>
}

export const StencilItemImage = ({ id, text, xmiType, bgColor, img, offset }) => {
  const [ domImage, setDomImage ] = useState(null);
  
  useEffect(() => {
    const image = new Image();
    image.src = img;
    setDomImage(image);
  }, [img]);

  return <div id={id}
              className="stencil-item"
              draggable
              style={{ "--bg-color": bgColor }}
              onDragStart={(e) => {
                e.dataTransfer.setData("stencil-item", xmiType);
                e.dataTransfer.setDragImage(domImage, offset[0], offset[1]);
              }}>
            { text }
         </div>
}




// ------------------------------------------------------------
// 30. Base Node
// ------------------------------------------------------------
export const isStormData = (obj) => {
  return obj instanceof StormData;
}

export const diagramColors = {
  "conceptual:Entity":                  "var(--skayl-violet)",
  "conceptual:Composition":             "hsl(var(--skayl-violet-hs) 50%)",
  "conceptual:Association":             "var(--skayl-purple)",
  "conceptual:AssociatedEntity":        "hsl(var(--skayl-purple-hs) 82%)",
  "conceptual:Observable":              "var(--skayl-teal)",
  "platform:View":                      "var(--skayl-grey)",
  "platform:CharacteristicProjection":  "hsl(var(--skayl-zero-hs) 55%)",
  "im:UoPInstance":                     "hsl(238 15% 45%)",
  "im:SourceNode":                      "hsl(var(--skayl-zero-hs) 0%)",
  "im:SinkNode":                        "hsl(var(--skayl-zero-hs) 0%)",
  "im:FanIn":                           "hsl(216 30% 31%)",
  "im:Generic":                         "hsl(216 30% 31%)",
  "im:TransformNode":                   "hsl(216 30% 31%)",
  "im:FilterNode":                      "hsl(216 30% 31%)",
  "im:ViewTransporterNode":             "hsl(216 30% 31%)",
  "im:SIMAdapter":                      "hsl(276 32% 53%)",
  "im:QueuingAdapter":                  "hsl(276 32% 53%)",
  "im:DataPump":                        "hsl(276 32% 53%)",
  default:                              "hsl(var(--skayl-zero-hs) 0%)",
}


/**
 * Diagram links require unique names - generated by BaseNode's createPortName method
 * Each node have a hash table that keeps track of these names - where the composition guid points to the portName
 * Complex diagram links uses a longer naming system for reverse lookups and to differentiate it from regular links
 *    i.e. Path Builder port guid: "<composition guid> <characteristic guid> <path position>"
 * 
 * Also the portGuid is separated by spaces because a space is not a valid character for a real GUID. 
 * 
 * @param  {...any} guids list of strings
 * @returns string separated by a space
 */
export const createPortGuid = (...guids) => {
  return guids.join(" ");
}

/**
 * Reverse of createPortGuid
 * 
 * @param {string} portGuid string joined by a space
 * @returns an array of strings
 */
export const splitPortGuid = (portGuid) => {
  return portGuid.split(" ");
}

/**
 * Calculate the distance between two points
 *    sqrt(dX^2 + dY^2)
 * 
 * @param {object} point1 object with x y coordinates
 * @param {object} point2 object with x y coordinates
 * @returns the distance between two points
 */
const distance = (point1, point2) => {
  const dXsq = Math.pow(point2.x - point1.x, 2);
  const dYsq = Math.pow(point2.y - point1.y, 2);
  return Math.sqrt(dXsq + dYsq);
}


/**
 * Given a point from the Source NodeModel, find the closet corner located on the Target NodeModel
 * 
 * @param {object} start_point a point located on the Source NodeModel
 * @param {class} nodeModel Target NodeModel (an instance of React Storm's NodeModel class)
 * @returns a point (object) with x y coordinates
 */
const getShortestDistance = (start_point, nodeModel) => {
  let shortestPoint, shortestDistance = Infinity;
  const box = nodeModel.getBoundingBox().points;

  for (let point of box) {
    const newDistance = distance(start_point, point);
    if (newDistance < shortestDistance) {
      shortestDistance = newDistance;
      shortestPoint = point;
    }
  }
  return shortestPoint;
}

/**
 * TODO: change name. it is a little confusing
 * Given a point from the Source NodeModel, find the closet corner from the Target NodeModel and convert values to percentage
 *    The endpoints of a diagram link have two sets of x y values - one is for the DIV layer and the other is for the SVG layer
 *    
 * This method is used for the DIV layer. It has a absolute position and sits along the edge of the diagram node.
 * 
 * @param {object} start_point a point located on the Source NodeModel
 * @param {class} nodeModel Target NodeModel (an instance of React Storm's NodeModel class)
 * @returns the closest point but converted into percentage
 */
export const getShortestWidgetDistance = (start_point, nodeModel) => {
  const shortest = getShortestDistance(start_point, nodeModel);

  return convertPortPositionToPercentage(shortest, nodeModel);
}


/**
 * Converts a point's x y values into percentage
 *    used for the DIV layer.
 * 
 * @param {object} point a point located on the Source NodeModel
 * @param {class} nodeModel nodeModel Target NodeModel (an instance of React Storm's NodeModel class)
 * @returns the closest point but converted into percentage
 */
export const convertPortPositionToPercentage = (point, nodeModel) => {
  const box = nodeModel.getBoundingBox().points;
  return {
    top: (point.y - box[0].y) / nodeModel.height,
    left: (point.x - box[0].x) / nodeModel.width,
  } 
}


/**
 * Calculate the slope given two points
 *    dY / dX
 * 
 * Keep in mind that [0, 0] is the upper left corner
 * 
 * @param {point} p1 
 * @param {point} p2 
 * @returns 
 */
const calculateSlope = (p1, p2) => {
  const numerator = p1.y - p2.y;
  const denominator = p1.x - p2.x;
  if (!denominator) return NaN;       // vertical line
  return numerator / denominator;
}


/**
 * Calculate the Y-Intercept for equation: y = mx + b
 * b = y - mx
 * 
 * @param {point} p1 
 * @param {point} p2 
 */
const calculateYIntercept = (p1, p2) => {
  const slope = calculateSlope(p1, p2);
  return p1.y - slope * p1.x;
}


export const calculateLineEquation = (sourcePoint, targetPoint) => {
  // line equation: y = mx + b
  return {
    slope: calculateSlope(sourcePoint, targetPoint),
    b: calculateYIntercept(sourcePoint, targetPoint)
  }
}


/**
 * Finds the corner with the shortest distance to target
 * 
 * @param {nodeModel} nodeModel 
 * @param {point} targetPoint 
 * @returns point
 */
const findClosestCorner = (nodeModel, targetPoint) => {
  const box = nodeModel.getBoundingBox().points;

  let shortest = Infinity, closest;
  for (let corner of box) {
    if (distance(corner, targetPoint) < shortest) {
      shortest = distance(corner, targetPoint);
      closest = corner;
    }
  }

  return closest;
}


const calculateIntersectingPoint = (lineEquation, nodeModel, corner) => {
  const box = nodeModel.getBoundingBox().points;
  const { slope, b } = lineEquation;

  // Vertical line - slope and b are undefined
  // line equation: x = num
  if (lineEquation.slope === NaN && lineEquation.b === NaN) {
    return {
      x: corner.x,
      y: nodeModel.getCenterPoint().y,
    }
  }

  // Horizontal line - slope is undefined
  // line equation: y = num
  if (lineEquation.slope === NaN) {
    return {
      x: nodeModel.getCenterPoint().x,
      y: b,
    }
  }

  const isPointInsideNode = (point) => {
    return point.x >= box[0].x && point.x <= box[1].x &&
           point.y >= box[0].y && point.y <= box[3].y;
  }

  // x = (y - b) / m
  const horizontalEdge = {
    x: (corner.y - b) / slope,
    y: corner.y,
  }

  // y = mx + b
  const verticalEdge = {
    x: corner.x,
    y: slope * corner.x + b,
  }

  if (isPointInsideNode(horizontalEdge)) return horizontalEdge;
  return verticalEdge;
}


export const findIntersectingPoint = (lineEquation, nodeModel, targetPoint) => {
  const corner = findClosestCorner(nodeModel, targetPoint);
  const portPos = calculateIntersectingPoint(lineEquation, nodeModel, corner);
  return {
    portPosition: portPos,
    widgetPosition: convertPortPositionToPercentage(portPos, nodeModel),
  }
}




// ------------------------------------------------------------
// 40. Scratchpad
// ------------------------------------------------------------
export const formatPathAttr = (comp, parent) => {
  return {
      guid: comp.guid,
      rolename: comp.rolename,
      xmiType: comp.xmiType,
      type: typeof comp.type === "string" ? comp.type: comp.type?.guid,
      parent: {
          guid: parent ? parent.guid : comp.parent.guid,
          name: parent ? parent.name : comp.parent.name,
          xmiType: parent ? parent.xmiType : comp.parent.xmiType,
      },
      deprecated: comp.deprecated
  }
}


export const confirmDeleteAttribute = (attr, callback) => {
  if(isPhenomGuid(attr.guid)) {
      BasicConfirm.show(<div>
          Are you sure you want to <span style={{color:"red"}}>remove</span> {attr.name || attr.rolename} from the diagram?
      </div>, () => callback(true));
  } else {
      getDeletableStatus(attr.guid).then(res => {
          const response = JSON.parse(res);
          const action = response.data.deletable ? "delete" : "deprecate";

          BasicAlert.hide();
          BasicConfirm.show(<div>
              <div>Are you sure you want to <span style={{color:"red"}}>{action}</span> {attr.name || attr.rolename} from the <span style={{fontWeight:600}}>model?</span></div>
              <div style={{fontStyle:"italic"}}>By confirming this action, the change will be reflected in the model.</div>
          </div>, () => callback(response.data.deletable))
      })
  }
}


export const cannotRemoveBecauseContainsProjection = (viewMap, attr) => {
  BasicAlert.show(<div>
      <div><b>{attr.name || attr.rolename}</b>, cannot be deleted because it is used in one (or more) projection path(s).</div>
      <div>Please clear the projection path first.</div>
      <br />
      <div>
          <label>Projectors in use:</label>
          <ul>
              {Object.values(viewMap).map((view) => (
                <li>{ isPhenomGuid(view.guid) 
                        ? <span style={{fontSize:14}}>{view.name}</span>
                        : <CadetLink newPage={true} node={view}
                                     style={{fontSize:14}} /> }</li>
              ))}
          </ul>
      </div>
  </div>, "Warning", true);
}

export const flattenEntityNode = (ent) => {
  return {
    guid: ent.guid,
    name: ent.name,
    xmiType: ent.xmiType,
    description: ent.description,
    specializes: ent.specializes,
    specializedBy: ent.specializedBy,
    children: ent.children.map(c => flattenCompositionNode(c, ent.guid)),
  }
}

export const flattenCompositionNode = (comp, parentGuid) => {
  return {
    guid: comp.guid,
    rolename: comp.rolename,
    xmiType: comp.xmiType,
    description: comp.description,
    type: comp.type,
    lowerBound: comp.lowerBound,
    upperBound: comp.upperBound,
    sourceLowerBound: comp.sourceLowerBound,
    sourceUpperBound: comp.sourceUpperBound,
    specializes: comp.specializes,
    specializedBy: comp.specializedBy,
    pathPairs: comp.pathPairs ? comp.pathPairs.map(pair => formatPathAttr(pair)) : undefined,
    parent: parentGuid,
    tag: comp.tag,
  }
}

export const flattenViewNode = (view) => {
  return {
    guid: view.guid,
    name: view.name,
    xmiType: view.xmiType,
    description: view.description,
    faceVersion: "2.1",
    structureKind: view.structureKind,
    children: view.children.map(c => serializeCharData(c, view)),
  }
}

export const flattenCharacteristicNode = (char={}, parentGuid) => {
  return {
    guid: char.guid,
    rolename: char.rolename,
    xmiType: char.xmiType,
    descriptionExtension: char.descriptionExtension,
    measurement: char.measurement,
    path: char.path,
    platformType: char.platformType,
    platformValues: char.platformValues,
    projectedCharacteristic: char.projectedCharacteristic,
    pathPairs: char.pathPairs.map(pair => formatPathAttr(pair)),
    lowerBound: char.lowerBound,
    upperBound: char.upperBound,
    optional: char.optional,
    attributeKind: char.attributeKind,
    viewType: char.viewType?.guid || char.viewType,
    parent: parentGuid,
  }
}

export const convertTextToLowerBound = (value, isSourceBound=false) => {
  if (typeof value !== 'string') value = "";
  let result = value.match(/\d*/)[0]                            // match digits
  if (result.length > 1) result = result.replace(/^0+/, '');    // remove leading zeroes
  
  if (!result) {
    return isSourceBound ? defaultBounds["sourceLowerBound"] : defaultBounds["lowerBound"];
  }
  return result;
}

export const convertTextToUpperBound = (value, isSourceBound=false) => {
  if (typeof value !== 'string') value = "";
  let result = value.match(/\*|\d*/)[0]                         // match digits or *
  if (result.length > 1) result = result.replace(/^0+/, '');    // remove leading zeroes

  if (result) {
    return (result === "*") ? "-1" : result;
  }
  return isSourceBound ? defaultBounds["upperBound"] : defaultBounds["sourceUpperBound"];
}

export const convertBoundToText = (value) => {
  return (value === "-1") ? "*" : value;
}

export const convertBoundsToText = (lower, upper, isSourceBounds=false) => {
  const defaultLower = isSourceBounds ? defaultBounds["sourceLowerBound"] : defaultBounds["lowerBound"]; 
  const defaultUpper = isSourceBounds ? defaultBounds["sourceUpperBound"] : defaultBounds["upperBound"]; 
  const valueLower = convertBoundToText(lower) || defaultLower;
  const valueUpper = convertBoundToText(upper) || defaultUpper;

  return `${valueLower}..${valueUpper}`;
}





// ------------------------------------------------------------
// 40a. Path Builder
// ------------------------------------------------------------
// deprecated - see createPortGuid
export const createPortGuidPath = (attrGuid, charGuid, pathPos) => {
  return [attrGuid, charGuid, pathPos].join(" ");
}

// deprecated - see splitPortGuid
export const splitPortGuidPath = (portGuid) => {
  return portGuid.split(" ");
}

// TODO: move to generic util file
export const isNestingChar = (char) => {
  return !!char.viewType;
}


// TODO: delete - see createPortGuid
export const createPathPortGuid = (attr, pathData) => {
  if(!attr || !pathData) return;
  const {viewChild, pathPos} = pathData;

  return `${attr.guid}--${viewChild.guid}--${pathPos}`;
}


// ------------------------------------------------------------
// 60. IDM Editor
// ------------------------------------------------------------
export const createImUoPIEndPoint = (out, parentGuid) => ({
  guid: createPhenomGuid(),
  xmiType: out ? "im:UoPOutputEndPoint" : "im:UoPInputEndPoint",
  dataType: "",
  messagingPattern: "pub-sub",
  parent: parentGuid,
  children: [],
})

export const createImPort = (out, parentGuid) => ({
  guid: createPhenomGuid(),
  xmiType: out ? "im:OutPort" : "im:InPort",
  flowTrigger: "NONE",
  dataType: "",
  parent: parentGuid,
  type: "Default",
  children: [],
});

export const createNodeConnection = (data={}) => ({
  guid: data.guid || createPhenomGuid(),
  xmiType: "im:NodeConnection",
  source: data.source || "",
  destination: data.destination || "",
  children: [],
});

export const createAssociationTransporterNodeToTransportChannel = (transporterGuid="", channelGuid="") => ({
  guid: createPhenomGuid(),
  xmiType: "im:TransporterNodeToTransportChannel",
  Transporter_Guid: transporterGuid,
  Channel_Guid: channelGuid,
  children: [],
})

export const createEquation = (name, description="", equation="") => ({
  guid: createPhenomGuid(),
  xmiType: "im:Equation",
  name: name,
  description: description,
  equation: equation,
  children: [],
})

export function findConnectedPorts(srcNodeModel, dstNodeModel) {
  for (let port of Object.values(srcNodeModel.getPorts())) {
    for (let link of Object.values(port.getLinks())) {
      if (link.targetPort && link.targetPort.getNode() === dstNodeModel) {
        return { srcPort: port, dstPort: link.targetPort };
      }
    }
  }

  return {};
}

export function findConnectedLink(srcPort, dstPort) {
  for (let link of Object.values(srcPort.getLinks())) {
    if (link.targetPort === dstPort) return link;
  }
  return false;
}


// TEMPORARY - until i can figure out why context is not added to backend's name_index
export const deconflictName = (name, contextList=[]) => {
  const matches = contextList.filter(c => c.name === name);
  return matches.length ? deconflictName(name + "_" + matches.length, contextList) : name;
}

/**
 * The Data Type of a UoPOutputEndpoint depends on the Message Port:
 *  -> Pub-Sub: return messageType
 *  -> Client: return requestType
 *  -> Server: return responseType
 * 
 * @param {node} srcMessagePort 
 * @returns dataType
 */
export function extractDataTypeFromSourceMessagePort(srcMessagePort) {
  return (srcMessagePort?.messagingPattern === "pub-sub" && srcMessagePort.messageType) ||
         (srcMessagePort?.messagingPattern === "Client" && srcMessagePort.requestType) ||
         (srcMessagePort?.messagingPattern === "Server" && srcMessagePort.responseType) || "";
}

/**
 * The Data Type of a UoPInputEndPoint depends on the Message Port:
 *  -> Pub-Sub: return messageType
 *  -> Client: return responseType
 *  -> Server: return requestType
 * 
 * @param {node} dstMessagePort 
 * @returns dataType
 */
export function extractDataTypeFromDestinationMessagePort(dstMessagePort) {
  return (dstMessagePort?.messagingPattern === "pub-sub" && dstMessagePort.messageType) ||
         (dstMessagePort?.messagingPattern === "Client" && dstMessagePort.responseType) ||
         (dstMessagePort?.messagingPattern === "Server" && dstMessagePort.requestType) || "";
}


// ------------------------------------------------------------
// 90. Helper
// ------------------------------------------------------------
// smm-save-nodes converts null to an empty string.
// to tell the backend if a value does not exist, its value need to be changed to "undefined"

/**
 * smm-save-nodes now converts null to an empty string.
 * value needs to be "undefined" to be ignored by the backend
 * 
 * @param {node} data provide a node and method will recursively replace null to undefined
 * @returns node copy
 */
export const convertNullToUndefined = (data) => {
  // return undefined instead of null
  if (data === null) {
    return undefined;
  }

  // return data if it is a primitive type or if it is a falsey value ("" or null or undefined)
  if (!data || typeof data !== 'object') {
    return data;
  }

  // create shallow copy to not mutate original and recursively go through the object/array
  const copy = clone(data);
  for (let key in copy) {
    copy[key] = convertNullToUndefined(copy[key]);
  }

  return copy;
}


// ------------------------------------------------------------
// 99. Uncategorized / Deprecated
// ------------------------------------------------------------
export const createNewNodeData = ({ guid, name="", xmiType, isStencil }) => {
  const data = {
    guid,
    name,
    description: "",
    xmiType,
    children: [],
    isStencil,
  }
  
  switch (xmiType) {
    case "platform:View":
        data.faceVersion = "2.1";
        data.structureKind = "nesting";
        break;
  }
  return data;
}

// use deepClone on this
export const defaultCharacteristic = {
    guid: null,
    rolename: "",
    xmiType: "platform:CharacteristicProjection",
    descriptionExtension: "",
    measurement: "",
    path: "",
    platformType: null,
    platformValues: [],
    projectedCharacteristic: "",
    pathPairs: [],
    optional: false,
    attributeKind: "privatelyScoped",
}

export const defaultComposition = {
    guid: null,
    rolename: "",
    description: "",
    xmiType: "conceptual:Composition",
    type: "",
    tag: "composition",
}

export const defaultAssocEntity = {
    guid: null,
    rolename: "",
    description: "",
    xmiType: "conceptual:AssociatedEntity",
    sourceLowerBound: "1",
    sourceUpperBound: "1",
    lowerBound: "1",
    upperBound: "1",
    type: "",
    tag: "associatedEntity",
}

export const serializeEntityData = (ent={}) => {
  return {
    guid: ent.guid,
    name: ent.name,
    xmiType: ent.xmiType,
    description: ent.description,
    specializes: ent.specializes,
    specializedBy: ent.specializedBy,
    children: ent.children.map(c => serializeCompositionData(c, ent)),
  }
}

export const serializeCompositionData = (comp={}, ent={}) => {
  return {
    guid: comp.guid,
    rolename: comp.rolename,
    xmiType: comp.xmiType,
    description: comp.description,
    type: comp.type,
    sourceLowerBound: comp.sourceLowerBound,
    sourceUpperBound: comp.sourceUpperBound,
    lowerBound: comp.lowerBound,
    upperBound: comp.upperBound,
    specializes: comp.specializes,
    specializedBy: comp.specializedBy,
    parent: ent.guid,
    tag: comp.tag,
  }
}

export const serializeViewData = (view={}) => {
  return {
    guid: view.guid,
    name: view.name,
    xmiType: view.xmiType,
    description: view.description,
    faceVersion: "2.1",
    structureKind: view.structureKind,
    children: view.children.map(c => serializeCharData(c, view)),
  }
}

export const serializeCharData = (char={}, view={}) => {
  return {
    guid: char.guid,
    rolename: char.rolename,
    xmiType: char.xmiType,
    descriptionExtension: char.descriptionExtension,
    measurement: char.measurement,
    path: char.path,
    platformType: char.platformType,
    platformValues: char.platformValues,
    projectedCharacteristic: char.projectedCharacteristic,
    pathPairs: char.pathPairs.map(pair => formatPathAttr(pair, pair.parent)),
    lowerBound: char.lowerBound,
    upperBound: char.upperBound,
    optional: char.optional,
    attributeKind: char.attributeKind,
    viewType: char.viewType?.guid || char.viewType,
    parent: view.guid,
  }
}



export const sortNodesByType = (node1, node2) => {
    if (node1.xmiType === node2.xmiType) {
        const name1 = node1.name || node1.rolename;
        const name2 = node2.name || node2.rolename;
        if (name1.toLowerCase() < name2.toLowerCase()) return -1;
        if (name1.toLowerCase() > name2.toLowerCase()) return 1;
    } else {
        if (node1.xmiType < node2.xmiType) return -1;
        if (node1.xmiType > node2.xmiType) return 1;
    }
    return 0;
}


export class ContextMenu extends React.Component {
    state = {
        visible: false,
        menuItems: [],
        offset: {}
    }

    show = (menuItems, offset) => {
        this.setState({visible: true, menuItems, offset});
    }

    close = () => {
        this.setState({visible: false, menuItems: [], offset: {}});
    }

    render() {
        return(<Popup show={this.state.visible}
                        offset={this.state.offset}>
                  <Menu vertical={true}
                          style={{ display: 'inline-block' }}
                          items={this.state.menuItems}
                          onSelect={(e) => {
                              this.close();
                              e.item.func();
                          }} />
            </Popup>)
    };
}


const StyledCog = styled.div`
    position: absolute;
    top: 80%;
    right: 0;
    width: 250px;
    z-index: 4;
    background: white;
    border: 1px solid #ccc;

    span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 8px !important;
    }

    input[type=checkbox] {
        margin: 0;
    }
`
export class CogOptions extends React.Component {
    state = {
        visible: false,
    }

    show = () => {
        this.setState({visible: true});
    }

    close = () => {
        this.setState({visible: false});
    }

    toggleDisplay = (offset) => {
        if(this.state.visible) {
            this.close();
        } else {
            this.show(offset);
        }
    }

    toggleDeletionWarnings = () => {
        const { $manager } = this.props;
        $manager.toggleRemovalWarning();
    }

    render() {
        const { $manager } = this.props;
        const options = {};

        options["Show Delete/Remove Confirmation"] = {
            func: this.toggleDeletionWarnings,
            checked: $manager.state.settings.showRemovalWarning,
        }

        // const options = [
        //     {text: `${$app.state.showDeleteWarning ? "Hide" : "Show"} Deletion Warnings`},
        // ];



        if(!this.state.visible) {
            return null;
        }

        return(
            <StyledCog>
                <ul className="k-widget k-reset k-header k-menu k-menu-vertical">
                    {Object.entries(options).map(([text, items], idx) => (
                        <li className="k-item k-menu-item"
                            key={idx}>
                            <span className="k-menu-link">
                                {text}
                                {"checked" in items &&
                                    <input type="checkbox"
                                            checked={items.checked}
                                            onClick={items.func} />}
                            </span>
                        </li>
                    ))}

                </ul>
            </StyledCog>
        )
    }
}


export const isDiagramNode = (guid) => {
    return typeof guid === "string" && guid.startsWith("DIAGRAM_");
};


// used to center the node when it dragged and dropped
export const createNewNodeCoords = (x, y, draggedIn=true) => {
    if(draggedIn) {
        x = x - (nodeProps.width / 2);
        y = y - nodeProps.minHeight;
    } else {
        x = x + 50; // add gutter
        // y = y;
    }

    return { x, y }
}




// modifiedPairs from stateless assumes the pathPairs is being built backwards (starting with the observable)
export const modifiedPairsForward = (pathPairs, projCharGuid) => {
    const goingUp = (idx, pathGuids) => {
        const currentHop = pathGuids[idx];
        const prevHop = pathGuids[idx - 1];

        if (currentHop.xmiType === "conceptual:Composition") return false;
        if (idx === 0) return currentHop.type === projCharGuid;

        const currentTypeGuid = currentHop.type;
        const prevTypeGuid = prevHop.type;

        if(currentTypeGuid !== prevTypeGuid) {
            return currentTypeGuid === prevHop.parent.guid;

        } else {
            if(prevHop.xmiType === "conceptual:Composition") {
                return true;
            } else {
                return !prevHop.goingUp;
            }
        }
    }

    pathPairs.forEach((pair, idx) => {
        pair["goingUp"] = goingUp(idx, pathPairs);
    });
    return pathPairs;
}



export const generateTextPath = ({pathPairs=[], pathHeadGuid, showPathHead=false}, $app) => {
    if(!pathPairs.length) return null;

    const pathHead = $app.getOptionNode(pathHeadGuid);
    pathPairs = modifiedPairsForward(pathPairs, pathHeadGuid);

    const pathText = pathPairs.map((pair, idx) => {
        let goingUp = pathPairs[idx]["goingUp"];
        return goingUp ? `->${pair.rolename}[${pair.parent.name}]` : `.${pair.rolename}`;
    })

    if(showPathHead) pathText.unshift(pathHead.name);
    return pathText.join("");
}





// isDifferent is used to see if the given node was edited.
// node1 usually consists of the current node
// node2 usually contains the original values
const str_whitelist = new Set(["name", "rolename", "xmiType", "description", "descriptionExtension", "type", "projectedCharacteristic", "path", "measurement", "optional", "lowerBound", "upperBound", "sourceLowerBound", "sourceUpperBound"]);
const obj_whitelist = new Set([]);

export const isDifferent = (node1, node2) => {
    if (!node1 || !node2) return true;

    for (let key in node1) {
        if ((str_whitelist.has(key) && node1[key] !== node2[key]) ||
            (obj_whitelist.has(key) && node1[key].guid !== node2[key].guid)) return true;

        let node1Type = typeof node1[key];
        // let node2Type = typeof node2[key];

        // Special Cases:
        switch(key) {
            // regular char: platformType can be a string (guid) or an object (new node)
            // nested char: platformType is null
            case "platformType":
              if(node1[key] && node1Type !== "string") return true;
        }
    }

    return false;
}

export const formatComposition = (comp) => {
  comp.type = typeof comp.type === 'string' ? comp.type : comp.type?.guid;
  comp.effectiveType = typeof comp.effectiveType === 'string' ? comp.effectiveType : comp.effectiveType?.guid;
  
  if(Array.isArray(comp.pathPairs)) {
      comp.pathPairs = comp.pathPairs.map(pair => formatPathAttr(pair, pair.parent))
  }
}

export const formatCharacteristic = (char, methods) => {
  // flatten path pairs && determine if path is deprecated
  if(Array.isArray(char.pathPairs)) {
      char.pathPairs = char.pathPairs.map(pair => formatPathAttr(pair, pair.parent));
      char.isPathDeprecated = char.pathPairs.some(pair => pair.deprecated === "true")
  }

  if(isNestingChar(char) && methods) {
      // redirect pointer to existing view
      const {viewType} = char;
      const existingView = methods.getOptionNode(viewType.guid);

      if(existingView) {
          char.viewType = existingView;
      } else {
          char.viewType = {
              guid: viewType.guid,
              xmiType: viewType.xmiType,
              name: viewType.name,
              description: viewType.description,
          }
      }
  }
}




/*
    * PATH BUILDER
    */




// gets the closest anchor points for both SrcNodeModel and TarNodeModel
export const getClosestAnchorPoints = (srcNodeModel, dstNodeModel) => {
    // edge case where the nodeModel has a width/height of 0
    const src_width = srcNodeModel.width || nodeProps.minWidth;
    const src_height = srcNodeModel.height || nodeProps.minHeight;

    // edge case where the nodeModel has a width/height of 0
    const tar_width = dstNodeModel.width || nodeProps.minWidth;
    const tar_height = dstNodeModel.height || nodeProps.minHeight;

    let closestSrc = 0;
    let closestTar = 0;
    let shortestDistance = Infinity;

    modellingPortPosition.forEach(([tar_x, tar_y], tar_idx) => {
        // skip middle points (position 3 and 7)
        if(tar_idx === 3 || tar_idx === 7) return;

        // calc target anchor position
        const target = [dstNodeModel.getX() + (tar_width * tar_x), dstNodeModel.getY() + (tar_height * tar_y)];

        modellingPortPosition.forEach(([src_x, src_y], src_idx) => {
             // skip middle points (position 3 and 7)
            if(src_idx === 3 || src_idx === 7) return;

            // calc source anchor position
            const source = [srcNodeModel.getX() + (src_width * src_x), srcNodeModel.getY() + (src_height * src_y)];

            // distance
            const dX = Math.pow(source[0] - target[0], 2);
            const dY = Math.pow(source[1] - target[1], 2);
            let distance = Math.sqrt(dX + dY);

            if(distance < shortestDistance) {
                shortestDistance = distance;
                closestSrc = src_idx;
                closestTar = tar_idx;
            }
        })
    })

    return {
        source: closestSrc,
        target: closestTar
    };
}


export const getPositionOfTarget = (srcNodeModel, dstNodeModel) => {
    // edge case where the nodeModel has a width/height of 0
    const src_width = srcNodeModel.width || nodeProps.minWidth;
    const src_height = srcNodeModel.height || nodeProps.minHeight;

    // edge case where the nodeModel has a width/height of 0
    const dst_width = dstNodeModel.width || nodeProps.minWidth;
    const dst_height = dstNodeModel.height || nodeProps.minHeight;

    const src_left_x = srcNodeModel.getX();
    const src_right_x = srcNodeModel.getX() + src_width;
    const src_top_y = srcNodeModel.getY();
    const src_bot_y = srcNodeModel.getY() + src_height;

    const dst_left_x = dstNodeModel.getX();
    const dst_right_x = dstNodeModel.getX() + dst_width;
    const dst_top_y = dstNodeModel.getY();
    const dst_bot_y = dstNodeModel.getY() + dst_height;

    if(dst_left_x > src_right_x) {
        return "right";
    } else if(src_left_x > dst_right_x) {
        return "left";
    } else if(dst_top_y > src_bot_y) {
        return "bottom";
    } else if(src_top_y > dst_bot_y) {
        return "top";
    }


    // if(dst_top_y > src_bot_y) {
    //     return "bottom";
    // } else if(src_top_y > dst_bot_y) {
    //     return "top";
    // } else if(dst_left_x > src_right_x) {
    //     return "right";
    // } else if(src_left_x > dst_right_x) {
    //     return "left";
    // }

    return false;
}


export const getLatestPortPosition = (nodeModel, targetGuid) => {
    const portGuids = Object.keys(nodeModel.getOutPorts());

    for(let i = portGuids.length - 1; i >= 0; i--) {
        const portGuid = portGuids[i];
        if(portGuid.split("--")[1]) continue;   // skip path outports

        const portName = nodeModel.getOutPorts()[portGuid];
        const port = nodeModel.getPort(portName);

        if(port) {
            const attr = port.getAttrData();
            if(attr.type === targetGuid) return port.options.position;
        }
    }
    return false;
}



export const getDomHeight = (domEle) => {
    if(!domEle) return 0;

    const rect = domEle.getBoundingClientRect();
    return rect.height;
}








export const showRemovalConfirm = (attr, callback) => {

    if(isDiagramNode(attr.guid)) {
        BasicConfirm.show(<div>
            Are you sure you want to <span style={{color:"red"}}>remove</span> {attr.name || attr.rolename} from the diagram?
        </div>, callback);
    } else {
        getDeletableStatus(attr.guid).then(res => {
            const response = JSON.parse(res);
            const action = response.data.deletable ? "delete" : "deprecate";

            BasicAlert.hide();
            BasicConfirm.show(<div>
                <div>Are you sure you want to <span style={{color:"red"}}>{action}</span> {attr.name || attr.rolename} from the <span style={{fontWeight:600}}>model?</span></div>
                <div style={{fontStyle:"italic"}}>By confirming this action, the change will be reflected in the model.</div>
            </div>, () => callback(response.data.deletable))
        })
    }
}


export const showCannotRemoveBcProjection = (viewMap, attr) => {
    BasicAlert.show(<div>
        <div><b>{attr.name || attr.rolename}</b>, cannot be deleted because it is used in one (or more) projection path(s).</div>
        <div>Please clear the projection path first.</div>
        <br />
        <div>
            <label>Projectors in use:</label>
            <ul>
                {Object.values(viewMap).map((view) => {
                    return (
                        <li>{isDiagramNode(view.guid) ?
                                <span style={{fontSize:14}}>{view.name}</span>
                            :
                                <CadetLink newPage={true} node={view}
                                            style={{fontSize:14}} />
                        }</li>
                    )
                })}
            </ul>
        </div>
    </div>, "Warning", true);
}




export const createBoundText = (lower, upper) => {
  const convert = (val) => val === "-1" ? "*" : (val || "1");
  return `${lower || "1"}..${convert(upper)}`;
}

















