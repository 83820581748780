import React from "react";
import { connect } from "react-redux";
import { PhenomLabel } from "../../util/stateless";
import {MergeErrorConfirm} from "./merge_error_confirm"
import PhenomLoadButton from "../../widget/LoaderButton";
import PhenomId from "../../../requests/phenom-id";
import { ManageSubMenu } from "./model_manage";
import ChangeSetManager from "./sections/ChangeSetManager";
import NavTree from "../../tree/NavTree";
import * as actionCreators  from "../../../requests/actionCreators";

class Push extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };
  }

  componentDidMount() {
    NavTree.collapseNavTree(false);
    // NavTree.fetchRelationsMap();
  }

  componentDidUpdate(prevProps) {

  }

  handleSelectAll = () => {
    NavTree.selectAllMergeCandidates();
  };

  selectNecessaryNodes = () => {
    NavTree.selectMergeCandidates( this.neededNodes.map(n => n.guid) );
  }

  selectSingleNode = (guid) => {
    NavTree.selectMergeCandidates([ guid ]);
  }

  handlePush = () => {
    const rejected = (errText) => {
      typeof errText === "string" && actionCreators.receiveErrors(errText);
    }

    const resolved = (response) => {
        if (response.status === "success") {
          actionCreators.receiveLogs("Push request registered.");
        } else if (Array.isArray(response.invariantErrors)) {
          this.setNeededNodes(response.invariantErrors);
          this.getAbsentParents();
          MergeErrorConfirm.show(this.neededNodes, this.selectNecessaryNodes, this.selectSingleNode);
        }
    }

    return NavTree.merge("push").then(resolved, rejected);
  }

  // this logic is from the original code
  getAbsentParents = () => {
    NavTree.getSelectedMergeCandidates().forEach(leaf => {
      const parentLeaf = leaf.getParentLeaf();

      if (parentLeaf && parentLeaf.isMergeCandidate() && !parentLeaf.isMarkedForMerge()) {
        const node = {};
        node["name"] = parentLeaf.getName();
        node["guid"] = parentLeaf.getGuid();
        node["error_type"] = "PARENT_ABSENT";
        node["error_name"] = leaf.getName();
        node["error_guid"] = leaf.getGuid();
        node["failure"] = false
        this.neededNodes.push(node);
      }
    })
  }

  // this logic is from the original code
  //    - "neededNode" is a guid
  setNeededNodes(response) {
    this.neededNodes = []
    const treeMap = NavTree.getSelectedMergeCandidates().map(leaf => leaf.getGuid());

    response.forEach((item, i) => {
      if (!treeMap.includes(item.neededNode)) {
        const neededLeaf = NavTree.getLeafNode(item.neededNode);

        const failure = !neededLeaf
        const node = {};
        node["name"] = !failure ? neededLeaf.getName() : "Not Found"
        node["guid"] = item.neededNode
        node["error_type"] = item.type
        node["error_name"] = item.nodes[0]["name"] ? item.nodes[0]["name"] : item.nodes[0]["rolename"]
        node["error_guid"] = item.nodes[0]["xmi:id"]
        node["failure"] = failure
        this.neededNodes.push(node)
      }
    })
  }

  render() {
    const phenomId = new PhenomId("push",this.props.idCtx);
    const cinc_works = this.props.userRole.indexOf('c') !== -1;
    return <div className="phenom-content-wrapper">
              <ManageSubMenu />
              <MergeErrorConfirm idCtx={phenomId.genPageId()}/>

              <div id={phenomId.gen("","wrapper")} className="branch-wrapper">
                <PhenomLabel text="Push" />
                <p>Select the nodes from the nav tree to push and click Request Push. Pushing changes to the parent branch requires parent administrator approval.</p>
                <p>If you are pushing enumerations, please ensure that all pieces necessary have been selected before pushing.</p>

                <div id={phenomId.gen("changes","wrapper")}>
                  <br />
                  <div className="flex-h" style={{alignItems: "center"}}>
                    <PhenomLoadButton onClick={this.handleSelectAll} disabled={cinc_works} text={"SELECT ALL"} id={phenomId.gen("changes","select-all-button")} style={{marginRight: 15}} />
                    <PhenomLoadButton onClick={this.handlePush} disabled={cinc_works} text={"REQUEST PUSH"} id={phenomId.gen("changes","request")}/>
                  </div>
                  {/* <div id={phenomId.gen("init","success-status")} /> */}
                </div>
              </div>

              <div className="branch-wrapper" style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <PhenomLabel text="Push Change Sets" style={{ marginBottom: 10 }} />
                <ChangeSetManager mergeMode="push" />
              </div>
            </div>
    
    
    
  }
}



const msp = (state) => ({
  userRole: state.user.userRole,
})


export default connect(msp)(Push)