import {CadetInput, CadetTextArea, LineLabel, PhenomSelect, PhenomLabel} from "../util/stateless";
import {Notifications} from "../edit/notifications";
import { SubMenuRight } from "../edit/edit-top-buttons";
import React from "react";
import { connect } from "react-redux";
import CodeMirror from "codemirror";
import $ from "jquery";
import "./../../libs/codemirror/clike";
import "./../../libs/codemirror/css";
import "./../../libs/codemirror/htmlmixed";
import "./../../libs/codemirror/javascript";
import "./../../libs/codemirror/xml";
import PhenomId from "../../requests/phenom-id";
import { applyPresetFilters, receiveResponse, receiveErrors, receiveLogs } from "../../requests/actionCreators"
import LoaderButton from "../widget/LoaderButton";
import NavTree from "../tree/NavTree";
import {getDateTimeForFilename} from "../util/util";
import { _ajax } from "../../requests/sml-requests";

class Templates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportBy: "Entire Model",
            templateOptions: {"loading...": true},
            invalidTemplates: [],
            templateName: null,
        };

        this.pageCodeMirror = undefined;
        this.noticeRef = undefined;
        this.originalData = undefined;
    }

    REPORT_BY_LIST = [
      {
        reportBy: "Entire Model",
        text: "None",
      },
      {
        reportBy: "Portable Component (UoP)",
        text: "UoPs",
      },
      {
        reportBy: "View",
        text: "Views",
      },
      {
        reportBy: "Tag_Filter",
        text: "Tags",
      },
    ]

    componentDidMount() {
        const pageDiv = document.getElementById("templateTextArea");
        this.pageCodeMirror = CodeMirror.fromTextArea(pageDiv, {
            htmlMode: true,
            mode: "htmlmixed",
            lineNumbers: true,
            tabMode: "indent"
        });
        this.pageCodeMirror.setSize(null, 355);
        this.pageCodeMirror.on("change", (cMir) => {
            cMir.save();
            this.setState({templateContent: cMir.getTextArea().value});
        });
        $.ajax({
            url: "/index.php?r=/template/index",
            method: "get"
        }).then(res => {
            const response = JSON.parse(res);
            const newTempalteOptions = {};

            if (response.valid_templates) {
                response.valid_templates.forEach(tmpl => newTempalteOptions[tmpl] = true);
            }
            if (response.invalid_templates) {
                response.invalid_templates.forEach(tmpl => newTempalteOptions[tmpl] = false);
            }
            this.setState({templateOptions: newTempalteOptions});
        });
    }


    componentDidUpdate(_, prevState) {
      // if (this.state.reportBy !== prevState.reportBy) {
      //   switch (this.state.reportBy) {
      //     case "Portable Component (UoP)":
      //       applyPresetFilters("uops");
      //       break;
      //     case "View":
      //       applyPresetFilters("views");
      //       break;
      //     case "Main_Program":
      //       applyPresetFilters("mps");
      //       break;
      //     case "Tag_Filter":
      //       applyPresetFilters("tags");
      //       break;
      //   }
      // }
    }

    saveTemplate = () => {
        return $.ajax({
            url: "/index.php?r=/template/post",
            method: "post",
            data: this.state,
        }).then(res => {
            const response = JSON.parse(res).response;
            const templOpts = Object.assign({}, this.state.templateOptions);

            if (response === true) {
                this.noticeRef.info(`Changes to ${this.state.templateName} saved.`);
                templOpts[this.state.templateName] = true;
            } else {
                let errorTxt = response.split("FreeMarker template error:")[1];
                errorTxt = errorTxt.split("Java stack trace")[0];
                this.noticeRef.error([`${this.state.templateName} was saved, but is invalid because of the following errors:`, errorTxt]);
                templOpts[this.state.templateName] = false;
            }
            this.setState({templateOptions: templOpts});
        });
    };

    launchFetch = e => {
        this.setState({templateName: e.target.value, templateContent: "loading...", errors: null});
        this.pageCodeMirror.setValue("loading...");
        const stateDup = Object.assign({}, this.state);
        delete stateDup["templateContent"];
        stateDup["templateName"] = e.target.value;

        _ajax({
            url: "/index.php?r=/template/get",
            method: "get",
            data: stateDup,
        }).then(res => {
            this.setPageFromData(res);
        }).catch(() => {
            this.pageCodeMirror.setValue("loading failed.");
        })
    };

    requestGeneratedFile = () => {
      const { reportBy } = this.state;

      const downloadFileType = "template";
      const { includeTags, excludeTags } = NavTree.getTagFilters();

      return $.ajax({
          url: "/index.php?r=/generate/generate-model-file",
          method: "post",
          data: {
              fileType: "template",
              reportBy,
              template: [this.state.templateName],
              selectedNodes: reportBy === "Entire Model" ? [] : window["treeRef"].getSelectedGuids(),
              includeTags: reportBy === "Tag_Filter" ? [...includeTags].join(",") : "",
              excludeTags: reportBy === "Tag_Filter" ? [...excludeTags].join(",") : "",
          }
      }).then(res => {
          const response = JSON.parse(res);
          receiveResponse(response);

          if (response.filename) {
            receiveLogs("Model file generated.");
            const newName = `${this.props.userIdentity.branchName}_${downloadFileType}${getDateTimeForFilename()}.${response.filename.match(/(?!\.)\w+$/)}`;
            window.location.href = `${process.env.NODE_ENV === "development" ? "http://localhost" : ""}/index.php?r=/generate/download-file&file=${response.filename}&newName=${newName}`;
          }
      }).fail(err => {
        receiveErrors("An error occurred while trying to generate your model. Please contact Skayl support for more help.");
      })
    };

    setPageFromData(response) {
        this.originalData = response;
        if (response.errors) {
            let errorTxt = response.errors.split("FreeMarker template error:")[1];
            errorTxt = errorTxt.split("Java stack trace")[0];
            response.errors = errorTxt;
        }
        this.setState(response);

        let langMode;

        if (this.state.templateName) {
            const langMap = {
                "html": "htmlmixed",
                "cpp": "text/x-c++src"
            };

            const foundLang = this.state.templateName.split(".")[1];
            langMode = langMap[foundLang];
        }

        this.pageCodeMirror.setOption("mode", langMode);
        this.pageCodeMirror.setValue(response.templateContent);
        this.pageCodeMirror.setCursor(this.pageCodeMirror.lineCount(), 0);
    }

    resetTemplate = () => {
        if (this.originalData) {
            this.setPageFromData(this.originalData);
        } else {
            this.clearPage();
        }
    };

    clearPage = () => {
        this.originalData = null;
        this.setState({
            templateName: "",
            templateContent: null,
            errors: null,
        });
        this.pageCodeMirror.setValue("");
    };

    render() {
        const phenomId = new PhenomId("templates",this.props.idCtx);
        return (
            <div className="phenom-content-wrapper">
                <nav className="sub-menu-actions" aria-label='form actions'>
                  <SubMenuRight>
                    <button id="form-action-save"
                            className="fas fa-save"
                            title="Save"
                            onClick={this.saveTemplate} /> 
                    <button id="form-action-clear"
                            className="fas fa-eraser"
                            title="Clear"
                            onClick={this.clearPage} /> 
                    <button id="form-action-reset"
                            className="fas fa-undo"
                            title="Reset"
                            onClick={this.resetTemplate} />
                  </SubMenuRight>
                </nav>
                <Notifications ref={ele => this.noticeRef = ele} />

                <div className="phenom-content-scrollable subview-wrapper">
                  <PhenomLabel text="TEMPLATES" id={phenomId.gen("","")}/>
                  <div className="flex-h" style={{marginTop: 5, justifyContent: "space-between"}}>
                      <select className="cadet-select" style={{width: "20%"}} size={17} value={null}
                          onChange={this.launchFetch}
                          id={phenomId.gen("","select")}>
                          <option value disabled selected style={{display: "none"}} id={phenomId.gen(["init","default"],"option")}></option>
                          {Object.keys(this.state.templateOptions).sort((a, b) => a.localeCompare(b)).map((tmpl,tIdx) => <option
                              id={phenomId.gen(["init",tIdx],"option")}
                              value={tmpl} style={{
                                  fontSize: 17,
                                  color: this.state.templateOptions[tmpl] ? null : "crimson",
                                  fontStyle: this.state.templateOptions[tmpl] ? "normal" : "italic"
                              }}>{tmpl}</option>)}
                      </select>
                      <div style={{width: "79%"}}>
                          <CadetInput onChange={(e) => this.setState({templateName: e.target.value})}
                              text={this.state.templateName} />
                          <CadetTextArea onChange={true} style={{height: 345}} id="templateTextArea"/>
                      </div>
                  </div>
                  {!this.state.errors || <PhenomLabel text="Errors" id={phenomId.gen("init","errors")}/>}
                  <div style={{fontSize: "90%", color: "crimson", margin: "10px 0"}}>{this.state.errors}</div>

                  <PhenomSelect label="Filter By"
                                id={phenomId.gen(["init","reportBy"])}
                                data={this.REPORT_BY_LIST}
                                value={this.state.reportBy}
                                dataItemKey="reportBy"
                                textField="text"
                                onChange={(e) => {
                                  this.setState({ reportBy: e.target.value })
                                }}
                                wrapperProps={{
                                  style:{ maxWidth: 300 },
                                }} />

                    <LoaderButton id={phenomId.gen("init","generate-button")}
                                  className='filled-button2'
                                  text="GENERATE"
                                  onClick={this.requestGeneratedFile} />
                </div>
            </div>
        );
    }
}




const msp = (state) => ({
    userIdentity: state.user.userIdentity,
})

export default connect (msp)(Templates);