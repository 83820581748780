import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core'
import ReactTooltip from 'react-tooltip';
import { getModelStats } from '../../../requests/sml-requests';
import { receiveErrors } from '../../../requests/actionCreators';

const dashKF = keyframes`
  100%{
    stroke-dashoffset: 0;
  }
`

const StyledSVG = styled.svg`
  path,
  rect {
    stroke: #ffffff;
    cursor: default;
  }

  // path {
  //   stroke-dasharray: 1604;
  //   stroke-dashoffset: 1604;
  //   animation: ${dashKF} 2s linear forwards infinite;
  // }

  text {
    dominant-baseline: middle;
    text-anchor: middle;
  }

  
`



class ProjectOverview extends React.Component {
  containerRef = React.createRef();
  svgNS = "http://www.w3.org/2000/svg";

  observer = null;
  desiredHeight = 0;
  desiredWidth = 0;

  state = {
    numEntities: 0,
    numAssociations: 0,
    numCompositions: 0,
    numAssociatedEntities: 0,
    numSpecializations: 0,

    numViews: 0,
    numCharacteristicsToObs: 0,
    numCharacteristicsToComplex: 0,

    numUniqueProjections: 0,
    numDuplicateContexts: 0,
  }


  componentDidMount() {
    getModelStats().then(res => {
      this.setState({ ...JSON.parse(res) }, () => {
        this.init();

        const parent = this.containerRef.current.parentElement;
        this.observer = new ResizeObserver(this.init);
        this.observer.observe(parent);
      });
    }).catch(err => {
      receiveErrors('Something went wrong. Failed to retrieve Model Stats');
    });
  }

  componentWillUnmount() {
    this.observer && this.observer.disconnect();
  }


  init = () => {
    const parent = this.containerRef.current.parentElement;
    const parentRect = parent.getBoundingClientRect();
    const parentGapString = getComputedStyle(parent,null).getPropertyValue('gap');
    const parentGap = parseInt(parentGapString, 10);    // remove the "px" and convert to number
    const headerRect = this.containerRef.current.querySelector("header").getBoundingClientRect();
    
    this.desiredWidth = (parentRect.width - parentGap) / 2;
    this.desiredHeight = parentRect.height - headerRect.height;
    this.createSections();
  }

  createSections = () => {
    const { numEntities, numAssociations, numSpecializations, numCompositions, numAssociatedEntities, numViews, numCharacteristicsToObs, numCharacteristicsToComplex, numUniqueProjections, numDuplicateContexts } = this.state;

    // Clear children
    const svg = this.containerRef.current.querySelector('svg');
    while (svg.firstChild) {
      svg.removeChild(svg.lastChild);
    }

    const allEntities = numEntities + numAssociations;
    const allAttributes = numCompositions + numAssociatedEntities;


    // Width
    const fixedSpecWidth = 40;
    const fixedCurveBoxWidth = 30;
    const fixedCurveWidth = 200 - fixedCurveBoxWidth;
    const sectionWidth = Math.max(this.desiredWidth - fixedCurveWidth - fixedCurveBoxWidth - fixedSpecWidth, 0) / 2;   // subtract fixed widths

    // Height
    let heightScale = 1.0;
    if ((numUniqueProjections + numDuplicateContexts) > allAttributes) {
        heightScale = allAttributes / (numUniqueProjections + numDuplicateContexts);
    }

    // Just rescale the desired height and base the calculations off of that!
    const scaledDesiredHeight = this.desiredHeight * heightScale;

    // Entities
    const heightOfEntities =  numEntities / allEntities * scaledDesiredHeight || 0;
    const heightOfAssociations = numAssociations / allEntities * scaledDesiredHeight || 0;
    const heightOfSpecializations = numSpecializations / allEntities * scaledDesiredHeight || 0;
    const widthOfEntities = numEntities / heightOfEntities || 0;
    // const widthOfAssociations = numAssociations / heightOfAssociations;

    // Attributes
    const heightOfAttrs = scaledDesiredHeight;
    const widthOfAttrs = allAttributes / this.desiredHeight || 0;

    // Characteristics
    const totalProjections = numUniqueProjections + numDuplicateContexts;
    // const projectionsToAttributes = totalProjections / allAttributes || 0;
    const allChars = numCharacteristicsToObs + numCharacteristicsToComplex;
    const heightOfChars = totalProjections / allAttributes * scaledDesiredHeight || 0;
    const widthOfChars = allChars / heightOfChars || 0;

    // Views
    const heightOfViews = heightOfChars;
    const widthOfViews = numViews / heightOfChars || 0;

    // Total Width
    const totalEntityWidth = widthOfEntities + widthOfAttrs;
    const totalViewWidth = widthOfChars + widthOfViews;

    // top left corner
    let start_x = 0;
    let start_y = 0;

    // --------------------------------------------------
    // Dimension of Specilizations
    // --------------------------------------------------
    const specs = {
      label: "Specializations",
      value: numSpecializations.toLocaleString("en-US"),
      fill: "hsl(0 60% 60%)",
      height: heightOfSpecializations,
      width: fixedSpecWidth,
      start: { x: start_x, y: start_y },
    }

    // --------------------------------------------------
    // Dimension of Entities
    // --------------------------------------------------
    start_x += fixedSpecWidth;
    start_y = 0;

    const entities = {
      label: "Entities",
      value: numEntities.toLocaleString("en-US"),
      fill: "hsl(267 60% 70%)",
      height: heightOfEntities,
      width: widthOfEntities / totalEntityWidth * sectionWidth || 0,
      start: { x: start_x, y: start_y },
    }

    // --------------------------------------------------
    // Dimension of Associations
    // --------------------------------------------------
    const associations = {
      label: "Associations",
      value: numAssociations.toLocaleString("en-US"),
      fill: "hsl(267 40% 60%)",
      height: heightOfAssociations,
      width: widthOfEntities / totalEntityWidth * sectionWidth || 0,
      start: { x: entities.start.x, y: entities.height },
    }

    // --------------------------------------------------
    // Dimension of Attributes
    // --------------------------------------------------
    start_x += entities.width;
    start_y = 0;

    const attrs = {
      label: "Attributes",
      value: allAttributes.toLocaleString("en-US"),
      fill: "hsl(267 60% 80%)",
      height: heightOfAttrs,
      width: widthOfAttrs / totalEntityWidth * sectionWidth || 0,
      start: { x: start_x, y: start_y },
    }

    // --------------------------------------------------
    // Dimension of Curves
    // --------------------------------------------------
    start_x += attrs.width;
    start_y = 0;

    // left side
    const heightOfCurves_left = numUniqueProjections / allAttributes * heightOfAttrs || 0;
    const heightOfUniques_left = numUniqueProjections / totalProjections * heightOfCurves_left || 0;
    const heightOfDuplicates_left = numDuplicateContexts / totalProjections * heightOfCurves_left || 0;

    const heightOfUniques_right = numUniqueProjections / totalProjections * heightOfChars || 0;
    const heightOfDuplicates_right = numDuplicateContexts / totalProjections * heightOfChars || 0;

    // const percentHeightOfObs = numCharacteristicsToObs / allChars;
    // const percentHeightOfComplex = numCharacteristicsToComplex / allChars;

    const curveBox = {
      label: "Percentage of Projected Attributes",
      // value: (projectionsToAttributes * 100).toFixed() + "%",
      fill: "hsl(var(--skayl-sky-hs) 65%)",
      height: heightOfUniques_left + heightOfDuplicates_left,
      width: fixedCurveBoxWidth,
      start: { x: start_x, y: start_y },
    }

    start_x += curveBox.width;
    start_y = 0;

    // point1 -> top left
    // point2 -> top right
    // point3 -> bot right
    // point4 -> bot left
    const uniques = {
      label: "Unique Projections",
      value: numUniqueProjections.toLocaleString("en-US"),
      fill: "hsl(var(--skayl-sky-hs) 80%)",
      height: Math.max(heightOfUniques_left, heightOfUniques_right),
      width: fixedCurveWidth,
      start: { x: start_x, y: start_y },
      point2: { x: start_x + fixedCurveWidth, y: start_y },
      point3: { x: start_x + fixedCurveWidth, y: heightOfUniques_right },
      point4: { x: start_x, y: heightOfUniques_left },
    }

    // control points between point1 and point2
    uniques["control1"] = { x: start_x + fixedCurveWidth / 2, y: start_y }
    uniques["control2"] = { x: start_x + fixedCurveWidth / 2, y: start_y }

    // control points between point3 and point4
    uniques["control3"] = { x: start_x + fixedCurveWidth / 2, y: uniques.point3.y }
    uniques["control4"] = { x: start_x + fixedCurveWidth / 2, y: uniques.point4.y }
    
    const duplicates = {
      label: "Duplicate Projections",
      value: numDuplicateContexts.toLocaleString("en-US"),
      fill: "hsl(var(--skayl-blue-hs) 50%)",
      height: Math.max(heightOfDuplicates_left, heightOfDuplicates_right),
      width: fixedCurveWidth,
      start: { x: start_x, y: uniques.point4.y },
      point2: { x: start_x + fixedCurveWidth, y: uniques.point3.y },
      point3: { x: start_x + fixedCurveWidth, y: uniques.point3.y + heightOfDuplicates_right },
      point4: { x: start_x, y: uniques.point4.y + heightOfDuplicates_left },
    }

    // control points between point1 and point2
    duplicates["control1"] = { x: start_x + fixedCurveWidth / 2, y: duplicates.start.y }
    duplicates["control2"] = { x: start_x + fixedCurveWidth / 2, y: duplicates.point2.y }

    // control points between point3 and point4
    duplicates["control3"] = { x: start_x + fixedCurveWidth / 2, y: duplicates.point3.y }
    duplicates["control4"] = { x: start_x + fixedCurveWidth / 2, y: duplicates.point4.y }

    // --------------------------------------------------
    // Dimension of Characteristics
    // --------------------------------------------------
    start_x += fixedCurveWidth;
    start_y = 0;

    const chars = {
      label: "Characteristics",
      value: allChars.toLocaleString("en-US"),
      fill: "hsl(115 50% 80%)",
      height: heightOfChars,
      width: widthOfChars / totalViewWidth * sectionWidth || 0,
      start: { x: start_x, y: start_y },
    }

    // --------------------------------------------------
    // Dimension of Views
    // --------------------------------------------------
    start_x += chars.width;
    start_y = 0;

    const views = {
      label: "Views",
      value: numViews.toLocaleString("en-US"),
      fill: "hsl(115 50% 60%)",
      height: heightOfViews,
      width: widthOfViews / totalViewWidth * sectionWidth || 0,
      start: { x: start_x, y: start_y },
    }

    // Specializations
    const specGroup = this.createGroup(specs);
    const specRect = this.createRect(specs);
          specGroup.appendChild( specRect, ...this.createText(specs) );
          svg.appendChild(specGroup);
          this.modifyTextFields(specGroup, specs);

    // Entities
    const entGroup = this.createGroup(entities);
    const entRect = this.createRect(entities);
          entGroup.append( entRect, ...this.createText(entities) );

    // Associations
    const assocGroup = this.createGroup(associations);
    const assocRect = this.createRect(associations);
          assocGroup.append( assocRect, ...this.createText(associations) );

    const entAssocGroup = document.createElementNS(this.svgNS, "g");
          entAssocGroup.appendChild(entGroup);
          entAssocGroup.appendChild(assocGroup);
          svg.appendChild(entAssocGroup);
          this.modifyTextFields(entGroup, entities);
          this.modifyTextFields(assocGroup, associations);

    // Attributes
    const attrGroup = this.createGroup(attrs);
    const attrRect = this.createRect(attrs);
          attrGroup.append( attrRect, ...this.createText(attrs) );
          svg.appendChild(attrGroup);
          this.modifyTextFields(attrGroup, attrs);

    // Box Curve
    const curveBoxGroup = this.createGroup(curveBox);
    const curveBoxRect = this.createRect(curveBox);
          curveBoxGroup.append( curveBoxRect, ...this.createText(curveBox) );
          svg.appendChild(curveBoxGroup);
          this.modifyTextFields(curveBoxGroup, curveBox);

    // Uniques Curve
    const curveGroup = this.createGroup();
    const uniqueGroup = this.createGroup(uniques);
    const uniqueCurve = this.createCurvedBox(uniques);
          uniqueGroup.appendChild( uniqueCurve );
          curveGroup.appendChild(uniqueGroup);

    // Duplicates Curve
    const dupGroup = this.createGroup(duplicates);
    const dupCurve = this.createCurvedBox(duplicates);
          dupGroup.appendChild( dupCurve );
          curveGroup.appendChild(dupGroup);

    const uniqueGroupText = this.createGroup();
    const dupGroupText = this.createGroup();
          uniqueGroupText.append( ...this.createText(uniques) );
          dupGroupText.append( ...this.createText(duplicates) );
          curveGroup.append( uniqueGroupText, dupGroupText );
          svg.appendChild(curveGroup);
          this.modifyTextFields(uniqueGroupText, uniques);
          this.modifyTextFields(dupGroupText, duplicates);
    
    // Characteristics
    const charGroup = this.createGroup(chars);
    const charRect = this.createRect(chars);
          charGroup.append( charRect, ...this.createText(chars) );
          svg.appendChild(charGroup);
          this.modifyTextFields(charGroup, chars);

    // Views
    const viewGroup = this.createGroup(views);
    const viewRect = this.createRect(views);
          viewGroup.append( viewRect, ...this.createText(views) );
          svg.appendChild(viewGroup);
          this.modifyTextFields(viewGroup, views);

    // Foreign Object
    const foreignObject = this.createForeignObject(sectionWidth + fixedSpecWidth, sectionWidth, fixedCurveWidth + fixedCurveBoxWidth, entities.fill, views.fill, uniques.fill);
          svg.appendChild(foreignObject);

    ReactTooltip.rebuild();
    this.forceUpdate();
  }

  modifyTextFields = (domEle, data) => {
    const texts = domEle.querySelectorAll('text');
    let textWidth = 0;
    let textHeight = 0;

    // find max width and max height
    for (let text of texts) {
      const textRect = text.getBoundingClientRect();
      textHeight += textRect.height;
      textWidth = Math.max(textWidth, textRect.width);
    }

    for (let text of texts) {      
      if (textHeight > data.height ||
          textWidth > data.width - 20) {
        text.style.display = "none";
        continue;
      }
    }
  }

  createAnimate = (data, id, begin) => {
    const { height } = data;

    const animate = document.createElementNS(this.svgNS, "animate");
          animate.setAttribute("attributeName", "height");
          animate.setAttribute("from", 0);
          animate.setAttribute("to", height);
          animate.setAttribute("dur", 0.5);
          animate.setAttribute("fill", "freeze");
          if (id) animate.setAttribute("id", id);
          if (begin) animate.setAttribute("begin", `${begin}+0.25s`);
    return animate;
  }

  createGroup = (data) => {
    const group = document.createElementNS(this.svgNS, "g");
    if (data) {
      let hoverText = data.label;
      if (data.value) hoverText += `<br />${data.value}`;
      group.setAttribute('data-tip', hoverText);
      group.setAttribute('data-for', "hoverTip");
      group.setAttribute('data-multiline', true);
    }
    return group;
  }

  createRect = (data) => {
    const { start, width, height, fill } = data;
    const rect = document.createElementNS(this.svgNS, "rect");
          rect.setAttribute('x', start.x);
          rect.setAttribute('y', start.y);
          rect.setAttribute('width', width);
          rect.setAttribute('height', height);
          rect.setAttribute('fill', fill);
    return rect;
  }

  createText = (data) => {
    const { start, width, height } = data;
    const texts = [];
    const label = document.createElementNS(this.svgNS, "text");
          label.innerHTML = data.label;
          // label.setAttribute("opacity", 0);
          label.setAttribute('x', start.x + width / 2);
          label.setAttribute('y', start.y + height / 2);
          texts.push(label);

    // const labelAnimate = document.createElementNS(this.svgNS, "animate");
    //       labelAnimate.setAttribute("attributeName", "opacity");
    //       labelAnimate.setAttribute("values", "0;1");
    //       labelAnimate.setAttribute("begin", 0.5);
    //       labelAnimate.setAttribute("dur", 0.5);
    //       labelAnimate.setAttribute("fill", "freeze");

    // label.appendChild(labelAnimate);
    if (data.value) {
      var value = document.createElementNS(this.svgNS, "text");
          value.innerHTML = data.value;
          // value.setAttribute("opacity", 0);
          value.setAttribute('x', start.x + width / 2);
          value.setAttribute('y', start.y + 16 + height / 2);
          texts.push(value);
    }

    // const valueAnimate = document.createElementNS(this.svgNS, "animate");
    //       valueAnimate.setAttribute("attributeName", "opacity");
    //       valueAnimate.setAttribute("values", "0;1");
    //       valueAnimate.setAttribute("begin", 0.5);
    //       valueAnimate.setAttribute("dur", 0.5);
    //       valueAnimate.setAttribute("fill", "freeze");
      
    // value.appendChild(valueAnimate);
    return texts;
  }


  // Draws a curved box
  // -- starts at upper left corner (start.x, start.y)
  // -- moves to control points 1 and 2
  // -- moves to upper right corner (point2.x, point2.y)
  // -- moves to bot right corner (point3.x, point3.y)
  // -- moves to control points 3 and 4
  // -- moves to bot left corner (point4.x, point4.y)
  // -- moves back to start
  createCurvedBox = (data) => {
    const { start, point2, point3, point4, control1, control2, control3, control4 } = data;

    let coordinates = `
      M ${start.x}, ${start.y}
      C ${control1.x}, ${control1.y} ${control2.x}, ${control2.y} ${point2.x}, ${point2.y}
      L ${point3.x}, ${point3.y}
      C ${control3.x}, ${control3.y} ${control4.x}, ${control4.y} ${point4.x}, ${point4.y}
      L ${start.x}, ${start.y}
    `

    let path = document.createElementNS(this.svgNS, "path");
    path.setAttribute("d", coordinates);
    path.setAttribute("fill", data.fill);
    return path;
  }

  createForeignObject = (entityWidth=0, viewWidth=0, curveWidth=0, entityColor, viewColor, curveColor) => {
    let cssText = `
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
      border: 1px solid white;
    `;

    let foreignObject = document.createElementNS(this.svgNS, "foreignObject");
        foreignObject.setAttribute("id", "project-info");
        foreignObject.setAttribute("width", this.desiredWidth);
        foreignObject.setAttribute("height", this.desiredHeight);
        foreignObject.style.display = "none";

    let container = document.createElement("div");
        container.style.cssText = `
          display: flex;
        `
    let entitySection = document.createElement("div");
        entitySection.innerHTML = "This section shows some information about the conceptual level of your model, the entities, associations, and their attributes that describe your system."
        entitySection.style.cssText = `
          ${cssText}
          width: ${entityWidth}px;
          height: ${this.desiredHeight}px;
          background-color: ${entityColor};
        `

    let curveSection = document.createElement("div");
        curveSection.innerHTML = "This section shows the relationship between the two, the semantic mappings of the messages to the system."
        curveSection.style.cssText = `
          ${cssText}
          width: ${curveWidth}px;
          height: ${this.desiredHeight}px;
          background-color: ${curveColor};
        `

    let viewSection = document.createElement("div");
        viewSection.innerHTML = "This section shows the views and their attributes that represent your message set."
        viewSection.style.cssText = `
          ${cssText}
          width: ${viewWidth}px;
          height: ${this.desiredHeight}px;
          background-color: ${viewColor};
        `

    container.append(entitySection, curveSection, viewSection);
    foreignObject.appendChild(container);
    return foreignObject
  }

  handleMouseEnter = () => {
    if (!this.containerRef.current) return;
    const projectInfo = this.containerRef.current.querySelector("#project-info");
    if (!projectInfo) return;
    projectInfo.style.display = "block";
  }

  handleMouseExit = () => {
    if (!this.containerRef.current) return;
    const projectInfo = this.containerRef.current.querySelector("#project-info");
    if (!projectInfo) return;
    projectInfo.style.display = "none";
  }

  render() {
    return <div ref={this.containerRef}>
              <header style={{ display: "inline-flex", alignItems: "center", gap: 5 }}>
                <h1>Project Overview</h1>
                <span className="fas fa-info-circle"
                      style={{ padding: "0.25em" }}
                      onMouseEnter={this.handleMouseEnter}
                      onMouseLeave={this.handleMouseExit} 
                      data-tip="This is your project at a glance, it compiles a variety of different statistic about your project and presents it in a graphical format. This will automatically update as your project grows and changes." data-for="hoverTip" data-place="right" />
              </header>
              <StyledSVG xmlns={this.svgNS}
                        width={this.desiredWidth}
                        height={this.desiredHeight}
                        viewBox={`0 0 ${this.desiredWidth} ${this.desiredHeight}`} />
            </div> 
  }
}


export default ProjectOverview;