import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { PhenomLabel } from "../../util/stateless";
import {MergeErrorConfirm} from "./merge_error_confirm"
import LoaderButton from "../../widget/LoaderButton";
import PhenomId from "../../../requests/phenom-id";
import { ManageSubMenu } from "./model_manage";
import ChangeSetManager from "./sections/ChangeSetManager";
import NavTree from "../../tree/NavTree";
import * as actionCreators from "../../../requests/actionCreators";
import { _ajax } from "../../../requests/sml-requests";
import { debounce } from "lodash";
import loadingIcon from "../../../images/Palette Ring-1s-200px.gif";


class Approve extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkBoxesDisabled: false,
      pendingRequests: [],
      requestsInView: [],
      familiesInView: [],
      loading: false,
      contentOverride: false,
      deprecateDeletes: false,
      deprecateMoves: false,
      fetchPendingItems: false,
    };

    this.noticeRef = undefined;
    this.origBranchName = undefined;
    this.neededNodes = undefined;

    this.debouncedFetchMergeRequests = debounce(() => {
      this.setState({ checkBoxesDisabled: true });
      NavTree.fetchMergeRequests(this.state.requestsInView).then(() => {
        this.setState({ checkBoxesDisabled: false });
      })
    }, 3000);
  }

  componentDidMount() {
    NavTree.collapseNavTree(false);
    this.getPendingItems();
    // NavTree.fetchRelationsMap();
  }

  // note: currently a push request can go through without the parent
  //       so on the approve page it may not render but this method can still select it.
  handleSelectAll = () => {
    NavTree.selectAllMergeCandidates();
  };

  selectNecessaryNodes = () => {
    NavTree.selectMergeCandidates( this.neededNodes.map(n => n.guid) );
  }

  selectSingleNode = (guid) => {
    NavTree.selectMergeCandidates([ guid ]);
  }

  toggleDeleteDeprecation = () => {
    this.setState({ deprecateDeletes: !this.state.deprecateDeletes})
  }

  toggleMoveDeprecation = () => {
    this.setState({ deprecateMoves: !this.state.deprecateMoves})
  }

  getPendingItems() {
    this.setState({ fetchPendingItems: true });

    const customErrors = {
      500: "There was an error retrieving pending merge requests, please try again in a few minutes"
    }

    return _ajax({
      url: "/index.php?r=/referencing-model/pending-merge-requests",
    }, customErrors).then((res) => {
      const response = res.requesters;
      this.setState({
        pendingRequests: response,
        fetchPendingItems: false,
      });
    });
  }

  handleCheckbox = (e) => {
    const selectedRequest = this.state.pendingRequests.find(penReq => penReq.id == e.target.value);
    const familiesInView = [...this.state.familiesInView];
    const requestsInView = [...this.state.requestsInView];

    if (e.target.checked) {
      familiesInView.push(selectedRequest.familyId);
      requestsInView.push(selectedRequest.id);
    } else {
      const rmIdx1 = familiesInView.indexOf(selectedRequest.familyId);
      rmIdx1 > -1 && familiesInView.splice(rmIdx1, 1);

      const rmIdx2 = requestsInView.indexOf(selectedRequest.id);
      rmIdx2 > -1 && requestsInView.splice(rmIdx2, 1);
    }

    this.setState({ requestsInView, familiesInView }, () => {
      this.debouncedFetchMergeRequests();
    });
  }

  handleApprove = () => {
    const rejected = (errText) => {
      typeof errText === "string" && actionCreators.receiveErrors(errText);
    }

    const resolved = (response) => {
        if (response.status === "success") {
          actionCreators.receiveLogs("Merge Completed");
          NavTree.reset();
        } else {
          if(response.errors.length) {
            if (typeof response.errors[0] === 'string') {
              actionCreators.receiveErrors(response.errors);
            } else {
              const fixables = ["references", "unreachable"];
              const allFixable = response.errors.every(err => fixables.includes(err.method));
              if(allFixable) {
                this.setNeededNodes(response.errors);
                MergeErrorConfirm.show(this.neededNodes, this.selectNecessaryNodes, this.selectSingleNode);
              } else {
                actionCreators.receiveErrors(Array.from(new Set(response.errors.map(err => err.text))));
              }
            }
          } else {
            actionCreators.receiveErrors(["Failed to perform merge."]);
          }
        }
    }

    return NavTree.merge("approve", false, this.state.deprecateDeletes, this.state.deprecateMoves).then(resolved, rejected);
  }

  handleDeleteRequest = (branchRefId) => {
    this.setState({pendingRequests: this.state.pendingRequests.filter((x) => x.id != branchRefId)})
    this.setState({loading: true});
    $.ajax({
      url: "/index.php?r=/referencing-model/delete-merge-request",
      method: "post",
      data: {branchRefId}
    }).then((res) => {
      this.setState({loading: false});
      // const response = JSON.parse(res).requesters;
    });

  }
  
  // this logic is from the original code
  getAbsentParents = () => {
    NavTree.getSelectedMergeCandidates().forEach(leaf => {
      const parentLeaf = leaf.getParentLeaf();

      if (parentLeaf && parentLeaf.isMergeCandidate() && !parentLeaf.isMarkedForMerge()) {
        const node = {};
        node["name"] = parentLeaf.getName();
        node["guid"] = parentLeaf.getGuid();
        node["error_type"] = "PARENT_ABSENT";
        node["error_name"] = leaf.getName();
        node["error_guid"] = leaf.getGuid();
        node["failure"] = false
        this.neededNodes.push(node);
      }
    })
  }

  // this logic is from the original code
  //    - "neededNode" is a guid
  setNeededNodes(response) {
    this.neededNodes = [];
    response.filter(er => er.method === "references" || er.method === "unreachable").forEach((err, i) => {
        const neededGuid = err.value;
        const neededLeaf = NavTree.getLeafNode(neededGuid);

            const failure = !neededLeaf;
            const node = {};
            node["name"] = !failure ? neededLeaf.getName() : "Not Found";
            node["guid"] = neededGuid;
            node["error_type"] = err.method
            node["error_name"] = err.name;
            node["error_guid"] = err.guid;
            node["failure"] = failure;
            node["deleted"] = neededLeaf?.isDeletedForMerge();
            this.neededNodes.push(node);
    });
  }

  renderSelectors() {
    if (this.state.fetchPendingItems) {
      return <img style={{ width: 120 }}
                src={loadingIcon} />;
    }

    const model_groups = Object.values(this.state.pendingRequests.reduce((acc, req) => {acc[req.modelId] ? acc[req.modelId].push(req) :acc[req.modelId] = [req]; return acc;}, {}));

    return model_groups.map(model_group => {
       const {modelId: projectId, modelName: projectName} = model_group[0];
       const phenomId = new PhenomId("approve",this.props.idCtx);

       return (
        <div style={{marginBottom: 15}} key={projectId}>
          <div style={{marginBottom: 5}}><label>{projectName}</label></div>
          {model_group.map(model => {
            const selected = this.state.requestsInView.includes(model.id);
            const familyMemberSelected = this.state.familiesInView.includes(model.familyId);

            return (
              <div style={{display: "flex", height: 30}} key={model.id}>
                <input
                  type="checkbox"
                  value={model.id}
                  checked={selected}
                  disabled={(familyMemberSelected && !selected) || this.state.checkBoxesDisabled == true}
                  onChange={this.handleCheckbox}
                  style={{margin: "1px 5px 0px 15px"}}/>
                <label style={{marginTop: 5, marginRight: 15}}>{model.name}</label>
                <LoaderButton className="bordered-button no-right-margin"
                  idCtx={phenomId.gen("","delete-merge-request")}
                  style={{margin: 0}}
                  text="DELETE"
                  onClick={(id) => this.handleDeleteRequest(model.id)} />
              </div>)})}
        </div>);
    });
  }

  render() {
    const phenomId = new PhenomId("approve",this.props.idCtx);
    const cinc_works = this.props.userRole.indexOf('c') != -1;
    return <div className="phenom-content-wrapper">
              <ManageSubMenu />
              <MergeErrorConfirm idCtx={phenomId.gen()} />

              <div id={phenomId.gen("branch","wrapper")} className="branch-wrapper">
                <PhenomLabel text="Approve" />
                <p style={{marginBottom: 30}}>Select the nodes from the nav tree to pull and click Approve. Pulling is immediate and will overwrite your current nodes. This action cannot be undone. If you are approving enumerations, please ensure that all pieces necessary have been selected before approving.</p>
                
                <div id={phenomId.gen("","wrapper")}>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    {this.renderSelectors()}
                  </div>
                  <div id={phenomId.gen("","wrapper")}
                      className="flex-h">
                      <LoaderButton onClick={this.handleSelectAll} disabled={cinc_works} text="SELECT ALL" id={phenomId.gen("","select-all-button")} style={{marginRight: 15}} />
                      <LoaderButton
                        idCtx={phenomId.gen("","approve")}
                        text="APPROVE"
                        onClick={this.handleApprove}
                        disabled={this.state.loading || cinc_works}
                        style={{marginLeft: "0"}}
                      />
                    <div style={{marginLeft: "15px", display: "flex"}}><input type="checkbox" checked={this.state.deprecateDeletes} onChange={this.toggleDeleteDeprecation}
                                                                          id={phenomId.gen("deprecations","deletes-as-deprecations-checkbox")} style={{paddingLeft: "50px"}} />
                    <div style={{marginTop: "15px", marginLeft: "5px"}}><span style={{fontWeight: "bold"}}>Approve Deletions as Deprecations</span></div></div>
                    <div style={{marginLeft: "15px", display: "flex"}}><input type="checkbox" checked={this.state.deprecateMoves} onChange={this.toggleMoveDeprecation}
                                                                          id={phenomId.gen("deprecations","moves-as-deprecations-checkbox")} style={{paddingLeft: "50px"}} />
                    <div style={{marginTop: "15px", marginLeft: "5px"}}><span style={{fontWeight: "bold"}}>Approve Node Moves as Deprecations</span></div></div>
                  </div>
                </div>
              </div>
              <div className="branch-wrapper" style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                  <PhenomLabel text="Approve Change Sets" style={{ marginBottom: 10 }} />
                  <ChangeSetManager mergeMode="approve" />
              </div>
            </div>
  }
}

const msp = (state) => ({
  userRole: state.user.userRole,
})


export default connect(msp)(Approve)
