import React from 'react';
import {Link} from "react-router-dom";
import $ from 'jquery';
import {elementDetail, getNodeWithAddenda} from "../../requests/sml-requests";
import {LineLabel, CadetInput, CadetTextArea} from "./stateless";
import {Tags} from "./tags";
import EditTopButtons from "../edit/edit-top-buttons";
import {Notifications, Notifications2} from "../edit/notifications";
import {NodeHistory2} from "../edit/node-history";
import PhenomId from "../../requests/phenom-id";
import {withPageLayout} from "../edit/node-layout"
import { createNodeUrl } from '../../requests/type-to-path';
import { getActiveChangeSetId } from '../../requests/actionCreators';
import ChangeSetPicker from '../widget/ChangeSetPicker';
import {createPhenomGuid} from "../util/util"
import NavTree from '../tree/NavTree';


export class StandardMeasurementSystemManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            referenceStandard: "",
            urlToStandard: "",
            related_measurements: [],
            editable: true,
            parent: "",
            possibleParents: [],
            possibleParentList: [],
        }

        this.original = {};
        this.noticeRef = React.createRef();
    }

    setNewState = () => {
        const newState = {
            guid: null,
            name: "",
            description: "",
            referenceStandard: "",
            related_measurements: [],
            editable: true,
            parent: "",
            possibleParents: [],
            possibleParentList: [],
        }

        this.setState({...newState});
        this.original = {...this.original, ...newState};
    }

    componentDidMount() {
        if(this.props.match.params.guid === "new") {
            this.setNewState();
            this.loadParents();
        } else {
            this.loadStandMeasSys();
            this.loadRelatedMeasurement(this.props.match.params.guid);
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.guid !== prevProps.match.params.guid) {
            if(this.props.match.params.guid === "new") {
                this.setNewState();
                this.loadParents();
            } else {
                this.loadStandMeasSys();
                this.loadRelatedMeasurement(this.props.match.params.guid);
            }
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    loadStandMeasSys = () => {
        getNodeWithAddenda(this.props.match.params.guid).then(res => {
            const response = JSON.parse(res);

            if (this.props.updateTemplateNode) {
                this.props.updateTemplateNode(response);
            }
            this.setStateFromResponse(response);
        })
    }

    loadRelatedMeasurement = (guid) => {
        elementDetail(guid).then((res) => {
            const response = JSON.parse(res);
            this.setStateFromResponse(response);
        });
    }

    isEdited = () => {
        return this.state.name !== this.original.name ||
               this.state.description !== this.original.description ||
               this.state.referenceStandard  !== this.original.referenceStandard ||
               this.state.urlToStandard  !== this.original.urlToStandard ;
    }

    loadParents = async () => {
        const res = await $.ajax({
            url: "/index.php?r=/node/model-nodes-of-type",
            method: "get",
            data: {
                type: "face:LogicalDataModel"
            }
        });
        const nodes = JSON.parse(res).nodes
        const possibleParents = {};

        nodes.forEach(node => {
            possibleParents[node.guid] = node;
        })

        this.original = {...this.original, ...possibleParents, possibleParentList: nodes};
        this.setState({ possibleParents, possibleParentList: nodes });
    }

    handleSave = () => {
        const noticeRef = this.noticeRef.current;

        if(!this.isEdited()) {
            return noticeRef.error("No changes detected.");
        }

        const data = {
            name: this.state.name,
            guid: this.state.guid || createPhenomGuid(),
            description: this.state.description,
            referenceStandard: this.state.referenceStandard,
            urlToStandard: this.state.urlToStandard,
            xmiType: "logical:StandardMeasurementSystem",
            changeSetId: getActiveChangeSetId(),
        }


        $.ajax({
            url: "/index.php?r=/node/smm-save-nodes",
            method: "post",
            data
        }).then(res => {
            const response = JSON.parse(res);
            Notifications2.parseResponse(response);

            if (response.guid) {
                NavTree.addNodes([ response ]);
                if (this.props.match.params.guid === "new") {
                    return this.props.history.push( createNodeUrl(response) );
                }

                if (this.props.updateTemplateNode) {
                    this.props.updateTemplateNode(response);
                }
                this.setStateFromResponse(response);
            }
        })
    }

    resetStandMeasSys = () => {
        this.setState({...this.original});
    }

    setStateFromResponse = (response) => {
        this.original = {...this.original, ...response};
        this.setState({ ...response }, () => {
            this.setEditingStatus();
        });
    }

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };

    sortByName = (a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }


    render() {
        const phenomId = new PhenomId("standard-measurement-system",this.props.idCtx);
        return(
            <div className="subview-wrapper flex-v" id={phenomId.gen("","wrapper")}>
                <Notifications ref={this.noticeRef} />
                <EditTopButtons style={this.props.match.from === "toggle" ? {top:"-100px"} : null}
                        saveBtn={this.saveStandMeasSys} resetBtn={this.resetStandMeasSys} canEdit={this.state.editable} />

                <div className="flex-h">
                    <div className="flex-v" style={{flexGrow:1}}>
                        <LineLabel text="STANDARD MEASUREMENT SYSTEM" idCtx={phenomId.gen("","name")}/>
                        <CadetInput text={this.state.name}
                                    disabled={!this.state.editable}
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    idCtx={phenomId.gen("","name")} />
                        <LineLabel text="DESCRIPTION" idCtx={phenomId.gen("","description")} />
                        <CadetTextArea text={this.state.description}
                                    disabled={!this.state.editable}
                                    onChange={(e) => this.setState({description: e.target.value})}
                                    idCtx={phenomId.gen("","description")} />

                        {this.props.match.params.guid === "new" &&
                            <div style={{margin:"5px 0 25px"}}>
                                <Link to="/edit/details/measurement_system/new"
                                        style={{padding:"5px 10px", backgroundColor:"#409b9e", color:"white", borderRadius:5, boxShadow:"3px 3px 3px #7e7e7e"}}
                                        id={phenomId.gen("","new-link")}>
                                    Switch to Measurement System
                                </Link>
                            </div>
                        }
                    </div>

                    <div className="edit-side-bar">
                        <NodeHistory2 guid={this.state.guid} idCtx={phenomId.gen("init")}/>
                        <ChangeSetPicker id={phenomId.genPageId()}
                                         disabled={!this.state.editable}
                                         label="Change Set" />
                    </div>
                </div>

                <LineLabel text="Reference Standard" idCtx={phenomId.gen("","reference-standard")} />
                <CadetInput text={this.state.referenceStandard}
                            disabled={!this.state.editable}
                            onChange={(e) => this.setState({referenceStandard: e.target.value})}
                            idCtx={phenomId.gen("","reference-standard")} />

                <LineLabel text="URL to External Standard Definition (optional)" idCtx={phenomId.gen("","url")} />
                <CadetInput text={this.state.urlToStandard}
                            disabled={!this.state.editable}
                            onChange={(e) => this.setState({urlToStandard: e.target.value})}
                            idCtx={phenomId.gen("","url")} />


                {this.props.match.params.guid === "new" &&
                    <><LineLabel text="Parent Package" idCtx={phenomId.gen("parent","")} />
                    <CadetInput
                        style={{margin:"10px 0", maxWidth:"300px"}}
                        disabled={!this.state.editable}
                        placeholder="Filter Parent Packages (Case Sensitive)"
                        onChange={(e) => this.filterParentSelection(e.target.value)}
                        idCtx={phenomId.gen("","parent")} />

                    <select className="cadet-select"
                            id={phenomId.gen("","select")}
                            size={6}
                            style={{minWidth:"200px"}}
                            value={this.state.parent}
                            disabled={!this.state.editable}
                            onChange={(e) => this.setState({parent: e.target.value})}>
                        <option value="" id={phenomId.gen(["parent","default"],"option")}>No Package Selected / Use Default</option>
                        {this.state.possibleParentList.sort((a,b) => this.sortByName(a,b)).map((ldm, idx) => {
                            return (
                                <option value={ldm.guid} id={phenomId.gen(["parent",idx],"option")}
                                        key={ldm.guid}>
                                    {ldm.name}
                                </option>);
                        })}
                    </select>
                    </>
                }

                {this.state.guid &&
                    <Tags guid={this.state.guid}
                        name={this.state.name}
                        disabled={!this.state.editable}
                        idCtx={phenomId.gen("init","")} />
                }
            </div>
        )
    }
}


export const EditStandardMeasurementSystemManager = withPageLayout(StandardMeasurementSystemManager, { renderDeleteBtn: false, renderResetBtn: false });
