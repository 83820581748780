import React from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useLocation } from 'react-router-dom';
import { connect, useSelector } from "react-redux";

export function MatomoTrackPageView(props) {

  const { trackPageView, trackEvent, pushInstruction } = useMatomo();
  const location = useLocation();
  const userIdentity = useSelector(state => state.user.userIdentity);

  // Track page view
  React.useEffect(() => {
    if (!userIdentity) return;
    pushInstruction('setUserId', userIdentity.id);
    trackPageView();
  }, [location, userIdentity])

  // const handleOnClick = () => {
  //   // Track click on button
  //   trackEvent({ category: 'sample-page', action: 'click-event' })
  // }


  return (
    <>
    </>
  )

}
