import React, { useState, useEffect } from "react";
import { NodeBox, NodeBoxWithFetch, FormatMenuHeaderCell, NodeComboBox, LineLabel, PackageComboBox, PhenomComboBox, PhenomInput, PhenomLabel, PhenomTextArea, PhenomToggle, DeprecatedIcon } from "../util/stateless";
import { NodeHistory2 } from "./node-history";
import { createPhenomGuid, isPhenomGuid, SKAYL_GUIDS, splitXmiType, validateNodeFields } from "../util/util";
import PhenomId from "../../requests/phenom-id";
import ChangeSetPicker from "../widget/ChangeSetPicker";
import { withPageLayout } from "./node-layout";
import * as actionCreators from "../../requests/actionCreators";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { PhenomLink } from "../widget/PhenomLink";
import { ConversionsDisplay } from "../util/conversions_display";
import { MeasurementAxisManager, NestedMeasurementAxis } from "./edit-measurement-axis-manager";
import { MeasurementAttribute } from "./edit-measurement-attribute";
import { cloneDeep } from "lodash";
import {Button, Toolbar, ToolbarItem} from "@progress/kendo-react-buttons";
import DeletionConfirm2 from "../dialog/DeletionConfirm2";
import { getNodesOfType } from "../../requests/sml-requests";


export class MeasurementManager extends React.Component {
    static defaultProps = {
      newNode: {
        name: "",
        xmiType: "logical:Measurement",
        description: "",
        realizes: "",
        realizations: [],
        measurementSystem: {},
        measurementAxis: [],
        children: [],
        parent: "",
        subModelId: undefined,
      },
      nodeAddenda: {
        coreAddenda: ["measurementAxisMULTI", "measurementSystem", "realizations", "childrenMULTI"],
        measurementAxisAddenda: ["measurementSystemAxis", "valueTypeUnitMULTI"],
        measurementSystemAddenda: ["coordinateSystem", "measurementSystemAxisMULTI"],
        measurementSystemAxisAddenda: ["axis"],
      },
      nodesOfTypeAddenda: {
        "logical:MeasurementSystem": {
          type: ["logical:MeasurementSystem", "logical:StandardMeasurementSystem"],
          coreAddenda: ["coordinateSystem", "measurementSystemAxisMULTI"],
          measurementSystemAxisAddenda: ["axis"],
        },
        "logical:MeasurementAxis": {
          type: "logical:MeasurementAxis",
          coreAddenda: ["measurementSystemAxis", "valueTypeUnitMULTI"],
          Filter: {
              excludes: {"xmi:type": "logical:Enumerated"},
              deref: ["valueTypeUnit", "valueType"],
          }
        }
      },
    }

    constructor(props) {
      super(props);
      this.phenomId = new PhenomId("measurement", this.props.idCtx);
    }

    // ------------------------------------
    // State
    // ------------------------------------
    domRef = React.createRef()
    axisRefs = {};
    attrRefs = {};
    fieldRefs = {};
    original = {};
    historyRef = undefined;
    defaultState = {
      measurementAxis: [],
      measurementAxisList: [],
      measurementList: [],
      countMSAs: 0,
      countMSAttrs: 0,
      isEnumerated: false,
      editable: true,
      newChildId: 0
    }
    state = cloneDeep(this.defaultState);

    // "required" checks for empty field as the input changes (user is typing)
    // "errorRef" checks for empty field after pressing the save button
    requiredFields = {
      name: {
        required: true,
        checkFirstChar: true,
        checkAllChars: true,
        errorRef: React.createRef(),
      },
      realizes: {
        required: true,
        errorRef: React.createRef(),
      },
      parent: {
        required: true,
        errorRef: React.createRef(),
      },
      measurementSystem: {
        required: true,
        errorRef: React.createRef(),
      },
    }

    // ------------------------------------
    // Life Cycle Methods
    // ------------------------------------

    componentDidMount() {
      this.initNodeState();
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.node !== this.props.node) {
        this.initNodeState();

      } else if (prevProps.nodesOfType !== this.props.nodesOfType) {
        this.initMSForEnumeration();
        this.setMeasurementAxisList();

      } else if (prevState.isEnumerated !== this.state.isEnumerated) {
        this.setMeasurementAxisList();
      }

      if (prevState.measurementSystem !== this.state.measurementSystem) {
        const countMSAs = this.state.measurementSystem?.measurementSystemAxis?.length || 0;
        this.setState({ countMSAs });
      }
    }

    // ------------------------------------
    // Initial Setup
    // ------------------------------------
    initNodeState = () => {
      const { node } = this.props;

      this.setState({
        ...cloneDeep(this.defaultState),
        ...node,
      }, () => {
        this.setMeasurementAxisList();
      });
    }


    /**
     * The user can click the "enumerated toggle" before the MS list is fully loaded - the MS field will be locked.
     * This will properly assign the MS after the long load time.
     */
    initMSForEnumeration = () => {
      const { node, nodesOfType } = this.props;
      const { isEnumerated } = this.state;

      if (isEnumerated && !node?.measurementSystem?.guid) {
        const measurementSystemList = nodesOfType["logical:MeasurementSystem"] || [];
        const measurementSystem = measurementSystemList.find(ms => ms.guid === SKAYL_GUIDS.measurementSystem) || {};
        return this.setState({ measurementSystem });
      }
    }

    // ------------------------------------
    // Getters
    // ------------------------------------

    getMeasurements() {
      let measurements = [];

      getNodesOfType("logical:Measurement").then(res => {
        let response = (JSON.parse(res)).nodes;
        response.forEach(node => measurements.push(node));
      });

      return measurements;
    }

    // ------------------------------------
    // Setters
    // ------------------------------------
    setMeasurementAxisList = () => {
      const { nodesOfType } = this.props;
      let measurementAxisList = [];
      
      if (!this.state.isEnumerated) {
        const list = nodesOfType["logical:MeasurementAxis"] || [];
        measurementAxisList = [...list];
      }

      measurementAxisList.unshift({
        guid: "",
        name: "<New>",
        xmiType: "logical:MeasurementAxis",
      })

      this.setState({ measurementAxisList });
    }

    addMeasurementAxis = (ma) => {
      if (!ma) return;
      const measurementAxis = [...this.state.measurementAxis];
      
      if (!ma.guid) {
        const newMA = cloneDeep(MeasurementAxisManager.defaultProps.newNode);
              newMA.guid = createPhenomGuid();
        measurementAxis.push(newMA);

      } else if (!measurementAxis.find(measAxis => measAxis.guid === ma.guid)) {
        measurementAxis.push(ma);
      }

      this.setState({ measurementAxis });
    }

    removeMeasurementAxis = (ma) => {
      const measurementAxis = [...this.state.measurementAxis];
      const removeIdx = measurementAxis.findIndex(measAxis => measAxis.guid === ma.guid);
      if (removeIdx > -1) measurementAxis.splice(removeIdx, 1);
      this.setState({ measurementAxis })
    }

    toggleIsEnumerated = (e) => {
      if (e.target.checked) {
        // measurementAxis consist of non-enumerated MAs
        const measurementAxis = [...this.state.measurementAxis].filter(measAxis => isPhenomGuid(measAxis.guid));

        const measurementSystemList = this.props.nodesOfType["logical:MeasurementSystem"] || [];
        const measurementSystem = measurementSystemList.find(ms => ms.guid === SKAYL_GUIDS.measurementSystem) || {};

        return this.setState({ measurementAxis, measurementSystem, isEnumerated: e.target.checked });
      }

      this.setEnumerated(e.target.checked);
    }

    setEnumerated = (bool) => {
      if (typeof bool !== 'boolean') return;
      this.setState({ isEnumerated: bool });
    }

    // ------------------------------------
    // Called by parent component
    // ------------------------------------
    handleReset = () => {
      this.initNodeState();
      for (let maGuid in this.axisRefs) {
        const axisRef = this.axisRefs[maGuid];
        axisRef?.handleReset && axisRef.handleReset();
      }
    }

    validateNode = () => {
      const { countMSAs, measurementAxis, isEnumerated } = this.state;
      let valid = validateNodeFields(this.requiredFields, this.state);

      if (measurementAxis.length !== countMSAs) {
        valid = false;
        setTimeout(() => {
          actionCreators.receiveWarnings("The number of measurement axes must match the number of measurement system axes")
        }, 0)
      }

      this.state.measurementAxis.forEach(measAxis => {
        const axisRef = this.axisRefs[measAxis.guid];
        if (axisRef?.validateNode && !axisRef.validateNode()) {
          valid = false;
        }
      })

      // Special case - new measurement can create have only 1 enumerated MA
      if (isEnumerated && measurementAxis.length > 1) {
        valid = false;
        setTimeout(() => {
          actionCreators.receiveErrors("Measurement can have only one Enumerated Measurement Axis");
        }, 0)
      }

      return valid;
    }

    addNewRows() {
      const newTable = Array.from(this.state.children);
      var newChild = {};
      newChild.guid = createPhenomGuid();
      newChild.parent = this.props.node.guid;
      newTable.push(newChild);
      this.setState({
        children: newTable
      });
    }

    deleteMeasAttr = (attrGuid) => {
      const attrRef = this.attrRefs[attrGuid];
  
      // If this is an uncommitted/placeholder node, just remove it from the array
      if (isPhenomGuid(attrGuid)) {
        var newTable = Array.from(this.state.children);
        newTable = newTable.filter(function( obj ) {
          return obj.guid !== attrGuid;
        });
        this.setState({
          children: newTable
        });
        return;
      }
      
      // If the node has a Real guid - use DeletionConfirm2
      // The third argument is a callback function
      // The fourth argument prevents DeletionConfirm2 from navigating away to a different page
      DeletionConfirm2.show(attrRef.getGuid(), attrRef.getRolename(), (status) => {
        if (!status.deleted) return;
        var newTable = Array.from(this.state.children);
        newTable = newTable.filter(function( obj ) {
          return obj.guid !== attrGuid;
        });
        this.setState({
          children: newTable
        });
      }, true);
    }

    generateNode = () => {
      const ma_nodes = [];
      const mattr_nodes = [];

      this.state.children.forEach(measAttr => {
        const attrRef = this.attrRefs[measAttr.guid];
        const attr = attrRef?.generateNode();
        if (attr) mattr_nodes.push(attr);
      })

      this.state.measurementAxis.forEach(measAxis => {
        const axisRef = this.axisRefs[measAxis.guid];
        const axis = axisRef?.generateNode();
        if (axis) ma_nodes.push(axis);
      })

      return {
        guid: this.state.guid,
        name: this.state.name,
        description: this.state.description,
        xmiType: "logical:Measurement",
        realizes: this.state.realizes,
        measurementSystem: this.state.measurementSystem?.guid,
        measurementAxis: ma_nodes,
        children: mattr_nodes,
        parent: this.state.parent || undefined,
        subModelId: this.state.subModelId,
      }
    }

    render() {
        const { editable, nodesOfType } = this.props;
        const { countMSAs, measurementAxis, isEnumerated } = this.state;
        const original = this.props.node;
        const phenomId = this.phenomId;
        let measAttrRowIdx = 0;
        
        const isNewPage = isPhenomGuid(this.state.guid);
        const showMASelect = isNewPage || !isEnumerated;
        
        const title = isEnumerated ? "Enumerated Measurement" : "Measurement";
        const measurementSystemList = nodesOfType["logical:MeasurementSystem"] || [];

        // little box that shows the number of MAs and MSAs
        let colorCounter = "#eee";
        if (measurementAxis.length > countMSAs) {
          colorCounter = "hsl(var(--bs-danger-hs) 50%)";
        }

        return <div className="edit-form" ref={this.domRef}>
                  <div className="p-row">
                    <div className="p-col">
                      <PhenomInput label={title}
                                    value={this.state.name}
                                    originalValue={original["name"]}
                                    disabled={!editable}
                                    autoFocus={true}
                                    id={phenomId.gen("", "name")}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    onClickResetIcon={() => this.setState({ name: original["name"] })}
                                    config={this.requiredFields["name"]} />

                      <PhenomTextArea label="Description"
                                      value={this.state.description}
                                      originalValue={original["description"]}
                                      disabled={!editable}
                                      id={phenomId.gen("", "description")}
                                      onChange={(e) => this.setState({ description: e.target.value })}
                                      onClickResetIcon={() => this.setState({ description: original["description"] })} />

                      {isNewPage &&
                      <div>
                        <PhenomLabel text="Enumerated Measurement" />
                        <PhenomToggle checked={isEnumerated}
                                      data={["No", "Yes"]}
                                      id={phenomId.gen("", "enum-toggle")}
                                      onChange={this.toggleIsEnumerated} />
                      </div>}
                    </div>

                    <div className="edit-side">
                      <NodeHistory2 guid={this.state.guid} 
                                    idCtx={phenomId.genPageId()}
                                    ref={ele => this.historyRef = ele} />
                      <ChangeSetPicker id={phenomId.genPageId()}
                                      label="Change Set" />
                    </div>
                  </div>

                  <div className="p-row">
                    <div className="p-col p-col-6">
                      <NodeComboBox label="Observable"
                                    xmiType="conceptual:Observable"
                                    selectedGuid={this.state.realizes}
                                    originalGuid={original["realizes"]}
                                    disabled={!editable}
                                    id={phenomId.gen("", "realizes")}
                                    onChange={(observable) => this.setState({ realizes: observable.guid })}
                                    onClickResetIcon={() => this.setState({ realizes: original["realizes"] })}
                                    config={this.requiredFields["realizes"]} />

                      <NodeBoxWithFetch guid={this.state.realizes} />
                    </div>
                    <div className="p-col p-col-6">
                      <PackageComboBox label="Package"
                                        xmiType="face:LogicalDataModel"
                                        nodeGuid={this.state.guid}
                                        selectedGuid={this.state.parent}
                                        originalGuid={original["parent"]}
                                        disabled={!editable}
                                        id={phenomId.gen("", "parent")}
                                        onChange={(parent) => this.setState({ parent: parent.guid })}
                                        onClickResetIcon={() => this.setState({ parent: original["parent"] })}
                                        config={this.requiredFields["parent"]} />
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                    <PhenomComboBox label="Measurement System"
                                    value={this.state.measurementSystem}
                                    originalValue={original["measurementSystem"]}
                                    data={measurementSystemList}
                                    dataItemKey="guid"
                                    disabled={!editable || isEnumerated}
                                    id={phenomId.gen("", "measurementSystem")}
                                    onChange={(measurementSystem) => this.setState({ measurementSystem })}
                                    onClickResetIcon={() => this.setState({ measurementSystem: original["measurementSystem"] })}
                                    config={this.requiredFields["measurementSystem"]} />
                    <CustomMeasurementSystemBox node={this.state.measurementSystem} />
                  </div>

                  <div>
                    <PhenomLabel text="Measurement Axis" />

                    <div style={{ display: "flex", alignItems: "center", gap: "1em", fontSize: 14, marginBottom: "1em" }}>
                      {showMASelect && <>
                        <label>Add measurement axis:</label>
                        <PhenomComboBox data={this.state.measurementAxisList}
                                        dataItemKey="guid"
                                        disabled={!editable}
                                        placeholder="Select one"
                                        id={phenomId.gen("", "add-measurement-axis")}
                                        onChange={this.addMeasurementAxis}
                                        containerProps={{
                                          style: {
                                            width: 500
                                          }
                                        }} /> 
                      </>}

                      <div style={{ display: "flex", alignItems: "center", gap: "0.6em", padding: "0.25em 0.5em", border: `2px solid ${colorCounter}` }}>
                        <span className="fas fa-info-circle" title="the number of measurement axes must match the number of measurement system axes" />
                        <span id={phenomId.gen("", "measurement-axes-count")}>Measurement Axes: {this.state.measurementAxis.length}</span>
                        <span id={phenomId.gen("", "measurement-system-axes-count")}>Measurement System Axes: {this.state.countMSAs}</span>
                      </div>
                    </div>
                    
                    <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
                      {measurementAxis.map((ma, idx) => {
                        let onClickCancelIcon;

                        // conditionally render the 'cancel' button
                        if (showMASelect) {
                          onClickCancelIcon = () => this.removeMeasurementAxis(ma)
                        }

                        return <NestedMeasurementAxis key={ma.guid} 
                                                      id={phenomId.gen("", idx.toString())}
                                                      node={ma}
                                                      nodesOfType={nodesOfType}
                                                      editable={editable}
                                                      parentNewPage={isPhenomGuid(this.state.guid)}
                                                      parentName={this.state.name}
                                                      parentEnumerated={isEnumerated}
                                                      parentObservable={this.state.realizes}
                                                      parentPackage={this.state.parent}
                                                      parentMeasurementSystem={this.state.measurementSystem?.guid || ""}
                                                      setEnumerated={this.setEnumerated}
                                                      onClickCancelIcon={onClickCancelIcon}
                                                      ref={el => this.axisRefs[ma.guid] = el} />
                      })}
                    </div>
                  </div>
                      
                  <div style={{position: "relative"}}> 
                    <LineLabel text="Measurement Attributes" style={{marginBottom: 15}} idCtx={phenomId.gen("details","measurement-attribute")}/>
                    <Grid
                        id={phenomId.gen("measurement-attribute","grid")}
                        data={this.state.children}
                        className="editorTable"
                        style={{marginTop: 10}}
                        
                        rowRender={(_, props) => {
                          const child = props.dataItem;
                          return (
                              <MeasurementAttribute
                                  idCtx={phenomId.gen(["measurement-attribute",measAttrRowIdx++])}
                                  ref={el => this.attrRefs[child.guid] = el}
                                  key={child.guid}
                                  canEdit={this.state.editable}
                                  element={child}
                                  dropDownValue={child.type}
                                  new={child.guid.startsWith("newChild")}
                                  onDelete={this.deleteMeasAttr}
                              />);
                          }}>
                  <GridNoRecords>
                            No Data Is Available For This Table
                        </GridNoRecords>
                        <Column title="NAME" field="rolename" />
                        <Column title="TYPE" field="type" />
                        <Column title= "DELETE" field="delete" width="75px" />
                      </Grid>

                      {!this.state.editable || <Toolbar>
                        <ToolbarItem>
                            <Button
                                id={phenomId.gen("measurement-attribute","create-new-rows-button")}
                                iconClass="fa fa-plus fa-fw"
                                onClick={() => {
                                    this.addNewRows();
                                }}>
                                Create
                            </Button>
                        </ToolbarItem>
                    </Toolbar>}
                  </div>

                  {/* Button for if no measurement attributes */}

                  <div>
                    <PhenomLabel text="Platform Types" />
                    <Grid data={this.state.realizations}>
                        <GridNoRecords>
                          No Data Is Available For This Table
                        </GridNoRecords>
                        <Column title="NAME" field="name" cell={(props) => {
                          return <td><PhenomLink node={props.dataItem} /></td>
                        }} />
                        <Column title="PRIMITIVE TYPE" field="xmiType" cell={(props) => {
                          return <td>{ splitXmiType(props.dataItem.xmiType) }</td>;
                        }} />
                      </Grid>
                  </div>

                  <ConversionsDisplay xmiType={"logical:MeasurementConversion"} refGuid={this.state.guid} />
        </div>
    }
}




const CustomMeasurementSystemBox = (props) => {
  const [measurementSystem, setMeasurementSystem] = useState(null);
  const [vtuMap, setVtuMap] = useState({});

  useEffect(() => {
    setMeasurementSystem(props.node);
  }, [props.node]);


  useEffect(() => {
    window["cachedRequest"]("newVTUS").then(data => {
      setVtuMap(data.value_type_units);
    })
  }, [])

  // invalid MS
  if (!measurementSystem) return null;

  const coordinateSystem = measurementSystem.coordinateSystem;
  const measurementSystemAxis = measurementSystem.measurementSystemAxis || [];

  return <div className="node-box-grid">
            <NodeBox node={measurementSystem} />
            <NodeBox node={coordinateSystem} />

            {measurementSystemAxis.map((msa) => {
              const vtuGuids = typeof msa.defaultValueTypeUnit === "string" ? msa.defaultValueTypeUnit.split(" ") : [];

              return <React.Fragment key={msa.guid}>
                        <NodeBox node={msa}>
                          {vtuGuids.map((vtuGuid) => {
                            const vtu = vtuMap[vtuGuid];
                            if (!vtu) return null;

                            const constraintName = vtu.children?.length ? vtu.children.map(cons => cons.name).join(", ") : "None";
                            return <div key={vtuGuid} style={{ marginLeft: 50, padding: 5, border: "2px solid #ddd" }}>
                                      <header style={{ marginBottom: 5 }}>
                                        <i>Default Value Type Unit</i>
                                      </header>
                                      <p>
                                        <b>Unit of Measure: </b>
                                        <span>{vtu.unit.name}</span>
                                      </p>
                                      <p>
                                        <b>Logical Value Type: </b>
                                        <span>{vtu.valueType.name}</span>
                                      </p>
                                      <p>
                                        <b>Constraint: </b>
                                        <span>{constraintName}</span>
                                      </p>
                                    </div>
                          })}
                        </NodeBox>
                        <NodeBox node={msa.axis} />
                </React.Fragment>
            })}
  </div>
}





export const EditMeasurementManager = withPageLayout(MeasurementManager);