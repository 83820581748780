import React from "react";
import { connect } from "react-redux";
import { PhenomLabel } from "../../util/stateless";
import {MergeErrorConfirm} from "./merge_error_confirm"
import LoaderButton from "../../widget/LoaderButton";
import PhenomId from "../../../requests/phenom-id";
import { ManageSubMenu } from "./model_manage";
import ChangeSetManager from "./sections/ChangeSetManager";
import NavTree from "../../tree/NavTree";
import * as actionCreators from "../../../requests/actionCreators";


class Pull extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            contentOverride: false,
            checked: false,
            deprecateDeletes: false,
            deprecateMoves: false,
        };

        this.noticeRef = undefined;
        this.parentBranchName = undefined;
        this.branchName = undefined;
        this.neededNodes = undefined;
    }

    componentDidMount() {
      NavTree.collapseNavTree(false);
      // NavTree.fetchRelationsMap();
    }

    componentDidUpdate(prevProps) {

    }

    handleSelectAll = () => {
      NavTree.selectAllMergeCandidates();
    };

    selectNecessaryNodes = () => {
      NavTree.selectMergeCandidates( this.neededNodes.map(n => n.guid) );
    }
  
    selectSingleNode = (guid) => {
      NavTree.selectMergeCandidates([ guid ]);
    }

    toggleDeleteDeprecation = () => {
      this.setState({ deprecateDeletes: !this.state.deprecateDeletes})
    }

    toggleMoveDeprecation = () => {
      this.setState({ deprecateMoves: !this.state.deprecateMoves })
    }

    handlePull = () => {
      const rejected = (errText) => {
        typeof errText === "string" && actionCreators.receiveErrors(errText);
      }
  
      const resolved = (response) => {
        if (response.status === "success") {
          actionCreators.receiveLogs("Pull Completed");
          NavTree.reset();
        } else {
          if(response.errors.length) {
            if (typeof response.errors[0] === 'string') {
              actionCreators.receiveErrors(response.errors);
            } else {
              const fixables = ["references", "unreachable"];
              const allFixable = response.errors.every(err => fixables.includes(err.method));

              if(allFixable) {
                this.setNeededNodes(response.errors);
                MergeErrorConfirm.show(this.neededNodes, this.selectNecessaryNodes, this.selectSingleNode);
              } else {
                actionCreators.receiveErrors(Array.from(new Set(response.errors.map(err => err.text))));
              }
            }
          } else {
            actionCreators.receiveErrors(["Failed to perform merge."]);
          }
        }
      }
  
      return NavTree.merge("pull", false, this.state.deprecateDeletes, this.state.deprecateMoves).then(resolved, rejected);
    }

    // this logic is from the original code
    getAbsentParents = () => {
      NavTree.getSelectedMergeCandidates().forEach(leaf => {
        const parentLeaf = leaf.getParentLeaf();

        if (parentLeaf && parentLeaf.isMergeCandidate() && !parentLeaf.isMarkedForMerge()) {
          const node = {};
          node["name"] = parentLeaf.getName();
          node["guid"] = parentLeaf.getGuid();
          node["error_type"] = "PARENT_ABSENT";
          node["error_name"] = leaf.getName();
          node["error_guid"] = leaf.getGuid();
          node["failure"] = false
          this.neededNodes.push(node);
        }
      })
    }

    // this logic is from the original code
    //    - "neededNode" is a guid
    setNeededNodes(response) {
      this.neededNodes = [];
      response.filter(er => er.method === "references" || er.method === "unreachable").forEach((err, i) => {
          const neededGuid = err.value;
          const neededLeaf = NavTree.getLeafNode(neededGuid);

              const failure = !neededLeaf;
              const node = {};
              node["name"] = !failure ? neededLeaf.getName() : "Not Found";
              node["guid"] = neededGuid;
              node["error_type"] = err.method
              node["error_name"] = err.name;
              node["error_guid"] = err.guid;
              node["failure"] = failure;
              node["deleted"] = neededLeaf?.isDeletedForMerge();
              this.neededNodes.push(node);
      })
    }
    


    render() {
        const phenomId = new PhenomId("pull",this.props.idCtx);
        const cinc_works = this.props.userRole.indexOf('c') !== -1;
        return <div className="phenom-content-wrapper">
                  <ManageSubMenu />
                  <MergeErrorConfirm idCtx={phenomId.gen("init")} />

                  <div id={phenomId.gen("","wrapper")} className="branch-wrapper">
                    <PhenomLabel text="Pull" />
                    <p>Select the nodes to pull from the nav tree on the left and then click Pull to pull from the parent branch. Pulling is immediate and will overwrite your current nodes. This action cannot be undone.</p>
                    <p>If you are pulling enumerations, please ensure that all pieces necessary have been selected before pulling.</p>

                    <div id={phenomId.gen(["init","changes"],"wrapper")} className="flex-h">
                        <LoaderButton onClick={this.handleSelectAll} disabled={cinc_works} text="SELECT ALL" id={phenomId.gen("changes","select-all-button")} style={{marginRight: 15}} />
                        <LoaderButton
                            idCtx={phenomId.gen("changes","pull")}
                            text="PULL CHANGES"
                            disabled={cinc_works}
                            onClick={this.handlePull}
                        />
                        <div style={{marginLeft: "15px", display: "flex"}}><input type="checkbox" checked={this.state.deprecateDeletes} onChange={this.toggleDeleteDeprecation}
                                                                        id={phenomId.gen("deprecations","deletes-as-deprecations-checkbox")} style={{paddingLeft: "50px"}} />
                        <div style={{marginTop: "15px", marginLeft: "5px"}}><span style={{fontWeight: "bold"}}>Pull Deletions as Deprecations</span></div></div>
                        <div style={{marginLeft: "15px", display: "flex"}}><input type="checkbox" checked={this.state.deprecateMoves} onChange={this.toggleMoveDeprecation}
                                                                        id={phenomId.gen("deprecations","moves-as-deprecations-checkbox")} style={{paddingLeft: "50px"}} />
                        <div style={{marginTop: "15px", marginLeft: "5px"}}><span style={{fontWeight: "bold"}}>Pull Moves as Deprecations</span></div></div>
                    </div>
                  </div>

                  <div className="branch-wrapper" style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <PhenomLabel text="Pull Change Sets" style={{ marginBottom: 10 }} />
                    <ChangeSetManager mergeMode="pull" />
                  </div>
                </div>
    }
}



const msp = (state) => ({
  userRole: state.user.userRole,
})


export default connect(msp)(Pull)
