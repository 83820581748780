import React, {Component} from "react";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import {Notifications2} from "../edit/notifications";
import PhenomId from "../../requests/phenom-id";

export default class PhenomLoadButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    doClick = () => {
        this.setState({loading: !this.state.loading}, () => {
            if (this.props.onClick) {
                const promise = this.props.onClick();
                const turn_off_spinner = () => this.setState({loading: false});
                if (promise) {
                    promise.catch(er => {
                        // Notifications2.parseErrors(er.responseText.split("&#039;")[3])
                        Notifications2.parseErrors("Server responded with an unexpected status code.  Please contact Skayl customer service.");
                    });
                    if (promise && (typeof promise.finally === 'function' || typeof promise.always === 'function')) {
                        this.setState({loading: true});
                        if (typeof promise.finally === 'function') {
                            promise.finally(turn_off_spinner);
                        } else if (typeof promise.always === 'function') {
                            promise.always(turn_off_spinner);
                        } else {
                            turn_off_spinner();
                        }
                    }
                } else {
                    turn_off_spinner();
                }
            } else {
                alert("This button has not been given an appropriate function.");
                this.setState({loading: false});
            }
        });
    }

    render() {
        const phenomId = new PhenomId("loader",this.props.idCtx);
        return (<div className="load-button-wrapper" style={this.props.divStyle} id={phenomId.gen("","wrapper")}>
            <img
                style={{display: this.state.loading ? "inline" : "none"}}
                src={loadingIcon} />
            <button
                id={phenomId.gen("","button")}
                className={this.props.className || "btn btn-primary".concat(this.props.addonClass || "")}
                style={this.props.style}
                onClick={this.doClick}
                disabled={this.props.disabled || this.state.loading}>
                {this.props.text || "Button"}</button>
        </div>);
    }
}
