import React from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import { SubMenuLeft, SubMenuRight } from '../../edit/edit-top-buttons'
import { KendoButtonWithDebounce, PhenomInput } from '../../util/stateless'
import { receiveErrors, receiveWarnings } from '../../../requests/actionCreators'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import SkaylAdmin from './skayl_admin'
import SkaylAccounts from './skayl_accounts'
import UserPModelManager from '../../manage/user_pmodel_manager'


class SkaylManager extends React.Component {

  state = {
    twoFACode: "",
    twoFAValidated: false,
  }

  submit2FA = (e) => {
    const { twoFACode } = this.state;
    e.preventDefault();

    if (!twoFACode) {
      return receiveWarnings("Please enter authenticaion code and try again.");
    }

    return $.ajax({
      method: "POST",
      url: "/index.php?r=/site/auth-validation",
      data: {
        username: this.props.username,
        password: twoFACode,
        requestType: "authkey"
      }
    }).then((res) => {
      const response = JSON.parse(res);

      if (response?.error) {
        return receiveErrors(response.error);
      }

      if (response?.keyData) {
        return this.setState({ twoFAValidated: true });
      }

      return receiveErrors("Invalid authentication code.");
    })
  }

  render2FA = () => {
    return <div>
      <form onSubmit={this.submit2FA}>
        <PhenomInput label="Two Factor Authentication"
                    value={this.state.twoFACode}
                    placeholder="Enter two factor authentication"
                    onChange={(e) => this.setState({ twoFACode: e.target.value })}>

          <KendoButtonWithDebounce onClick={this.submit2FA}>Submit</KendoButtonWithDebounce>
        </PhenomInput>

      </form>
    </div>
  }

  render() {
    return <div className="fill-vertical">
      <Switch>
        <Route path="/settings/skayl/accounts" component={SkaylAccounts} />
        <Route path="/settings/skayl/admin" component={SkaylAdmin} />
        <Route path="/settings/:main_page(skayl)/user_model_manage/:username" component={UserPModelManager} />
        <Redirect to="/settings/skayl/accounts" />
      </Switch>
    </div>
  }
}


export const SkaylSubMenu = (props) => {
  return <nav className="sub-menu-actions" aria-label='form actions'>
          <SubMenuLeft>
            <NavLink id="attribute-usage-link"
                    to="/settings/skayl/accounts">Accounts</NavLink>
            <NavLink id="entity-usage-link"
                    to="/settings/skayl/admin">Admin</NavLink>
          </SubMenuLeft>

          {props.children &&
            <SubMenuRight>
              { props.children }
            </SubMenuRight> }
        </nav>
}

const msp = (state) => ({
  username: state.user.userIdentity?.username,
});

export default connect(msp)(SkaylManager);