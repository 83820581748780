import React, {Component} from 'react';
import { getActiveProjectChanges } from "../../requests/sml-requests";
import { receiveErrors } from "../../requests/actionCreators";
import { connect } from "react-redux";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import {PhenomLink} from "../../components/widget/PhenomLink";
import { formatDate, getShortenedStringRepresentationOfXmiType } from "../../components/util/util"
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";

class ProjectChanges extends Component {

    constructor (props){
        super(props);
        this.dateFilter = this.dateFilter.bind(this);
        this.onlyMe = this.onlyMe.bind(this);
    }

    state = {
        loading: false,
        allProjectChanges: [],
        activeProjectChanges: [],
        sortConfig: [
            {field: 'date', dir: 'desc'},
        ],
        numberOfEvents: 50,
        filteredDate: "",
        onlyMeChecked : false
    };

    componentDidMount() {
        this.getAllActiveProjectData();
    }

    async getAllActiveProjectData(){
        if (this.inputIsValidNumber(this.state.numberOfEvents)) {
            this.setState({ loading: true, activeProjectChanges: [] });
            getActiveProjectChanges(this.state.numberOfEvents).then(res => {
                const response = JSON.parse(res);

                if (response.data != null) {
                    this.setState({
                      loading: false,
                      allProjectChanges:  response.data.nodes,
                      activeProjectChanges: response.data.nodes.filter(this.dateFilter).filter(this.onlyMe)
                    });
                }
            }).catch(_ => {
                this.setState({ loading: false });
                receiveErrors('Something went wrong. Failed to retrieve Active Project Changes.');
            });
        } else {
            //receiveErrors('Please enter a valid number of Events.');
        }
    }

      inputIsValidNumber(numberString) {
        let valid = true;
        if (numberString == "" || !parseInt(numberString)) {
            valid = false;
        }
        return valid;
      }

      isDirectChildOfPackageList(parent){
        return (parent.guid == "root") //prevent Top level package descriptions from displaying root.<name>
                ||
                    [ "face:ConceptualDataModel",
                        "face:LogicalDataModel",
                        "face:PlatformDataModel"].indexOf(parent.xmiType) > -1;
      }

      getUpdatedString(data, elementIsDirectChildOfPackage) {
        let updatedStringArray = [];
        if (data.previousVersions != null) {
            Object.entries(data.previousVersions).map(([k,v]) => {
              const getValueExpr = (val) => {
                if (typeof val === "string") {
                  return `"${val}"`;
                } else {
                  return <PhenomLink node={val}/>
                }
              };
              const uKey = data["xmi:id"] + "-" + k;
              
              if (data.hasOwnProperty(k) && (v == ""))               //CREATED attr
                    updatedStringArray.push(<span key={uKey}>{` set attribute '${k}' to `} {getValueExpr(data[k])};</span>);
              else if (data.hasOwnProperty(k) && v != data[k])        //UPDATE attr
                    if (data[k] == "<new_image>")
                        updatedStringArray.push(<span key={uKey}>{` updated diagram image.`}</span>);
                    else if (data[k] == "<new_content>" && updatedStringArray.length == 0)
                        updatedStringArray.push(<span key={uKey}>{` updated diagram content.`}</span>);
                    else if (data[k] != "<new_content>")
                        updatedStringArray.push(<span key={uKey}>{` changed attribute '${k}' from`} {getValueExpr(v)} {"to"} {getValueExpr(data[k])};</span>);
              else if (!data.hasOwnProperty(k))                       //REMOVED attr
                    updatedStringArray.push(<span key={uKey}>{` removed attribute '${k}'`};</span>);
            });

            if (elementIsDirectChildOfPackage) {
                return <span>Updated {getShortenedStringRepresentationOfXmiType(data.xmiType)} node <PhenomLink node={data}/>:{updatedStringArray}</span>;
            }
            else {
                return <span>Updated {getShortenedStringRepresentationOfXmiType(data.xmiType)} node <PhenomLink node={data.parent}/>.<PhenomLink node={data}/>:{updatedStringArray}</span>;
            }
        }
      }

      rowRender = (_, props) => {
        const data = props.dataItem;
        const action = this.determineBranchChangeAction(data.action);
        const elementIsDirectChildOfPackage = this.isDirectChildOfPackageList(data.parent);
        const elementIsAttributeComposition = data.xmiType == "conceptual:Composition";
        const elementIsAssociatedEntity = data.xmiType == "conceptual:AssociatedEntity";
        const created_date = new Date(data.date);

        return <tr id={`perm-${data.guid}`}>
          <td style={{padding:"0 5px"}}>{formatDate(created_date, true)}</td>
          <td style={{padding:"0 5px"}}>{ data.editor }</td>
          <td style={{padding:"0 5px"}}>{ action }</td>
          <td style={{padding:"0 5px"}}>
             {data.action == "new" && (elementIsDirectChildOfPackage && <span>Created {getShortenedStringRepresentationOfXmiType(data.xmiType)} node <PhenomLink node={data}/>.</span>
                                    || elementIsAttributeComposition && <span>Composed <PhenomLink node={data}/> into <PhenomLink node={data.parent}/>.</span>
                                    || elementIsAssociatedEntity && <span>Added <PhenomLink node={data}/> as participant of <PhenomLink node={data.parent}/>.</span>
                                    || <span>Added node <PhenomLink node={data}/> to <PhenomLink node={data.parent}/>.</span>)}
             {data.action == "upd" && this.getUpdatedString(data, elementIsDirectChildOfPackage)}
             {data.action == "del" && <span>Deleted {getShortenedStringRepresentationOfXmiType(data.xmiType)} node <PhenomLink node={data}/>.</span>}
             {data.action == "dpr" && <span>Deprecated {getShortenedStringRepresentationOfXmiType(data.xmiType)} node <PhenomLink node={data}/>.</span>}
             {data.action == "mov_in" && <span>Moved {getShortenedStringRepresentationOfXmiType(data.xmiType)} node <PhenomLink node={data}/>{data.bref_name && ` into ${data.bref_name}`}.</span>}
             {data.action == "mov_out" && <span>Moved {getShortenedStringRepresentationOfXmiType(data.xmiType)} node <PhenomLink node={data}/>{data.bref_name && ` out of ${data.bref_name}`}.</span>}
          </td>
        </tr>
      }

      setNumberOfEvents(number) {
        this.setState({ numberOfEvents: number }, () => { this.getAllActiveProjectData() } );
      }

      setDateFilter = (e) => {
             this.setState({ filteredDate: e.target.value}, () => {
                this.setState({ activeProjectChanges: this.state.allProjectChanges.filter(this.dateFilter).filter(this.onlyMe)});
            });
      }

      sortChange = (event) => {
        this.setState({
            sortConfig: event.sort
        });
    }

      determineBranchChangeAction(action){
        switch(action){
            case "del":
                return "Deletion"
            case "new":
                return "Creation"
            case "dpr":
                return "Deprecation"
            case "upd":
                return "Update"
            case "mov_in":
                return "Moved In"
            case "mov_out":
                return "Moved Out"
        }
      }

    showMyActivity = (event) => {
        if (event.target.checked) { this.setState({ onlyMeChecked: true}, () =>
            this.setState({ activeProjectChanges:  this.state.activeProjectChanges.filter(this.onlyMe)}))}
        else {this.setState({ onlyMeChecked: false,
                              activeProjectChanges:  this.state.allProjectChanges.filter(this.dateFilter)})};
    }

    onlyMe(node) {
        if (this.state.onlyMeChecked) {
            return node.editor == this.props.username;
        }
        else{
            return true;
        }
    }

    dateFilter(node) {
        if (this.state!= null){
            if (this.state.filteredDate == "") {
                return true;
            }
            else {
                return new Date(node.date) >= new Date(this.state.filteredDate);
            }
        }
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
                 id="change-summary">
                <div style={{display: 'flex', alignItems : 'center', justifyContent: 'space-between'}}>
                    <div>
                        <h1>CHANGE SUMMARY</h1>
                    </div>
                    <div>
                        <input onChange={this.showMyActivity} id="project-changes-only-me-checkbox" type="checkbox" style={{marginRight:5}}/>
                        <label htmlFor="project-changes-only-me-checkbox" style={{marginRight:10}}>Only Show My Activity</label>
                        <input id="project-changes-number-of-events"
                            type="text"
                            value={this.state.numberOfEvents}
                            onChange={(e) => this.setNumberOfEvents(e.target.value)}
                            style={{width: "50px", marginRight:5}}/>
                        <label htmlFor="project-changes-number-of-events" style={{marginRight:5}}> Events</label>
                        <input type="date" id="project-changes-date" name="Date" style={{marginRight:5}} onChange={this.setDateFilter}></input>
                        <label htmlFor="project-changes-date" style={{marginRight:5}}> Date</label>
                    </div>
                </div>
                <Grid data={this.state.sortConfig.length ? orderBy(this.state.activeProjectChanges, this.state.sortConfig) : this.state.activeProjectChanges}
                    scrollable={"scrollable"}
                    style={{ overflow: "auto" }}
                    sortable
                    sort={this.state.sortConfig}
                    onSortChange={this.sortChange}
                    rowRender={this.rowRender}
                    resizable={true}>
                    <GridNoRecords>
                        { this.state.loading 
                          ? <div>
                                <img id="loading-spinner"
                                     style={{ width: 30 }}
                                     src={loadingIcon} />
                                <span>Fetching data</span>
                            </div>
                          : <span>No data available</span> }
                        </GridNoRecords>
                    <GridColumn title='Date Time' field='date' width="150px"/>
                    <GridColumn title='Author' field='editor' width="150px"/>
                    <GridColumn title='Action' field='action'width="150px"/>
                    <GridColumn title='Description' field='description'/>
                </Grid>
            </div>
        );
    }
}

const msp = (state) => ({

    username : state.user.userIdentity.username

});

export default connect(msp)(ProjectChanges)
