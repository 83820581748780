import {ColorCollapsable, Lwa, Lwtd} from "../util/stateless";
import {fetchAvailableTags, getSemanticEquivalencyByTag} from "../../requests/sml-requests";
import React from "react";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import PhenomId from "../../requests/phenom-id";

export class SemanticEquivalencyByGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: ["loading..."],
            tag1: null,
            tag2: null,
            loading: false,
            matching: [],
            not_matching: [],
            total: false,
            userId: window["userIndentity"] ?? ["id"]
        };
        this.phenomId = new PhenomId("semantic-equivalency-by-group",this.props.idCtx);
    }

    componentDidMount() {
        fetchAvailableTags().then((res) => {
            const tags = JSON.parse(res).tags || [];

            this.setState({tags: tags}, () => {
                if (document.cookie.includes(`${this.state.userId}EqPrefs=`)) {
                    const tags = document.cookie.split(";").find(cookie => cookie.includes(`${this.state.userId}EqPrefs=`)).split("=")[1].split(" ");
                    if (this.state.tags.includes(tags[0]) && this.state.tags.includes(tags[1])) {
                        this.setState({tag1: tags[0], tag2: tags[1]}, this.getEquivalency);
                    }
                }
            });
        });
    }

    componentDidUpdate(_, prevState) {
        if ((this.state.tag1 !== prevState.tag1 || this.state.tag2 !== prevState.tag2) &&
            this.state.tag1 !== this.state.tag2 &&
            this.state.tags.includes(this.state.tag1) &&
            this.state.tags.includes(this.state.tag2)) {
            this.getEquivalency();
        }
    }

    getQueryVariables = () => {
      const data = {};
      if (!window.location.search) return data;

      const query = window.location.search.substring(1);
      const items = query.split('&');
      for (let i = 0; i < items.length; i++) {
          const pair = items[i].split('=');
          data[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
      return data;
    }

    getEquivalency() {
        this.setState({loading: true});
        getSemanticEquivalencyByTag(this.state.tag1, this.state.tag2).then((res) => {
            const response = JSON.parse(res);
            response.loading = false;
            document.cookie = `${this.state.userId}EqPrefs=${this.state.tag1} ${this.state.tag2}`;
            this.setState(response, this.drawStat);
        });
    }

    generateMatchingContent() {
        const phenomId = this.phenomId;
        return (
            <table className="impact-table"
            // id="matching"
            id={phenomId.gen("matching","table")}>
                <thead>
                <tr>
                    <td>MATCHED PATH</td>
                    <td>FROM</td>
                    <td></td>
                    <td></td>
                    <td>TO</td>
                    <td></td>
                    <td>STRENGTH</td>
                </tr>
                </thead>
                <tbody>
                {this.state.matching.map((match,idx) => {
                    return (<tr>
                        <Lwtd txt={match.from.path} max={50} id={phenomId.gen(["matching",idx],"path")}/>
                        <td><Lwa txt={match.from.view.name} max={30}
                                 href={`/edit/details/view/${match.from.view.guid}/`}
                                 id={phenomId.gen(idx,"view-a")}/></td>
                        <td><Lwa txt={match.from.char.name} max={30}
                                 href={`/edit/details/characteristic/${match.from.char.guid}/`}
                                 id={phenomId.gen(idx,"char-a")}/>
                        </td>
                        <td>{">>>"}</td>
                        <td><Lwa txt={match.to.view.name} max={30}
                                 href={`/edit/details/view/${match.to.view.guid}/`}
                                 id={phenomId.gen(idx,"view-b")}/></td>
                        <td><Lwa txt={match.to.char.name} max={30}
                                 href={`/edit/details/characteristic/${match.to.char.guid}/`}
                                 id={phenomId.gen(idx,"char-b")}/></td>
                        <td style={{textAlign: "center"}}>100%</td>
                    </tr>);
                })}
                </tbody>
            </table>
        );
    }

    generateDangilingContent() {
        const phenomId = this.phenomId;
        return (
            <table className="impact-table"
            // id="dangling"
            id={phenomId.gen("dangling","table")}>
                <thead>
                <tr>
                    <td>VIEW</td>
                    <td>CHARACTERISTIC</td>
                    <td>PATH</td>
                </tr>
                </thead>
                <tbody>
                {this.state.not_matching.map((record,idx) => {
                    return (<tr>
                        <td><Lwa txt={record.view.name} max={35}
                                 href={`/edit/details/view/${record.view.guid}/`}
                                 id={phenomId.gen(["dangling",idx],"view")}/></td>
                        <td><Lwa txt={record.char.name} max={35}
                                 href={`/edit/details/characteristic/${record.char.guid}/`}
                                 id={phenomId.gen(idx,"char")}/></td>
                        <Lwtd txt={record.path} max={70}/>
                    </tr>);
                })}
                </tbody>
            </table>
        );
    }

    drawStat = () => {
        const canvas = document.getElementById(this.phenomId.gen("init","eq-canvas"));
        const context = canvas.getContext("2d");
        const pi = Math.PI;

        context.beginPath();
        context.arc(75, 75, 70, 0, 2 * pi, false);
        context.fillStyle = "#bcbec0";
        context.fill();

        context.beginPath();
        context.arc(75, 75, 55, (3 * pi / 2), ((3 * pi / 2) + (2 * pi) * (this.state.matched / this.state.total)), false);
        context.lineWidth = 30;
        context.strokeStyle = "#489bb3";
        context.stroke();

        context.beginPath();
        context.arc(75, 75, 50, 0, 2 * pi, false);
        context.fillStyle = "white";
        context.fill();

        context.font = "30px Source Sans Pro";
        context.fillStyle = "#9a9a9a";
        context.textAlign = "center";
        context.fillText(`${Math.round((this.state.matched / this.state.total) * 100) || 0}%`, canvas.width / 2, canvas.height / 2 + 5);

        context.font = "15px Source Sans Pro";
        context.fillStyle = "#9a9a9a";
        context.textAlign = "center";
        context.fillText("coverage", canvas.width / 2, canvas.height / 2 + 20);
    };

    render() {
        const phenomId = this.phenomId;
        return (
            <div className="phenom-content-scrollable flex-h subview-wrapper tree-height-w-buttons" style={{paddingRight: 0, width: "100%"}}>
                <div className="flex-v" style={{width: "15%", position: "relative", alignItems: "center"}}>
                    <select
                        className="cadet-select"
                        style={{width: "100%"}}
                        value={this.state.tag1}
                        id={phenomId.gen("tag1","select")}
                        onChange={e => this.setState({tag1: e.target.value})}
                    >
                        <option disabled={true} selected={true} id={phenomId.gen(["tag1","default"],"option")} value={null}>Select a tag</option>
                        {this.state.tags.map((tag,idx) => <option value={tag} id={phenomId.gen(["tag1",idx],"option")}>{tag}</option>)}
                    </select>
                    <select
                        className="cadet-select"
                        style={{width: "100%"}}
                        value={this.state.tag2}
                        id={phenomId.gen(["init","tag2"],"select")}
                        onChange={e => this.setState({tag2: e.target.value})}
                    >
                        <option disabled={true} selected={true} id={phenomId.gen(["tag2","default"],"option")} value={null}>Select a tag</option>
                        {this.state.tags.map((tag,idx) => <option value={tag}
                                                            id={phenomId.gen(["tag2",idx],"option")}
                                                            disabled={tag === this.state.tag1}>{tag}</option>)}
                    </select>
                    {this.state.total === false ||
                    <div className="flex-v" style={{alignItems: "center"}}>
                        <canvas
                            // id="eq-canvas"
                            id={phenomId.gen("init","eq-canvas")}
                            width={150}
                            height={150}
                            style={{width: 150, height: 150}}>
                        </canvas>
                        <label
                            style={{marginTop: 10, color: "#7f7f7f"}}
                            id={phenomId.gen("init","view-attributes-matched-label")}>{this.state.matched} / {this.state.total}</label>
                        <label style={{color: "#7f7f7f"}}>View Attributes Matched</label>
                    </div>}
                    <img style={{display: this.state.loading ? "block" : "none", top: 112, width: 145, left: "unset"}}
                         className="ring-o-loading" src={loadingIcon} id={phenomId.gen("init","loading-img")}/>
                </div>
                <div className="flex-v" style={{width: "calc(85% - 30px)", marginLeft: 30, marginTop: -4}}>
                    <ColorCollapsable
                        idCtx={phenomId.gen("init","semantic-equivalency")}
                        heading="Semantic Equivalency Found"
                        color="#489bb3"
                        content={this.state.matching.length ? this.generateMatchingContent() :
                            <div id="matching">No entries to display.</div>}
                        vMargin={20}
                        contentId={phenomId.gen(["init","matching"],"table")}
                        collapsableStyle={{overflowX: "scroll"}}
                        default={!this.state.matching.length}
                    />
                    <ColorCollapsable
                        idCtx={phenomId.gen("init","no-semantic-equivalency")}
                        heading="No Semantic Equivalency Found"
                        color="#dc143c"
                        content={this.state.not_matching.length ? this.generateDangilingContent() :
                            <div id="dangling">No entries to display.</div>}
                        vMargin={20}
                        contentId={phenomId.gen(["init","dangling"],"table")}
                        collapsableStyle={{overflowX: "scroll"}}
                        default={!this.state.not_matching.length}
                    />
                </div>
            </div>
        );
    }
}
