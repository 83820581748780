import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { acceptLicense, getLicense, acceptPermsUpdate } from '../../requests/sml-requests';
import { updateUserSettings } from '../../requests/actionCreators';
import styled from '@emotion/styled';
import $ from 'jquery';
import { StyledLicenseContent } from '../../views/License';
import permsImage from '../../images/perms-splash-screenshot.png';


class Splash extends React.Component {
  state = {
    license_confirmed: false,
    license_version: "0",
    license_text: "",

    accepted_perms_update: false,
  }

  componentDidMount() {
    this.fetchLicense();
    this.fetchPermsUpdate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.fetchLicense();
      this.fetchPermsUpdate();
    }
  }

  // =========================================
  // LICENSE
  // =========================================
  fetchLicense() {
    const { user } = this.props;
    if (!user?.authenticated) return;

    getLicense().then(res => {
      if (res === "#void") return;
      const { settings } = user.userIdentity;
      const response = JSON.parse(res);

      // show license popup
      if (!settings.license_version || settings.license_version < response.license_version) {
        this.setState({
          license_confirmed: false,
          license_version: response.license_version,
          license_text: response.license_text
        })
      } else {
        // hide license popup
        this.setState({ license_confirmed: true });
      }
    })
  }

  acceptLicense = () => {
    acceptLicense(this.state.license_version).then((res) => {
      if (res === "#true") {
        updateUserSettings({license_version: this.state.license_version});
        this.setState({ license_confirmed: true });
      } 
    })
  }

  fetchPermsUpdate() {
    const { user } = this.props
    if (!user?.authenticated) return;
    
    const { settings } = user.userIdentity;
    this.setState({
      accepted_perms_update: settings.accepted_perms_update
    })
  }

  acceptPermsUpdate = () => {
    acceptPermsUpdate().then((res) => {
      if (res === "#true") {
        this.setState({ accepted_perms_update: true });
      } 
    })
  }

  render() {
    const { user } = this.props;
    if (!user?.authenticated) return null;

    const { settings } = user.userIdentity;


    // Must render License Agreement first
    if (!this.state.license_confirmed) {
      return <LicenseConfirm text={this.state.license_text}
                             onConfirm={this.acceptLicense} />
    } else if (!this.state.accepted_perms_update) {
      return <PermsAcknowledge onConfirm={this.acceptPermsUpdate} />
    }

    return null;
  }
}





const LicenseConfirm = (props) => {
  const [confirm, setConfirm] = useState(false);

  if (!props.text) return null;

  return <Dialog title="Terms of Use" className="dialog-80vh dialog-80vw dialog-no-exit">
          <StyledLicenseContent>
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
          </StyledLicenseContent>
          <div>
              <input type="checkbox" 
                     id="licence-checkbox" 
                     checked={confirm}
                     style={{ marginRight: 5 }}
                     onChange={() => setConfirm(!confirm)} />
              <label htmlFor="checkbox">{ "I have read and agree to the Terms of Use" }</label>
          </div>
          <DialogActionsBar>
                <button className='k-button'
                        id="licence-accept" 
                        disabled={!confirm}
                        onClick={props.onConfirm}>
                          Accept
                </button>
          </DialogActionsBar>
  </Dialog>
}



const PermsAcknowledge = (props) => {
  const [confirm, setConfirm] = useState(false);

  return <Dialog title="Project and Model Sharing Update" className="dialog-80vh dialog-80vw dialog-no-exit">
          <p>PHENOM's permissions system has been updated to make it easier to use. The main two differences are the ability to grant Read, Write, and Admin permissions independently and <br/>
             the introduction of a new permission type, Owner. <br/>
             <br/>
             Users with Admin or Owner permissions to projects and models are allowed to give Read, Write, Admin, or combinations of those to other users for easy collaboration. </p>
          <h2 style={{fontWeight: "bold"}}>Read Permissions</h2>
          <p>Having Read permissions to a project and its models allows the user to switch to that project and see all the contained content but not create, edit, or update it. </p>
          <h2 style={{fontWeight: "bold"}}>Write Permissions</h2>
          <p>Having Write permissions to a project and its models allows the user to modify any of its content, including pulling and approving push requests. Read permissions are required <br/>
             to have Write permission. </p>
          <h2 style={{fontWeight: "bold"}}>Admin Permissions</h2>
          <p>Having Admin permissions to a project or a model allows the user set the permissions of other users to this project or model, and create inheriting projects or models. Admin <br/>
             users can share their project or model to users within their account with permissions up to Admin. Read permissions are required to have Admin permission. </p>
          <h2 style={{fontWeight: "bold"}}>Owner Permissions</h2>
          <p>Having Owner permissions to a project or a model provide the user with Admin permissions plus the ability to delete the project or model, to change the metadata of it, and <br/>
             to share it with user external to their account. Owner users can grant permissions up to Owner for internal users and Admin for external users. <br/>
             <br/>
             Note that Account administrators have the ability to grant any permissions to their account users up to Owner permissions. </p>
          <img src={permsImage} alt="Permissions UI"/>
          <p>For more information regarding the update, visit the <a href="https://kb.phenomportal.com/index.php/Permissions" target="_blank" style={{textDecoration: "revert"}}>PHENOM Knowledgebase</a></p>
          <div>
              <input type="checkbox" 
                     id="perms-checkbox" 
                     checked={confirm}
                     style={{ marginRight: 5 }}
                     onChange={() => setConfirm(!confirm)} />
              <label htmlFor="checkbox">{ "I acknowledge I read the update to the PHENOM permissions system." }</label>
          </div>
          <DialogActionsBar>
                <button className='k-button'
                        id="perms-accept" 
                        disabled={!confirm}
                        onClick={props.onConfirm}>
                          Accept
                </button>
          </DialogActionsBar>
  </Dialog>
}



const msp = (state) => ({
  user: state.user,
})

export default connect(msp)(Splash);