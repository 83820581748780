import React, { useState } from "react";
import { LineLabel, SelectChangeSetId } from "../util/stateless";
import LoaderButton from "../widget/LoaderButton";
import PhenomId from "../../requests/phenom-id"
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";



export function SubMenuLeft({ children, ...restProps }) {
  const [processingRequest, setProcessingRequest] = useState(false);

  const handleClick = async (callback) => {
    try {
      setProcessingRequest(true);
      callback && await callback();
      setProcessingRequest(false);
      
    } catch (error) {
      setProcessingRequest(false);
    }
  }

  return <div className="sub-menu-left" role="left sub menu" {...restProps}>
            {React.Children.map(children, (child) => {
              if (!child) return null;
              return React.cloneElement(child, {
                ...child.props,
                onClick: () => handleClick(child.props.onClick),
                disabled: child.props.disabled || processingRequest,
              })
            })}

            {processingRequest &&
            <img id="loading-spinner"
                  style={{ width: 30 }}
                  src={loadingIcon} /> }
          </div>
}

export function SubMenuRight({ children, ...restProps }) {
  const [processingRequest, setProcessingRequest] = useState(false);

  const handleClick = async (callback) => {
    try {
      setProcessingRequest(true);
      callback && await callback();
      setProcessingRequest(false);

    } catch (error) {
      setProcessingRequest(false);
    }
  }

  return <div className="sub-menu-right" role="right sub menu" {...restProps}>
            {processingRequest &&
            <img id="loading-spinner"
                  style={{ width: 30 }}
                  src={loadingIcon} /> }

            {React.Children.map(children, (child) => {
              if (!child) return null;
              return React.cloneElement(child, {
                ...child.props,
                onClick: () => handleClick(child.props.onClick),
                disabled: child.props.disabled || processingRequest,
              })
            })}
          </div>
}




class NR_EditTopButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDown: false,
            changeSetId: "0"
        };
    }

    doClickFunction(callback) {
        callback();
    }

    setChangeSetId(e) {
        this.setState({changeSetId: e.target.value});
        // this.state.changeSetId = e.target.value == "0" ? null : e.target.value;
        this.props.setChangeSetId(e);
    }

    navigateTo(destination) {
      switch (destination) {
        case "idm":
          this.props.history.push(`/edit/${destination}`);
          break;
        default:
          this.props.history.push(`/edit/details/${destination}`)
      }
      window["treeRef"].clearSelection();
  };

    render() {
        return null;

        const winCanEdit = window["canEdit"];
        const propsCanEdit = this.props.canEdit !== undefined ? this.props.canEdit : true;
        const expired = window["expired"];
        const phenomId = new PhenomId("edit-top-buttons");

        return (
            <div ref={this.props.ref} className="top-button-wrapper flex-h" style={this.props.style}>
                {winCanEdit && this.props.canCreate !== false &&
                    <button className="bordered-button" onBlur={() => this.setState({dropDown: false})}
                        onClick={() => this.setState({dropDown: !this.state.dropDown})}
                        style={{width: 105 }}
                        disabled={expired}
                        id={phenomId.gen("create")}>CREATE ▼</button>}
                {!this.state.dropDown || <ul className='top-buttons-list' onClick={this.clearTreeSelection} id={phenomId.gen("list")}>
                    <li id={phenomId.gen("list","package")} onMouseDown={() => this.navigateTo(`package/new/`)}>PACKAGE</li>
                    <li id={phenomId.gen("list","entity")} onMouseDown={() => this.navigateTo(`entity/new/`)}>ENTITY</li>
                    <li id={phenomId.gen("list","view")} onMouseDown={() => this.navigateTo(`view/new/`)}>VIEW</li>
                    <li id={phenomId.gen("list","platform-type")} onMouseDown={() => this.navigateTo(`platform_type/new/`)}>PLATFORM TYPE</li>
                    <li id={phenomId.gen("list","constraint")} onMouseDown={() => this.navigateTo(`constraint/new/`)}>CONSTRAINT</li>
                    <li id={phenomId.gen("list","service")} onMouseDown={() => this.navigateTo(`portable_component/new/`)}>SERVICE</li>
                    <li id={phenomId.gen("list", "logical")} className="sub-menu">◀ LOGICAL
                        <ul className="top-buttons-list">
                            <li id={phenomId.gen("logical","measurement")} onMouseDown={() => this.navigateTo(`measurement/new`)}>MEASUREMENT</li>
                            <li id={phenomId.gen("logical","measurement-system")} onMouseDown={() => this.navigateTo(`measurement_system/new`)}>MEASUREMENT SYSTEM</li>
                            <li id={phenomId.gen("logical","measurement-system-axis")} onMouseDown={() => this.navigateTo(`measurement_system_axis/new/`)}>MEASUREMENT SYSTEM AXIS</li>
                            <li id={phenomId.gen("logical","coordinate-system")} onMouseDown={() => this.navigateTo(`coordinate_system/new/`)}>COORDINATE SYSTEM</li>
                            <li id={phenomId.gen("logical","coordinate-system-axis")} onMouseDown={() => this.navigateTo(`coordinate_system_axis/new/`)}>COORDINATE SYSTEM AXIS</li>
                            <li id={phenomId.gen("logical","conversion")} onMouseDown={() => this.navigateTo(`conversion/new/`)}>CONVERSION</li>
                            {window["expertMode"] && <li id={phenomId.gen("logical","unit")} onMouseDown={() => this.navigateTo(`unit/new/`)}>UNIT</li>}
                        </ul>
                    </li>
                    <li id={phenomId.gen("list", "integration")} className="sub-menu">◀ INTEGRATION
                        <ul className="top-buttons-list">
                            <li id={phenomId.gen(["list","integration"],"idm")} onMouseDown={() => this.navigateTo(`idm`)}>
                                CONTEXT
                            </li>
                            <li id={phenomId.gen("integration","transport-channel")} onMouseDown={() => this.navigateTo(`transport_channel/new/`)}>
                                TRANSPORT CHANNEL
                            </li>
                            <li id={phenomId.gen("integration","uop-instance")} onMouseDown={() => this.navigateTo(`uop_instance/new/`)}>
                                UOP INSTANCE
                            </li>
                            <li id={phenomId.gen("integration","main-program")} onMouseDown={() => this.navigateTo(`main_program/new/`)}>
                                MAIN PROGRAM
                            </li>
                            <li id={phenomId.gen("integration","processing-element")} onMouseDown={() => this.navigateTo(`processing_element/new/`)}>
                                PROCESSING ELEMENT
                            </li>
                        </ul>
                    </li>
                    {window["expertMode"] && <li id={phenomId.gen("list","observable")} onMouseDown={() => this.navigateTo(`observable/new/`)}>OBSERVABLE</li>}
                </ul>}
                {!winCanEdit || !this.props.deleteBtn || <LoaderButton divStyle={{marginTop: "-10px", marginRight: (this.props.resetBtn ? "0px" : "10px")}}
                    id={phenomId.gen("edit-top-buttons","delete")}
                    className="bordered-button"
                    text="DELETE"
                    onClick={this.state.changeSetId == "0" ? () => this.props.deleteBtn() : () => this.props.deleteBtn(this.state.changeSetId)}
                    disabled={expired || !propsCanEdit}
                />}
                {!winCanEdit || !this.props.resetBtn || <button
                    id={phenomId.gen("edit-top-buttons","reset")}
                    className="bordered-button"
                    onClick={this.props.resetBtn}
                    disabled={expired || !propsCanEdit}>
                    RESET
                </button>}
                {!winCanEdit || !this.props.saveBtn ||
                    <LoaderButton divStyle={{marginTop: "-10px"}}
                        id={phenomId.gen("edit-top-buttons","save")}
                        className="bordered-button"
                        text="SAVE"
                        onClick={this.state.changeSetId == "0" ? () => this.props.saveBtn() : () => this.props.saveBtn(this.state.changeSetId)}
                        disabled={expired || !propsCanEdit}
                    />}
                {this.props.hideSelectableChangeSets || <div style={{marginTop: "-5px", marginLeft: 5}}>
                    <LineLabel text="Choose a Change Set:" style={{fontSize: 13}} idCtx={phenomId.gen("change-set","")}/>
                    <SelectChangeSetId value={this.state.changeSetId}
                        idCtx={phenomId.gen("change-set","")}
                        onChange={(e) => this.setChangeSetId(e)} />
                </div>}
                {
                    this.props.buttons && Object.entries(this.props.buttons).map((entry,eIdx) => {
                        const btn = entry[0];
                        const func = entry[1];
                        const btnClass = btn.split("-")[0];
                        const btnName = btn.split("-")[1];

                        return <LoaderButton divStyle={{marginTop: "-10px"}}
                            id={phenomId.gen("edit-top-buttons",`${eIdx}`)}
                            className={`${btnClass}-button`}
                            text={btnName}
                            onClick={func}
                        />;
                    })
                }
            </div >
        );
    }
}


const EditTopButtons = NR_EditTopButtons;
export default EditTopButtons;
