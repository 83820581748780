import React from 'react'
import { PackageComboBox, PhenomInput, PhenomLabel, PhenomSelect, PhenomTextArea } from '../util/stateless';
import { createPhenomGuid, deGuidify, splitXmiType } from '../util/util';
import ChangeSetPicker from '../widget/ChangeSetPicker';
import { NodeHistory2 } from './node-history';
import { withPageLayout } from "./node-layout";
import PhenomId from "../../requests/phenom-id";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Button, Toolbar } from '@progress/kendo-react-buttons';
import DeletionConfirm2 from '../dialog/DeletionConfirm2';
import { logicalValueTypeList } from '../../global-constants';



export class ValueTypeManager extends React.Component {
  static defaultProps = {
    newNode: {
      name: "",
      xmiType: "logical:Boolean",
      description: "",
      parent: "",
      standardReference: "",
      children: [],
      subModelId: undefined,
    },
    nodeAddenda: {
      coreAddenda: ["childrenMULTI"],
      coreAddendaChildren: ["allowedValueMULTI"],
    }
  }

  constructor(props) {
    super(props);

    this.phenomId = new PhenomId("value-type", this.props.idCtx);
  }

  labelHash = {}
  pagination = {
    skip: 0,
    take: 50,
  }
  state = {
    ...this.props.newNode,
    selectedLabelGuids: new Set(),
  }
  xmiTypeList = logicalValueTypeList.map(type => splitXmiType(type));

  componentDidMount() {
    this.initNodeState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.node !== this.props.node) {
      this.initNodeState();
    }
  }

  initNodeState = () => {
    const { node } = this.props;

    this.labelHash = deGuidify(node.children || []);
    this.pagination = { skip: 0, take: 50 }
    this.setState({ ...node })
  }

  generateNode = () => {
    const data = {
      guid: this.state.guid,
      name: this.state.name,
      xmiType: this.state.xmiType,
      description: this.state.description,
      parent: this.state.parent || undefined,
      subModelId: this.state.subModelId,
    }

    if (data.xmiType === "logical:Enumerated") {
      data.standardReference = this.state.standardReference;
      data.children = this.state.children.filter(label => this.isLabelEdited(label));
    }

    return data;
  }

  pageChange = (event) => {
    this.pagination = {
      skip: event.page.skip,
      take: event.page.take,
    }

    this.forceUpdate();
  }

  isLabelEdited = (label) => {
    const original = this.labelHash[label?.guid];
    if (!original) return true;

    // compare label attributes with original
    let isAttrEdited = ["name", "description"].some(attr => label[attr] !== original[attr]);

    return isAttrEdited;
  }

  addNewLabel = () => {
    const children = [...this.state.children];
    children.push({
      guid: createPhenomGuid(),
      name: "",
      description: "",
      xmiType: "logical:EnumerationLabel",
    });

    // jump to the last page
    const pageIndex = Math.floor((children.length - 1) / this.pagination.take);
    this.pagination = {
      ...this.pagination,
      skip: pageIndex * this.pagination.take,
    }

    this.setState({ children });
  }

  updateLabelProp = (node, key, value) => {
    const children = [...this.state.children];
    const idx = children.findIndex(label => label.guid === node.guid);
    if (idx < 0) return;

    children[idx] = {
      ...node,
      [key]: value,
    }

    this.setState({ children });
  }
  
  toggleLabelCheckbox = (guid) => {
    const selectedLabelGuids = new Set(this.state.selectedLabelGuids);

    if (selectedLabelGuids.has(guid)) {
      selectedLabelGuids.delete(guid);
    } else {
      selectedLabelGuids.add(guid);
    }

    this.setState({ selectedLabelGuids });
  }

  deleteLabels = () => {
    if (!this.state.selectedLabelGuids.size) return;
    const selectedLabelGuids = [...this.state.selectedLabelGuids];

    DeletionConfirm2.showMulti([...this.state.selectedLabelGuids], "Labels", (status) => {
      if (!status.deleted) return;
      const children = [...this.state.children];

      for (let labelGuid of selectedLabelGuids) {
        const removeIdx = children.findIndex(label => label.guid === labelGuid);
        removeIdx > -1 && children.splice(removeIdx, 1);
      }

      this.setState({ children, selectedLabelGuids: new Set() })
    }, true);
  }

  renderLabelCheckboxCell = (cellProps) => {
    const isChecked = this.state.selectedLabelGuids.has(cellProps.dataItem.guid);

    return <td style={{ textAlign: "center" }}>
        <Checkbox label=""
                  checked={isChecked}
                  onChange={() => this.toggleLabelCheckbox(cellProps.dataItem.guid)} />
    </td>
  }

  renderLabelNameCell = (cellProps) => {
    return <td>
        <PhenomInput value={cellProps.dataItem.name}
                     onChange={(e) => this.updateLabelProp(cellProps.dataItem, "name", e.target.value)} />
    </td>
  }

  renderLabelDescriptionCell = (cellProps) => {
    return <td>
        <PhenomInput value={cellProps.dataItem.description}
                     onChange={(e) => this.updateLabelProp(cellProps.dataItem, "description", e.target.value)} />
    </td>
  }

  renderRow = (_, cellProps) => {
    const isEdited = this.isLabelEdited(cellProps.dataItem);

    return <tr style={{ background: isEdited ? "hsl(var(--skayl-sky-hs) 86%)" : null }}>
      { this.renderLabelCheckboxCell(cellProps) }
      { this.renderLabelNameCell(cellProps) }
      { this.renderLabelDescriptionCell(cellProps) }
    </tr>
  }


  render() {
    const { editable } = this.props;
    const original = this.props.node;
    const isEnumerated = this.state.xmiType === "logical:Enumerated";

    return <div className="edit-form">
            <div className="p-row">
              <div className="p-col">
                <PhenomInput label="Value Type"
                             value={this.state.name}
                             originalValue={original["name"]}
                             disabled={!editable}
                             autoFocus={true}
                             id={this.phenomId.genPageId("name")}
                             onChange={(e) => this.setState({ name: e.target.value })}
                             onClickResetIcon={() => this.setState({ name: original["name"] })} />
                <div className="p-row p-with-flex">
                  <PhenomSelect label="Type"
                                value={splitXmiType(this.state.xmiType)}
                                data={this.xmiTypeList}
                                disabled={!editable || isEnumerated}
                                dataDisabled={["Enumerated"]}
                                id={this.phenomId.genPageId("type")}
                                onChange={(e) => this.setState({ xmiType: `logical:${e.target.value}` })} />

                  <PackageComboBox label="Parent Package"
                                    xmiType="face:LogicalDataModel"
                                    placeholder="<Default>"
                                    nodeGuid={this.state.guid}
                                    selectedGuid={this.state.parent}
                                    originalGuid={original["parent"]}
                                    disabled={!editable}
                                    id={this.phenomId.genPageId("parent")}
                                    onChange={(parent) => this.setState({ parent: parent.guid })}
                                    onClickResetIcon={() => this.setState({ parent: original["parent"] })} />
                </div>

                <PhenomTextArea label="Description"
                                value={this.state.description}
                                originalValue={original["description"]}
                                disabled={!editable}
                                id={this.phenomId.genPageId("description")}
                                onChange={(e) => this.setState({ description: e.target.value })} />
              </div>

              <div className="edit-side">
                <NodeHistory2 guid={this.state.guid}
                              ref={ele => this.historyRef = ele} />
                <ChangeSetPicker label="Change Set"
                                 disabled={!editable} />
              </div>
            </div>

            {isEnumerated &&
              <PhenomInput label="Standard Reference"
                            value={this.state.standardReference}
                            originalValue={original["standardReference"]}
                            disabled={!editable}
                            id={this.phenomId.genPageId("standardReference")}
                            onChange={(e) => this.setState({ standardReference: e.target.value })}
                            onClickResetIcon={() => this.setState({ standardReference: original["standardReference"] })} /> }

            {!!this.state.children.length &&
            <div>
              <PhenomLabel text="Labels" />
              <Toolbar>
                <Button icon="trash"
                        onClick={this.deleteLabels}>Selected</Button>
              </Toolbar>
              <Grid data={this.state.children.slice(this.pagination.skip, this.pagination.take + this.pagination.skip)}
                    pageable={this.state.children.length > this.pagination.take}
                    skip={this.pagination.skip}
                    take={this.pagination.take}
                    total={this.state.children.length}
                    onPageChange={this.pageChange}
                    rowRender={this.renderRow}>
                <GridNoRecords>
                  No Data is available for this table.
                </GridNoRecords>
                <GridColumn title="Select" width="60px" />
                <GridColumn title="Name" />
                <GridColumn title="Description" />
              </Grid>
              <Toolbar>
                <Button icon="plus"
                        onClick={this.addNewLabel}>Add new label</Button>
              </Toolbar>
            </div> }
          </div>
  }
}


export const EditValueTypeManager = withPageLayout(ValueTypeManager);
