import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { KendoButtonWithDebounce, PhenomComboBox, PhenomInput, PhenomSelect, PhenomToggle } from '../util/stateless';
import { PhenomLink } from '../widget/PhenomLink';
import { cloneDeep } from 'lodash';
import { createPhenomGuid, formatDate, isPhenomGuid } from '../util/util.js'
import $ from 'jquery';
import { receiveLogs, receiveResponse } from '../../requests/actionCreators';
import { BasicConfirm } from '../dialog/BasicConfirm';


const isDateExpired = (dateRaw) => {
  if (!dateRaw) return false;
  const expire_date = new Date(dateRaw);
  return Date.now() > expire_date.getTime();
}

const willDateExpire = (dateRaw) => {
  if (!dateRaw) return false;
  const expire_date = new Date(dateRaw);
  const twoWeeks = 1209600000;
  return Date.now() > expire_date.getTime() - twoWeeks && Date.now() < expire_date.getTime();
}

export const UserNameCell = ({ user_id, username="", onChange }) => {
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);
  const isCurrentUser = loggedInUserId === user_id;

  return <td>
      <PhenomInput value={username}
                   disabled={isCurrentUser}
                   onChange={onChange} />
    </td>
}

export const UserEmailCell = ({ user_id, email, onChange }) => {
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);
  const isCurrentUser = loggedInUserId === user_id;

  return <td>
      <PhenomInput value={email}
                   disabled={isCurrentUser}
                   onChange={onChange} />
    </td>
}

export const UserActiveCell = ({ user_id, active=false, onChange }) => {
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);
  const [data, _] = useState(["Disabled", "Enabled"]);

  // user cannot edit himself
  if (loggedInUserId === user_id) {
    return <td />
  }

  return <td><PhenomSelect value={active ? data[1] : data[0]}
                           data={data}
                           onChange={onChange} /></td>
}

export const UserAdminCell = ({ user_id, isAdmin=false, onChange }) => {
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);
  const [data] = useState(["No", "Yes"]);
  const isDisabled = loggedInUserId === user_id;

  return <td style={{ textAlign: "center" }}>
    <PhenomToggle
      checked={isAdmin}
      data={data}
      disabled={isDisabled}
      onChange={onChange} />
  </td>
}

export const UserPasswordCell = ({ user_id, onClick }) => {
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);

  // user cannot edit himself
  if (loggedInUserId === user_id) {
    return <td />
  }

  return <td style={{ textAlign: "center" }}>
    <Button iconClass="fas fa-edit"
            onClick={onClick} />
  </td>
}

export const UserDeleteCell = ({ user_id, onClick }) => {
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);

  // user cannot edit himself
  if (loggedInUserId === user_id) {
    return <td />
  }

  return <td style={{ textAlign: "center" }}>
    <KendoButtonWithDebounce iconClass="fas fa-trash"
                             onClick={onClick} />
  </td>
}

/**
 * Show user's phenom license
 *    allow admin to revoke the license
 */
export const UserAssignedPhenomLicenseCell = ({ user_id, license_id, isRevoke=false, onClick }) => {
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);
  const isCurrentUser = loggedInUserId === user_id;
  let revokeIconClass = isRevoke ? "fas fa-link" : "fas fa-unlink";
  let revokeTitle = isRevoke ? "Cancel revoke" : "Revoke";

  // invalid
  if (!loggedInUserId) {
    return <td />
  }

  return <td>
          <div style={{ display: "flex", alignItems: "center", gap: 5, justifyContent: "space-between", whiteSpace: "nowrap"  }}>
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              { license_id }
            </span>
            {!isCurrentUser &&
              <Button iconClass={revokeIconClass}
                      title={revokeTitle}
                      onClick={onClick} /> }
          </div>
  </td>
}

/**
 * Show unassigned phenom licenses
 *    allow admin to assign a license to user
 */
export const UserUnassignedPhenomLicenseCell = ({ user_id, phenom_licenses={}, invoke_users_to_phenom_licenses={}, onChange, onClickCancelIcon, newLicenses }) => {
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);
  const [unassigned_licenses, setUnassignedLicenses] = useState([]);
  const [selected_license, setSelectedLicense] = useState();

  useEffect(() => {
    const license_assignees = new Set(Object.values(invoke_users_to_phenom_licenses));
    
    let unassigned = Object.values(phenom_licenses).filter(lic => !lic.user_id && !isDateExpired(lic.expiration_date) && !license_assignees.has(lic.license_id))

    // invoke_users_to_phenom_licenses = { <user_id>: <license_id>  }
    let selected_license_id = invoke_users_to_phenom_licenses[user_id];
    let selected = phenom_licenses[selected_license_id];

    setUnassignedLicenses(unassigned);
    setSelectedLicense(selected);
  }, [phenom_licenses, invoke_users_to_phenom_licenses, user_id, newLicenses]);


  // user cannot edit himself
  if (loggedInUserId === user_id) {
    return <td />
  }

  return <td>
    <PhenomComboBox data={unassigned_licenses}
                    value={selected_license}
                    placeholder="Click to assign"
                    dataItemKey="license_id"
                    onChange={onChange}
                    onClickCancelIcon={onClickCancelIcon} />
  </td>
}

/**
 * Show phenom license's user
 *    allow admin to revoke the license
 * (reverse pov of UserAssignedPhenomLicenseCell)
 */
 export const PhenomLicenseAssignedUserCell = ({ user_id, username="", isRevoke=false, onClick, license_id, users={}, invoke_phenom_licenses_to_users={}, onChange, onClickCancelIcon }) => {
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);
  const [uassigned_users, setUnassignedUsers] = useState([]);
  const [selected_user, setSelectedUser] = useState();
  const isCurrentUser = loggedInUserId === user_id;
  let revokeIconClass = isRevoke ? "fas fa-link" : "fas fa-unlink";
  let revokeTitle = isRevoke ? "Cancel revoke" : "Revoke";

  useEffect(() => {
    const user_assignees = new Set(Object.values(invoke_phenom_licenses_to_users));
    const unassigned = Object.values(users).filter(user => !user.license_id && !user_assignees.has(user.id))
    
    // invoke_phenom_licenses_to_users = { <license_id>: <user_id> }
    let selected_user_id = invoke_phenom_licenses_to_users[license_id];
    let selected = users[selected_user_id];

    setUnassignedUsers(unassigned);
    setSelectedUser(selected);
  }, [users, invoke_phenom_licenses_to_users, license_id]);

  // user cannot edit himself
  if (loggedInUserId === user_id) {
    return <td>
              <div style={{ display: "flex", alignItems: "center", gap: 5, justifyContent: "space-between", whiteSpace: "nowrap"  }}>
                <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  { username }
                </span>
              </div>
    </td>
  }

  return <td>
          <div style={{ display: "flex", alignItems: "center", gap: 5, justifyContent: "space-between", whiteSpace: "nowrap"  }}>
            <PhenomComboBox data={uassigned_users}
                    value={selected_user}
                    placeholder={ username }
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    dataItemKey="id"
                    onChange={onChange}
                    onClickCancelIcon={onClickCancelIcon} />
            {!isCurrentUser &&
              <Button iconClass={revokeIconClass}
                      title={revokeTitle}
                      onClick={onClick} /> }
          </div>
  </td>
}



/**
 * Show unassigned user
 *    allow admin to assign user to a phenom license
 * (reverse pov of UserUnassignedPhenomLicenseCell)
 */
 export const PhenomLicenseUnassignedUserCell = ({ user_id, license_id, users={}, invoke_phenom_licenses_to_users={}, onChange, onClickCancelIcon }) => {
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);
  const [uassigned_users, setUnassignedUsers] = useState([]);
  const [selected_user, setSelectedUser] = useState();

  useEffect(() => {
    const user_assignees = new Set(Object.values(invoke_phenom_licenses_to_users));
    const unassigned = Object.values(users).filter(user => !user.license_id && !user_assignees.has(user.id))
    
    // invoke_phenom_licenses_to_users = { <license_id>: <user_id> }
    let selected_user_id = invoke_phenom_licenses_to_users[license_id];
    let selected = users[selected_user_id];

    setUnassignedUsers(unassigned);
    setSelectedUser(selected);
  }, [users, invoke_phenom_licenses_to_users, license_id]);


  // user cannot edit himself
  if (loggedInUserId === user_id) {
    return <td />
  }

  return <td>
    <PhenomComboBox data={uassigned_users}
                    value={selected_user}
                    placeholder="Click to assign"
                    dataItemKey="id"
                    onChange={onChange}
                    onClickCancelIcon={onClickCancelIcon} />
  </td>
}

export const UserExpireDateCell = ({ dateRaw, userId, onChange }) => {
  const [currDate, setCurrDate] = useState(null);
  const loggedInUserId = useSelector(state => state.user.userIdentity?.id);
  const isCurrentUser = loggedInUserId === userId;

  useEffect(() => {
    const date = dateRaw ? new Date(dateRaw) : "";
    setCurrDate(date);
  }, [dateRaw])

  let background;
  if (isDateExpired(dateRaw)) background = "var(--bs-danger)";
  if (willDateExpire(dateRaw)) background = "var(--bs-warning)";

  if (isCurrentUser || !onChange) {
    return <td style={{ background }}>
      <DatePicker value={currDate}
                  width="100%"
                  disabled={true} />
    </td>
  }

  if (!isCurrentUser) {
    return <td style={{ background }}>
      <DatePicker value={currDate}
                  width="100%"
                  onChange={onChange} />
    </td>
  }

}

export const FormattedDateCell = ({ dateRaw }) => {
  if (!dateRaw) {
    return <td />
  }

  return <td>
    { formatDate(dateRaw) }
  </td>
}

export const UserProjectsCell = ({ username }) => {
  const history = useHistory();
  const redirect = () => {
    history.push(`/settings/skayl/user_model_manage/${username}`);
  }

  return <td style={{ textAlign: "center" }}>
    <Button iconClass="fas fa-tasks"
            title="Manage user's projects"
            onClick={redirect} />
  </td>
}


export const UserCurrentProjectCell = ({ all_projects={}, current_project_id, available_projects=[], onChange }) => {
  const [projects, setProjects] = useState([]);
  const [currProject, setCurrProject] = useState();

  useEffect(() => {
    const projects = [];
    available_projects.forEach(id => all_projects[id] && projects.push(all_projects[id]));
    setProjects(projects);
  }, [all_projects, available_projects])

  useEffect(() => {
    const project = projects.find(proj => proj.id === current_project_id);
    setCurrProject(project);
  }, [projects, current_project_id])

  return <td>
    <PhenomComboBox data={projects}
                    value={currProject}
                    textField="name"
                    dataItemKey="id"
                    onChange={onChange} />
  </td>
}



export const SkaylAccountInfo = ({ account_info={}, cinc_licenses={}, phenom_licenses={}, style }) => {
    const { accountName, subscriptionLicense, subscriptionRole } = account_info;
    const [num_active_phenom_licenses, setNumActivePhenomLicenses] = useState(0);
    const [num_unassigned_phenom_licenses, setNumUnassignedPhenomLicenses] = useState(0);
    const [num_cinc_licenses, setNumCincLicenses] = useState(0);

    useEffect(() => {
      const license_list = Object.values(phenom_licenses);
      const unexpired_licenses = license_list.filter(lic => !isDateExpired(lic.expiration_date));
      const num_active = unexpired_licenses.length;
      const num_unassigned = license_list.filter(lic => !lic.user_id).length;

      setNumActivePhenomLicenses(num_active);
      setNumUnassignedPhenomLicenses(num_unassigned);
    }, [phenom_licenses])

    useEffect(() => {
      setNumCincLicenses(Object.keys(cinc_licenses).length);
    }, [cinc_licenses])


    if (!accountName) {
      return null;
    }

    return <div style={style}>
      <table>
        <tbody>
          <tr>
            <th>Subscription Name</th>
            <td>{ accountName }</td>
          </tr>
          <tr>
            <th>Subscription License</th>
            <td>{ subscriptionLicense }</td>
          </tr>
          <tr>
            <th>Subscription Role</th>
            <td>{ subscriptionRole }</td>
          </tr>
          <tr>
            <th>Active PHENOM Licenses</th>
            <td>{ num_active_phenom_licenses } (unassigned: { num_unassigned_phenom_licenses })</td>
          </tr>
          <tr>
            <th>Number of CinC Licenses</th>
            <td>{ num_cinc_licenses }</td>
          </tr>
        </tbody>
      </table>
    </div>
}




export class SkaylPhenomLicenses extends React.Component {
  state = {
    licenses: [],
    currentExpireDate: null,
    newExpireDate: null,
  }

  componentDidMount() {
    this.initVersionList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.licenses !== this.props.licenses) {
      this.initVersionList();
    }
  }

  initVersionList = () => {
    const licenseList = Object.values(this.props.licenses);
    this.setState({ 
      licenses: cloneDeep(licenseList),
    });
  }

  isLicenseEdited = (license) => {
    const { phenom_licenses_revoke, invoke_phenom_licenses_to_users, phenom_licenses_new_date } = this.props;

    // check if license is set as revoked:
    const isRevokeLicense = phenom_licenses_revoke.has(license.license_id);

    // check if license is to be invoked:
    const isInvokeLicense = invoke_phenom_licenses_to_users[license.license_id];

    // check if license expire date is to be changed:
    let newLicenseDate = phenom_licenses_new_date[license.license_id];

    return isRevokeLicense || isInvokeLicense || newLicenseDate;
  }

  renderUserCell = (cellProps) => {
    const { users, invoke_phenom_licenses_to_users, phenom_licenses_revoke, toggleRevokePhenomLicense, assignInvokePhenomLicense, clearInvokePhenomLicense } = this.props;
    const license = cellProps.dataItem;

    if (license.user_id) {
      return <PhenomLicenseAssignedUserCell user_id={license.user_id}
                                            username={license.username}
                                            license_id={license.license_id}
                                            users={users}
                                            isRevoke={phenom_licenses_revoke.has(license.license_id)}
                                            invoke_phenom_licenses_to_users={invoke_phenom_licenses_to_users}
                                            onClick={() => toggleRevokePhenomLicense(license.license_id)}
                                            onChange={(user) => { toggleRevokePhenomLicense(license.license_id); assignInvokePhenomLicense(license.license_id, user.id) }}
                                            onClickCancelIcon={() => { toggleRevokePhenomLicense(license.license_id); clearInvokePhenomLicense(license.license_id)}} />
    }

    return <PhenomLicenseUnassignedUserCell user_id={license.user_id}
                                            license_id={license.license_id}
                                            users={users}
                                            invoke_phenom_licenses_to_users={invoke_phenom_licenses_to_users}
                                            onChange={(user) => assignInvokePhenomLicense(license.license_id, user.id)}
                                            onClickCancelIcon={() => clearInvokePhenomLicense(license.license_id)} />
  }

  renderRow = (_, cellProps) => {
    const { phenom_licenses_new_date, assignNewExpireDatePhenomLicense } = this.props;
    const isEdited = this.isLicenseEdited(cellProps.dataItem);
    const license = cellProps.dataItem;
    const expire_date = phenom_licenses_new_date[license.license_id] || license.expiration_date;

    return <tr style={{ background: isEdited ? "hsl(var(--skayl-sky-hs) 86%)" : null }}>
      <td>{ license.license_id }</td>

      <UserExpireDateCell dateRaw={expire_date}
                          userId={license.user_id}
                          onChange={(e) => assignNewExpireDatePhenomLicense(license.license_id, e.target.value)} />

      { this.renderUserCell(cellProps) }
    </tr>
  }

  render() {
    const { licenses=[], currentExpireDate, newExpireDate } = this.state; 
    const { selectedAccountName, handleCreatePhenomLicense, handleUpdatePhenomExpireDates } = this.props; 

    return <div>
      <Grid data={licenses}
            rowRender={this.renderRow}>
        <GridNoRecords>No data available</GridNoRecords>
        <GridColumn title="License" />
        <GridColumn title="Expiration Date" />
        <GridColumn title="User" />
      </Grid>
      {selectedAccountName && <Toolbar>
        <ToolbarItem>
          <Button onClick={() => handleCreatePhenomLicense()} icon="plus">Add Phenom License</Button>
        </ToolbarItem>
        <ToolbarItem>
          <DatePicker width="100%"
                      onChange={(e) => this.setState({currentExpireDate: e.target.value})} />
        </ToolbarItem>
        <ToolbarItem>to</ToolbarItem>
        <ToolbarItem>
          <DatePicker width="100%"
                      onChange={(e) => this.setState({newExpireDate: e.target.value})} />
        </ToolbarItem>
        <ToolbarItem>
          <Button onClick={() => handleUpdatePhenomExpireDates(currentExpireDate, newExpireDate)}>Update Expiration Dates</Button>
        </ToolbarItem>
      </Toolbar>}
    </div>
  }
}

export const CincLicenseExpireDateCell = ({ dateRaw, onChange }) => {
  const [currDate, setCurrDate] = useState(null);

  useEffect(() => {
    const date = dateRaw ? new Date(dateRaw) : "";
    setCurrDate(date);
  }, [dateRaw])

  let background;
  if (isDateExpired(dateRaw)) background = "var(--bs-danger)";
  if (willDateExpire(dateRaw)) background = "var(--bs-warning)";

  return <td style={{ background }}>
    <DatePicker value={currDate}
                width="100%"
                onChange={onChange} />
  </td>
}

export const CincLicenseTypeCell = ({ type, cellProps, onChange, disabled }) => {
  const [data, _] = useState(["PROD", "EVAL"]);
  const license = cellProps.dataItem;

  return <td>
    <PhenomSelect value={type === "PROD" ? data[0] : data[1]}
                  data={data}
                  disabled={disabled}
                  onChange={onChange} />
  </td>
}

export class SkaylCincLicenses extends React.Component {

  state = {
    licenses: [],
  }

  componentDidMount() {
    this.initLicenseList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.licenses !== this.props.licenses) {
      this.initLicenseList();
    }
  }

  initLicenseList = () => {
    const licenseList = Object.values(this.props.licenses);
    this.setState({ 
      licenses: cloneDeep(licenseList),
    });
  }

  isLicenseEdited = (license) => {
    const { cinc_licenses_change_type } = this.props;

    // check if license type is to be changed:
    let newType = cinc_licenses_change_type[license.license_id];

    return newType;
  }

  showLogs = (dataItem) => {
    $.ajax({
        url: "/index.php?r=/generate/get-generation-logs",
        method: "post",
        data:{license_id: dataItem.license_id}
    }).then(res => {
        res = JSON.parse(res)
        this.props.showLogs(res.logs)
    });
}

  renderLastUsageCell = (cellProps) => {
    const { last_usage_guid, last_usage_name, last_usage_time } = cellProps.dataItem;

    if (!last_usage_guid || !last_usage_name || !last_usage_time) {
      return <td />
    }
    
    return <td>
      <PhenomLink node={{ guid: last_usage_guid, xmiType: "ddm:MainProgram", name: last_usage_name }} />
    </td>
  }

  renderRow = (_, cellProps) => {
    const { handleChangeCincType, cinc_licenses_change_type } = this.props;
    const isEdited = this.isLicenseEdited(cellProps.dataItem);
    const license = cellProps.dataItem;
    const type = cinc_licenses_change_type[license.license_id] || license.type;

    return <tr style={{ background: isEdited ? "hsl(var(--skayl-sky-hs) 86%)" : null }}>
      <td>{ license.license_id }</td>
      <CincLicenseTypeCell type={type}
                           cellProps={cellProps} 
                           disabled={true}
                           onChange={(e) => handleChangeCincType(license.license_id, e.target.value)} />
      <td><Button onClick = {()=>this.showLogs(cellProps.dataItem)}>Logs</Button></td>
    </tr>
  }

  render() {
    const { licenses=[] } = this.state; 
    const { selectedAccountName, handleCreateCincLicense } = this.props;

    return <div>
      <Grid data={licenses}
            rowRender={this.renderRow}>
        <GridNoRecords>No data available</GridNoRecords>
        <GridColumn title="License" />
        <GridColumn title="Type" />
        <GridColumn title="Logs" />
      </Grid>
      { selectedAccountName && <Toolbar>
        <ToolbarItem>
          <Button icon="plus"
                  onClick={() => handleCreateCincLicense("PROD")}>Add CinC PROD License</Button>
        </ToolbarItem>
        <ToolbarItem>
          <Button icon="plus"
                  onClick={() => handleCreateCincLicense("EVAL")}
                  style={{paddingLeft: "5px"}}>Add CinC EVAL License</Button>
        </ToolbarItem>
      </Toolbar> }
    </div>
  }
}


export class SkaylCincVersions extends React.Component {

  state = {
    versions: [],
    associate_cinc_versions: [],
    available_cinc_versions: [],
  }

  componentDidMount() {
    this.initVersionList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.versions !== this.props.versions) {
      this.initVersionList();
    }
  }

  initVersionList = () => {
    const versionList = Object.values(this.props.versions).sort((v1, v2) => v1.name.localeCompare(v2.name));
    const available_cinc_versions = this.props.available_cinc_versions;
    this.setState({ 
      versions: cloneDeep(versionList),
      available_cinc_versions
    });
  }

  generateRequest = () => {
    const request = [];

    for (let ver of this.state.versions) {
      if (!this.isVersionEdited(ver)) {
        continue;
      }

      const req = { ...ver };

      // if "id" is undefined, the backend creates new cinc version
      if (isPhenomGuid(req.id)) {
        delete req["id"];
      }

      request.push(req);
    }

    return request;
  }

  handleChangeVersion = (id, key, value) => {
    if (!id || !key) {
      return;
    }

    this.setState((prevState) => {
      const versions = [...prevState.versions];
      const idx = versions.findIndex(v => v.id === id);

      if (idx > -1) {
        versions[idx] = { 
          ...versions[idx], 
          [key]: value 
        };
      }

      return { versions }
    })
  }

  isVersionEdited = (version) => {
    const original = this.props.versions[version?.id];

    if (!original) {
      return true;
    }

    // compare version's attributes with original
    let isAttrEdited = ["name", "config_name", "description"].some(key => version[key] !== original[key]);

    return isAttrEdited;
  }

  addNewVersion = () => {
    const { versions, associate_cinc_versions } = this.state;
    const { accountVersions } = this.props;
    
    // accountVersions is a prop that denotes that this component refers CinC versions that belong to a given account
    if (!accountVersions) {
      versions.push({
        id: createPhenomGuid(),
        name: "",
        description: "",
        config_name: "",
      })
      this.setState({ versions })
    }
    else {
      const maxId = versions.length > 0 ? Math.max(...versions.map(ver => ver.id)) : -1;

      versions.push({
        id: maxId + 1,
        name: "",
        description: "",
        config_name: "",
        new: true,
      })
  
      this.setState({ 
        versions,
      });
    }
    
  }

  updateNewVersion = (newVersion, versionRow) => {
    const { versions, associate_cinc_versions } = this.state;
    
    const associateVersionIndex = associate_cinc_versions.findIndex((version) => version.id === versionRow.id);
    if (associateVersionIndex !== -1) {
      associate_cinc_versions.splice(associateVersionIndex, 1);
    }

    associate_cinc_versions.push({ 
      id: versionRow.id,
      config_name: newVersion.config_name,
    })

    const versionIndex = versions.findIndex((version) => version.id === versionRow.id)
    versions.splice(versionIndex, 1, { 
      id: versionRow.id,
      name: newVersion.name,
      description: newVersion.description,
      config_name: newVersion.config_name,
      new: true,
    });

    this.setState({ 
      versions,
      associate_cinc_versions, 
    });
  }

  handleDeleteCincVersion = (version) => {
    if (!version) {
      return;
    }

    const versions = [...this.state.versions];
    const removeIdx = versions.findIndex(ver => ver.id === version.id);

    if (removeIdx < 0) {
      return;
    }

    // delete from version list
    versions.splice(removeIdx, 1);

    if (isPhenomGuid(version.id)) {
      return this.setState({ versions });
    }

    BasicConfirm.show(`You are about to delete '${version.name}'. Are you sure?`, () => {
      return $.ajax({
        method: "post",
        url: "/index.php?r=/skayl/delete-cinc-version",
        data: {
          versionId: version.id,
        }
      }).then(res => {
        const response = JSON.parse(res);
        receiveResponse(response);

        if (response.status === "success") {
          receiveLogs("Successfully deleted CinC Version");
          return this.setState({ versions });
        }
      })
    })
  }

  handleDisassociateCincVersion = (version) => {
    const { versions, associate_cinc_versions } = this.state;
    const { accountName, fetchAccountInfo } = this.props;
    
    if (!version) {
      return;
    }

    const removeIdx = versions.findIndex(ver => ver.id === version.id);

    if (removeIdx < 0) {
      return;
    }

    // delete from version list
    const deletedVersion = versions.splice(removeIdx, 1).at(0);

    if (deletedVersion.new) {
      const associateRemoveIdx = associate_cinc_versions.findIndex(ver => ver.id === version.id);
      associate_cinc_versions.splice(associateRemoveIdx, 1);

      return this.setState({ 
        versions,
        associate_cinc_versions,
      });
    }

    return $.ajax({
      method: "post",
      url: "/index.php?r=/skayl/disassociate-cinc-version",
      data: {
        config_name: version.config_name,
        account_name: accountName,
      }
    }).then(res => {
      const response = JSON.parse(res);

      receiveResponse(response);
      if (response.status === "success") {
        fetchAccountInfo(accountName);
        receiveLogs("Successfully removed CinC version from account.")
      }
    })
  }

  renderNameCell = (cellProps) => {
    const { accountVersions } = this.props;
    const { versions, available_cinc_versions } = this.state;
    const version = cellProps.dataItem;

    if (!accountVersions) {
      return <td>
        <PhenomInput value={version.name}
                     onChange={(e) => this.handleChangeVersion(version.id, "name", e.target.value)} />
      </td>
    }
    else if (!version.new) {
      return <td>{ version.name }</td>
    }
    else {
      return <td>
        <PhenomComboBox data={Object.values(available_cinc_versions).filter((ver) => !versions.find((v) => v.name === ver.name))}
                        placeholder="Select a CinC Version"
                        value={version.name}
                        onChange={(newVersion) => { this.updateNewVersion(newVersion, version); version.name = newVersion.name}} />
      </td>
    }
  }

  renderDescriptionCell = (cellProps) => {
    const { accountVersions } = this.props;
    const version = cellProps.dataItem;

    if (accountVersions) {
      return <td>{ version.description }</td>
    }

    return <td>
      <PhenomInput value={version.description}
                   onChange={(e) => this.handleChangeVersion(version.id, "description", e.target.value)} />
    </td>
  }

  renderConfigNameCell = (cellProps) => {
    const { accountVersions } = this.props;
    const version = cellProps.dataItem;

    if (accountVersions) {
      return <td>{ version.config_name }</td>
    }

    return <td>
      <PhenomInput value={version.config_name}
                   onChange={(e) => this.handleChangeVersion(version.id, "config_name", e.target.value)} />
    </td>
  }

  renderDeleteCell = (cellProps) => {
    const version = cellProps.dataItem;
    const { accountVersions } = this.props;
    let removeIconClass = "fas fa-unlink";
    let removeTitle = "Remove From Account";

    var onClick;
    if (!accountVersions) {
      onClick = () => this.handleDeleteCincVersion(version);
    }
    else {
      onClick = () => this.handleDisassociateCincVersion(version);
    }
    
    return <td>
      <Button iconClass={removeIconClass}
              title={removeTitle}
              disabled={!!version.num_account_ids}
              onClick={onClick} />
    </td>
  }

  renderRow = (_, cellProps) => {
    const isEdited = this.isVersionEdited(cellProps.dataItem);

    return <tr style={{ background: isEdited ? "hsl(var(--skayl-sky-hs) 86%)" : null }}>
      { this.renderNameCell(cellProps) }
      { this.renderConfigNameCell(cellProps) }
      { this.renderDescriptionCell(cellProps) }
      { this.renderDeleteCell(cellProps) }
    </tr>
  }

  render() {
    const { versions=[] } = this.state; 
    const { accountVersions, accountName } = this.props;

    return <div>
      <Grid data={versions}
            rowRender={this.renderRow}>
        <GridNoRecords>No data available</GridNoRecords>
        <GridColumn title="Name" field="name" />
        <GridColumn title="Config Name" field="config_name" />
        <GridColumn title="Description" field="description" />
        <GridColumn title="Remove" width="80px" />
      </Grid>
      { accountName && accountVersions && <Toolbar>
          <ToolbarItem>
            <Button icon="plus"
                    onClick={this.addNewVersion}>Add CinC Version</Button>
          </ToolbarItem>
      </Toolbar> }
    </div>
  }
}