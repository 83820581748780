// PROPS: pre-selected conversion guid - conGuid (string)
//        conversion selection function - setCon (f)

import React from "react";
import { getNodesOfType } from "../../requests/sml-requests";
import PhenomId from "../../requests/phenom-id";
import {PhenomLink} from "../widget/PhenomLink";
import {LineLabel, DeprecatedIcon } from "./stateless";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";



export class ConversionsDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            conversions: [],
        };
    }

    componentDidMount() {
      this.getConversions();
    }

    componentDidUpdate(prevProps,prevState) {
      if (prevProps.refGuid != this.props.refGuid) {
        this.getConversions();
      }
    }

    getConversions = () => {
      getNodesOfType(this.props.xmiType, {
        coreAddenda: ["source", "target"]
      }).then((list) => {
          const resp = JSON.parse(list);
          const nodes = resp.nodes.filter(node => this.props.refGuid == node.source.guid || this.props.refGuid == node.target.guid);
          this.setState({conversions: nodes});
      });
    }



    render() {
        const phenomId = new PhenomId("conversions-display",this.props.idCtx);
        return (
            <div id={phenomId.gen("","wrapper")} style={{padding: "15px 0px 15px 0px"}}>
              <LineLabel text="Conversions" style={{marginBottom: 15}} idCtx={phenomId.gen("conversions","")}/>
              <Grid
                  id={phenomId.gen("conversions","grid")}
                  data={this.state.conversions || []}
                  className="editorTable"
                  style={{marginTop: 10}}
                  rowRender={(_, props) => {
                      var con = props.dataItem;
                      var source = con.source || {};
                      var target = con.target || {};
                      var guid = con.guid;


                      return (
                      <tr id={phenomId.genPageId()}
                          style={{background: "#f2f2f2", fontStyle:"italic"}}>
                          <td title={con.name}
                              id={phenomId.gen("init","name-label")}
                              style={{overflowX: "hidden", position:"relative"}}>
                                  <>
                                    <DeprecatedIcon deprecated={con.deprecated} />
                                    <PhenomLink
                                            node={con}
                                            id={phenomId.gen("init","view-link")}/>
                                  </>
                          </td>

                          <td title={source.name}
                              id={phenomId.gen("source","name-label")}
                              style={{overflowX: "hidden", position:"relative"}}>
                                  <>
                                    <DeprecatedIcon deprecated={source.deprecated} />
                                    {source.guid == this.props.refGuid ?
                                      <div>{source.name}</div>
                                      :
                                      <PhenomLink
                                            node={source}
                                            id={phenomId.gen("init","view-link")}/>}
                                  </>
                          </td>

                          <td title={target.name}
                              id={phenomId.gen("source","name-label")}
                              style={{overflowX: "hidden", position:"relative"}}>
                                  <>
                                    <DeprecatedIcon deprecated={target.deprecated} />
                                    {target.guid == this.props.refGuid ?
                                      <div>{target.name}</div>
                                      :
                                      <PhenomLink
                                            node={target}
                                            id={phenomId.gen("init","view-link")}/>}
                                  </>
                          </td>
                      </tr>)
                  }}
                  resizable>
                  <GridNoRecords>
                      No Data Is Available For This Table
                  </GridNoRecords>
                  <Column title="NAME" field="name" />
                  <Column title="SOURCE" field="source" />
                  <Column title="TARGET" field="target" />
              </Grid>
            </div>
        );
    }
}
