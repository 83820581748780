import React from 'react';
import ReactTooltip from "react-tooltip";
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { PortWidget } from "@projectstorm/react-diagrams-core";

import { BaseNodeModel, BaseNodeWidget } from '../../base/BaseNode';
import { StormAnchor } from "../../base/BasePort";
import { ImPortModel } from "../misc/ImPort";
import StormData from '../../base/StormData';
import { isStormData } from '../../util';



// ------------------------------------------------------------
// Factory - React Storm
// ------------------------------------------------------------
export class ImUopInstanceNodeFactory extends AbstractReactFactory {
  constructor() {
    super("uopi-node");
  }

  generateModel(event) {
    return new ImUopInstanceNodeModel();
  }

  generateReactWidget(event) {
    return <ImUopInstanceNodeWidget engine={this.engine} node={event.model}/>;
  }
}


// ------------------------------------------------------------
// Node Model
// ------------------------------------------------------------
export class ImUopInstanceNodeModel extends BaseNodeModel {
  constructor(options={}, $app, settings={}, ) {
    super(
      {...options, type: "uopi-node"},
      $app,
      settings,
    )

    this.settings = {
      ...this.settings,
      reverseAlignment: true,
      ...settings,
    };

    this.init();
  }


  // @override
  init() {
    // create a general purpose port (used for drag and drop)
    // Parent uses "BasePortModel"
    const port = new ImPortModel({
      name: "draw-tool",
      in: false,
      attrData: new StormData(),
    });
    this.addPort(port);
    port.reportPosition();
  }



  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------
  /**
   * Find an EndPoint (child node) with a matching Message Port
   *
   * @param {string} msgPortGuid
   * @param {boolean} isInEndPoint Search through Inputs if true, otherwise search through Outputs
   * @returns Endpoint node (typed as StormData) if found, otherwise undefined.
   */
  findChild(msgPortGuid, isInEndPoint) {
    const xmiType = isInEndPoint ? "im:UoPInputEndPoint" : "im:UoPOutputEndPoint";
    return this.getChildrenData().find(childData => childData.getXmiType() === xmiType && childData.getAttr("connection") === msgPortGuid);
  }

  /**
   * Check if Node can accept in incoming connection
   *   -> The dragging node cannot be this node
   *   -> This node must have an available Inport
   *
   * @returns True if node can accept an incoming connection, False otherwise
   */
  isValidDropTarget() {
    const dragNodeModel = this.$app.getDraggingFor();

    // dragNodeModel cannot be the current nodeModel
    if (!dragNodeModel || dragNodeModel === this) {
      return false;
    }

    // special case
    if (["im:ComposedInPort", "im:ComposedOutPort"].includes(dragNodeModel.getXmiType())) {
      return false;
    }

    // a valid inPort must exist - have zero connections
    return this.hasAvailableInputEndPoints();
  }

  hasAvailableOutputEndPoints() {
    const msgPorts = this.getAvailableMessagePorts(false);
    return msgPorts.length > 0;
  }


  hasAvailableInputEndPoints() {
    const msgPortGuids = new Set(this.getAvailableMessagePorts(true).map(msgPortData => msgPortData.getGuid()));
    this.getUnavailableInputEndPoints().forEach(endpointData => {
      const msgPortGuid = endpointData.getAttr("connection");
      msgPortGuids.has(msgPortGuid) && msgPortGuids.delete(msgPortGuid);
    })
    return msgPortGuids.size > 0;
  }

  /**
   * Get a list of available InputEndPoints
   *    Looking For:
   *      Child node exist but the port does not exist yet (meaning it has zero links)
   *      Child node exist and the existing port has zero links
   *
   * @returns array of InputEndPoints (children nodes)
   */
  getAvailableInputEndPoints() {
    return this.getChildrenData()
               .filter(childData => {
                  if (childData.getXmiType() !== "im:UoPInputEndPoint") return false;
                  const port = this.getPort(childData.getGuid());
                  return !port || port.validLinkCount();
              })
  }

  getUnavailableInputEndPoints() {
    return this.getChildrenData()
               .filter(childData => {
                  if (childData.getXmiType() !== "im:UoPInputEndPoint") return false;
                  const port = this.getPort(childData.getGuid());
                  return port && !port.validLinkCount();
              })
  }

  /**
   * Gathers a list of Views when selecting a Data Type for Block Nodes/Node Connections,
   *
   * @param {boolean} isInEndPoint If true, filter children by Inputs only. Otherwise filter by Outputs
   * @returns a list of views
   */
  getAvailableDataTypes(isInEndPoint=true) {
    const viewDatas = new Set();
    const xmiType = isInEndPoint ? "im:UoPInputEndPoint" : "im:UoPOutputEndPoint";

    this.getChildrenData().forEach(childData => {
      if (childData.getXmiType() !== xmiType) return;

      const msgPortData = this.$app.getDiagramStormData(childData.getAttr("connection"));
      if (!isStormData(msgPortData)) return;

      const view = this.$app.getViewList.find(view => view.guid === msgPortData.getAttr("messageType"));
      if (view) viewDatas.add(view);
    })

    return [...viewDatas];
  }

  /**
   * Gathers a list of Message Ports - when establishing a connection from a uopi
   *
   * @param {boolean} isInboundMessage
   * @returns a list of message ports
   */
  getAvailableMessagePorts(isInboundMessage=true) {
    const portableComponent = this.$app.getDiagramStormData(this.getStormData().getAttr("realizes"));
    if (!isStormData(portableComponent)) {
      return [];
    }

    const pcChildren = portableComponent.getChildren().map(childGuid => this.$app.getDiagramStormData(childGuid));
    return pcChildren.filter(msgPortData => isStormData(msgPortData) && msgPortData.getXmiType() === "uop:MessagePort" &&
                                            ((isInboundMessage && msgPortData.getAttr("messageExchangeType") === "InboundMessage") ||
                                             (!isInboundMessage && msgPortData.getAttr("messageExchangeType") === "OutboundMessage") ||
                                             msgPortData.getAttr("messagingPattern") === "Client" ||
                                             msgPortData.getAttr("messagingPattern") === "Server"));
  }

  /**
   * Gathers a list of Message Ports - when establishing a connection to a uopi
   *   Limits the list based on available Input End Points because an Input cannot accept multiple connections
   *
   * @returns a list of message ports
   */
  getAvailableMessagePortsBasedOnInputEndPoints() {
    const ignoreInputs = new Set(this.getUnavailableInputEndPoints().map(childData => childData.getAttr("connection")));
    return this.getAvailableMessagePorts(true).filter(msgPortData => !ignoreInputs.has(msgPortData.getGuid()));
  }


  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------
  setInPort (attrData, options={}) {
    try {
      if (!isStormData(attrData)) throw "invalid data, failed to create in port";
      const portName = options.portName || attrData.getGuid();
      const port = new ImPortModel({
        name: portName,
        in: true,
        attrData,
      })

      this.addPort(port);
      port.reportPosition();
      return port;

    } catch(err) {
      console.error(err);
    }
  }

  setOutPort (attrData, options={}) {
    try {
      if (!isStormData(attrData)) throw "invalid data, failed to create in port";
      const portName = options.portName || attrData.getGuid();
      const port = new ImPortModel({
        name: portName,
        in: false,
        attrData,
      })

      this.addPort(port);
      port.reportPosition();
      return port;

    } catch(err) {
      console.error(err);
    }
  }



  // ------------------------------------------------------------
  // NODE
  // ------------------------------------------------------------
  createImOutPort(attrData) {
    const port = this.setOutPort(attrData);
          port.setDefaultPosition();
    return port;
  }

  createImInPort(attrData) {
    const port = this.setInPort(attrData);
          port.setDefaultPosition();
    return port;
  }
}





// ------------------------------------------------------------
// Widget
// ------------------------------------------------------------
class ImUopInstanceNodeWidget extends BaseNodeWidget {
  constructor(props) {
    super(props);

    this.nodeModel.registerListener({
      selectionChanged: (e) => {
        if (!e.isSelected) {
          this.state.isEditing && this.setState({ isEditing: !this.state.isEditing });
        }
      }
    })
  }

  componentDidUpdate(_, prevState) {
    if (prevState.isEditing !== this.state.isEditing) {
      ReactTooltip.hide();
    }

    if (prevState.isEditing !== this.state.isEditing) {
      if (this.state.isEditing) {
        this.nodeModel.setSelected(true);
      } else {
        this.nodeModel.setLocked(false);
      }
    }
  }

  // ------------------------------------------------------------
  // Drag and Drop
  // ------------------------------------------------------------
  dragStartCreateConnection = (e) => {
    this.repositionDrawTool(e);
    this.$app.setDraggingFor(this.nodeModel);

    const handleMouseUp = (e) => {
      const element = this.$app.engine.getMouseElement(e);
      if (element instanceof BaseNodeModel && element.isValidDropTarget()) {
        this.$app.establishConnection(this.nodeModel, element, e);
      }

      this.$app.setDraggingFor(null);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    window.addEventListener("mouseup", handleMouseUp);
  }


  // ------------------------------------------------------------
  // Render Methods
  // ------------------------------------------------------------
  renderResizeBars = () => {
    return <div>
              <div className="resize-top" onMouseDown={this.startResize} />
              <div className="resize-right" onMouseDown={this.startResize} />
              <div className="resize-bottom" onMouseDown={this.startResize} />
              <div className="resize-left" onMouseDown={this.startResize} />
              <div className="resize-top-left" onMouseDown={this.startResize} />
              <div className="resize-top-right" onMouseDown={this.startResize} />
              <div className="resize-bottom-left" onMouseDown={this.startResize} />
              <div className="resize-bottom-right" onMouseDown={this.startResize} />
           </div>
  }

  renderTopDrawIcons = () => {
    if (!this.nodeModel.isSelected() || this.state.isEditing || !this.nodeModel.hasAvailableOutputEndPoints()) return null;

    return <div className="top-draw-icons">
            <span className="k-icon k-i-sort-asc-sm"
                  data-port-name="draw-tool"
                  data-tip="Drag to create Connection"
                  data-for="diagramTip"
                  id={this.phenomId.genPageId("draw-tool")}
                  onDragStart={(e) => e.preventDefault()}   // bug fix - if you highlight other dom elements before clicking this element, it causes unpredictable actions
                  onMouseDown={this.dragStartCreateConnection} />
          </div>
  }

  renderHeader = () => {
    const { isEditing } = this.state;
    const stormData = this.nodeModel.getStormData();
    const isUncommitted = !isEditing && this.$app.isShowUncommitted() && stormData.isEdited();

    return <div id={this.phenomId.genPageId("header")}
                className="node-header"
                style={{ color: isUncommitted ? "var(--skayl-orange)" : null}}
                data-tip={stormData.getDescription()}
                data-for="diagramTip">
                  {isEditing
                    ? <input id={this.phenomId.genPageId("name-input")}
                             className="node-input node-input-head"
                             type="text"
                             value={stormData.getName()}
                             placeholder="Name"
                             autoFocus={true}
                             autoComplete="off"
                             ref={el => this.nameRef = el}
                             onChange={((e) => this.nodeModel.updateProp("name", e.target.value) )}
                             onFocus={() => this.nodeModel.setLocked(true)} />
                    : <span>{stormData.getName()}</span> }
    </div>
  }

  render() {
    const { isEditing } = this.state;
    const isSelected = this.nodeModel.isSelected();
    const isTargetable = this.nodeModel.isValidDropTarget();
    const classes = ["node-container-center", "node-color-border", "block-container"];
    if (isTargetable) classes.push("selectable-node");

    return (
      <div id={this.phenomId.genPageId("uop-container")}
           ref={el => this.widgetRef = el}
           onDoubleClick={() => this.setState({ isEditing: !isEditing })}>

        { this.renderHalo() }
        { this.renderTopDrawIcons() }
        { this.renderResizeBars() }

        <div className={classes.join(" ")}
             style={{ "--nodeColor": this.nodeModel.getNodeColor(),
                      width: this.nodeModel.width || null,
                      height: this.nodeModel.height || null }}>

          { this.renderHeader() }
        </div>

          {/* ANCHOR POINTS AND PORTS */}
          <div className="anchor-point-container">
              {Object.values(this.nodeModel.getPorts()).map(port => {
                if (port.getName() === 'draw-tool') {
                  return <PortWidget key={port.getOptions().id}
                                     port={port}
                                     engine={this.props.engine}
                                     style={{ position: "absolute",
                                              left: "50%",
                                              top: "50%", }} />
                }

                return <UoPiStormAnchor key={port.getOptions().id}
                                        port={port}
                                        $app={this.$app}
                                        engine={this.props.engine}
                                        visible={isSelected || isTargetable} />
              })}
          </div>
      </div>
    );
  }
}





// ------------------------------------------------------------
// Custom Storm Anchor
// ------------------------------------------------------------
export class UoPiStormAnchor extends StormAnchor {
  getLabelPosition = () => {
    const { top, left } = this.props.port.getWidgetPosition();
    const buffer = 3;

    // float left
    if (left === 0) {
      return {
        top: `-${buffer}px`,
        right: `calc(100% + ${buffer}px)`
      }

    // float right
    } else if (left === 1) {
      return {
        top: `-${buffer}px`,
        left: `${this.getAnchorSize() + buffer}px`
      }

    // float top
    } else if (top === 0) {
      return {
        writingMode: "vertical-lr",
        left: `-${buffer}px`,
        bottom: `calc(100% + ${buffer}px)`,
      }

    // float bottom
    } else {
      return {
        writingMode: "vertical-lr",
        top: `${this.getAnchorSize() + buffer}px`,
        left: `-${buffer}px`
      }
    }
  }



  render() {
    const { $app, port, engine, visible } = this.props;
    const isSelected = port.isLinkSelected();

    const endpoint = $app.getDiagramStormData(port.getAttrGuid());
    const msgPort = endpoint && $app.getDiagramStormData(endpoint.getAttr("connection"));
    const name = msgPort?.getName() || "";

    return <span className="anchor-point"
                 onMouseUp={this.props.onMouseUp}
                 onMouseDown={this.handleDragAnchor}
                 ref={el => this.portRef = el}
                 style={{ "--anchor-size": this.getAnchorSize() + "px",
                          top: this.getTopPosition(),
                          left: this.getLeftPosition(), }}>
              <PortWidget key={port.options.id}
                          engine={engine}
                          port={port} />

              <span className="anchor-point-bullet"
                    style={{ visibility: visible || isSelected ? "visible" : "hidden",
                             borderRadius: "30px",
                             background: isSelected ? "rgb(0,192,255)": null, }} />

              <span className="anchor-point-label"
                    style={this.getLabelPosition()}>{ name }</span>
            </span>
  }
}
