import ImportCSV from "./import/import_csv";
import AddPlaceholders from "./import/add_placeholders";
import React from "react";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import Merge from "../manage/branch/merge";
import $ from "jquery";
import { connect } from "react-redux";
import NavTree from "../tree/NavTree";

class Import extends React.Component {
    componentDidMount() {
        NavTree.collapseNavTree(true);
        NavTree.assignPresetPageFilters("data");
    }

    componentWillUnmount() {
      NavTree.clearPageFilters();
    }

    render() {
        return (
            <div className="phenom-content-wrapper">
                {this.props.canEdit && !this.props.expired &&
                  <nav className="sub-menu-actions">
                    <NavLink
                        title="Import CSV"
                        to="/edit/import/import_csv"
                        activeClassName="active">
                        CSV
                    </NavLink>
                    <NavLink
                        title="Add Placeholders"
                        to="/edit/import/add_placeholders"
                        activeClassName="active">
                        PLACEHOLDERS
                    </NavLink>
                    <NavLink
                        title="Merge External Model"
                        to="/edit/import/merge"
                        activeClassName="active">
                        MERGE EXTERNAL MODEL
                    </NavLink>
                  </nav> }
                <Switch>
                  <Route path="/edit/import/import_csv" component={ImportCSV}/>
                  <Route path="/edit/import/add_placeholders" component={AddPlaceholders}/>
                  <Route path="/edit/import/merge" component={Merge} />
                  <Redirect to="/edit/import/import_csv" />
                </Switch>
                <div id="success-status"/>
            </div>
        );
    }
}


const msp = (state) => ({
  canEdit: state.user.canEdit,
  expired: state.user.expired,
})

export default connect(msp)(Import);


// TODO: DELETE? NOT USED?
export function fetchSubModelTree(nested = true) {
    return $.ajax({
        url: "/index.php?r=/sub-model/users-index-tree",
        method: "get"
    }).then((res) => {
        function traverse(node, head, parent, treeBranch, family) {
            let thisFamily;
            if (parent) {
                thisFamily = family;
            } else {
                thisFamily = [];
            }

            if (node.children) {
                Object.entries(node.children).forEach((e) => {
                    let newBranchId;
                    if (nested) {
                        newBranchId = treeBranch.push({
                            text: e[0],
                            children: [],
                            type: "model"
                        });
                    }
                    traverse(e[1], e[0], node.name, nested ? treeBranch[newBranchId - 1].children : treeBranch, thisFamily);
                    thisFamily.push(e[0]);
                });
            } else {
                Object.entries(node).forEach((e) => {
                    const leaf = e[1];
                    const newBranchId = treeBranch.push({
                        text: leaf.name,
                        children: [],
                        type: "submodel",
                        additionalData: {
                            permissions: leaf.permissions,
                            dependencies: JSON.parse(leaf.dependencies),
                            id: leaf.id,
                            family: thisFamily,
                            created: leaf.created,
                            description: leaf.description
                        }
                    });

                    traverse(leaf, null, head, nested ? treeBranch[newBranchId - 1].children : treeBranch, thisFamily);
                    thisFamily.push(leaf.name);
                });

                const tailLeaf = treeBranch.shift();
                treeBranch.push(tailLeaf);
            }
        }

        const tree = [];
        traverse({children: JSON.parse(res)}, null, null, tree, null);
        return tree;
    });
}
