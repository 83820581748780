import React from 'react';
import PhenomId from '../../../../requests/phenom-id';
import { ModelLeaf, ProjectLeaf } from '../../../tree/leaf/ModelLeaf';
import { PhenomCollapsable } from '../../../util/stateless';
import { ModelDetail } from './ModelDetail';
import { ProjectCopyAddOn, ProjectDetail, ProjectDetailModelFamily, ProjectDetailModelSelect } from './ProjectDetail';
import $ from 'jquery';
import { BasicAlert } from '../../../dialog/BasicAlert';
import { receiveErrors, receiveLogs, receiveResponse, receiveWarnings, updateBranchName } from '../../../../requests/actionCreators';
import { isPhenomGuid } from '../../../util/util';
import PermissionDetail from './PermissionDetail';
import ProjectFilter from './ProjectFilter';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import NavTree from '../../../tree/NavTree';
import ReactTooltip from 'react-tooltip';
import { MANAGE_OPTIONS } from '../model_manage';
import { _ajax } from '../../../../requests/sml-requests';





export class ManageDetail extends React.Component {

  detailRef = React.createRef();
  familyRef = React.createRef();
  selectNewModelRef = React.createRef();
  permissionRef = React.createRef();
  projectFilterRef = React.createRef();


  state = {
    fixupModels: [],
  }


  save = async () => {
    const { leaf, createPublishModel, createPublishProject, createFilteredProject, manage_option } = this.props;
    const isNew = isPhenomGuid( leaf.getId() );

    if (isNew) {
      if (leaf instanceof ProjectLeaf) {
        await this.saveNewProject();

      } else if (leaf instanceof ModelLeaf) {
        await this.saveNewModel();
      }

    } else {
      if (leaf instanceof ProjectLeaf) {

        if (manage_option === MANAGE_OPTIONS.newCopyProject) {
          await this.saveCopyProject();
        } else if (createFilteredProject) {
          await this.saveFilteredProject();
        } else if (createPublishProject) {
          await this.publishNewProject();
        } else {
          await this.saveExistingProject();
        }

      } else if (leaf instanceof ModelLeaf) {
        if (createPublishModel) {
          await this.publishNewModel();
        } else {
          await this.saveExistingModel();
        }
      }
    }
  }

  /**
   * New Model
   * 
   */
  saveNewModel = async () => {
    const { leaf, createPublishModel, createFilteredProject } = this.props;
    const data = this.detailRef.current.serialize();
    const requestForm = new FormData();

    requestForm.append("name", data.name);
    requestForm.append("description", data.description);
    requestForm.append("file", data.files[0]?.getRawFile());
    requestForm.append("checks", data.checks);
    requestForm.append("referencedSubModel", null);        // this is needed for some reason
    requestForm.append("exclusion", null);                 // dunno what this is
    requestForm.append("dependencies", null);              // dunno what this is
    requestForm.append("retained_types", data.retained_types);

    BasicAlert.show(`Saving '${data.name}'`, "Processing request", false);
    
    const res = await $.ajax({
      url: "/index.php?r=/sub-model/create-from-import",
      method: "POST",
      data: requestForm,
      processData: false,     // needed because of the Raw file
      contentType: false,     // needed because of the Raw file
      error: (jqXHR, textStatus, thrownError) => {
        BasicAlert.hide();
        // case: ERR_EMPTY_RESPONSE
        if (jqXHR.status === 0) {
          receiveErrors("Your upload is taking longer than expected. You can check back later to see if it has completed.");
        }
      }
    })

    BasicAlert.hide();
    const response = JSON.parse(res);
    let selectModelId;

    if (Array.isArray(response.subModelIds) && response.subModelIds.length) {
      // the success message should've been generated by the backend/Yii
      if (response.subModelIds.length === 1) {
        response.logs = [`Model '${data.name}' was successfully created.`];
      } else {
        response.logs = ["Models were successfully created, they are now present in your Model Tree."];
      }
      
      selectModelId = response.subModelIds[0];
    }
    
    receiveResponse(response);
    this.props.refreshTrees(selectModelId);

    // genSpecIssues can still be triggered - the fixup wizard will not work as intended because the backend creates multiple subModels    
    // if (response.fixupData?.genSpecIssues?.length) {
    //     this.setState({ 
    //       fixupModels: [{
    //         modelId: response.subModelIds,
    //         modelName: response.subModelName,
    //         genSpecIssues: response.fixupData.genSpecIssues,
    //         checked: true,
    //       }]
    //     })
    // }
  }



  /**
   * New Project
   * 
   */
  saveNewProject = async () => {
    const data = this.detailRef.current.serialize();
    const { selectedSubmodelIds, blankModels, newModels, newModelFiles, } = this.selectNewModelRef.current.serialize();

    // validating it here or else it'll require a rework in Yii
    if (!this.validateNewProjectData()) return;

    const requestForm = new FormData();
    requestForm.append("name", data.name);
    requestForm.append("description", data.description);
    requestForm.append("subModelIds", JSON.stringify(selectedSubmodelIds || []));
    requestForm.append("userSubModels", JSON.stringify(blankModels || []));

    // Note: we're attaching multiple files to the request, but they can't be nested as a single object for Form Data
    //        -- the file attributes will be broken up and then reconstructing in Yii
    newModels.length && requestForm.append("newModels", JSON.stringify(newModels));
    newModelFiles.length && newModelFiles.forEach(file => requestForm.append("file[]", file));

    BasicAlert.show(`Saving '${data.name}'`, "Processing request", false);

    const res = await $.ajax({
      url: "/index.php?r=/referencing-model/create-project-and-models",
      method: "POST",
      data: requestForm,
      processData: false,     // needed because of the Raw file
      contentType: false,     // needed because of the Raw file
      error: (jqXHR, textStatus, thrownError) => {
        BasicAlert.hide();
        // case: ERR_EMPTY_RESPONSE
        if (jqXHR.status === 0) {
          receiveErrors("Your upload is taking longer than expected. You can check back later to see if it has completed.");
        }
      }
    })

    BasicAlert.hide();
    const response = JSON.parse(res);
    let fixups = [];
    let selectProjectId;
    
    for (let item of response) {

      // Created new SubModel
      if (item.subModelId) {

        if (item.fixupData?.genSpecIssues?.length) {
          fixups.push({
            modelId: item.subModelId,
            modelName: item.subModelName,
            genSpecIssues: item.fixupData.genSpecIssues,
            checked: true,
          })
        }

        // Created new Referencing Model
        if (item.subModelName) {
          receiveLogs(`Model '${item.subModelName}' was successfully created`);
        }
      }

      // Created new Project
      if (item.status === "success") {
        selectProjectId = item.data?.model_id;
      }

      // Contains error/warning messages or contains project's success message
      receiveResponse(item);
    }

    // Show Fixup wizard popup
    if (fixups.length) {
      this.setState({ fixupModels: fixups });
    }

    // Project was successfully created
    if (selectProjectId) {
      this.props.refreshTrees(null, selectProjectId);

    } else {
      // Models are created first and then it attempts to create the Project
      // if the Project creation errors out, the Models may or may not exist in the database
      //   -- UI is refreshed (note the await) and then attempt to reselect the model in subtree
      if (selectProjectId) {
        await this.props.refreshTrees(null, selectProjectId);
      }

      const subModels = response.filter(item => item.subModelId);
      
      this.selectNewModelRef.current && subModels.forEach(item => this.selectNewModelRef.current.selectModelById(item.subModelId));
      this.selectNewModelRef.current && subModels.forEach(item => this.selectNewModelRef.current.removeNewModelByName(item.originalName));
      
      if (subModels.length) {
        receiveErrors("Something went wrong with the Project creation, please try again. The successfully created models were auto selected.");
      }
    }
  }

  /**
   * Publish New Project
   * 
   */
     publishNewProject = async () => {
      const { leaf } = this.props;
      const data = this.detailRef.current.serialize();
  
      BasicAlert.show(`Saving '${data.name}'`, "Processing request", false);
      const res = await $.ajax({
        url: "/index.php?r=/referencing-model/publish",
        method: "POST",
        data: {
          name: data.name,
          description: data.description,
          checks: data.checks,
          copyPerms: data.copyPerms,
          project_id: leaf.getId(),
        },
      });
  
      BasicAlert.hide();
      const response = JSON.parse(res);
      let selectProjectId;
  
      if (Number.isInteger(response)) {
        receiveLogs(`Successfully created ${data.name}`);
        selectProjectId = response;
  
      } else {
        receiveResponse(response);
      }
  
      this.props.refreshTrees(null, selectProjectId);
    }

  /**
   * Publish New Model
   * 
   */
  publishNewModel = async () => {
    const { leaf } = this.props;
    const data = this.detailRef.current.serializeImport();

    BasicAlert.show(`Saving '${data.name}'`, "Processing request", false);
    const res = await $.ajax({
      url: "/index.php?r=/sub-model/publish",
      method: "POST",
      data: {
        name: data.name,
        description: data.description,
        checks: data.checks,
        referencedSubModel: leaf.getId(),
      },
    });

    BasicAlert.hide();
    const response = JSON.parse(res);
    let selectModelId;

    if (Number.isInteger(response)) {
      receiveLogs(`Successfully created ${data.name}`);
      selectModelId = response;

    } else {
      receiveResponse(response);
    }

    this.props.refreshTrees(selectModelId);
  }

  /**
   * Existing Model
   * 
   */
  saveExistingModel = async () => {
    const { leaf } = this.props;

    // 1) Update Model Name/Description
    // ----------------------------------------------
    if (this.detailRef.current?.isEdited()) {
      BasicAlert.show("Saving - Model info", "Processing request", false);
      const detail = this.detailRef.current.serializeImport();
      const res = await this.pushDetails(detail);
      
      if (res === "true") {
        leaf.updateData(detail);
        receiveLogs(`Model '${detail.name}' successfully saved`);
      } else {
        BasicAlert.hide();
        return receiveErrors(res);
      }
    }

    // 2) Update Permissions
    // ----------------------------------------------
    if (this.permissionRef.current?.isEdited()) {
      const permissions = this.permissionRef.current.serialize();
      const res = await this.updatePermissions(permissions);
      const response = JSON.parse(res);
      receiveResponse(response);

      if (response.status === 'success') {
        const newPerms = {}
        permissions.forEach(perm => newPerms[perm.username] = perm);
        leaf.setPermissions(newPerms);
      } else {
        return BasicAlert.hide();
      }
    }

    await this.props.refreshTrees(leaf.getId());
    BasicAlert.hide();
  }

  /**
   * Existing Project
   * 
   */
  saveExistingProject = async () => {
    const { leaf, modelIndex, activeProjectId } = this.props;

    // 1) Update Project Name/Description
    // ----------------------------------------------
    if (this.detailRef.current?.isEdited()) {
      BasicAlert.show("Saving - Project info", "Processing request", false);
      const detail = this.detailRef.current.serialize();
      const res = await this.pushDetails(detail);

      if (res === "true") {
        leaf.updateData(detail);
        activeProjectId === leaf.getId() && updateBranchName(detail.name);
        receiveLogs(`Project '${detail.name}' successfully saved`);
      } else {
        BasicAlert.hide();
        return receiveErrors(res);
      }
    }

    // 2) Update Permissions
    // ----------------------------------------------
    if (this.permissionRef.current?.isEdited()) {
      const permissions = this.permissionRef.current.serialize();
      const res = await this.updatePermissions(permissions);
      const response = JSON.parse(res);
      receiveResponse(response);

      if (response.status === 'success') {
        const newPerms = {}
        permissions.forEach(perm => newPerms[perm.username] = perm);
        leaf.setPermissions(newPerms);
      } else {
        return BasicAlert.hide();
      }
    }

    // 3) Update Submodels or add new model
    // ----------------------------------------------
    const familyData = this.familyRef.current?.serialize();

    // 3A) Redeclare submodels - change versions
    // ----------------------------------------------
    if (familyData?.redeclare) {
      BasicAlert.show("Saving - Changing model version", "Processing request", false);

      const res = await $.ajax({
        url: "/index.php?r=/referencing-model/re-declare-sub-models",
        method: "post",
        data: { newSubModels: familyData.redeclare }
      });

      const response = JSON.parse(res);
      receiveResponse(response);

      if (response?.status === "success") {
        leaf.setModelLeaves( familyData.redeclare.map(id => modelIndex[id]) );
      }
    }

    // 3B) Add New Blank Model
    // ----------------------------------------------
    if (familyData?.blankModelName) {
      BasicAlert.show("Saving - Redeclare model contents", "Processing request", false);

      const res = await $.ajax({
        url: "/index.php?r=/referencing-model/add-blank",
        method: "post",
        data: { branchName: familyData.blankModelName }
      });

      if (res === "#true") {
        receiveLogs("Successfully re-declared model contents");
        NavTree.reset();
      } else {
        receiveResponse(res);
      }
    }


    // 3C) Add Additional Model
    // ----------------------------------------------
    if (familyData?.additionId) {
      BasicAlert.show("Saving - Redeclare model contents", "Processing request", false);

      const res = await $.ajax({
        url: "/index.php?r=/referencing-model/add-inheriting",
        method: "post",
        data: { additionId: parseInt(familyData.additionId) }
      });

      const response = JSON.parse(res);
      receiveResponse(response);

      if (response?.status === "success") {
        NavTree.reset();
      }
    }

    await this.props.refreshTrees(null, leaf.getId());
    BasicAlert.hide();
  }



  saveCopyProject = async () => {
    const { leaf, manage_option } = this.props;

    if (manage_option !== MANAGE_OPTIONS.newCopyProject) {
      return;
    }

    // 1) Update Project Name/Description
    // ----------------------------------------------
    BasicAlert.show("Saving - Project info", "Processing request", false);
    const detail = this.detailRef.current.serialize();

    try {
      const response = await _ajax({
        url: "/index.php?r=/referencing-model/copy-project",
        method: "post",
        data: {
          projectId: leaf.getId(),
          ...detail,
        }
      })

      if (response?.status === "success") {
        receiveLogs(`Successfully copied project ${leaf.getName()}`);
        const new_project_id = response.data.project_id;
        this.props.refreshTrees(null, new_project_id);
      }

    } catch (error) {
      console.error(error);
    }




    
    


  }

  pushDetails = async (detail={}) => {
    const { leaf, activeProjectId } = this.props;
    const url = leaf instanceof ProjectLeaf ? "/index.php?r=/referencing-model/rename-project" : "/index.php?r=/sub-model/rename-branch-ref";

    return await $.ajax({
      url,
      method: "POST",
      data: {
        pModelId: leaf.getId(),
        newName: detail.name,
        newDescription: detail.description || "",
      }
    })
  }

  updatePermissions = async (permissions=[]) => {
    const { leaf } = this.props;
    const url = leaf instanceof ProjectLeaf ? "/index.php?r=/referencing-model/set-permissions" : "/index.php?r=/sub-model/set-permissions";

    return await $.ajax({
      url,
      method: "POST",
      data: { permissions }
    })
  }



  /**
   * Filtered Projects
   * 
   */
  saveFilteredProject = async () => {
    BasicAlert.show("Creating filtered project. This may take a few minutes", "Processing request", false);
    const data = this.projectFilterRef.current.serialize();

    const res = await $.ajax({
      url: "/index.php?r=/referencing-model/save-filtered-project/",
      method: "post",
      data,
      error: (jqXHR, textStatus, thrownError) => {
        BasicAlert.hide();
        // case: ERR_EMPTY_RESPONSE
        if (jqXHR.status === 0) {
          receiveErrors("The request is taking longer than expected. You can check back later to see if it has completed.");
        }
      }
    })
    const response = JSON.parse(res);
    // case: timeout
    if (response.error === "The server responded with error code timeout. Please contact Skayl support for more help.") {
      receiveWarnings("The request has timed out, but that does not mean that " +
                      "the operation has failed - filtered model creation may take some time. " + 
                      "Please come back later and refresh your Model Manage page to see if your " + 
                      "model filter request has completed.");
    }

    if (response.status === "success") {
      await this.props.refreshTrees(null, response.data?.model_id);
      return BasicAlert.hide();
    }
    else {
      receiveResponse(response);
    }

    BasicAlert.hide();
  }

  validateNewProjectData = () => {
    const projectData = this.detailRef.current.serialize();
    const { selectedSubmodelIds, blankModels, newModels, newModelFiles, } = this.selectNewModelRef.current.serialize();
    const errors = [];

    if (!projectData.name) {
      errors.push("Project name is required");
    }

    if (!selectedSubmodelIds.length && !blankModels.length && !newModels.length) {
      errors.push("At least one model must be included when creating a new project");
    }

    if (blankModels.some(data => !data.name) || newModels.some(data => !data.name)) {
      errors.push("Model name is required");
    }

    if (newModelFiles.some(file => !file)) {
      errors.push("A model is missing a file. Please attach a model file and try again.");
    }

    if (errors.length) {
      receiveErrors(errors);
    }

    return !errors.length;
  }

  /**
   * Fixup Wizard
   * 
   */
  handleFixupCheckbox = (idx, checked) => {
    const fixupModels = [...this.state.fixupModels];
    // toggle checked
    fixupModels[idx].checked = !fixupModels[idx].checked;
    this.setState({ fixupModels })
  }

  saveFixupModels = async () => {
    BasicAlert.show("Performing auto fixup", "Processing Request", false);
    const fixups = this.state.fixupModels.filter(fixup => fixup.checked);
    this.setState({ fixupModels: [] });

    const promises = fixups.map((fixup) => {
      return $.ajax({
        url: "/index.php?r=/sub-model/fixup-submodel",
        method: "POST",
        data: { subModelId: fixup.modelId },
      })
    })

    BasicAlert.hide();
    Promise.all(promises).then(res => {
      if (res.every(result => result === "#true")) {
        receiveLogs("Poof! Skayl Magic Complete!");
      } else {
        receiveErrors("Something went wrong. Please contact Skayl customer support.")
      }
    })
  }

  cancelFixupModels = () => {
    this.setState({ fixupModels: [] });
  }

  render() {
    const isNewLeaf = !!this.props.leaf?.isNewLeaf();
    const isCopyProject = this.props.manage_option === MANAGE_OPTIONS.newCopyProject;

    if (this.props.leaf instanceof ProjectLeaf) {

      if (this.props.createFilteredProject) {
        return <ProjectFilter projectLeaf={this.props.leaf}
                              createFilteredProject={this.props.createFilteredProject}
                              ref={this.projectFilterRef} />
      }

      return <div className="project-detail">
              <FixUpWizard fixupModels={this.state.fixupModels} 
                           onCheckbox={this.handleFixupCheckbox}
                           onSave={this.saveFixupModels}
                           onCancel={this.cancelFixupModels} />


              <ProjectDetail leaf={this.props.leaf}
                             ref={this.detailRef} 
                             manage_option={this.props.manage_option}
                             createPublishProject={this.props.createPublishProject} />
  
              <ProjectDetailModelFamily leaf={this.props.leaf}
                                        activeProjectId={this.props.activeProjectId}
                                        projectIndex={this.props.projectIndex}
                                        modelIndex={this.props.modelIndex}
                                        refreshTrees={this.props.refreshTrees}
                                        ref={this.familyRef}
                                        manage_option={this.props.manage_option}
                                        createPublishProject={this.props.createPublishProject} />
  
              { !this.props.createPublishProject && !isCopyProject &&
                <>
                  <ProjectDetailModelSelect leaf={this.props.leaf}
                                            modelIndex={this.props.modelIndex}
                                            branchIndex={this.props.branchIndex}
                                            ref={this.selectNewModelRef} />

                  <PermissionDetail leaf={this.props.leaf}
                                    user={this.props.user}
                                    ref={this.permissionRef} />
                </> }
             </div>

    } else if (this.props.leaf instanceof ModelLeaf) {
      return <div className="project-detail">
                <FixUpWizard fixupModels={this.state.fixupModels}
                             onCheckbox={this.handleFixupCheckbox}
                             onSave={this.saveFixupModels}
                             onCancel={this.cancelFixupModels} />

                <ModelDetail leaf={this.props.leaf}
                             activeProjectId={this.props.activeProjectId}
                             projectIndex={this.props.projectIndex}
                             modelIndex={this.props.modelIndex}
                             createPublishModel={this.props.createPublishModel}
                             ref={this.detailRef} />

                <PermissionDetail leaf={this.props.leaf}
                                  user={this.props.user}
                                  createPublishModel={this.props.createPublishModel}
                                  ref={this.permissionRef} />
             </div>  
    }

    return null;
  }
}





export class ImportCheckList extends React.Component {
  constructor(props) {
    super(props);
    this.phenomId = new PhenomId("import-checklist");
  }

  state = {
    assertUid: true,
    fixPathsNoObs: true,
    fixCharNoRoleName: true,
    checkAllChecks: true,
  }

  importCheckList = {
    // checkUnique: ["check-name-collisions", "Check for Name Collisions."],
    assertUid: ["assert-uid", "Assert IDs"],
    fixPathsNoObs: ["check-paths-no-obs", "Fix paths not ending in Observable"],
    fixCharNoRoleName: ["", "Fix View Characteristics w/o name"]
  }

  serialize = () => {
    return JSON.stringify(this.state);      // needs to be stringified for FormData and Yii expects it to be a string
  }

  handleCheckAllBox = (e) => {
    const checkList = {};

    for (let key of Object.keys(this.importCheckList)) {
      checkList[key] = e.target.checked;
    }

    this.setState({ ...checkList });
  }

  renderCreateHeading() {
    return <div className="flex-h" 
                style={{ flex:1, flexDirection:"row", justifyContent: "right", paddingRight:"10px" }}>
              <div className="flex-h">
                <input type="checkbox" 
                       checked={Object.keys(this.importCheckList).every(key => this.state[key] === true)} 
                       onChange={this.handleCheckAllBox}
                       id="import-checklist-check-all" 
                       onClick={(e) => {
                        e.stopPropagation();
                       }}/>
                <div style={{ display:"flex", paddingLeft: "0.3em" }}>All </div>
              </div>
          </div>
  }

  render() {
    const phenomId = this.phenomId;
    
    return <PhenomCollapsable label="Import Checks/Fixes"
                              headerChildren={this.renderCreateHeading()}>
    <div id={phenomId.gen(["render-create", "createChecks"],"wrapper")}>
      {Object.keys(this.importCheckList).map((key) => {
        return <div style={{ display: "flex", alignItems: "center", gap: 5, margin: "5px 0" }}>
                <label>{ this.importCheckList[key][1] }</label>
                <input type="checkbox" 
                      checked={this.state[key]} 
                      onChange={(e) => this.setState({[key]: e.target.checked})}
                      id={`import-checklist-${key}`}/>
              </div>
      })}
    </div>
  </PhenomCollapsable>
  }
}

export class QueryCheckList extends React.Component {
  constructor(props) {
    super(props);
    this.phenomId = new PhenomId("query-checklist");
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  state = {
    checkAllChecks: false,

    process_CDM_queries: false,
    process_LDM_queries: false,

    retainCDM: false,
    retainLDM: false,
    retainEntityModelLDM: false,
    retainEntityModelPDM: false,
  }

  queryCheckList = {
    process_CDM_queries: ["process-cdm-queries", "Process CDM queries"],
    process_LDM_queries: ["process-ldm-queries", "Process LDM queries"],
  }

  retainCheckList = {
    retainCDM: ["retain-cdm-queries", "Retain CDM queries"],
    retainLDM: ["retain-ldm-queries", "Retain LDM queries"],
    retainEntityModelLDM: ["retain-ldm-model", "Retain LDM Entity model"],
    retainEntityModelPDM: ["retain-pdm-model", "Retain PDM Entity model"],
  }

  serialize = () => {
    return JSON.stringify(this.state);      // needs to be stringified for FormData and Yii expects it to be a string
  }

  getSerializeData = () => {
    const {process_CDM_queries, process_LDM_queries, retainCDM, retainLDM, retainEntityModelLDM, retainEntityModelPDM} = this.state;
    let checks = {process_CDM_queries, process_LDM_queries};
    let retainedTypes = [];
    let data;


    if (retainCDM) {
      retainedTypes.push(...["conceptual:Query", "conceptual:CompositeQuery", "conceptual:QueryComposition"])
    }

    if (retainLDM) {
      retainedTypes.push(...["logical:Query", "logical:CompositeQuery", "logical:QueryComposition"])
    }

    if (retainEntityModelLDM) {
      retainedTypes.push(...["logical:Entity", "logical:Association", "logical:Composition", "logical:Participant", "logical:ParticipantCharacteristic", "logical:ParticipantPathNode"])
    }

    if (retainEntityModelPDM) {
      retainedTypes.push(...["platform:Entity", "platform:Association", "platform:Composition", "platform:Participant", "platform:ParticipantCharacteristic", "platform:ParticipantPathNode"])
    }

    data = [checks, retainedTypes];

    return data;
  }


  handleCheckAllBox = (e) => {
    this.setState({
      process_CDM_queries: e.target.checked,
      process_LDM_queries: e.target.checked,
      retainCDM: e.target.checked,
      retainLDM: e.target.checked,
      retainEntityModelLDM: e.target.checked,
      retainEntityModelPDM: e.target.checked,
    })
  }

  renderQueryHeading() {
    return <div className="flex-h" 
                style={{ flex:1, flexDirection:"row", paddingRight:"10px" }}>
              <span className="fas fa-info-circle"
                  style={{margin: "2px 5px 0 0"}}
                  data-tip="Normally, when a FACE model is imported into Phenom, only platform templates are translated into Phenom views, with the semantic projects gathered from the query related to the template. 
                  Choosing the CDM Queries to be processed into Phenom views will process them as if they were a flat template including all of the selections of the query. The measurement and platform type attributes of the views fields will be set to placeholders. 
                  LDM Queries can also be processed, in which case only the platform types of the resulting view's fields will be set to placeholders. The resulting views, however, will still be exported as platform level queries and templates.
                  
                  <br/><br/>Additionally, when a FACE model is imported into Phenom, some nodes are removed, to be re-generated as needed during model export. The retention options will allow you to keep some of those nodes attached to the model. 
                  While not visible in Phenom, they will be able to be re-introduced into your model on export by selecting the 'Recover Retention Artifacts' option.
                  <ul>
                    <li>CDM Queries - retain the conceptual query and composite query nodes.</li>
                    <li>LDM Queries - retain the logical query and composite query nodes. Required LDM Entity Model content will also be retained.</li>
                    <li>LDM Entity Model - retain the logical entity, association, composition, and participant nodes.</li>
                    <li>PDM Entity Model - retain the platform entity, association, composition, and participant nodes.</li>
                  <ul>"
                  data-for="hoverTip"
                  data-html={true}
                  data-place="left"/>
              <div className="flex-h"
                    style={{ flex:1, flexDirection:"row", justifyContent: "right"}}>
                <input type="checkbox" 
                       checked={Object.keys(this.queryCheckList).every(key => this.state[key] === true)} 
                       onChange={this.handleCheckAllBox}
                       id="query-checklist-check-all" 
                       onClick={(e) => {
                        e.stopPropagation()
                       }}/>
                <div style={{ display:"flex", paddingLeft: "0.3em" }}>All </div>
              </div>
          </div>
  }

  renderQueryChecks() {
    const phenomId = this.phenomId;

    return <div id={phenomId.gen(["render-query", "queryChecks"],"wrapper")}>
              {Object.keys(this.queryCheckList).map((key) => {
                return <div style={{ display: "flex", alignItems: "center", gap: 5, margin: "5px 0", paddingLeft:10 }}>
                        <input type="checkbox" 
                              checked={this.state[key]} 
                              onChange={(e) => this.setState({[key]: e.target.checked})}
                              id={`query-checklist-${key}`}/>
                        <label>{ this.queryCheckList[key][1] }</label>
                      </div>
              })}
              {Object.keys(this.retainCheckList).map((key) => {
                return <div style={{ display: "flex", alignItems: "center", gap: 5, margin: "5px 0", paddingLeft: 10 }}>
                        <input type="checkbox" 
                              checked={this.state[key]} 
                              onChange={(e) => this.setState({[key]: e.target.checked})}
                              id={`query-checklist-${key}`}/>
                        <label>{ this.retainCheckList[key][1] }</label>
                      </div>
              })}
            </div>
  }

  render() {
    const phenomId = this.phenomId;

    return <PhenomCollapsable label="CDM/LDM Queries Processing"
                              headerChildren={this.renderQueryHeading()}>
      <div id={phenomId.gen(["render-query", "queryChecks"],"wrapper")}>
          {Object.keys(this.queryCheckList).map((key) => {
            return <div style={{ display: "flex", alignItems: "center", gap: 5, margin: "5px 0", paddingLeft:10 }}>
                    <input type="checkbox" 
                          checked={this.state[key]} 
                          onChange={(e) => this.setState({[key]: e.target.checked})}
                          id={`query-checklist-${key}`}/>
                    <label>{ this.queryCheckList[key][1] }</label>
                  </div>
          })}
          {Object.keys(this.retainCheckList).map((key) => {
            return <div style={{ display: "flex", alignItems: "center", gap: 5, margin: "5px 0", paddingLeft: 10 }}>
                    <input type="checkbox" 
                          checked={this.state[key]} 
                          onChange={(e) => this.setState({[key]: e.target.checked})}
                          id={`query-checklist-${key}`}/>
                    <label>{ this.retainCheckList[key][1] }</label>
                  </div>
          })}
      </div>
    </PhenomCollapsable>
  }
}




const FixUpWizard = (props) => {

  if (!props.fixupModels?.length) return null;


  return <Dialog title="Model Fixup Wizard" className="dialog-no-exit">
            <div style={{
              maxWidth: "calc(100vh - 300px)",
              maxHeight: "calc(80vh - 340px)",
              overflowY: "auto"
            }}>
              <h3 style={{ margin: "0 0 10px" }}><b>Generalization-Specialization Inconsistencies Detected</b></h3>
              <p>
                Some specializing elements do not contain all attributes required by their specialization relationship.
                This may be resolved by either adding new matching attributes to these elements or declaring existing attributes as specializers.
                PHENOM can automatically add the appropriate matching attributes now.
              </p>
              <p>
                Alternatively, you will be able to later review these issues in more detail and manually resolve them after running a PHENOM health-check.
              </p>
              <p>
                If you perform the auto-fix, the below attributes will be created.
                Additionally, similar attributes will be created in elements specializing those listed.
              </p>

              <label>Specialized Attribute Additions:</label>

              {props.fixupModels.map((fixup, idx) => {
                if (!fixup.genSpecIssues) return null;

                return <div>
                        <label style={{ display: "inline-flex", alignItems: "center", gap: 5, marginLeft: 12, fontSize: 16, fontWeight: 400 }}>
                          <input type="checkbox" checked={fixup.checked} onChange={() => { props.onCheckbox(idx) }} />
                          { fixup.modelName }
                        </label>

                        <ul>
                          {fixup.genSpecIssues.map((entry, jdx) => {
                            if (jdx % 2 == 0) return null;
                            return <li>{entry}.{fixup.genSpecIssues[jdx - 1]}</li>
                          })}
                        </ul>
                      </div>
              })}
            </div>
            <DialogActionsBar>
                <button id="fixup-wizard-cancel"
                        className="k-button"
                        onClick={props.onCancel}>
                    Resolve Issues Later
                </button>
                <button id="fixup-wizard-confirm"
                        className="k-button"
                        onClick={props.onSave}>
                          Perform Auto Fix
                </button>
            </DialogActionsBar>

  </Dialog>
}