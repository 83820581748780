export const accountLicenses = ["COMMERCIAL", "SBIR"]
export const accountRoles = ["MODELER", "CINCWORKS", "FULL"]
export const cincVersions = ["cinc-2.0.0", "cinc-2.0.1", "cinc-2.1.0", "cinc-2.1.1"]


/*
Babylon models for measurement systems. Should map guids to filenames. Try: "default":"wgs84.glb" to test .
*/
export const BABYLON_MODELS = {
    "default": false,
    "_vZiY2Ag5EeSFspy8Kj3F4Q": "wgs84.glb"
};



/**
 * Collection of site-wide urls
 * 
 */
export const PHENOM_URL = {
    "dashboard": "/dashboard",
    "edit": "/edit/details",
    "integration": "/integration/details",
    "generate": "/generate",
    "manage": "/manage",
}


export const primitiveTypeList = [
  "platform:Boolean",
  "platform:Char",
  "platform:WChar",
  "platform:String",
  "platform:WString",
  "platform:Double",
  "platform:LongDouble",
  "platform:Float",
  "platform:Long",
  "platform:LongLong",
  "platform:ULong",
  "platform:ULongLong",
  "platform:Short",
  "platform:UShort",
  "platform:Octet",
  "platform:Enumeration",
  "platform:Fixed",
  "platform:CharArray",
  "platform:WCharArray",
  "platform:IDLStruct",
  "platform:IDLArray",
  "platform:IDLSequence",
  "platform:BoundedString",
  "platform:BoundedWString",
].sort();


export const logicalValueTypeList = [
  "logical:Boolean",
  "logical:Character",
  "logical:Enumerated",
  "logical:Integer",
  "logical:Natural",
  "logical:NonNegativeReal",
  "logical:Real",
  "logical:String",
].sort();

export const constraintsToPrimitives = {
  "platform:RegularExpressionConstraint": ["platform:Char", "platform:String", "platform:WChar", "platform:WString"],
  "platform:RealRangeConstraint": ["platform:Double", "platform:Float", "platform:LongDouble"],
  "platform:IntegerRangeConstraint": ["platform:Octet", "platform:UShort", "platform:ULong", "platform:Short", "platform:Long", "platform:ULongLong", "platform:LongLong"],
}