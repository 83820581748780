import React, { Component } from "react";
import Helmet from "react-helmet";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Details } from "../components/navigate/details";
import MeridianMap from "../components/navigate/meridian_map";
import SkratchpadManager from "../components/navigate/diagrams/manager/SkratchpadManager";
import StormManager from "../components/navigate/diagrams/manager/StormManager";
import HealthCheck from "../components/generate/health_check";
import ModelStats from "../components/generate/model_stats";
import Import from "../components/manage/import";
import $ from "jquery";
import * as actionThunks from "../requests/actionThunks"



class DataModel extends Component {
    state = {
      meridianSelection: false,
    }

    constructor(props) {
      super(props);
      $.ajax({
        url: "/index.php?r=/referencing-model/is-published",
        method: "get"
      }).then(res => {
        const response = JSON.parse(res);
        if (!response) {
          this.subMenu.push(
            {
              url: "/edit/import",
              text: "Import"
            });
          this.forceUpdate();
        }
      });
    }

    subMenu = [
      {
        url: "/edit/details",
        text: "Details"
      },
      {
        url: "/edit/scratchpad",
        text: "Diagram"
      },
      {
        url: "/edit/health_check",
        text: "HealthCheck"
      },
      {
        url: "/edit/model_stats",
        text: "Analytics"
      },
      {
        url: "/edit/meridian_map",
        text: "Meridian Map"
      },
      {
        url: "/edit/view_trace",
        text: "View Trace"
      }
    ]

    componentDidMount() {
      actionThunks.getActiveChangeSets();
    }

    setMeridianSelection = (selection) => {
        this.setState({meridianSelection: selection});
    };

    render() {
        return (<>
            <Helmet>
                <title>Navigate Project</title>
            </Helmet>
            
            <section id="phenom-content" aria-label='content'>
              <nav className="sub-menu-pages" aria-label='sub menu'>
                  {this.subMenu.map((sub, idx) => (
                    <NavLink to={sub.url} key={idx} activeClassName="active">
                      {sub.text}
                    </NavLink>
                  ))}
              </nav>
              <Switch>
                <Route path="/:mode(edit)/details/:xmi_type?/:guid?/:parentGuid?" component={Details}/>
                {/* <Route path="/:mode(edit)/scratchpad" render={(props) => <StormManager stormType="scratchpad" />}/> */}

                <Route path="/:mode(edit)/scratchpad" component={SkratchpadManager}/>
                <Route path="/:mode(edit)/health_check" component={HealthCheck} />
                <Route path="/:mode(edit)/model_stats" component={ModelStats} />

                <Route path="/:mode(edit)/meridian_map"
                      render={() => <MeridianMap selection={this.state.meridianSelection}
                                                  setMeridianSelection={this.setMeridianSelection}/>}/>
                <Route path="/:mode(edit)/view_trace" render={(props) => <StormManager stormType="view_trace" />}/>
                <Route path="/:mode(edit)/import/:sub_page?" component={Import}/>
                <Redirect to="/edit/details" />
              </Switch>
            </section>
        </>);
    }
}






export default DataModel