import {CollapseHeader, LineLabel} from "../util/stateless";
import React, { Component } from "react";
import $ from "jquery";
import PhenomId from "../../requests/phenom-id";
import { getUsernameFromResetCode } from "../../requests/sml-requests";
import * as actionCreators from "../../requests/actionCreators";

export class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            newPass: "",
            confirmPass: "",
            badConfirm: false,
        };
    }

    componentDidMount() {
        getUsernameFromResetCode(this.props.match.params.code).then(res => {
            const response = JSON.parse(res);
            if (response.errors) {
                actionCreators.receiveErrors(response.errors);
                this.props.history.replace("/request_password_reset");
            } else if (response.data) {
                this.setState({username: response.data});
            }
        });
    }

    confirmEntries() {
        return this.state.username.length && this.state.newPass.length && this.state.confirmPass.length && this.state.newPass === this.state.confirmPass;
    }

    setEntry(field, value) {
        this.setState({[field]: value});
    }

    comparePass = () => {
        this.setState({badConfirm: this.state.newPass !== this.state.confirmPass});
    };

    launchPasswordReset = () => {
        $.ajax({
            url: "/index.php?r=/site/reset-password",
            method: "post",
            data: {
                username: this.state.username,
                newPassword: this.state.newPass,
                resetCode: this.props.match.params.code
            },
        }).then((res) => {
            const response = JSON.parse(res);
            if (response.errors) {
                actionCreators.receiveErrors(response.errors);
            } else if (response.response && response.response === true) {
                actionCreators.receiveLogs("Your password has been reset. You will now be redirected to the login page.");
                setTimeout(() => {
                    this.props.history.replace("/");
                }, 3000);
            } else {
                actionCreators.receiveErrors("An error occurred during the request process. Please contact Skayl Support for further help.");
            }
        });
    };

    render() {
        const phenomId = new PhenomId("password-reset",this.props.idCtx);
        return (<section id="phenom-content" style={{backgroundColor: "#fff"}}>
            <div className="phenom-content-wrapper">
              <div className="phenom-content-scrollable">
                <CollapseHeader text="RESET PASSWORD" id={phenomId.gen("","reset-password")}/>
                <nav className="tab-nav" style={{height: 30}}></nav>
                <main style={{padding: 20, backgroundColor: "#fff", height: "100%"}}>
                    <LineLabel text="Username" idCtx={phenomId.gen("","username")}/>
                    <input value={this.state.username} onChange={(e) => this.setEntry("username", e.target.value)}
                          placeholder="enter your account username" type="text" className="cadet-text-input"
                          id={phenomId.gen("","username-input")}/>
                    <LineLabel text="New Password" idCtx={phenomId.gen("","new-password")}/>
                    <input onChange={(e) => this.setEntry("newPass", e.target.value)} placeholder="enter your new password"
                          type="password" className="cadet-text-input" id={phenomId.gen("","new-password-input")}/>
                    <LineLabel text="Confirm New Password" idCtx={phenomId.gen("","confirm-new-password")}/>
                    <input
                        onFocus={() => this.setState({badConfirm: false})}
                        onBlur={this.comparePass}
                        onChange={(e) => this.setEntry("confirmPass", e.target.value)}
                        placeholder="confirm your new password"
                        type="password"
                        className={"cadet-text-input " + (this.state.badConfirm ? "invalid" : "")}
                        idCtx={phenomId.gen("","confirm-new-password-input")} 
                    />
                    <button
                        onMouseEnter={this.comparePass}
                        onClick={this.launchPasswordReset}
                        style={{marginTop: 5}}
                        disabled={!this.confirmEntries()}
                        className="filled-button"
                        id={phenomId.gen("","reset-button")}>
                        RESET PASSWORD
                    </button>
                </main>
              </div>
            </div>
        </section>);
    }
}
