import React, {Component} from "react";
import ReactTooltip from "react-tooltip";
import styled from "@emotion/styled";
import $ from "jquery";
import { Action, CanvasWidget, InputType } from "@projectstorm/react-canvas-core";
import { cloneDeep } from "lodash";
import createEngine, {
    DagreEngine,
    DiagramModel,
    PathFindingLinkFactory,
    DefaultLinkModel,
} from "@projectstorm/react-diagrams";

import { getNodeEffectiveType, modelDeprecateNode, modelRemoveNode } from '../../../requests/sml-requests';
import { FadingDirections, Toggle } from "../../util/stateless";
import { BasicAlert } from "../../dialog/BasicAlert";
import { BasicConfirm } from "../../dialog/BasicConfirm";
import SidePanel from "../diagrams/manager/SidePanel";

import "../diagrams/design/scratchpadStyles.css";
import Stencil from "./Stencil";
import {nodeColors} from '../diagrams/design/nodeDesign';
import PhenomId from '../../../requests/phenom-id';

import {
    ContextMenu,
    createNewNodeCoords,
    modifiedPairsForward,
    isDiagramNode,
    createPathPortGuid,
    formatPathAttr,
    generateTextPath,
    isNestingChar,
    randomColor,
    CogOptions,
    showRemovalConfirm,
    showCannotRemoveBcProjection,
} from "../diagrams/util";

import { StormState } from "../diagrams/stormy/StormState"

import {
    // Labels
    DiagramLabelFactory,

    // Nodes
    EntityNodeFactory,
    ViewNodeFactory,

    BoxShapeFactory,
    LineShapeFactory,
    TextBoxShapeFactory,

    // Links
    CompositionLinkFactory,
    PathLinkFactory,
    ShapeLinkFactory,

    // Ports
    CompositionPortFactory,
    PathPortFactory,
    ShapePortFactory,

    // Models
    EntityNodeModel,
    ViewNodeModel,
    CompositionLinkModel,

    BoxShapeModel,
    LineShapeModel,
    TextBoxShapeModel,

    // Others
    ShapePortModel,
    SelectionWindow,

    nodeProps,
    spProps,
    StyledIcon,
    DiagramNodeModel,
    DiagramShapeModel,
} from '../diagrams/index';
import { createPhenomGuid, isPhenomGuid } from "../../util/util";

const StyledStencilBar = styled.div`
    grid-area: stencil;
`
const GraphContainer = styled.div`
    top: 0;
    position: relative;
    height: 100%;
    width: 100%;
    /* left: 110px; */
    right: 0;
    overflow: auto;
`

const PathBuilder = styled.div`
    display: block;
    position: absolute;
    top: 0;
    height: 56px;
    width: 100%;
    z-index: 5;
`

const PathText = styled.div`
    font-size: 16px;
    padding: 5px 10px;
    background: #f6f6f6;
`

const pathInstructions = [
    "Select an Entity or an Association to begin the path (Highlighted in green).",
    "Select a Hop by choosing a Composition or a Participant. (If there are no choices click Undo or Cancel)",
];


const nodeModelWhitelist = new Set(["entity-node", "view-node"]);

export default class Skratchpad extends Component {
    constructor(props) {
        super(props);

        // modes: ["modelling", "projection"]
        // modeAction: ["select-a-projChar", "select-a-hop"]
        // selectingHop: guid
        this.state = {
            mode: "modelling",
            modeAction: "",
            selectingHop: "",
            pathBuilder: cloneDeep(this.defaultPathBuilder),
            zoomLevel: 100,
            loadingDiagram: false,
            showUncommitted: false,
            selectionRect: {
                top:0,
                left:0,
                width:0,
                height:0,
            },
            editable: true,
        };

        this.contextState = {
            guid: createPhenomGuid(),
            name: this.props.fileName,
            description: "",
            xmiType: "skayl:DiagramContext",
            category: "s",
            children: [],
        }

        this.phenomId = new PhenomId(this.props.idCtx);
        this.pathInstructionsIdx = 0;

        /*
        * This configures the layout processor
        * */
        this.dagreEngine = new DagreEngine({
            graph: {
                rankdir: "LR",
                ranker: "tight-tree",
                marginx: 25,
                marginy: 25
            },
            includeLinks: true
        });

        // removes default actions
        this.engine = createEngine({
            registerDefaultDeleteItemsAction: false,
            registerDefaultZoomCanvasAction: false,
        });

        this.engine.getStateMachine().pushState(new StormState());

        this.engine.getNodeFactories().registerFactory(new EntityNodeFactory());
        this.engine.getNodeFactories().registerFactory(new ViewNodeFactory());

        this.engine.getNodeFactories().registerFactory(new TextBoxShapeFactory());
        this.engine.getNodeFactories().registerFactory(new BoxShapeFactory());

        this.engine.getNodeFactories().registerFactory(new LineShapeFactory());

        this.engine.getLinkFactories().registerFactory(new CompositionLinkFactory());
        this.engine.getLinkFactories().registerFactory(new PathLinkFactory());
        this.engine.getLinkFactories().registerFactory(new ShapeLinkFactory());

        this.engine.getPortFactories().registerFactory(new CompositionPortFactory());
        this.engine.getPortFactories().registerFactory(new PathPortFactory());
        this.engine.getPortFactories().registerFactory(new ShapePortFactory());

        this.engine.getLabelFactories().registerFactory(new DiagramLabelFactory());

        /*
         * MODEL
         */
        this.model = new DiagramModel();

        this.model.registerListener({
            eventDidFire: e => {
                if(e.function === "nodesUpdated") {
                    this.resizeCanvas();
                }
                if (e.function === "zoomUpdated") {
                    const zoomLevel = parseInt(e.zoom);
                    this.setState({ zoomLevel });
                    this.zoomSlider.current.value = zoomLevel;
                }
            },
        });

        this.engine.setModel(this.model);

        this.engine.getActionEventBus().registerAction(new CustomDeleteItemsAction());
        this.engine.getActionEventBus().registerAction(new CustomMouseDownAction());
        this.engine.getActionEventBus().registerAction(new CustomMouseUpAction());
        // this.engine.getActionEventBus().registerAction(new CustomScrollCanvasAction());
        // this.engine.getActionEventBus().registerAction(
		// 	new Action({
		// 		type: InputType.MOUSE_DOWN,
		// 		fire: (event) => {
		// 			const element = this.engine.getActionEventBus().getModelForEvent(event);
		// 			if (!element.isSelected()) {
		// 				this.engine.getModel().clearSelection();
		// 			}
		// 			element.setSelected(true);
		// 			this.engine.repaintCanvas();
		// 		}
		// 	})
        // );

        this.zoomSlider = React.createRef();
        this.sidePanelRef = React.createRef();

        /*
         * This reference is important to have because it allows us to easily access the
         * state and methods in this file from individual nodes
         * */
        this.engine.$app = this;

        // frequently used functions from manager
        this.getOptionNode = this.props.manager.getOptionNode;
    }

    componentDidMount() {
        this.tabData = this.props.tabData;
        this.typeOptions = this.props.typeOptions;
        this.domSvgLayerOnTop();

        // React Storm only listens for these mouse actions: Mouse_Down, Mouse_Up, Mouse_Wheel, and Mouse_Move
        $(this.engine.canvas).on('contextmenu', (e) => {
            e.preventDefault();
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.typeOptions !== this.props.typeOptions) {
            this.typeOptions = this.props.typeOptions;
        }
    }



    /*
     * Data
     */
    defaultPathBuilder = {
        rebuildPathLinksOnCancel: false,
        builder:[],
        pathPairs: [],
        viewData: {},
        projCharData: {},
        strokeData: {},
    }

    getPhenomDomId = () => {
      return this.phenomId.genPageId();
    }

    getCanvasLayerDOM = () => {
      return this.engine.canvas;
    }

    getModelLayerDOM = () => {
      return this.engine.canvas.querySelector('div:first-of-type');
    }

    getSvgLayerDOM = () => {
      return this.engine.canvas.querySelector('svg:first-of-type');
    }

    domModelLayerOnTop = () => {
      const svgLayer = this.getSvgLayerDOM();
      svgLayer.style.zIndex = "0";
    }

    domSvgLayerOnTop = () => {
      const svgLayer = this.getSvgLayerDOM();
      svgLayer.style.zIndex = "1";
    }

    getMode = () => {
        if (this.state.mode === "projection") {
            return "p";
        }
        return "m";
    }

    switchToModellingMode = () => {
      this.setState({mode: "modelling"},
                      () => { this.forceNodesToUpdate(); this.changeHeaderColor() });
    }

    switchToProjectionMode = () => {
      this.setState({mode: "projection"},
                      () => { this.forceNodesToUpdate(); this.changeHeaderColor() });
    }

    changeHeaderColor = () => {
      this.props.manager.changeNavColor(this.state.mode);
    }

    toggleMode = (mode) => {
        switch(mode) {
            case "modelling":
                return this.switchToModellingMode();
            case "projection":
                return this.switchToProjectionMode();
        }

        if(this.state.mode === "modelling") {
            this.switchToProjectionMode();
        } else {
            this.switchToModellingMode();
        }
    }

    getContextState = () => {
        return this.contextState;
    }

    setContextState = (state={}) => {
        // shallow copy because side panel has react.memo
        this.contextState = { ...this.contextState, ...state };

        if (state.name) {
          this.props.manager.updateTabProps("fileName", state.name, this.props.tabId);
        }

        this.forceUpdate();
    }



    /*
     * Used by Manager
     */
    addUncommittedNode = (node) => {
      if(!node) return;
      const { tabId } = this.props.tabData;
      this.props.manager.addUncommittedNode(node, tabId);
    }

    removeUncommittedNode = (guid) => {
      if(!guid) return;
      const { tabId } = this.props.tabData;
      this.props.manager.removeUncommittedNode(guid, tabId);
    }

    loadDiagram = async (content) => {
        // diagram nodes mount in edit mode.  this is used to prevent it on load.
        await this.setState({loadingDiagram: true});
        await this.restoreSession(content);

        // diagram 1.0 converted to scratchpad
        if(content.old_diagram) {
            await this.rebuildOldDiagramConnectors(content.rebuildConnectors);
            await this.forceNodesToUpdate();
            await this.forceLinksToUpdate();
            await this.engine.repaintCanvas();
        }
    }

    // when loading diagram - the entire enviroment is being rebuilt
    restoreSession = async (content) => {
      if(!content) return;

      const linkModels = content.layers.find(e => e.type === "diagram-links").models;
      const nodeModels = content.layers.find(e => e.type === "diagram-nodes").models;
      const recreatePath = {};

      // 0) Readd non-active diagram nodes
      if(content.diagramNodes) {
          for(let diagramNode of content.diagramNodes) {
              this.props.manager.addUncommittedNode(diagramNode);
              this.props.manager.setPlaceholderId(diagramNode.guid);
              this.props.manager.setOptionNode(diagramNode);
              this.props.manager.addToNavTree(diagramNode);
          }
      }

      for (let nodeModel of Object.values(nodeModels)) {
          // note: at this point the nodeModel constructor was not triggered yet (this is JSON data)
          let jsonData = nodeModel.nodeData;

          // 1) skip ShapeModels (Line, Arrows, Box, Textbox)
          if(!nodeModelWhitelist.has(nodeModel.type)) continue;

          // 2) path connectors can easily go out of sync
          // -> recreatePath is used to simulate the "eye" click on diagram load
          if(jsonData.xmiType === "platform:View") {
            recreatePath[nodeModel.id] = {
                ...nodeModel.outPortMap,
            }
          }

          // 3) readd Diagram Node to data bank
          if(isDiagramNode(jsonData.guid)) {
              this.props.manager.addUncommittedNode(jsonData);
              this.props.manager.setOptionNode(jsonData);

          // 4) fetch Real Nodes to get most up-to-date data
          } else {
            let fetchedData = await this.props.manager.getNode(jsonData);
            if(fetchedData) {
                // check if the loaded node contains diagram children
                // add the diagram children back to the fetchedData
                // note: this also checks if diagram child was already added (i.e. was loaded from a different tab)
                jsonData.children.forEach(child => {
                    if(child.guid.startsWith("DIAGRAM_") && !fetchedData.children.find(oc => oc.guid === child.guid)) {
                        fetchedData.children.push(child);
                    }
                })

            // delete the node if the fetch fails (i.e. came from a different subModel or it no longer exists)
            } else {
                this.removeNodeFromRestoreSession(nodeModel, nodeModels, linkModels);
            }
          }
      }

      // 6) *** begin loading the content into the Diagram ***
      this.model.deserializeModel(content, this.engine);
      await this.engine.repaintCanvas(true);

      for(let nodeModel of this.engine.getModel().getNodes()) {
          // skip ShapeModels
          if(!nodeModelWhitelist.has(nodeModel.options.type)) continue;
          nodeModel.rebuild(true);
          // await nodeModel.forceWidgetToUpdate(false);
      }
      // this.engine.repaintCanvas(true).then(() => {
      //     this.engine.getModel().getNodes().forEach(nodeModel => {
      //         // skip ShapeModels
      //         if(nodeModelWhitelist.has(nodeModel.options.type)) {
      //             nodeModel.rebuild(true);
      //             // nodeModel.forceWidgetToUpdate(false);
      //         }
      //     });
      // });
      await this.forceNodesToUpdate();
      await this.forceLinksToUpdate();
      this.model.clearSelection();
      this.resizeCanvas();

      // 7) recreating the path links had issues.
      // -> the "eyeball click" is simulated to recreate the most up-to-date path.
      for (let nodeModelId in recreatePath) {
          const portMap = recreatePath[nodeModelId];
          const viewNodeModel = this.model.getNode(nodeModelId);
          const children = viewNodeModel?.getNodeData()?.children;
          if(!children) continue;

          for (let portGuid in portMap) {
            let portGuids = portGuid.split("--");

            if(portGuids[1]) {
                const attr = children.find(c => c.guid === portGuids[1]);
                if(attr) await viewNodeModel.$widget.expandAttrPath(attr);
              }
          }
      }

      // diagram nodes mount in edit mode. this is used to prevent it on load.
      await this.setState({loadingDiagram: false});
    }

    // for diagram 1.0 converted to scratchpad
    rebuildOldDiagramConnectors = async (rebuildConnectors = []) => {
        for (let [guid, rolenames] of Object.entries(rebuildConnectors)) {
            const nodeModel = this.findNodeModel(guid);
            if(!nodeModel) continue;

            const nodeData = this.getOptionNode(guid);

            for(let rolename of rolenames) {
                const child = nodeData.children.find(c => c.rolename === rolename);
                if(!child) continue;

                switch(nodeData.xmiType) {
                    case "conceptual:Entity":
                    case "conceptual:Association":
                        const dstNodeModel = this.findNodeModel(child.type);
                        if(dstNodeModel) {
                            await nodeModel.establishLink(child, dstNodeModel);
                        }
                        break;
                    case "platform:View":
                        if(nodeModel.$widget) {
                            await nodeModel.$widget.expandAttrPath(child);
                        }
                }
            }
        }
    }

    // when loading - if a bad node is found, remove it from the json before attempting to recreate the diagram.
    removeNodeFromRestoreSession = (removeMe, nodeModels, linkModels) => {
        removeMe.ports.forEach(port => {
            port.links.forEach(linkId => delete linkModels[linkId]);
        })
        delete nodeModels[removeMe.id];
    }

    rebuildNodes = () => {
        this.engine.getModel().getNodes().forEach(nodeModel => {
            nodeModel.rebuild();
            nodeModel.forceWidgetToUpdate(false);
        });
    }



    /*
     * TOOLBAR
     */
    forceClearCanvas = () => {
        this.model.getNodes().forEach(node => node.remove());
        this.model.getLinks().forEach(link => link.remove());
        this.engine.repaintCanvas();
    }


    clearGraph = () => {
        BasicConfirm.show(`Are you sure you want to clear this diagram?`, () => {
            this.model.getNodes().forEach(node => node.remove());
            this.model.getLinks().forEach(link => link.remove());
            this.engine.repaintCanvas();
        });
    };

    autoLayout = () => {
        this.dagreEngine.redistribute(this.model);
        this.engine
            .getLinkFactories()
            .getFactory(PathFindingLinkFactory.NAME)
            .calculateRoutingMatrix();
        this.engine.repaintCanvas();
    };

    updateZoomLevel = (zoom) => {
        const zoomLevel = parseInt(zoom);

        if(20 <= zoomLevel && zoomLevel <= 500) {
            this.setState({zoomLevel});
            this.model.setZoomLevel(zoomLevel);
        }
    };



    /*
     * ADD NODES TO CANVAS
     */
    getGuidsFromTree = (guids=[], pos) => {
        const nodeWhitelist = ["conceptual:Observable"];
        const nodeModel = this.engine.getMouseElement(window["diagramMouseEvent"]);
        const newNodes = [];

        guids.forEach(guid => {
            const node = this.getOptionNode(guid);
            if(node && nodeWhitelist.includes(node.xmiType)) newNodes.push(node);
        })

        delete window["diagramMouseEvent"];
        if(nodeModel) nodeModel.$widget.createAttrFromTree(newNodes);
    }

    addStencilNode = (event) => {
        if (event.dataTransfer.getData("storm-diagram-node").trim() === "") return;
        const mouse_point = this.engine.getRelativeMousePoint(event);

        const {type} = JSON.parse(event.dataTransfer.getData("storm-diagram-node"));
        let nodeModel, shapeOffset;

        switch (type) {
            case "conceptual:Entity":
            case "conceptual:Association":
                var nodeData = this.props.manager.createNewStencilNode(type);
                nodeModel = new EntityNodeModel({nodeData}, this);
                break;

            case "platform:View":
                var nodeData = this.props.manager.createNewStencilNode(type);
                nodeModel = new ViewNodeModel({nodeData}, this);
                break;

            case "textbox":
                var shape1 = new TextBoxShapeModel();
                shapeOffset = [-100, -100];
                break;

            case "arrow":
                var shape1 = new LineShapeModel();
                var shape2 = new LineShapeModel();
                var arrowHead = "mediumArrow";
                shapeOffset = [-100, 0];
                break;

            case "line":
                var shape1 = new LineShapeModel();
                var shape2 = new LineShapeModel();
                shapeOffset = [-100, 0];
                break;

            case "box":
                var shape1 = new BoxShapeModel();
                shapeOffset = [-100, -100];
                break;

            default:
                return;
        }
        const point = this.engine.getRelativeMousePoint(event);

        if(shape1) {
            let shapes = [shape1];
            shape1.setPosition(point.x + shapeOffset[0], point.y + shapeOffset[1]);

            if(shape2) {
                let port1 = shape1.addPort(new ShapePortModel({isIn: false, name: 'out-1'}));
                let port2 = shape2.addPort(new ShapePortModel({isIn: true, name: 'in-1', arrowHead}));
                shape2.setPosition(point.x + 100, point.y);
                shapes.push(shape2);

                this.model.addLink(port1.link(port2));
            }

            this.model.addAll(...shapes);
            this.forceUpdate();
            return;
        }

        nodeModel.setPosition(point);
        this.model.addNode(nodeModel);

        const {x, y} = createNewNodeCoords(mouse_point.x, mouse_point.y);
        nodeModel.setPosition(x, y);
        this.engine.repaintCanvas();
        this.resizeCanvas();
        setTimeout(() => {
            this.model.clearSelection();
            nodeModel.setSelected(true);
        }, 0);
    };

    // params
    // absolutePos ==> if true, use pos as the coordinates, otherwise use the mouse position as the coordinates
    addNodes = async (nodes, pos, absolutePos = false, showDialog=false, draggedIn=true, expanded=false) => {
        if(!nodes) return;
        if (!Array.isArray(nodes)) nodes = [nodes];
        const diagramNodes = [];
        const prevRequestNames = this.props.manager.isPrevRequestStillInProgress(nodes)

        if(prevRequestNames.length) {
            return BasicAlert.show(<div>Please wait a moment, the previous fetch request is still in progress for nodes: <b>{prevRequestNames.join(", ")}</b>.  Please try again later.</div>, "Processing...", true);
        }

        if(showDialog) {
            let noun = nodes.length > 1 ? "Nodes" : "Node";
            BasicAlert.show(`Adding ${nodes.length} ${noun}`, `Adding ${noun}`, false);
        }

        this.props.manager.disableDragFromNavTree(nodes);

        // Loops through two lists:
        // 1) retrieves new nodes from db
        // 2) looks for active diagram nodeModels
        for(let node of nodes) {
            const {guid, name, xmiType} = node;

            // if nodeModel exist then add to list.
            // it will get passed into establishLinkWithAllNodes
            let nodeModel = this.findNodeModel(guid);
            if(nodeModel) {
                diagramNodes.push(nodeModel);
                continue;
            }

            let nodeData = this.getOptionNode(guid);
            // fetch data if node doesn't exist yet.
            if(!nodeData || (!nodeData.diagramNodeLoaded && !isDiagramNode(nodeData.guid))) {
              nodeData = await this.props.manager.getNode(node);
            }
            // exit if node doesn't exist after fetch.
            if(!nodeData) continue;

            // create a new nodeModel
            // and place in the diagram
            switch (xmiType) {
                case "conceptual:Entity":
                case "conceptual:Association":
                    nodeModel = new EntityNodeModel({nodeData}, this);
                    break;
                case "platform:View":
                    nodeModel = new ViewNodeModel({nodeData}, this);
                    break;
                default:
                    continue;
            }
            
            if (!nodeModel) break;
            if(absolutePos && expanded === false) {
                var point = {x: pos[0], y: pos[1]};
            }
            else if(absolutePos && expanded === true) {
                    var point = this.findNearestEmptySpace(pos[2]);
            } else {
                var point = this.engine.getRelativePoint(pos[0], pos[1]);
            }

            // if user dragged in multiple nodes at once. Need to set new coords for next nodes
            const prevNode = diagramNodes[diagramNodes.length - 1];
            if(!prevNode) {
                const {x, y} = createNewNodeCoords(point.x, point.y, draggedIn);
                nodeModel.setPosition(x, y);
            } else {
                const {x, y} = createNewNodeCoords(prevNode.getX() + nodeProps.width, prevNode.getY(), false);
                nodeModel.setPosition(x, y);
            }

            if(nodeModel) diagramNodes.push(nodeModel);
            this.model.addNode(nodeModel);
            this.forceUpdate();
        }

        if (showDialog) {
            BasicAlert.hide();
        }

        this.props.manager.enableDragFromNavTree(nodes);

        this.engine.repaintCanvas();
        this.establishLinkWithAllNodes(diagramNodes);
        this.resizeCanvas();
        setTimeout(() => {
            const targetNode = diagramNodes[diagramNodes.length - 1];
            
            if (targetNode){
                this.model.clearSelection();
                targetNode.setSelected(true);
            }
        }, 0);
        return diagramNodes.length > 1 ? diagramNodes : diagramNodes[0];
    };

    createSpecializationConnection = (srcNodeModel, dstNodeModel, outPos=2, inPos=0) => {
        const srcData = srcNodeModel.getNodeData();
        const dstData = dstNodeModel.getNodeData();
        const srcPortOptions = {specializes: dstData.guid};
        const dstPortOptions = {specializedBy: srcData.guid};

        srcNodeModel.establishLink(srcData, dstNodeModel, null, srcPortOptions, dstPortOptions);
    }

    establishLinkWithAllNodes = (addedNodeModels=[]) => {
      const addedGuids = addedNodeModels.map(nodeModel => nodeModel.getNodeData().guid)

      // Loop through the new Nodes
      addedGuids.forEach((addedGuid, idx) => {
        const addedNode = this.getOptionNode(addedGuid);

        switch(addedNode.xmiType) {
          case "conceptual:Association":
          case "conceptual:Entity":
            addedNode.children.forEach((child) => {
              if(!child.type) return;

              const typeGuid = typeof child.type === 'string' ? child.type : child.type.guid;
              if(this.props.tabData.activeIds.has(typeGuid)) {
                const existingNodeModel = this.findNodeModel(typeGuid);
                addedNodeModels[idx].establishLink(child, existingNodeModel);
              }
            })
          break;
        }
      });

      // Loop through active Nodes (already exist in diagram)
      [...this.props.tabData.activeIds].filter(guid => !addedGuids.includes(guid)).forEach((activeGuid, idx) => {
        const activeNode = this.getOptionNode(activeGuid);

        switch(activeNode.xmiType) {
          case "conceptual:Association":
          case "conceptual:Entity":
            activeNode.children.forEach((child) => {
              if(!child.type) return;
              const typeGuid = typeof child.type === 'string' ? child.type : child.type.guid;
              const addedGuidIdx = addedGuids.findIndex(g => g === typeGuid);

              if(addedGuidIdx > -1) {
                const activeNodeModel = this.findNodeModel(activeGuid);
                activeNodeModel.establishLink(child, addedNodeModels[addedGuidIdx]);
              }
            })

            if(activeNode.specializes) {
              const specializedGuid = typeof activeNode.specializes === 'string' ? activeNode.specializes : activeNode.specializes.guid;
              const addedGuidIdx = addedGuids.findIndex(g => g === specializedGuid);
              if(addedGuidIdx > -1) {
                const activeNodeModel = this.findNodeModel(activeGuid);
                this.createSpecializationConnection(activeNodeModel, addedNodeModels[addedGuidIdx]);
              }
            }

            if(Array.isArray(activeNode.specializedBy)) {
              const specializedByGuids = activeNode.specializedBy.map((el) => typeof el === 'string' ? el : el.guid);
              specializedByGuids.forEach((specializedByGuid) => {
                const addedGuidIdx = addedGuids.findIndex(g => g === specializedByGuid);
                if(addedGuidIdx > -1) {
                  const activeNodeModel = this.findNodeModel(activeGuid);
                  this.createSpecializationConnection(addedNodeModels[addedGuidIdx], activeNodeModel);
                }
              })
            }

            break;
        }
      })
    }



    /*
     * HELPER
     */
    findNodeModel = (guid) => {
        return this.engine.getModel().getNodes().find(node => node.getNodeData()?.guid === guid);
    }

    // returns nodeModel that has a child with the given guid
    findNodeModelWithChildren = (guid) => {
        let nodeModels = this.engine.getModel().getNodes();
        let foundNodeModel = [];
        nodeModels.forEach(nodeModel => {
            const nodeData = nodeModel.getNodeData();

            if (nodeData.children.length){
                const children = [...nodeData.children];
                children.forEach(child => {
                    if (child.type === guid || child.guid == guid){
                        foundNodeModel = [...foundNodeModel, nodeModel];
                    }})
                }})

        return foundNodeModel;
    }

    // checks if the point has node collision on canvas
    isNodeCollision = (targetX, targetY, direction) => {
        const nodes = this.engine.getModel().getNodes();
        let xOffset = 75;
        let yOffset = 75;
        const x = targetX;
        const y = targetY;
        let nodesPos = [];
        let flag = true;

        //get nodes coordinates
        nodes.forEach(node => {
            nodesPos.push(node.getBoundingBox())
        })

        nodesPos.forEach(rect => {
            // get the differing x and y points
            const x1 = rect.points[0].x;
            const y1 = rect.points[0].y;
            const x2 = rect.points[1].x;
            const y2 = rect.points[2].y;

            //calcualte min/maxes for boundaries
            let minX = Math.min(x1, x2)
            let maxX = Math.max(x1, x2)
            let minY = Math.min(y1, y2)
            let maxY = Math.max(y1, y2)

            // include offset for spacing
            if (direction === "left"){
                xOffset += 100;
            }
            if (direction === "above"){
                yOffset += 125;
            }

            minX -= xOffset;
            maxX += xOffset;
            minY -= yOffset;
            maxY += yOffset;
            
            //check if out of bounds
            if(x < 0 || y < 0){
                flag = false;
            }

            // check x range and y range
            if(x > minX && x < maxX && y > minY && y < maxY){
                flag = false;
            }
        });
        return flag;
    }

    // finds the nearest empty space on the diagram given the bounding box of a node
    findNearestEmptySpace = (targetRectArray) => {
        const rect = {...targetRectArray};
        let spaceFound = false;
        let increment = 50;
        
        // get the differing x and y points
        const x1 = rect.points[0].x;
        const y1 = rect.points[0].y;
        const x2 = rect.points[1].x;
        const y2 = rect.points[2].y;

        //calcualte min/maxes for boundaries
        let minX = Math.min(x1, x2)
        let maxX = Math.max(x1, x2)
        let minY = Math.min(y1, y2)
        let maxY = Math.max(y1, y2)

        while(spaceFound === false){
            //check right
            spaceFound = this.isNodeCollision(maxX + increment, minY);
            if (spaceFound === true){
                return {x: maxX + increment - 50, y: minY}
            }
            //check below
            spaceFound = this.isNodeCollision(minX, maxY + increment - 50);
            if (spaceFound === true){
                return {x: minX - 50, y: maxY + increment - 50}
            }
            //check left
            spaceFound = this.isNodeCollision(minX - increment - 400, minY, "left");
            if (spaceFound === true){
                return {x: minX - increment - 450, y: minY}
            }
            //check above
            spaceFound = this.isNodeCollision(minX, minY - increment - 200, "above");
            if (spaceFound === true){
                return {x: minX - 50, y: minY - increment - 150}
            }
            increment += 100;
        }
    }

    // used to force all nodes to update
    forceNodesToUpdate = () => {
        this.engine.model.getNodes().forEach(nodeModel => {
            nodeModel.restoreNodeSize();
            nodeModel.forceWidgetToUpdate(false);
        });
        ReactTooltip.rebuild();
        this.engine.repaintCanvas();
        this.forceUpdate();
    };

    forceLinksToUpdate = () => {
        this.engine.model.getLinks().forEach(linkModel => {
            linkModel.forceLinkToUpdate();
        });
    }

    deleteNodes = (nodeModels=[], showDialog=true) => {
        // In PathBuilder mode
        if(this.state.modeAction === "select-a-projChar" || this.state.modeAction === "select-a-hop") {
            return BasicAlert.show("You are trying to remove a node. Please finish building the path and try again later.", "Path Builder", true);
        }

        if (this.props.manager.state.settings.showRemovalWarning && showDialog) {
            BasicConfirm.show(`Are you sure you want to remove ${nodeModels.length} ${nodeModels.length > 1 ? "items" : "item"} from the diagram?`, () => {
                nodeModels.forEach(model => model.remove());
                this.engine.repaintCanvas();
            })
        } else {
            nodeModels.forEach(model => model.remove());
            this.engine.repaintCanvas();
        }
    }

    // deletes a nodeModels child if the type matches the given guid
    deleteAssociationChildren = (nodeModels=[], guids=[]) => {
        if(this.state.modeAction === "select-a-projChar" || this.state.modeAction === "select-a-hop") {
            return;
        }

        guids.forEach(guid => {
            nodeModels.forEach(nodeModel => {
                const nodeData = nodeModel.getNodeData();
                nodeData.children = nodeData.children.filter(child => child.type !== guid)
            })})
    }

    // used by Manager and context menu from NavTree
    resetNodes = async (guids=[]) => {
      for(let guid of guids) {
        const nodeModel = this.findNodeModel(guid);
        if(!nodeModel) continue;
        const nodeData = nodeModel.getNodeData();
        if(isDiagramNode(nodeData.guid)) continue;

        // removes all ports (except real comp out-ports)
        for(let portGuid of Object.keys(nodeModel.getOutPorts())) {
          const portGuids = portGuid.split("--");
          if(portGuids[1] || isDiagramNode(portGuids[0])) {
            await nodeModel.removeDiagramLink(portGuid);
          }
        }

        // removes all ports (except real comp in-ports)
        for(let portGuid of Object.keys(nodeModel.getInPorts())) {
          const portGuids = portGuid.split("--");
          if(portGuids[1] || isDiagramNode(portGuids[0])) {
            await nodeModel.removeDiagramLink(portGuid);
          }
        }

        // remove all diagram attrs
        for(let i = nodeData.children.length - 1; i >= 0; i--) {
            const attr = nodeData.children[i];
            if(isDiagramNode(attr.guid)) {
              nodeData.children.splice(i, 1);
            }
        }
        nodeModel.forceWidgetToUpdate(false);
      }
    }

    removeDiagramAttr = async (nodeModel, attrGuid) => {
      if(!nodeModel || !attrGuid || !isDiagramNode(attrGuid)) return;
      const nodeData = nodeModel.getNodeData();

      const attrOutPorts = Object.keys(nodeModel.getOutPorts()).filter(key => key.startsWith(attrGuid));
      const idx = nodeData.children.findIndex(child => child.guid === attrGuid);

      if(idx > -1) {
        for(let portGuid of attrOutPorts) {
          await nodeModel.removeDiagramLink(portGuid);
        }

        nodeData.children.splice(idx, 1);
        nodeModel.forceWidgetToUpdate(false);
      }
    }

    deleteNodeChild = async (attr, parentNodeModel, forceDelete=false) => {
        if(!attr || !attr.guid || !parentNodeModel) return;
        const parentData = parentNodeModel.getNodeData();
        const viewMap = {};

        // Exit if in pathBuilder mode
        if(this.state.modeAction === "select-a-projChar" || this.state.modeAction === "select-a-hop") {
            return BasicAlert.show("You are trying to remove an attribute. Please finish building the path and try again later.", "Path Builder", true);
        }

        // Exit if attr has specialization
        if(attr.specializes) {
            return BasicAlert.show(<div>Cannot <span style={{fontWeight: 600, color: "red"}}>delete</span> this attribute because it contains a specialization</div>, "Warning", true);
        }

        BasicAlert.show("One moment please. Checking deletable status.", "Processing", false);

        // Entity Only
        if(parentData.xmiType === "conceptual:Entity" || parentData.xmiType === "conceptual:Association") {
            // Check for Real Projections (for Entities)
            if(!isDiagramNode(parentData.guid)) {
                const res = await $.ajax({
                    url: "/index.php?r=/entity/page-data",
                    method: "get",
                    data: {
                        guid: parentData.guid,
                    },
                });

                const usageData = JSON.parse(res);
                const usageChild = usageData.children.find(c => c.guid === attr.guid);

                if(usageChild && usageChild.projectors) {
                    usageChild.projectors.forEach(view => {
                        if(!viewMap[view.guid]) viewMap[view.guid] = view;
                    })
                }
            }

            // Check for Diagram Projections (inPorts has a variable that matches attr's guid)
            Object.values(parentNodeModel.getInPorts()).forEach((portName) => {
                const port = parentNodeModel.getPort(portName);
                if(!port) return;

                if(port.options.targetAttrGuid === attr.guid) {
                    const { pathData={} } = port.options;

                    if(pathData.viewNodeData) {
                        let viewNode = pathData.viewNodeData;
                        viewMap[viewNode.guid] = viewNode;
                    }
                }
            })
        }

        // *** Show error if Projection exist ***
        if(Object.keys(viewMap).length) {
            return showCannotRemoveBcProjection(viewMap, attr);
        }

        // Out Ports with current attr
        const attrOutPorts = Object.keys(parentNodeModel.getOutPorts()).filter(key => key.startsWith(attr.guid));

        BasicAlert.hide();

        if(isDiagramNode(attr.guid)) {
            if(this.props.manager.state.settings.showRemovalWarning) {
              showRemovalConfirm(attr, () => this.removeDiagramAttr(parentNodeModel, attr.guid));
            } else {
              this.removeDiagramAttr(parentNodeModel, attr.guid);
            }
        } else {
            showRemovalConfirm(attr, async (isDeletable) => {
              attrOutPorts.forEach(portGuid => {
                parentNodeModel.removeDiagramLink(portGuid);
              })

              if(isDeletable) {
                  const result = await modelRemoveNode(attr.guid);
                  if(result) {
                      const childIdx = parentData.children.findIndex(c => c.guid === attr.guid);
                      if(childIdx > -1) parentData.children.splice(childIdx, 1);
                  }
              } else {
                  const res = await modelDeprecateNode(attr.guid);
                  if(res === "#true") {
                      attr.deprecated = "true";
                  }
              }

              parentNodeModel.sortChildrenByType();
              parentNodeModel.forceWidgetToUpdate();
            })
        }
    }

    resizeCanvas = () => {
      const zoom = this.model.getZoomLevel() / 100;

      if(zoom > 1) {
          var scroll_width = Math.floor(this.engine.canvas.scrollWidth / zoom);
          var scroll_height = Math.floor(this.engine.canvas.scrollHeight / zoom);
      } else {
          var scroll_width = this.engine.canvas.scrollWidth;
          var scroll_height = this.engine.canvas.scrollHeight;
      }

      if(scroll_width > this.engine.canvas.clientWidth) {
          this.engine.canvas.style.width = (scroll_width + 1000) + "px";
      }

      if(scroll_height > this.engine.canvas.clientHeight) {
          this.engine.canvas.style.height = (scroll_height + 500) + "px";
      }
    }

    revealInTree = (guid) => {
      // const guid = this.nodeModel.getNodeData().guid;
      window["treeRef"].searchLeafByGuid(guid);
    };



    /*
     * CLICK COMMANDS
     */
    _handleContextMenu = async (e, items) => {
        // const offSet = {left: e.clientX, top: e.clientY};
        const offSet = {left: e.pageX, top: e.pageY};
        const menuItems = items || {};
        this.ctxMenuRef.show(menuItems, offSet);
    };

    _handleContextClick = (event) => {
        if (this.ctxMenuRef !== undefined) {
            const showContextMenu = this.ctxMenuRef.state.visible;
            const wasOutside = event.target.contains !== this.ctxMenuRef;
            if (wasOutside && showContextMenu) this.ctxMenuRef.close();
        }
    };

    _closeContextMenu = () => {
        if (this.ctxMenuRef) {
            this.ctxMenuRef.close();
        }
    };



    /*
     * ===================
     * PATH BUILDER
     * ===================
     */
    getAeEffectiveType = (ae) => {
        return ae.effectiveType || ae.type;
    }

    handlePathUndo = async () => {
        const { builder, viewData, pathPairs=[], } = this.state.pathBuilder;

        if(!builder.length) return;
        const projCharData = builder[0];
        const latestElement = builder[builder.length - 1];
        const latestTargetPort = latestElement.inPort;
        const latestPair = pathPairs[pathPairs.length - 1];

        modifiedPairsForward(pathPairs, projCharData.targetNodeData.guid);

        // remove link from ViewNode to ProjChar
        if(!latestPair) {
          this.pathInstructionsIdx = 0;
          viewData.nodeModel.removeLink(projCharData.portGuid);
          this.setState({
            mode: "projection",
            modeAction: "select-a-projChar",
            selectingHop: "",
          }, () => {
            viewData.nodeModel.removeLink(latestElement.portGuid);
            builder.pop();
            this.forceNodesToUpdate();
            this.forceLinksToUpdate();
          });

        } else {
          const latestTypeNode = this.getOptionNode(latestPair.type);

          // removes the last hop - ending on type Observable
          if(latestTypeNode.xmiType === "conceptual:Observable") {
            latestTargetPort.removeTargetAttrGuid();
            pathPairs.pop();
            this.forceNodesToUpdate();
            this.forceLinksToUpdate();

          // removes the link and the last hop
          } else {
            this.removeHop(latestPair, latestElement.portGuid);
            builder.pop();
            pathPairs.pop();

            const sLatestElement = builder[builder.length - 1];
            this.setState({
              "selectingHop": sLatestElement.targetNodeData.guid
            }, () => {
              this.forceNodesToUpdate();
              this.forceLinksToUpdate();
            });
          }
        }
        this.forceUpdate();
    }

    handlePathCancel = async () => {
        const { builder, viewData, rebuildPathLinksOnCancel, } = this.state.pathBuilder;

        while(builder.length) {
          await this.handlePathUndo();
        }

        if(rebuildPathLinksOnCancel && viewData.childData.pathPairs?.length) {
            await this.buildExistingPath(viewData.childData, viewData.childData.pathPairs, false);
            return;
        }

        this.stopPathBuilder();
    }

    // Started by ViewNode
    startPathBuilder = async (viewNodeModel, viewChild) => {
        if(!viewNodeModel || !viewChild) return;

        const { viewData, strokeData } = this.state.pathBuilder;

        // remove existing path arrows if it exist
        if(Object.keys(viewNodeModel.getOutPorts()).some(portGuid => portGuid.split("--")[1] === viewChild.guid)) {
            this.state.pathBuilder.rebuildPathLinksOnCancel = true;
            await viewNodeModel.removePathLinks(viewChild);
            this.forceUpdate();
        }

        // 0) set instruction text
        this.pathInstructionsIdx = 0;

        // 1) store View data
        viewData.nodeModel = viewNodeModel;
        viewData.nodeData = viewNodeModel.getNodeData();
        viewData.childData = viewChild;

        // 2) create Stroke details
        strokeData.strokeColor = randomColor();
        strokeData.strokeDashSize = Math.floor(Math.random() * Math.floor(20) + 10);      // num between 20 and 30
        strokeData.strokeDashOffset = Math.floor(Math.random() * Math.floor(15) + 5);     // num between 5 and 20

        // 3) switch to path building mode
        this.setState({
            mode: "projection",
            modeAction: "select-a-projChar"
        }, () => {
          this.changeHeaderColor();
          this.forceNodesToUpdate();
          this.forceLinksToUpdate();
        });
    }

    stopPathBuilder = () => {
        this.setState({
            modeAction: "",
            selectingHop: "",
            pathBuilder: cloneDeep(this.defaultPathBuilder)
        }, () => {
          this.forceNodesToUpdate();
          this.forceLinksToUpdate();
        });
    }

    buildExistingPath = async (existingViewChild, newPathPairs, showPrimitiveDialog=true) => {
        // 0) requirement: startPathBuilder or storePathViewData needs to be called before this function
        // used by ViewNode, PathLink, and HandlePathCancel
        await this.selectProjChar(existingViewChild.projectedCharacteristic);

        for(let pair of newPathPairs) {
            await this.selectHop(pair, showPrimitiveDialog);
        }
    }

    selectPrimitive = (observable) => {
        const { builder, viewData, pathPairs, } = this.state.pathBuilder;
        const projCharData = builder[0];
        const viewNodeModel = viewData.nodeModel;
        const viewNodeData = viewData.nodeData;
        const viewChild = viewData.childData;
        const copy = cloneDeep(viewChild);

        if(!copy.rolename){
            let newName = observable.name[0].toLowerCase() + observable.name.substring(1);
            let countDupName = viewData.nodeData.children.reduce((total, curr) => curr.rolename && curr.rolename.startsWith(newName) ? total + 1 : total, 0);
            if(countDupName > 0) newName = newName + "_" + countDupName;
            copy["rolename"] = newName;
        }

        // Clear selection - while modal is displayed, the delete key will try to delete the node.
        this.engine.getModel().clearSelection();

        this.props.manager.charModalRef.show(copy, observable, false,
            // confirm func
            async (newViewChild) => {
                viewChild.pathPairs = pathPairs;
                viewChild.path = pathPairs.map(pair => pair.guid).join(" ");
                viewChild.projectedCharacteristic = projCharData.targetNodeData.guid;

                Object.entries(newViewChild).forEach(([key, val]) => {
                    viewChild[key] = val;
                })

                if(viewChild.isPlaceholder) {
                    viewChild.isPlaceholder = false;
                    viewNodeData.children.push(viewChild);

                    if(viewNodeModel.$widget) {
                        viewNodeModel.$widget.createNewPlaceholder();
                    }
                }

                this.stopPathBuilder();
                await viewNodeModel.forceWidgetToUpdate(false);
                this.forceNodesToUpdate();
                this.forceLinksToUpdate();
                this.engine.repaintCanvas();
            },
            () => {
                this.handlePathUndo();
            })
    }

    // Selected by EntityNode
    selectProjChar = async (projCharGuid) => {
        if(!projCharGuid) return;

        const continuePathBuilder = async () => {
          const { builder, viewData, strokeData, } = this.state.pathBuilder;

          const nodeWidth = viewData.nodeModel.width || nodeProps.width;
          const projChar = this.getOptionNode(projCharGuid);
          const pathData = {
            viewNodeData: viewData.nodeData,
            viewChild: viewData.childData,
            strokeData,
            goingUp: false,
            pathPos: 0,
          }

          // 1) add ProjChar to diagram (and create a link from View to ProjChar)
          const projCharNodeModel = await this.addNodes(projChar, [viewData.nodeModel.getX() + nodeWidth, viewData.nodeModel.getY(), viewData.nodeModel.getBoundingBox()], true, false, false, true);
          const { targetPort } = viewData.nodeModel.establishPathLink(viewData.childData, pathData, projCharNodeModel);

          // 2) store ProjectedChar data
          builder.push({
            type: "projChar",
            targetNodeModel: projCharNodeModel,
            targetNodeData: projChar,
            portGuid: createPathPortGuid(viewData.childData, pathData),
            inPort: targetPort,
            pathData,
          })

          // 2) change instructions
          this.pathInstructionsIdx = 1;

          // 3) begin path building
          this.setState({
              modeAction: "select-a-hop",
              selectingHop: projChar.guid
          }, () => {
            this.forceNodesToUpdate();
            this.forceLinksToUpdate();
          });
        }

        /***************************************************
          PAUSE - node must be committed before continuing
        ****************************************************/
        if(isDiagramNode(projCharGuid)) {
            this.props.manager.showDialogSingle(projCharGuid, continuePathBuilder);
        } else {
            await continuePathBuilder();
        }
    }

    selectHop = async (attr, showModal=true) => {
        try{
            if(!attr || !attr.type) throw "error";
            
            const { builder, viewData, strokeData,  pathPairs, } = this.state.pathBuilder;
            const projCharData = builder[0];
            const latestElement = builder[builder.length - 1];
            const latestNodeModel = latestElement.targetNodeModel;
            const latestTargetPort = latestElement.inPort;

            const continuePathBuilder = async () => {
                const parentGuid = typeof attr.parent === "string" ? attr.parent : attr.parent.guid;
                const parentNodeData = this.getOptionNode(parentGuid);
                const effectiveTypeGuid = typeof attr.effectiveType === "string" ? attr.effectiveType : attr.effectiveType?.guid;
                const typeGuid = effectiveTypeGuid ? effectiveTypeGuid
                                                   : typeof attr.type === "string" ? attr.type : attr.type?.guid;

                // need to override type with effectiveType if it exist
                const copy = { ...attr, type: typeGuid }
                const hop = formatPathAttr(copy, parentNodeData);
                
                const typeNodeData = this.getOptionNode(typeGuid);
                // 1) assign "going up" or "going down" data
                pathPairs.push(hop);
                modifiedPairsForward(pathPairs, projCharData.targetNodeData.guid);

                // 2) change path arrow's position to point to selected hop
                latestTargetPort.addTargetAttrGuid(hop.guid);

                // 3) ending hop - exit
                if (typeNodeData.xmiType === "conceptual:Observable") {
                  if(showModal) {
                      this.selectPrimitive(typeNodeData);
                  } else {
                      this.stopPathBuilder();
                      this.forceNodesToUpdate();
                      this.forceLinksToUpdate();
                  }
                  return;
                }

                // 4) add hop to diagram and create link from latestNodeModel to hop's parent/type
                const coords = [latestNodeModel.getX() + nodeProps.width, latestNodeModel.getY(), latestNodeModel.getBoundingBox()];
                const pathData = {
                  viewNodeData: viewData.nodeData,
                  viewChild: viewData.childData,
                  strokeData,
                  goingUp: hop.goingUp,
                  pathPos: pathPairs.length - 1,
                }

                if (hop.goingUp) {
                  var selectedNodeData = parentNodeData;
                  var selectedNodeModel = await this.addNodes(parentNodeData, coords, true, false, false, true);
                  var { targetPort } = selectedNodeModel.establishPathLink(hop, pathData, latestNodeModel);

                } else {
                  var selectedNodeData = typeNodeData;
                  var selectedNodeModel = await this.addNodes(typeNodeData, coords, true, false, false, true);
                  var { targetPort } = latestNodeModel.establishPathLink(hop, pathData, selectedNodeModel);
                }

                builder.push({
                  type: "hop",
                  targetNodeModel: selectedNodeModel,
                  targetNodeData: selectedNodeData,
                  hopData: hop,
                  portGuid: createPathPortGuid(hop, pathData),
                  inPort: targetPort,
                  pathData,
                })

                this.setState({
                    selectingHop: selectedNodeData.guid
                }, () => {
                  this.forceNodesToUpdate();
                  this.forceLinksToUpdate();
                });
            }

            /***************************************************
              PAUSE - node must be committed before continuing
            ****************************************************/
            if(isDiagramNode(attr.guid)) {
                const parentGuid = typeof attr.parent === "string" ? attr.parent : attr.parent.guid;

                this.props.manager.showDialogSingle(parentGuid, continuePathBuilder);
            } else {
                await continuePathBuilder();
            }
        } catch(err) {
            const msg = (text) => `The selected attribute is missing a ${text}`;

            if(!attr.type) console.error(msg("type"));
            if(!attr.parent) console.error(msg("parent"));
            if(!attr.rolename) console.error(msg("rolename"));
        }
    }

    removeHop = async (pair, portGuid) => {
        if (pair.goingUp) {
            const parentNodeModel = this.findNodeModel(pair.parent.guid);
            if (parentNodeModel) {
                // const portGuid = createPathPortGuid(pair, {viewChild, goingUp: pair.goingUp});
                parentNodeModel.removeLink(portGuid);
            }

        } else {
            const typeNodeModel = this.findNodeModel(pair.type);
            if (typeNodeModel) {
                // const portGuid = createPathPortGuid(pair, {viewChild, goingUp: pair.goingUp});
                typeNodeModel.removeLink(portGuid);
            }
        }
    }


    handleOnDrop = (event) => {
      const rawTreeData = event.dataTransfer.getData("treeNodes");
      const treeNodes = rawTreeData && JSON.parse(rawTreeData);
      if (treeNodes) {
        return this.addNodes(treeNodes, [event.clientX, event.clientY]);
      }

      const rawStencilData = event.dataTransfer.getData("storm-diagram-node");
      const stencilNode = rawStencilData && JSON.parse(rawStencilData);
      if (stencilNode) {
        return this.addStencilNode(event);
      }
    }

    disableEditing = (disabled) => {
        this.setState({ editable: !disabled })
    }

    renderHeader = () => {
        const isEditable = this.props.canEdit && this.state.editable;

        return (<div id={this.phenomId.genPageId("header")}
                              className="storm-header"
                              onClick={() => {
                                  const selections = this.engine.getModel().getSelectedEntities();
                                  if(selections.length) {
                                      this.engine.getModel().clearSelection()
                                  }
                              }}>
                    <div className="toolbar-container" style={{background: "#f6f6f6", display:"flex", justifyContent:"space-between"}}>
                        <div className="joint-toolbar joint-theme-modern" style={{padding:"16px 10px 5px"}}>
                            <div className="joint-toolbar-group joint-theme-modern" data-group="layout">
                                <button id={this.phenomId.genPageId("clear-graph-btn")}
                                        title="Clear Diagram"
                                        className="joint-widget joint-theme-modern fas fa-eraser"
                                        style={{fontSize:20, justifyContent:"center", border:"none"}}
                                        disabled={!isEditable}
                                        onClick={this.clearGraph} />

                                <button id={this.phenomId.genPageId("auto-layout-btn")}
                                        className="joint-widget joint-theme-modern"
                                        disabled={!isEditable}
                                        onClick={this.autoLayout}>Auto layout
                                </button>
                            </div>
                            <div className="joint-toolbar-group joint-theme-modern" data-group="zoom">
                                {/* <button className="joint-widget joint-theme-modern" data-type="zoomToFit"
                                        onClick={() => this.engine.zoomToFit()}/> */}
                                <label className="joint-widget joint-theme-modern"
                                        data-type="label">Zoom:</label>
                                <div className="joint-widget joint-theme-modern" data-type="zoomSlider">
                                    <i id={this.phenomId.genPageId("zoom-out-btn")}
                                       className="fa fa-minus-circle" style={{marginRight: 5}}
                                       onClick={() => this.updateZoomLevel(this.state.zoomLevel - 5)} />
                                    <input id={this.phenomId.genPageId("zoom-range-slider")}
                                           type="range" ref={this.zoomSlider} min="20" max="500" step="5"
                                           className="input" onChange={(e) => this.updateZoomLevel(e.target.value)}/>
                                    <output id={this.phenomId.genPageId("zoom-out-percentage")}>{this.state.zoomLevel}</output>
                                    <span className="units" style={{marginRight: 5}}> %</span>
                                    <i id={this.phenomId.genPageId("zoom-in-btn")}
                                       className="fa fa-plus-circle" aria-hidden="true"
                                       onClick={() => this.updateZoomLevel(this.state.zoomLevel + 5)} />
                                </div>
                                <div className="joint-widget joint-theme-modern" data-type="separator"/>
                            </div>
                            <div className="joint-toolbar-group joint-theme-modern">
                                <div style={{position:"absolute", top:"-14px", right:0, left:0, fontSize:12, fontStyle:"italic", color:"var(--skayl-gray)", textAlign:"center"}}>
                                    Diagram
                                </div>
                                <button id={this.phenomId.genPageId("load-diagram-btn")}
                                        title="Load Diagram"
                                        className="joint-widget joint-theme-modern fas fa-file-import"
                                        style={{fontSize:20, justifyContent:"center", border:"none"}}
                                        onClick={() => this.props.manager.showDialog("load")} />
                                <button id={this.phenomId.genPageId("save-diagram-btn")}
                                        title="Save Diagram"
                                        className="joint-widget joint-theme-modern fas fa-save"
                                        style={{fontSize:22, justifyContent:"center", border:"none"}}
                                        disabled={!isEditable}
                                        onClick={() => this.props.manager.saveDiagram(this.props.tabId)} />
                                <button id={this.phenomId.genPageId("save-as-diagram-btn")}
                                        title="Save Diagram As"
                                        className="joint-widget joint-theme-modern fa-solid fa-floppy-disks"
                                        style={{fontSize:22, justifyContent:"center", border:"none"}}
                                        disabled={!isEditable}
                                        onClick={() => this.props.manager.showDialog("saveAs")} />
                                <button id={this.phenomId.genPageId("export-canvas-btn")}
                                        title="Export Canvas"
                                        className="joint-widget joint-theme-modern fas fa-file-export"
                                        style={{fontSize:20, justifyContent:"center", border:"none"}}
                                        onClick={() => {
                                            this.props.manager.screenshotDiagram("png")
                                        }} />
                                <div className="joint-widget joint-theme-modern" data-type="separator"/>
                            </div>
                            <div className="joint-toolbar-group joint-theme-modern" data-group="uncommitted" style={{ gap: 5 }}>
                                <div style={{position:"absolute", top:"-16px", right:0, left:0, fontSize:12,  fontStyle:"italic", color:"var(--skayl-gray)", textAlign:"center"}}>
                                    Model
                                </div>
                                <button id={this.phenomId.genPageId("commit-nodes-btn")}
                                        className="joint-widget joint-theme-modern" data-type="button"
                                        disabled={!isEditable}
                                        onClick={() => this.props.manager.showDialog("commit")}>Commit
                                </button>
                            </div>

                            <div className="joint-toolbar-group joint-theme-modern">
                                <div className="joint-widget joint-theme-modern" data-type="separator"/>
                            </div>

                            <div className="joint-toolbar-group joint-theme-modern" style={{fontSize:12}}>
                                <div style={{position:"absolute", top:"-21px", right:0, left:0, fontSize:12,  fontStyle:"italic", color:"var(--skayl-gray)", textAlign:"center"}}>
                                    Connector Visibility
                                </div>
                                <Toggle idCtx={this.phenomId.genPageId("connector-visibility")}
                                        disabled={false}
                                        options={["Modeling", "Projection"]}
                                        startingPosition={this.state.mode === "modelling" ? 0 : 1}
                                        style={{width:80}}
                                        toggleFunction={this.toggleMode} />
                            </div>

                            <div className="joint-toolbar-group joint-theme-modern">
                                <div className="joint-widget joint-theme-modern" data-type="separator"/>
                            </div>

                            <div className="joint-toolbar-group joint-theme-modern" style={{width:"100%", fontSize:12, width:140}}>
                                <div style={{position:"absolute", top:"-21px", right:0, left:0, fontSize:10,  fontStyle:"italic", color:"var(--skayl-gray)", textAlign:"center"}}>
                                  Highlight Uncommitted Changes
                                </div>
                                <Toggle idCtx={this.phenomId.genPageId("uncommitted-status")}
                                        disabled={false}
                                        options={["OFF", "ON"]}
                                        startingPosition={this.state.showUncommitted ? 1 : 0}
                                        style={{width:75, margin:"0 auto"}}
                                        toggleFunction={() => this.setState({showUncommitted: !this.state.showUncommitted}, () => {
                                                                this.forceNodesToUpdate();
                                                                this.forceLinksToUpdate();
                                                              })} />
                            </div>
                        </div>
                        <div className="joint-toolbar joint-theme-modern">
                            <StyledIcon id={this.phenomId.genPageId("extra-options-btn")}
                                        cog
                                        style={{fontSize: 22}}
                                        onClick={() => this.cogRef.toggleDisplay()} />
                            <CogOptions ref={el => this.cogRef = el} $manager={this.props.manager} />
                        </div>
                    </div>
                </div>)
    }

    renderStencilBox = () => {
        return(<StyledStencilBar id={this.phenomId.genPageId("stencil-box")} className="stencil-box">
                <Stencil id={this.phenomId.genPageId("stencil-entity")} model={{type: "conceptual:Entity"}} name="Entity" color={nodeColors["conceptual:Entity"]}/>
                <Stencil id={this.phenomId.genPageId("stencil-assoc")} model={{type: "conceptual:Association"}} name="Association" color={nodeColors["conceptual:Association"]}/>
                {/* <Stencil model={{type: "uop"}} name="UoP" color={nodeColors["uop:PortableComponent"]}/> */}
                <Stencil id={this.phenomId.genPageId("stencil-view")} model={{type: "platform:View"}} name="View" color={nodeColors["platform:View"]}/>
                <Stencil type="blank"/>
                <Stencil id={this.phenomId.genPageId("stencil-text-box")} model={{type: "textbox"}} name="Text Box" color="var(--skayl-black)"/>
                <Stencil id={this.phenomId.genPageId("stencil-arrow")} model={{type: "arrow"}} name="Arrow" color="var(--skayl-black)"/>
                <Stencil id={this.phenomId.genPageId("stencil-line")} model={{type: "line"}} name="Line" color="var(--skayl-black)"/>
                <Stencil id={this.phenomId.genPageId("stencil-box")} model={{type: "box"}} name="Box" color="var(--skayl-black)"/>
            </StyledStencilBar>)
    }

    renderToolTips = () => {
        return(<>
            <ReactTooltip id='diagramTip' delayShow={1000} getContent={(el) => {
              if(!el) return;
              return <div style={{maxWidth:300}}>{el}</div>
            }}/>
            <ReactTooltip id='hoverLeftTip' delayShow={1000} place="left" getContent={() => {}}/>
            <ReactTooltip id='errTip' type="error" getContent={() => {}}/>
        </>)
    }

    // Note: AnchoredPath modifies the pathPairs (adds goingUp)
    renderPathBuilderHeader = () => {
        let pathText = "";
        if (this.state.pathBuilder.pathPairs.length && this.state.pathBuilder.projCharNodeData) {
            pathText = generateTextPath({pathPairs: this.state.pathBuilder.pathPairs, pathHeadGuid: this.state.pathBuilder.projCharNodeData.guid}, this);
            // pathText = AnchoredPath({pathPairs: this.state.pathBuilder.pathPairs, pathHead: this.state.pathBuilder.projChar.name, txtOnly: true})
        }

        return (
            <PathBuilder>
                <PathText>
                    <div style={{fontStyle:"italic", marginBottom:5}}>{pathInstructions[this.pathInstructionsIdx]}</div>
                    <div style={{fontWeight: 600}}>Path Builder: {pathText}</div>

                    <div style={{display:"flex", position:"absolute", top:0, right:20, textAlign:"center"}}>
                        <div style={{marginRight:8}}>
                            <StyledIcon undo style={{fontSize:26}} onClick={this.handlePathUndo} />
                            <div style={{fontSize:14, fontStyle:"italic"}}>Undo</div>
                        </div>
                        <div>
                            <StyledIcon cancel style={{fontSize:26}} onClick={this.handlePathCancel} />
                            <div style={{fontSize:14, fontStyle:"italic"}}>Cancel</div>
                        </div>
                    </div>
                </PathText>
            </PathBuilder>
        )
    }

    render() {
        let canvasClasses = ["srd-demo-canvas", "svg-on-top"];
        if(this.state.showUncommitted) canvasClasses.push("show-uncommitted");
        canvasClasses.push(this.state.mode);
        canvasClasses.push(this.state.modeAction);

        return (<React.Fragment>
            <div className="storm-diagram">
                {this.renderHeader()}

                {(this.state.modeAction === "select-a-projChar" || this.state.modeAction === "select-a-hop") &&
                        this.renderPathBuilderHeader()}

                {this.renderStencilBox()}

                <div className="storm-canvas">
                    <GraphContainer
                            id={this.phenomId.genPageId("graph-container")}
                            data-scratchpad={this.props.tabId}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={this.handleOnDrop}
                            onClick={this._handleContextClick}
                            ref={el => this.containerDOM = el}>
                        <FadingDirections idCtx={this.phenomId.genPageId()} text="Use the stencil container to mock-up entities."/>
                        <CanvasWidget className={canvasClasses.join(" ")} engine={this.engine} smartRouting={true} />
                    </GraphContainer>
                </div>

                <div className="storm-sidebar">
                    <SidePanel idCtx={this.phenomId.genPageId("side-panel")} 
                               $app={this} 
                               contextState={this.getContextState()}
                               canEdit={this.props.canEdit}
                               ref={this.sidePanelRef} />
                </div>

            </div>

            {this.renderToolTips()}

            <ContextMenu ref={(el) => this.ctxMenuRef = el}/>
        </React.Fragment>);
    }
}

class CustomDeleteItemsAction extends Action {
    constructor(options = {}) {
        options = {
            keyCodes: [46],
            ...options
        };

        super({
            type: InputType.KEY_DOWN,
            fire: (event) => {
                if (options.keyCodes.indexOf(event.event.keyCode) !== -1) {
                    const selectedEntities = this.engine.getModel().getSelectedEntities();
                    // TODO: the following line needs to be calculated differently
                    if (selectedEntities.find(e => e.isLocked())) return;

                    if(selectedEntities.length === 1 && selectedEntities[0] instanceof DefaultLinkModel) {
                        // In PathBuilder mode
                        if(this.engine.$app.state.modeAction === "select-a-projChar" || this.engine.$app.state.modeAction === "select-a-hop") {
                            return BasicAlert.show("You are trying to remove a path connector. Please finish building the path and try again later.", "Path Builder", true);
                        }

                        const selected = selectedEntities[0];
                        if(selected.$widget) {
                            selected.$widget.deleteLink();
                        }
                    } else {
                        if(selectedEntities.length) this.engine.$app.deleteNodes(selectedEntities);
                    }

                    ReactTooltip.hide();
                }
            }
        });
    }
}



class CustomMouseUpAction extends Action {
  constructor() {
    super({
      type: InputType.MOUSE_UP,
      fire: (e) => {
        const {event} = e;
        const element = this.engine.getMouseElement(event);
        if(!element || event.button !== 2) return;

        const offset = {left: event.pageX + 5, top: event.pageY};
        const { $widget } = element;
        const { $app } = this.engine;
        const { ctxMenuRef } = $app;
        const menuItems = [];

        // EntityNode, ViewNode
        if(element instanceof DiagramNodeModel) {
          const guid = element.getNodeData().guid;

          menuItems.push({
            text: "Edit",
            func: () => $widget.toggleEditMode(true),
          });

          menuItems.push({
            text: "Remove",
            func: () => $app.deleteNodes([element]),
          });

          if(!isDiagramNode(guid)) {
            menuItems.push({
              text: "Reveal in tree",
              func: () => $app.revealInTree(guid),
            });
          }

          menuItems.push({
            text: "Show all attributes",
            func: () => element.toggleHiddenAttributeGuids(true),
          });


          switch(element.options.type) {
            case "entity-node":
              menuItems.push({
                text: "Show only diagram compositions",
                func: element.showOnlyAttributesWithActivePorts,
              })

              menuItems.push({
                text: `${element.getSettings().showCompositionType ? "Hide" : "Show"} types`,
                func: element.toggleShowCompositionType,
              })

              if($app.state.mode === "modelling") {
                const showUsage = $app.sidePanelRef.current.state.showUsage;
                menuItems.push({
                  text: `${showUsage ? "Hide" : "Show"} usage`,
                  func: $app.sidePanelRef.current.toggleUsage,
                })
              }
          }
        }

        // Composition connectors
        else if (element instanceof CompositionLinkModel) {
          const { sourcePort, targetPort } = element;
          const srcData = sourcePort.getAttrData();

          if(srcData && srcData.xmiType === "conceptual:AssociatedEntity") {
            menuItems.push({
              text: "Edit",
              func: () => sourcePort.parent.$widget.openAssociationModal(srcData)
            });
          }

          // keeps the attribute, removes the connector and ports
          menuItems.push({
            text: "Remove",
            func: $widget.deleteLink,
          })

          if(!srcData.specializes) {
            // removes the attribute, port and connector
            menuItems.push({
              text: "Delete",
              func: () => $app.deleteNodeChild(srcData, sourcePort.parent)
            })
          }
        }

        // render context menu
        if(menuItems.length) {
          ctxMenuRef.show(menuItems, offset);
        }
      }
    })
  }
}

class CustomMouseDownAction extends Action {
    constructor() {

        super({
            type: InputType.MOUSE_DOWN,
            fire: (e) => {
                const {event} = e;
                const element = this.engine.getMouseElement(event);

                if(this.engine.$app.cogRef.state.visible) {
                    this.engine.$app.cogRef.close();
                }

                if(element) return;

                switch(event.button) {
                    case 0:
                        // this.startSelectionDrag(event);
                        break;
                    case 2:
                        this.startCanvasDrag(event);
                        break;
                }
            }
        });

        this.start_point = {};
        this.curr_point = {};
    }

    // =================================
    // SELECTION DRAG
    // =================================
    startSelectionDrag = (e) => {
        // create selection window if and only if nothing was selected

        // const model = this.engine.getModel();
        this.engine.model.clearSelection();
        this.start_point = this.engine.getRelativeMousePoint(e);

        window.addEventListener("mousemove", this.resizeSelectionWindow);
        window.addEventListener("mouseup", this.makeSelections);
        window.addEventListener("mouseup", this.selectionCleanUp);
    }


    resizeSelectionWindow = (e) => {
        const { $app } = this.engine;
        const zoom = this.engine.getModel().getZoomLevel() / 100;

        this.curr_point = this.engine.getRelativeMousePoint(e);

        let width = Math.abs(this.curr_point.x - this.start_point.x) * zoom;
        let height = Math.abs(this.curr_point.y - this.start_point.y) * zoom;

        let left = this.curr_point.x < this.start_point.x ? this.curr_point.x : this.start_point.x;
        let top = this.curr_point.y < this.start_point.y ? this.curr_point.y : this.start_point.y;

        $app.setState({
            selectionRect: {
                top: top * zoom,
                left: left * zoom,
                width,
                height
            }
        })
    }

    selectionCleanUp = () => {
        const { $app } = this.engine;

        $app.setState({
            selectionRect: {
                top:0,
                left:0,
                width:0,
                height:0
            }
        })

        window.removeEventListener("mousemove", this.resizeSelectionWindow);
        window.removeEventListener("mouseup", this.makeSelections);
        window.removeEventListener("mouseup", this.selectionCleanUp);
    }


    makeSelections = () => {
        const model = this.engine.getModel();

        model.getNodes().forEach(nodeModel => {
            let isNodeSelected = false;

            // Line-Shape is special case and is handled with the points
            if(nodeModel.options.type !== "line-shape") {
                const pos = nodeModel.getPosition();
                const node_pos = {
                    left_x: pos.x,
                    right_x: pos.x + nodeModel.width,
                    top_y: pos.y,
                    bot_y: pos.y + nodeModel.height
                };

                isNodeSelected = this.isInsideSelectionWindow(node_pos);
                if(isNodeSelected) nodeModel.setSelected(true);
            }

            // Loop through points and check their positions
            for(let port of Object.values(nodeModel.getPorts())) {
                const link = Object.values(port.getLinks())[0];

                if(link) {
                    const points = link.getPoints();

                    if(isNodeSelected) {
                        points.slice(1, -1).forEach(point => {
                            if(this.isPointInsideSelectionWindow(point)) {
                                point.setSelected(true)
                                link.setSelected(true);
                            }
                        })

                    } else {
                        const {sourcePort, targetPort} = link;
                        const start = points[0];
                        const end = points[points.length - 1];

                        const isLinkSelected = [start, end].every(point => this.isPointInsideSelectionWindow(point));
                        if(isLinkSelected) {
                            link.setSelected(true);
                            points.slice(1, -1).forEach(point => {
                                if(this.isPointInsideSelectionWindow(point)) point.setSelected(true);
                            })

                            if(nodeModel.options.type === "line-shape") {
                                [sourcePort.parent, targetPort.parent].forEach(p => p.setSelected(true));
                            }
                        }
                    }
                }
            }
        })
    }

    isInsideSelectionWindow = ({left_x, right_x, top_y, bot_y}) => {
        // if(!start_point || !curr_point) return false;

        const select_left_x = Math.min(this.start_point.x, this.curr_point.x);
        const select_right_x = Math.max(this.start_point.x, this.curr_point.x);
        const select_top_y = Math.min(this.start_point.y, this.curr_point.y);
        const select_bot_y = Math.max(this.start_point.y, this.curr_point.y);

        return left_x >= select_left_x && right_x <= select_right_x &&
               top_y >= select_top_y && bot_y <= select_bot_y;
    }

    isPointInsideSelectionWindow = (point) => {
        const pos = point.getPosition();
        const point_pos = {
            left_x: pos.x,
            right_x: pos.x,
            top_y: pos.y,
            bot_y: pos.y
        };

        return this.isInsideSelectionWindow(point_pos);
    }


    // =================================
    // CANVAS DRAG
    // =================================
    startCanvasDrag = (e) => {
        // if(e.preventDefault) e.preventDefault();
        // if(e.stopPropagation) e.stopPropagation();

        // perform canvas drag if and only if nothing was selected
        const element = this.engine.getMouseElement(e);
        if(element) return;

        // let start_point = {x: e.clientX, y: e.clientY};
        // let curr_point;

        this.start_point = {x: e.clientX, y: e.clientY};

        window.addEventListener("mousemove", this.moveCanvas);
        window.addEventListener("mouseup", this.stopMoveCanvas);
    }

    moveCanvas = (e) => {
        const {canvas} = this.engine;
        // curr_point = {x: e.clientX, y: e.clientY};
        let curr_point = {x: e.clientX, y: e.clientY};

        const diff_x = this.start_point.x - curr_point.x;
        const diff_y = this.start_point.y - curr_point.y;

        canvas.parentElement.scrollLeft += diff_x;
        canvas.parentElement.scrollTop += diff_y;

        this.start_point = curr_point;
    }

    stopMoveCanvas = () => {
        window.removeEventListener("mousemove", this.moveCanvas);
        window.removeEventListener("mouseup", this.stopMoveCanvas);
    }
}





// export class CustomScrollCanvasAction extends Action {
// 	constructor(options = {}) {
// 		super({
// 			type: InputType.MOUSE_WHEEL,
// 			fire: (actionEvent) => {
//                 const { event } = actionEvent;
//                 event.stopPropagation();

// 				const model = this.engine.getModel();

// 				let offsetY = model.getOffsetY();
//                 let scrollDelta = options.inverseZoom ? -event.deltaY : event.deltaY;

//                 if (scrollDelta < 0) {
//                     model.setOffsetY(offsetY - 4);
//                 } else {
//                     model.setOffsetY(offsetY + 4);
//                 }

//                 this.engine.repaintCanvas();
// 			}
// 		});
// 	}
// }

// class BlockPageScroll extends Component {
//     constructor(props) {
//         super(props);

//         this.scrollRef = React.createRef();
//         this.stopScroll = e => e.preventDefault();
//     }

//     componentDidUpdate() {
//         this.scrollRef.current.addEventListener("wheel", this.stopScroll);
//     }

//     componentWillUnmount() {
//         this.scrollRef.current.removeEventListener("wheel", this.stopScroll);
//     }

//     render() {
//         return (<div ref={this.scrollRef}>
//             {this.props.children}
//         </div>);
//     }
// }
