import React, {Component} from "react";
import ReactTooltip from "react-tooltip";
import $ from "jquery";
import { Action, CanvasWidget, InputType } from "@projectstorm/react-canvas-core";
import createEngine, {
    DagreEngine,
    DiagramModel,
    DefaultLinkModel,
} from "@projectstorm/react-diagrams";

import { FadingDirections, Toggle } from "../../../util/stateless";
import { ContextMenu, randomChars } from "../../../util/util";
import { BasicAlert } from "../../../dialog/BasicAlert";
import { BasicConfirm } from "../../../dialog/BasicConfirm";

import PhenomId from '../../../../requests/phenom-id';

import { BasePortFactory } from "../base/BasePort";
import { BaseNodeModel } from "../base/BaseNode";
import { BaseLinkModel, BaseLinkFactory } from "../base/BaseLink";
import { ViewTraceNodeFactory, ViewTraceNodeModel } from "./node/ViewTraceNode";
import { VertexTraceNodeFactory, VertexTraceNodeModel } from "./node/VertexTraceNode";
import { nodeProps } from '../index';
import { StormState } from "../stormy/StormState";
import NavTree from "../../../tree/NavTree";

import {
    centerCoordinatesOnDragEnd,
    DiagramToolBar,
    createPortGuid,
    splitPortGuid,
    isStormData,
    diagramColors,
} from "../util";





/**
 * INDEX
 * ------------------------------------------------------------
 * 01. Tool Bar
 * 02. State
 * 03. Life Cycle Methods
 * 04. Stencil Box
 * 05. Canvas
 * 06. Model
 * 07. Getters
 * 08. Setters
 * 09. Remove
 * 10. Link
 * 15. Drag and Drop (Adding Nodes to Diagram)
 * 20. Override Tracings
 * 21. Vertex Nodes
 * 22. View Trace Nodes
 * 25. Side Bar
 * 50. Helper
 * 55. Action - Modal Methods
 * 60. Action - Custom Delete Action
 * 61. Action - Custom Mouse Up Action
 * 62. Action - Custom Mouse Down Action
 * ------------------------------------------------------------
 */



const nodeModelWhitelist = new Set(["view-trace-node", "vertex-trace-node"]);



export default class ViewTrace extends Component {
    constructor(props) {
        super(props);
        this.phenomId = new PhenomId(`${this.props.id}-view-trace`);

        // INTERFACE
        [
          "resizeCanvas",
          "startDragMapping",
          "endDragMapping",
          "getDiagramStormData",
          "getPhenomDomId",
          "getSvgLayerDOM",
          "getCanvasLayerDOM",
          "getVertexNodeModel",
          "getMappingFor",
          "hasVertexNodeModel",
          "isLinkTypeHidden",
          "setDiagramUnsavedStatus",
          "setNodeModelFromCharGuids",
          "createLink",
          "createVertexStormData",
          "createVertexNodeModel",
          "removeVertexNodeModel",
        ].forEach((funcName) => {
          if (!this[funcName]) throw Error(`View Trace function '${funcName}' was not found.`);
        })

        /*
        * This configures the layout processor
        * */
        this.dagreEngine = new DagreEngine({
            graph: {
                rankdir: "LR",
                ranker: "tight-tree",
                marginx: 25,
                marginy: 25
            },
            includeLinks: true
        });

        // removes default actions
        this.engine = createEngine({
            registerDefaultDeleteItemsAction: false,
            registerDefaultZoomCanvasAction: false,
        });

        this.engine.getStateMachine().pushState(new StormState());

        this.engine.getNodeFactories().registerFactory(new ViewTraceNodeFactory());
        this.engine.getNodeFactories().registerFactory(new VertexTraceNodeFactory());

        this.engine.getLinkFactories().registerFactory(new BaseLinkFactory());
        this.engine.getPortFactories().registerFactory(new BasePortFactory());


        /*
         * MODEL
         */
        this.model = new DiagramModel();

        this.model.registerListener({
            eventDidFire: e => {
              switch (e.function) {
                case "nodesUpdated":
                  this.setDiagramUnsavedStatus();
                  this.resizeCanvas();
                  this.centerAllVertices();
                  break;
              }
            },
        });

        this.engine.setModel(this.model);

        this.engine.getActionEventBus().registerAction(new CustomDeleteItemsAction());
        this.engine.getActionEventBus().registerAction(new CustomMouseDownAction());
        this.engine.getActionEventBus().registerAction(new CustomMouseUpAction());

        this.sidePanelRef = React.createRef();

        /*
         * This reference is important to have because it allows us to easily access the
         * state and methods in this file from individual nodes
         * */
        this.engine.$app = this;


        // ------------------------------------------------------------
        // 01. Tool Bar
        // ------------------------------------------------------------
        this.toolbarOptions = [
          {
            id: "tool-clear-diagram",
            type: "fa-button",
            title: "Clear Diagram",
            classNames: ["fas", "fa-eraser"],
            onClick: () => BasicConfirm.show(`Are you sure you want to clear this diagram?`, () => {
                  this.model.getNodes().forEach(node => node.remove());
                  this.model.getLinks().forEach(link => link.remove());
                  this.engine.repaintCanvas();
            })
          },
          {
            id: "tool-clear-nubs",
            type: "text-button",
            text: "Clear Nubs",
            onClick: this.removeVerticesWithLessThanTwoPorts,
          },
          {
            id: "tool-separator-" + randomChars(1),
            type: "separator",
          },
          {
            id: "tool-zoom",
            type: "zoom",
            zoomLevel: this.model.getZoomLevel(),
            updateZoomLevel: this.updateZoomLevel,
          },
          {
            id: "tool-separator-" + randomChars(1),
            type: "separator",
          },
          {
            id: "tool-section-" + randomChars(1),
            type: "section",
            text: "Diagram",
            items: [
              {
                id: "load-diagram",
                type: "fa-button",
                title: "Load Diagram",
                classNames: ["fas", "fa-file-import"],
                onClick: () => this.props.$manager.showDialog("load"),
              },
              {
                id: "save-diagram",
                type: "fa-button",
                title: "Save Diagram",
                classNames: ["fas", "fa-save"],
                onClick: () => this.props.$manager.showDialog("save"),
              },
              {
                id: "export-diagram",
                type: "fa-button",
                title: "Export Diagram",
                classNames: ["fas", "fa-file-export"],
                onClick: () => this.props.$manager.exportDiagramWithoutCropping(this.props.tabIdx),
              },
            ],
          },
          {
            id: "tool-separator-" + randomChars(1),
            type: "separator",
          },
          {
            id: "mapping-section",
            type: "section",
            text: "Mappings",
            items: [
              {
                id: "show-link-toggle",
                type: "toggle",
                options: ["OFF", "ON"],
                startingPosition: 1,
                style: {width:75, margin:"0 auto"},
                toggleFunction: (show) => {
                  const hideLinkTypes = new Set(this.state.hideLinkTypes);

                  if (show) {
                    hideLinkTypes.delete("trace-override");
                    this.buildAllTraceOverrides();
                  } else {
                    hideLinkTypes.add("trace-override");
                  }

                  this.setState({ hideLinkTypes }, () => {
                    this.refresh();
                  })
                }
              }
            ]
          }
        ]
    }

    // ------------------------------------------------------------
    // 02. State
    // ------------------------------------------------------------
    version = 2
    saved = true
    vertices = {}

    // used to create "tracing override" links
    charGuidsToNodeModelTable = {}

    state = {
      hideLinkTypes: new Set(),

      // used to create mapping between two chars
      mappingFor: {
        viewNodeModel: null,
        charData: null,
        reverseAlignment: false,
      },

      // used to show semantic mapping
      semanticsFor: null,

      // used to snap vertex nodes back to center
      canvasWidth: 0,
      canvasHeight: 0,
    }

    setDiagramUnsavedStatus = () => {
      this.setDiagramSavedStatus(false);
    }

    setDiagramSavedStatus = (bool) => {
      this.saved = bool;
    }
  
    getDiagramSaveStatus = () => {
      return this.saved;
    }
  
    hasContent = () => {
      return !!this.model.getNodes().length;
    }

    refresh = () => {
      this.model.getNodes().forEach(nodeModel => {
        nodeModel.restoreNodeSize();
        nodeModel.forceWidgetToUpdate();
      })
  
      this.model.getLinks().forEach(linkModel => {
        linkModel.forceLinkToUpdate();
      })
  
      ReactTooltip.rebuild();
      this.engine.repaintCanvas();
    }

    // ------------------------------------------------------------
    // 03. Life Cycle Methods
    // ------------------------------------------------------------
    componentDidMount() {
      // React Storm only listens for these mouse actions: Mouse_Down, Mouse_Up, Mouse_Wheel, and Mouse_Move
      $(this.engine.canvas).on('contextmenu', (e) => {
        e.preventDefault();
      })

      // =================
      // Vertex Node Model
      // =================
      // Vertex Nodes are not locked into the center position.
      // if the window size changes or if the canvas size changes (i.e. user collapses the NavTree), then the Vetex Node will snap back to the center
      const { clientWidth, clientHeight } = this.getCanvasLayerDOM();
      this.setState({ canvasWidth: clientWidth, canvasHeight: clientHeight });
      window.addEventListener("resize", this.centerAllVertices);                  // note: componentDidUpdate will reposition the node

      // =================
      // Center Line
      // =================
      const lineDOM = document.createElement("line");
      lineDOM.style.cssText = `
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 3px;
        background: black;
      `
      this.getCanvasLayerDOM().prepend(lineDOM);

      // =======================
      // Canvas Background Color
      // =======================
      this.getCanvasLayerDOM().style.background = "linear-gradient(to right, hsla(0deg, 100%, 50%, 8%) 47%, hsl(0 0% 96%) 47%, hsl(0 0% 96%) 53%, hsla(225deg, 100%, 50%, 8%) 53%)";
    }

    componentDidUpdate() {
      const { clientWidth, clientHeight } = this.getCanvasLayerDOM();
      if (this.state.canvasWidth !== clientWidth ||
          this.state.canvasHeight !== clientHeight) {
            this.setState({ canvasWidth: clientWidth, canvasHeight: clientHeight },
              this.centerAllVertices());
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.centerAllVertices);
    }



    // ------------------------------------------------------------
    // 04. Stencil Box
    // ------------------------------------------------------------


    // ------------------------------------------------------------
    // 05. Canvas
    // ------------------------------------------------------------
    getCanvasLayerDOM = () => {
      return this.engine.canvas;
    }

    getModelLayerDOM = () => {
      return this.engine.canvas.querySelector('div:first-of-type');
    }

    getSvgLayerDOM = () => {
      return this.engine.canvas.querySelector('svg:first-of-type');
    }

    domModelLayerOnTop = () => {
      const svgLayer = this.getSvgLayerDOM();
      svgLayer.style.zIndex = "0";
    }

    domSvgLayerOnTop = () => {
      const svgLayer = this.getSvgLayerDOM();
      svgLayer.style.zIndex = "1";
    }

    resizeCanvas = () => {
      const zoom = this.model.getZoomLevel() / 100;
      const canvas = this.getCanvasLayerDOM();
      const canvas_height = canvas.clientHeight;

      if (zoom > 1) {
          // var scroll_width = Math.floor(canvas.scrollWidth / zoom);
          var scroll_height = Math.floor(canvas.scrollHeight / zoom);
      } else {
          // var scroll_width = canvas.scrollWidth;
          var scroll_height = canvas.scrollHeight;
      }

      // if(scroll_width > canvas.clientWidth) {
      //     canvas.style.width = (scroll_width + 1000) + "px";
      // }

      if (scroll_height > canvas_height) {
        canvas.style.height = (canvas_height + 500) + "px";
      }

      this.forceUpdate();
    }



    // ------------------------------------------------------------
    // 06. Model
    // ------------------------------------------------------------
    /**
     * Standard Load Method
     * 
     * @param {object} presentationData 
     */
    loadDiagram = async (presentationData={}) => {
      await this.restoreSession(presentationData);
      this.resizeCanvas();
      this.centerAllVertices();
      this.removeVerticesWithNoPorts();

      if(presentationData.old_diagram) {
        this.rebuildOldDiagramConnectors(presentationData.rebuildConnectors);
      }

      this.buildAllTraceOverrides();
      this.refresh();
    }

    /**
     * 
     * @param {object} presentationData 
     * @returns void
     */
    restoreSession = async (presentationData) => {
      // invalid
      if (!presentationData) {
        return;
      }

      const overrideGuids = new Set();
      const linkModels = presentationData.layers.find(e => e.type === "diagram-links").models;
      const nodeModels = presentationData.layers.find(e => e.type === "diagram-nodes").models;

      this.formatDiagramVersionOne(presentationData);
      this.cleanPresentationData(presentationData);
      
      // reassign reference pointers
      for (let uid in nodeModels) {
        let jsonNodeModel = nodeModels[uid]
        let jsonData = jsonNodeModel.nodeData;

        // invalid node
        if (!jsonData?.guid) {
          continue;
        }

        // skip ShapeModels (Line, Arrows, Box, Textbox)
        if (!nodeModelWhitelist.has(jsonNodeModel.type)) {
          continue;
        }

        const stormData = this.getDiagramStormData(jsonData.guid);
        jsonNodeModel.nodeData = stormData;
        
        switch (stormData.getXmiType()) {
          case "platform:View":
            overrideGuids.add(stormData.getGuid());
            break;
          case "skayl:Vertex":
            break;
          default:
        }

        for (const port of jsonNodeModel.ports) {
          const jsonPortData = port?.portData;
          if (!jsonPortData?.guid) {
            continue;
          }
          port.portData = this.getDiagramStormData(jsonPortData.guid);
        }
      }

      // reassign reference pointers
      for (let uid in linkModels) {
        const jsonLinkModel = linkModels[uid];
        const jsonData = jsonLinkModel.attrData;
        if (!jsonData?.guid) {
          continue;
        }

        jsonLinkModel.attrData = this.getDiagramStormData(jsonData.guid);
      }

      // 3) fetch override trace data
      await this.props.$manager.fetchViewTraceOverride([...overrideGuids]);

      // 4) Load Content
      this.model.deserializeModel(presentationData, this.engine);
      this.engine.repaintCanvas();
    }


    formatDiagramVersionOne = (presentationData) => {
      const { version=0, layers } = presentationData;
      const linkModels = presentationData.layers.find(e => e.type === "diagram-links").models;
      const nodeModels = layers.find(e => e.type === "diagram-nodes").models;

      // exit if version is 2 or higher
      if (version >= 2) {
        return;
      }

      // ---------------------------------------------
      // -- LOOP THROUGH NODEMODELS --
      // ---------------------------------------------
      for (let jsonNodeModel of Object.values(nodeModels)) {
        const jsonData = jsonNodeModel.nodeData;

        switch (jsonNodeModel.type) {
          case "vertex-trace-node":
            var vertexData = this.props.$manager.createVertexStormData(jsonData.tracing);
            jsonNodeModel.nodeData = vertexData.getData();
            break;

          default:
        }

        // ---------------------------------------------
        // -- LOOP THROUGH PORTS --
        // ---------------------------------------------
        for (const port of [...jsonNodeModel.ports]) {
          if (port.name === 'draw-tool') continue;
          const attrGuid = port.name;
          const jsonAttr = {
            guid: attrGuid,
          }

          // assign port's portData
          port.portData = jsonAttr;

          // a port can have at most one link
          const linkId = port.links[0];
          const jsonLink = linkModels[linkId];

          // assign link's attrData
          if (jsonLink) {
            jsonLink.attrData = jsonAttr;
          }
        }
      }
    }

    /**
     * React Storm uses the presentationData to rebuild the environment
     *    -> Nodes are spread out in different sections
     *    -> Loop through each node and remove them if they don't exist in the database anymore
     * 
     * @param {object} presentationData 
     */
    cleanPresentationData = (presentationData) => {
      const linkModels = presentationData.layers.find(e => e.type === "diagram-links").models;
      const nodeModels = presentationData.layers.find(e => e.type === "diagram-nodes").models;

      // remove trace override links and recreate it with the latest data
      // this is to ensure the diagram is not out of sync
      // note: when the node rebuilds, the port will be removed because the link no longer exist
      for (let jsonLinkModel of Object.values(linkModels)) {
        if (jsonLinkModel.settings?.linkType === "trace-override") {
          delete linkModels[jsonLinkModel.id];
        }
      }

      // ---------------------------------------------
      // -- CLEAN UP NODEMODELS --
      // ---------------------------------------------
      for (let jsonNodeModel of Object.values(nodeModels)) {
        const jsonData = jsonNodeModel.nodeData;

        const stormData = this.getDiagramStormData(jsonData?.guid);
        if (!jsonData || !isStormData(stormData)) {
          this.removeNodeFromPresentationData(jsonNodeModel, nodeModels, linkModels);
          continue;
        }

        // ---------------------------------------------
        // -- CLEAN UP PORTS --
        // ---------------------------------------------
        for (const port of [...jsonNodeModel.ports]) {
          if (port.name === 'draw-tool') continue;

          const jsonAttrData = port.portData;
          const attr = this.getDiagramStormData(jsonAttrData?.guid);

          if (!jsonAttrData || !isStormData(attr)) {
            port.links.forEach(linkId => delete linkModels[linkId]);
            const removeIdx = jsonNodeModel.ports.findIndex(p => p.id === port.id);
            removeIdx > -1 && jsonNodeModel.ports.splice(removeIdx, 1);
          }
        }

        // ---------------------------------------------
        // -- CLEAN UP NODE CONNECTIONS --
        // ---------------------------------------------
        for (const port of [...jsonNodeModel.ports]) {
          if (port.name === 'draw-tool') continue;

          for (const linkId of [...port.links]) {
            const jsonLink = linkModels[linkId];
            const jsonAttrData = jsonLink?.attrData;

            // node connection was deleted
            const nodeConnection = this.getDiagramStormData(jsonAttrData?.guid);
            if (!jsonAttrData || !isStormData(nodeConnection)) {
              delete linkModels[linkId];
              const removeIdx = port.links.findIndex(id => id === linkId);
              removeIdx > -1 && port.links.splice(removeIdx, 1);
            }
          }

          // delete port if it has no links
          if (!port.links.length) {
            const removeIdx = jsonNodeModel.ports.findIndex(p => p.id === port.id);
            removeIdx > -1 && jsonNodeModel.ports.splice(removeIdx, 1);
          }
        }
      }
    }

    /**
     * Delete a node from the Presentation Data
     *   The model and json can easily go out of sync.
     *   When this happens, the node is removed from the presentation data before it is reinjected back into the diagram
     *
     * @param {NodeModel} removeMe
     * @param {object} nodeModels
     * @param {object} linkModels
     */
    removeNodeFromPresentationData = (removeMe, nodeModels, linkModels) => {
      removeMe.ports.forEach(port => {
          port.links.forEach(linkId => delete linkModels[linkId]);
      })
      delete nodeModels[removeMe.id];
    }


    rebuildOldDiagramConnectors = (rebuildConnectors) => {
      for (let charGuid of rebuildConnectors) {
        const viewNodeModel = this.getNodeModelFromCharGuid(charGuid);
        const charData = this.getDiagramStormData(charGuid);
        if (isStormData(charData)) viewNodeModel.traceViewAttribute(charData);
      }
    }

    serializeModel = () => {
      const serializedHash = {};

      // View Nodes
      this.model.getNodes().forEach(nodeModel => {
        const node = nodeModel.getStormData();
        if (isStormData(node)) {
          serializedHash[node.getGuid()] = node.serializeData();
        }
      })

      const serializedData = this.model.serialize();
            serializedData.version = this.version;
            serializedData.nodeHash = serializedHash;

      return serializedData
    }




    // ------------------------------------------------------------
    // 07. Getters
    // ------------------------------------------------------------
  
    /**
     * Dereference a node
     * 
     * @param {string} guid 
     * @returns StormData if found, undefined otherwise
     */
    getDiagramStormData = (guid) => {
      return this.props.$manager.getDiagramStormData(guid);
    }

    // deprecated
    getDiagramNodeData = (guid) => {
      return this.getDiagramStormData(guid);
    }

    getVertexNodeModel = (pathGuids) => {
      return this.vertices[pathGuids];
    }

    hasVertexNodeModel = (pathGuids) => {
      return !!this.vertices[pathGuids];
    }

    getMappingFor = () => {
      const { mappingFor } = this.state;
      if (!mappingFor.viewNodeModel || !mappingFor.charData) {
        return null;
      }
      return mappingFor;
    }

    getPhenomDomId = () => {
      return this.phenomId.genPageId();
    }

    isLinkTypeHidden = (linkType) => {
      return this.state.hideLinkTypes.has(linkType);
    }

    isShowUncommitted = () => {
      return this.state.showUncommitted;
    }

    // ------------------------------------------------------------
    // 08. Setters
    // ------------------------------------------------------------
    setVertexNodeModel = (pathGuids, nodeModel) => {
      this.vertices[pathGuids] = nodeModel;
    }

    createVertexNodeModel = async (nodeData, {x, y}) => {
      const settings = { lockHorizontalMovement: true }
      const vertexNodeModel = new VertexTraceNodeModel({ nodeData }, this, settings);
      vertexNodeModel.centerNodePosition({x, y});

      this.setVertexNodeModel(nodeData.getAttr("tracing"), vertexNodeModel);
      this.model.addNode(vertexNodeModel);
      await this.forceUpdate();
      await this.engine.repaintCanvas(true);
      return vertexNodeModel;
    }

    centerAllVertices = () => {
      Object.values(this.vertices).forEach((vertexNodeModel) => {
        vertexNodeModel.centerNodePosition();
      })
      this.forceUpdate();
    }

    /**
     * 
     * @param {NodeModel} viewNodeModel 
     * @param {StormData} charData 
     * @param {boolean} reverseAlignment 
     */
    setMappingFor = (viewNodeModel, charData, reverseAlignment) => {
      const mappingFor = {
        viewNodeModel,
        charData,
        reverseAlignment,
      }

      this.setState({ mappingFor }, () => {
        this.refresh();
      })
    }

    createVertexStormData = (path) => {
      return this.props.$manager.createVertexStormData(path);
    }

    // ------------------------------------------------------------
    // 09. Remove
    // ------------------------------------------------------------
    removeVertexNodeModel = (pathGuids) => {
      delete this.vertices[pathGuids];
    }

    removeVerticesWithNoPorts = () => {
      for (let tracingGuid in this.vertices) {
        const VertexNodeModel = this.vertices[tracingGuid];

        if (!VertexNodeModel) {
          this.removeVertexNodeModel(tracingGuid);
          continue;
        }

        VertexNodeModel.removeNodeWithNoPorts();
      }
    }

    removeVerticesWithLessThanTwoPorts = () => {
      for (let tracingGuid in this.vertices) {
        const VertexNodeModel = this.vertices[tracingGuid];

        if (!VertexNodeModel) {
          this.removeVertexNodeModel(tracingGuid);
          continue;
        }

        VertexNodeModel.removeNodeWithLessThanTwoPorts();
      }
    }

    // ------------------------------------------------------------
    // 10. Link
    // ------------------------------------------------------------
    /**
     * Adds Link Node to the canvas
     * 
     * @param {Port} srcPort 
     * @param {Port} dstPort 
     * @param {StormData} linkData 
     * @returns LinkNodeModel
     */
    createLink = (srcPort, dstPort) => {
      // exit if link already exist
      const link = Object.values(srcPort.links)[0];
      if (link?.targetPort) return link;

      const newLink = this.model.addLink(srcPort.link(dstPort));
      newLink.setAttrData(srcPort.getAttrData())
      srcPort.reportPosition();
      dstPort.reportPosition();

      this.engine.repaintCanvas();
      return newLink;
    }

    createDashedLink = (srcPort, dstPort) => {
      // exit if link already exist
      // this is to prevent the setColor and setDashSize from triggering again if the link already exist
      let link = Object.values(srcPort.links)[0];
      if (link?.targetPort) return link;

      link = this.createLink(srcPort, dstPort);
      link.setLinkColor();
      link.setDashSize();
      link.setDashOffset();
      link.setArrowHead("thinArrow");
      return link;
    }

    // ------------------------------------------------------------
    // 15. Drag and Drop (Adding Nodes to Diagram)
    // ------------------------------------------------------------
    /**
     * Add color attribute to NodeModel
     *    originally this was handled by the node's contructor, but it fails when reloading the diagram because the constructor triggers before the deserialization step
     * 
     * @param {NodeModel} nodeModel 
     */
    addStormColor = (nodeModel) => {
      const stormData = nodeModel.getStormData();
      nodeModel.setNodeColor(diagramColors[stormData.getXmiType()] || diagramColors["default"]);
    }

    /**
     * Handles Drag and Drop from NavTee
     * 
     * @param {object} node 
     * @param {array} pos x, y coordindates
     * @param {object} config extra drag and drop options
     * @returns NodeModel if successfully created, undefined otherwise;
     */
    addNode = async (node, pos, config={}) => {
      if (!node?.guid || !node?.xmiType) return;
      const { useRelativePoint=true, centerNodeOnDrop=true, showDialog=false } = config;
      let point = useRelativePoint ? this.engine.getRelativePoint(pos[0], pos[1]) : { x: pos[0], y: pos[1] };

      if (showDialog) {
        BasicAlert.show(`Adding ${node.name || "node"}`, "Adding node", false);
      }

      // Node was already added
      let nodeModel = this.findNodeModel(node.guid);
      if (nodeModel) {
        return nodeModel;
      }

      // create a new nodeModel
      // and place in the diagram
      switch (node.xmiType) {
        case "platform:View":
          var nodeData = await this.props.$manager.getOrFetchDiagramStormData(node);
          if (isStormData(nodeData)) {
            nodeModel = new ViewTraceNodeModel({ nodeData, }, this);
          }
          break;

        default:
      }

      // something went wrong
      if (!nodeModel) {
        return;
      }

      // centers the node relative to the mouse
      if (centerNodeOnDrop) {
        const adjusted = centerCoordinatesOnDragEnd(point.x, point.y, 200);
        point.x = adjusted.x;
        point.y = adjusted.y;
      }

      nodeModel.setPosition(point.x, point.y);
      this.addStormColor(nodeModel);
      this.setNodeModelFromCharGuids(nodeModel);
      this.model.addNode(nodeModel);

      this.forceUpdate();
      return nodeModel;
    }



    addNodes = async (nodes, pos, config={}) => {
      if(!nodes) return;
      if (!Array.isArray(nodes)) nodes = [nodes];
      const { useRelativePoint=true, centerNodeOnDrop=true, showDialog=false } = config;
      const fetchOverrideGuids = [];
      const diagramNodes = [];
      let point = useRelativePoint ? this.engine.getRelativePoint(pos[0], pos[1]) : { x: pos[0], y: pos[1] };

      if (showDialog) {
        let noun = nodes.length > 1 ? "Nodes" : "Node";
        BasicAlert.show(`Adding ${nodes.length} ${noun}`, `Adding ${noun}`, false);
      }

      for (let node of nodes) {
        // invalid node
        if (!node?.guid || !node?.xmiType) {
          continue;
        }

        // fetch override data and show mapping
        fetchOverrideGuids.push(node.guid);

        // if user dragged in multiple nodes at once. Need to set new coords for next nodes
        const prevNode = diagramNodes[diagramNodes.length - 1];

        if (prevNode) {
          point.x = prevNode.getX() + nodeProps.width + 50;
          point.y = prevNode.getY();
        } else if (centerNodeOnDrop) {
          const adjusted = centerCoordinatesOnDragEnd(point.x, point.y);
          point.x = adjusted.x;
          point.y = adjusted.y;
        }

        const nodeModel = await this.addNode(node, [point.x, point.y], { useRelativePoint: false, centerNodeOnDrop: false, showDialog: false });
        if (!nodeModel) {
          continue;
        }

        diagramNodes.push(nodeModel);
      }

      BasicAlert.hide();
      this.engine.repaintCanvas();
      this.resizeCanvas();
      this.centerAllVertices();

      diagramNodes.forEach(diagramNodeModel => {
        diagramNodeModel.traceViewAttributesToExistingVertices();
      })

      // Show Override Links
      await this.props.$manager.fetchViewTraceOverride(fetchOverrideGuids);
      await this.buildAllTraceOverrides()

      return diagramNodes;
    };

    // ------------------------------------------------------------
    // 20. Override Tracings
    // ------------------------------------------------------------
    getNodeModelFromCharGuid = (charGuid) => {
      return this.charGuidsToNodeModelTable[charGuid];
    }

    setNodeModelFromCharGuids = (viewNodeModel) => {
      for (let charGuid of viewNodeModel.getStormData().getChildren()) {
        this.charGuidsToNodeModelTable[charGuid] = viewNodeModel;
      }
    }

    buildTraceOverride = (srcGuid, dstGuid) => {
      const srcNodeModel = this.getNodeModelFromCharGuid(srcGuid);
      const dstNodeModel = this.getNodeModelFromCharGuid(dstGuid);
      if (!srcNodeModel || !dstNodeModel) return;

      let srcPortGuid = createPortGuid(srcGuid, dstGuid);
      let dstPortGuid = createPortGuid(dstGuid, srcGuid);
      let srcPort = srcNodeModel.getPort(srcPortGuid);
      let dstPort = dstNodeModel.getPort(dstPortGuid);

      if (!srcPort) {
        let srcCharData = this.getDiagramStormData(srcGuid);
        srcPort = srcNodeModel.setOutPort(srcCharData, { portName: srcPortGuid });
      }

      if (!dstPort) {
        let dstCharData = this.getDiagramStormData(dstGuid);
        dstPort = dstNodeModel.setInPort(dstCharData, { portName: dstPortGuid });
      }

      if (srcPort && dstPort) {
        const link = this.createDashedLink(srcPort, dstPort);
        link.setLinkType("trace-override");
        // link.forceLinkToUpdate();
        this.forceUpdate();
      }
    }

    buildAllTraceOverrides = () => {
      const overrideTable = this.props.$manager.getViewTraceOverrideTable();

      Object.entries(overrideTable).forEach(([srcGuid, dstGuid]) => {
        this.buildTraceOverride(srcGuid, dstGuid);
      })
    }

    // removes from database and from local hash table
    removeTraceOverrideFromDataBase = (srcGuid, dstGuid) => {
      this.props.$manager.removeViewTraceOverride(srcGuid, dstGuid);
    }

    // removes link only
    removeTraceOverride = (srcGuid, dstGuid) => {
      const srcNodeModel = this.getNodeModelFromCharGuid(srcGuid);
      if (!srcNodeModel) return;

      let srcPortGuid = createPortGuid(srcGuid, dstGuid);
      srcNodeModel.removePortByGuid(srcPortGuid);
      this.engine.repaintCanvas();
    }

    // loops through hash table and removes link only
    removeAllTraceOverrides = () => {
      const overrideTable = this.props.$manager.getViewTraceOverrideTable();
      Object.entries(overrideTable).forEach(([srcGuid, dstGuid]) => {
        this.removeTraceOverride(srcGuid, dstGuid);
      })
    }

    /**
     * 
     * @param {event} e 
     * @param {StormData} srcAttrData 
     * @returns void
     */
    startDragMapping = (e, srcAttrData) => {
      // invalid node
      if (!isStormData(srcAttrData)) {
        return;
      }

      // this triggers onMouseDown and when clicking a link.
      // to distinguish between the two, the node highlight action will trigger when the user moves his mouse.
      const srcNodeModel = this.getNodeModelFromCharGuid(srcAttrData.getGuid());
      if (!srcNodeModel) {
        return;
      }

      const highlightDroppableAreas = () => {
        // set mapping for
        const reverseAlignment = srcNodeModel.getSettings().reverseAlignment;
        this.setMappingFor(srcNodeModel, srcAttrData, reverseAlignment);

        const zoomLevel = this.model.getZoomLevel() / 100;

        // port mapping tool position
        const point = this.engine.getRelativePoint(e.clientX, e.clientY);
        const posX = point.x / zoomLevel;
        const posY = point.y / zoomLevel;

        const portName = e.target.dataset.portName;
        const port = srcNodeModel.getPort(portName);
              port.setPosition(posX, posY);

        // manually set the link's endpoint to match the mouse's current position
        // because $app setState is triggered, the endpoint of newDragLink jumps to 0,0 of the graph.
        this.engine.repaintCanvas(true).then((res) => {
          const link = Object.values(port.getLinks())[0];
                link && link.getLastPoint().setPosition(posX, posY);

          port.reportPosition();
        })

        // clean up after initial move
        window.removeEventListener("mousemove", highlightDroppableAreas);
      }

      const cleanUp = () => {
        this.setMappingFor(null, null, false);
        window.removeEventListener("mousemove", highlightDroppableAreas);
        window.removeEventListener("mouseup", cleanUp);
      }

      window.addEventListener("mousemove", highlightDroppableAreas);
      window.addEventListener("mouseup", cleanUp);
    }

    /**
     * 
     * @param {StormData} dstCharData 
     */
    endDragMapping = async (dstCharData) => {
      const mappingFor = this.getMappingFor();
      const srcCharData = mappingFor.charData;

      const targetCharGuid = this.props.$manager.getTargetViewTraceOverrideGuid(srcCharData.getGuid());
      if (targetCharGuid) {
        await this.removeTraceOverrideFromDataBase(srcCharData.getGuid(), targetCharGuid);
      }

      this.props.$manager.saveViewTraceOverride(srcCharData.getGuid(), dstCharData.getGuid());
    }


    // ------------------------------------------------------------
    // 21. Vertex Nodes
    // ------------------------------------------------------------


    // ------------------------------------------------------------
    // 22. View Trace Nodes
    // ------------------------------------------------------------
    updateViewTraceNodePositionsBasedOnZoom = (oldZoomLevel) => {
      this.model.getNodes().forEach((nodeModel) => {
        if (nodeModel instanceof ViewTraceNodeModel) {
          nodeModel.updateNodePositionBasedOnZoom(oldZoomLevel);
        }
      })
    }

    // ------------------------------------------------------------
    // 25. Side Bar
    // ------------------------------------------------------------

    // ------------------------------------------------------------
    // 50. Helper
    // ------------------------------------------------------------
    findNodeModel = (guid) => {
        return this.engine.getModel().getNodes().find(node => node.getStormData()?.guid === guid);
    }

    addViewFromSemanticClick = (newView, semanticCharGuid) => {
      const existingViewNodeModel = this.getNodeModelFromCharGuid(semanticCharGuid);

      let posX, posY;
      if (existingViewNodeModel) {
        posX = existingViewNodeModel.getX();
        posY = existingViewNodeModel.getY() + existingViewNodeModel.height + 50;
      } else {
        const canvas = this.getCanvasLayerDOM().getBoundingClientRect();
        const relativePoint = this.engine.getRelativePoint(canvas.x + 50, canvas.y + 50);
        posX = relativePoint.x;
        posY = relativePoint.y;
      }

      this.addNodes(newView, [posX, posY], { useRelativePoint: false, centerNodeOnDrop: false });
    }

    updateZoomLevel = (zoom) => {
      const oldZoomLevel = this.model.getZoomLevel();

      this.model.setZoomLevel(zoom);
      this.engine.repaintCanvas(true).then(() => {
        // it was requested that when zooming in/out the nodes will move relative to the center line
        // because the node's position is changing, it will trigger "resizeCanvas"
        // however "resizeCanvas" can after the canvas is repainted or else the "height" is out of sync
        this.updateViewTraceNodePositionsBasedOnZoom(oldZoomLevel);
        this.centerAllVertices();
        this.engine.repaintCanvas();
      });
    }

    handleOnDrop = (event) => {
      const rawTreeData = event.dataTransfer.getData("treeNodes");
      const treeNodes = rawTreeData && JSON.parse(rawTreeData);
      if (treeNodes && treeNodes.every(node => node.xmiType === "platform:View")) {
        return this.addNodes(treeNodes, [event.clientX, event.clientY]);
      }
    }

    render() {
        let canvasClasses = ["srd-demo-canvas"];

        return (
          <div className="storm-diagram">
            <div className="storm-header">
              <DiagramToolBar options={this.toolbarOptions} />
            </div>

            <div className="storm-canvas" style={{background: "#F6F6F6"}}>
              <div id={this.phenomId.genPageId("graph-container")}
                    className="storm-container"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={this.handleOnDrop}
                    onClick={this._handleContextClick}
                    ref={el => this.containerDOM = el}>
                <FadingDirections idCtx={this.phenomId.genPageId()} text="Click and drag at least one View from the Nav Tree onto the Red Canvas and at least one View onto the Blue Canvas."/>
                <CanvasWidget className={canvasClasses.join(" ")} engine={this.engine} smartRouting={true} />
              </div>
            </div>
          </div>
        );
    }
}

// ------------------------------------------------------------
// 60. Action - Custom Delete Action
// ------------------------------------------------------------
class CustomDeleteItemsAction extends Action {
    constructor(options = {}) {
        options = {
            keyCodes: [46],
            ...options
        };

        super({
            type: InputType.KEY_DOWN,
            fire: (event) => {
                if (options.keyCodes.indexOf(event.event.keyCode) !== -1) {
                    const selectedEntities = this.engine.getModel().getSelectedEntities();

                    if (selectedEntities.find(e => e.isLocked())){
                      return;
                    }

                    selectedEntities.forEach((ent) => {
                      ent.remove();
                    })

                    ReactTooltip.hide();
                    this.engine.repaintCanvas();
                }
            }
        });
    }
}


// ------------------------------------------------------------
// 61. Action - Custom Mouse Up Action
// ------------------------------------------------------------
class CustomMouseUpAction extends Action {
  constructor() {
    super({
      type: InputType.MOUSE_UP,
      fire: (e) => {
        const {event} = e;
        const element = this.engine.getMouseElement(event);
        if(!element || event.button !== 2) return;

        const offset = { left: event.pageX + 5, top: event.pageY };
        const { $app } = this.engine;
        const menuItems = [];

        // ------------------------------------------------------------
        // NodeModel
        // ------------------------------------------------------------
        if (element instanceof BaseNodeModel) {
          const nodeGuid = element.getStormData().getGuid();

          if (element.getOptions().type === "vertex-trace-node") {
            menuItems.push({
              text: "Change Color",
              type: "colorInput",
              dontCloseMenu: true,
              colorChange: (hexColor) => {
                element.setNodeColor(hexColor)
              },
            })
          }

          if (element.getOptions().type === "view-trace-node") {
            menuItems.push({
              text: "Reveal in tree",
              func: () => NavTree.scrollToLeaf(nodeGuid),
            })

            menuItems.push({
              text: "Trace all attributes",
              func: () => element.traceAllViewAttributes(),
            })
          }

          menuItems.push({
            text: "Remove",
            func: () => element.remove(),
          })

        // ------------------------------------------------------------
        // LinkModel
        // ------------------------------------------------------------
        } else if (element instanceof DefaultLinkModel) {
          const linkType = element.getSettings && element.getSettings().linkType;
          const isTraceOverride = linkType === "trace-override";

          menuItems.push({
            text: "Change Color",
            type: "colorInput",
            dontCloseMenu: true,
            func: () => this.engine.getModel().clearSelection(),  // when changing colors, the line will highlight blue (indicating it is "selected").  selection is cleared because it was covering up the color change effect.
            colorChange: (hexColor) => {
              element.setLinkColor(hexColor)
            },
          })

          menuItems.push({
            text: "Remove",
            func: () => {
              element.remove();
              this.engine.repaintCanvas();
            },
          })

          if (isTraceOverride) {
            menuItems.push({
              text: "Delete Mapping",
              func: () => {
                const { sourcePort } = element;
                $app.removeTraceOverrideFromDataBase(...splitPortGuid(sourcePort.getName()));
              },
            })
          }
        }

        // render context menu
        if(menuItems.length) {
          ContextMenu.show(menuItems, offset);
        }
      }
    })
  }
}

// ------------------------------------------------------------
// 62. Action - Custom Mouse Down Action
// ------------------------------------------------------------
class CustomMouseDownAction extends Action {
    constructor() {
        super({
            type: InputType.MOUSE_DOWN,
            fire: (e) => {
                const {event} = e;
                const element = this.engine.getMouseElement(event);
                if(element) return;

                switch(event.button) {
                    case 2:
                        this.startCanvasDrag(event);
                        break;
                }
            }
        });
        this.start_point = {};
        this.curr_point = {};
    }

    // =================================
    // CANVAS DRAG
    // =================================
    startCanvasDrag = (e) => {
        // if(e.preventDefault) e.preventDefault();
        // if(e.stopPropagation) e.stopPropagation();

        // perform canvas drag if and only if nothing was selected
        const element = this.engine.getMouseElement(e);
        if(element) return;

        // let start_point = {x: e.clientX, y: e.clientY};
        // let curr_point;

        this.start_point = {x: e.clientX, y: e.clientY};

        window.addEventListener("mousemove", this.moveCanvas);
        window.addEventListener("mouseup", this.stopMoveCanvas);
    }

    moveCanvas = (e) => {
        const {canvas} = this.engine;
        // curr_point = {x: e.clientX, y: e.clientY};
        let curr_point = {x: e.clientX, y: e.clientY};

        const diff_x = this.start_point.x - curr_point.x;
        const diff_y = this.start_point.y - curr_point.y;

        canvas.parentElement.scrollLeft += diff_x;
        canvas.parentElement.scrollTop += diff_y;

        this.start_point = curr_point;
    }

    stopMoveCanvas = () => {
        window.removeEventListener("mousemove", this.moveCanvas);
        window.removeEventListener("mouseup", this.stopMoveCanvas);
    }
}
