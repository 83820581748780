import {getNodeWithAddenda, getNodesOfType, getTransformCode} from "../../requests/sml-requests";
import React from "react";
import {LineLabel, PhenomSelect, PhenomTextArea, PhenomLabel} from "../util/stateless";
import {Notifications} from "../edit/notifications";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import $ from "jquery";
import PhenomId from "../../requests/phenom-id";


// Looks like this file is not in use.
export class ViewMediation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          viewIn: null,
          viewOut: null,
          manualTransforms: null,
          transformCodeSD: "",
          transformCodeDS: "",
          views: [],
        };
        this.phenomId = new PhenomId("view-mediation");
        this.noticeRef = undefined;
    }

    componentDidMount() {
      this.fetchViews();
    }

    componentDidUpdate(prevProps, prevState) {
      let currViewIn = this.state.viewIn?.guid;
      let currViewOut = this.state.viewOut?.guid;
      let prevViewIn = prevState.viewIn?.guid;
      let prevViewOut = prevState.viewOut?.guid;
      if (currViewIn != null && prevViewIn != null && (currViewIn != prevViewIn || currViewOut != prevViewOut)) {
        this.updateTransform(currViewIn, currViewOut)
      }

    }

  fetchViews = () => {
    getNodesOfType("platform:View").then(res => {
      const views = JSON.parse(res).nodes.map(p => ({
        guid: p.guid,
        xmiType: p.xmiType,
        name: p.name,
      }))
      views.unshift({
        guid: "",
        xmiType: "platform:View",
        name: "--None--"
      })
      this.setState({views: views});
    })
  }

  findView = (guid) => {
    return this.state.views.find(view => view.guid == guid)
  }

  updateTransform = (guidIn, guidOut, manualTransforms) => {
    getTransformCode(guidIn, guidOut, this.state.manualTransforms).then(res => {
      let data = JSON.parse(res).data;
      this.setState({transformCodeSD: data})
    });
    getTransformCode(guidOut, guidIn, this.state.manualTransforms).then(res => {
      let data = JSON.parse(res).data;
      this.setState({transformCodeDS: data})
    })
  }


    render() {
        const phenomId = this.phenomId;
        return (
            <div className={"subview-wrapper"} style={{}}>
                <PhenomLabel text={"View Mediation"} style={{paddingBottom: "5px"}}/>
                <Notifications ref={ele => this.noticeRef = ele}/>
                <div className={"flex-h"} style={{marginBottom: "30px"}}>
                  <div>
                    <PhenomSelect label="Source"
                                  id={phenomId.gen("source","")}
                                  value={null}
                                  data={this.state.views}
                                  dataItemKey="guid"
                                  labelProps={{style: {textTransform: "none"}}}
                                  onChange={(e) => this.setState({viewIn: this.findView(e.target.value)})} />
                  </div>
                  <div style={{paddingLeft:"50px"}}>
                    <PhenomSelect label="Destination"
                                  id={phenomId.gen("destination","")}
                                  value={null}
                                  data={this.state.views}
                                  dataItemKey="guid"
                                  labelProps={{style: {textTransform: "none"}}}
                                  onChange={(e) => this.setState({viewOut: this.findView(e.target.value)})} />
                  </div>

                </div>
                <div style={{marginBottom: "30px"}}>
                  <PhenomTextArea label={"Source -> Destination"}
                                  value={this.state.transformCodeSD}
                                  disabled={true}
                                  labelProps={{style: {textTransform: "none"}}}
                                  rows={15}
                                  id={phenomId.gen("", "mediation-code-sd")} />
                </div>
                <div>
                  <PhenomTextArea label={"Destination -> Source"}
                                  value={this.state.transformCodeDS}
                                  disabled={true}
                                  labelProps={{style: {textTransform: "none"}}}
                                  rows={15}
                                  id={phenomId.gen("", "mediation-code-ds")} />
                </div>
            </div>
        );
    }
}
