import React, {Component} from "react";
import { PhenomInput, PhenomTextArea, PhenomRadioButtons, PackageComboBox } from "../../util/stateless";
import {NodeHistory2} from "../node-history";
import PhenomId from "../../../requests/phenom-id"
import { withPageLayout } from "../node-layout";
import { createPhenomGuid, splitCamelCaseWithSpace } from "../../util/util"
import ChangeSetPicker from "../../widget/ChangeSetPicker";



const tpmTypeList = [
  {
    text: "Multicast-UDP",
    key: "udp",
  },
  {
    text: "DDS",
    key: "dds",
  },
  {
    text: "AMQP",
    key: "amqp",
  },
]

const udpList = [
  {
    text: "Local Ip",
    key: "localIp",
  },
  {
    text: "Local Port",
    key: "localPort",
  },
  {
    text: "Destination Ip",
    key: "destinationIp",
  },
  {
    text: "Destination Port",
    key: "destinationPort",
  },
  {
    text: "TTL (1-255)",
    key: "ttl",
  },
]


const ddsList = [
  {
    text: "Domain Participant Id (numeric)",
    key: "domainParticipantId",
  },
  {
    text: "Qos Library Name",
    key: "qosLibraryName",
  },
  {
    text: "Qos Participant Profile",
    key: "qosParticipantProfile",
  },
  {
    text: "Qos Datawriter Profile",
    key: "qosDatawriterProfile",
  },
]



export class TransportChannelManager extends Component {
    static defaultProps = {
      newNode: {
        name: "",
        xmiType: "im:TransportChannel",
        description: "",
        tpmType: "udp",
        config: "",
        parent: "",
        subModelId: undefined,
      },
    }

    // ------------------------------------------------------------
    // State
    // ------------------------------------------------------------
    newNode = {
      name: "",
      xmiType: "im:TransportChannel",
      description: "",
      tpmType: "udp",
      config: "",
      parent: null,
    }
    state = {...this.props.newNode}

    // ------------------------------------------------------------
    // Life Cycle Methods
    // ------------------------------------------------------------
    componentDidMount() {
      this.setNodeState();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.node !== this.props.node) {
        this.setNodeState();
      }
    }

    // ------------------------------------------------------------
    // Getters
    // ------------------------------------------------------------
    generateNode = () => {
      return {
        guid: this.state.guid,
        name: this.state.name,
        xmiType: this.state.xmiType,
        description: this.state.description,
        tpmType: this.state.tpmType,
        config: this.joinConfigData(),
        parent: this.state.parent || undefined,
        subModelId: this.state.subModelId,
      }
    }

    splitConfigData = (node) => {
      const config = node?.config || "";
      let localIp="", localPort="", destinationIp="", destinationPort="", ttl="", domainParticipantId="", qosLibraryName="", qosParticipantProfile="", qosDatawriterProfile="", host="", port="", exchange="", login="", password="";

      switch (node.tpmType) {
        case "amqp":
          [host="", port="", exchange="", login="", password=""] = config.split(",");
          break;
        case "dds":
          [domainParticipantId="", qosLibraryName="", qosParticipantProfile="", qosDatawriterProfile=""] = config.split(",");
          break;
        case "udp":
        default:
          [localIp="", localPort="", destinationIp="", destinationPort="", ttl=""] = config.split(",");
      }

      return { localIp, localPort, destinationIp, destinationPort, ttl, domainParticipantId, qosLibraryName, qosParticipantProfile, qosDatawriterProfile, host, port, exchange, login, password }
    }

    joinConfigData = () => {
      const { localIp, localPort, destinationIp, destinationPort, ttl, domainParticipantId, qosLibraryName, qosParticipantProfile, qosDatawriterProfile, host, port, exchange, login, password } = this.state;
      switch (this.state.tpmType) {
        case "amqp":
          return [host, port, exchange, login, password].join(",");
        case "dds":
          return [domainParticipantId, qosLibraryName, qosParticipantProfile, qosDatawriterProfile].join(",");
        case "udp":
        default:
          return [localIp, localPort, destinationIp, destinationPort, ttl].join(",");
      }
    }

    // ------------------------------------------------------------
    // Setters
    // ------------------------------------------------------------
    setNodeState = () => {
      const { node={} } = this.props;
      if (!node.guid) node.guid = createPhenomGuid();

      this.setState({
        ...node,
        tpmType: node.tpmType || "udp",
        ...this.splitConfigData(node),
      })
    }

    updateProp = (e) => {
      const target = e.target.id.match(/\b(name|description|tpmType|localIp|localPort|destinationIp|destinationPort|ttl|domainParticipantId|qosLibraryName|qosParticipantProfile|qosDatawriterProfile|host|port|exchange|login|password)\b/);
      if (!target) return;
      

      // input limits
      switch (target[0]) {
        case "description":
          // keep string as is (after switch statement, commas are removed)
          return this.setState({ [target[0]]: e.target.value });

        case "domainParticipantId":
          // numeric
          var num = e.target.value.match(/[0-9]*/)[0];
          return this.setState({ [target[0]]: num });

        case "qosLibraryName":
        case "qosParticipantProfile":
        case "qosDatawriterProfile":
          // alpha-numeric
          var val = e.target.value.match(/[0-9A-Za-z_]*/)[0];
          return this.setState({ [target[0]]: val });

        case "ttl":
          // limit: 1-255
          var num = e.target.value.match(/[0-9]*/)[0];
          if (!num || (num > 0 && num < 256)) this.setState({ [target[0]]: num });
          return;

        default:
      }

      // remove comma
      let value = e.target.value.replace(/[,]/g, "");
      this.setState({ [target[0]]: value});
    }


    render() {
      const { editable } = this.props;
      const original = this.props.node;
      const phenomId = new PhenomId('edit-transport-channel');

      return <div className="edit-form">
              <div className="p-row">
                <div className="p-col">
                    <PhenomInput label="Transport Channel"
                                //  className="grid-col-6"
                                value={this.state.name}
                                originalValue={original["name"]}
                                disabled={!editable}
                                autoComplete={this.props.autoComplete}
                                id={phenomId.gen("init", "name")}
                                onChange={(e) => this.setState({ name: e.target.value })} />

                    <PhenomTextArea label="Description"
                                    value={this.state.description}
                                    originalValue={original["description"]}
                                    disabled={!editable}
                                    autoComplete={this.props.autoComplete}
                                    id={phenomId.gen("init", "description")}
                                    onChange={(e) => this.setState({ description: e.target.value })} />

                    <PackageComboBox label="Parent Package"
                                      xmiType="skayl:IntegrationModel"
                                      placeholder="<Default>"
                                      nodeGuid={this.state.guid}
                                      selectedGuid={this.state.parent}
                                      originalGuid={original["parent"]}
                                      disabled={!editable}
                                      autoComplete={this.props.autoComplete}
                                      id={phenomId.gen("", "parent")}
                                      onChange={(parent) => this.setState({ parent: parent.guid })}
                                      onClickResetIcon={() => this.setState({ parent: original["parent"] })} />
                </div>

                <div className="edit-side">
                  <NodeHistory2 guid={this.state.guid}
                                idCtx={phenomId.genPageId()}
                                ref={ele => this.historyRef = ele} />
                  <ChangeSetPicker id={phenomId.genPageId()}
                                   label="Change Set"
                                   autoComplete={this.props.autoComplete} />
                </div>
              </div>

              <PhenomRadioButtons label="Type"
                                  data={tpmTypeList}
                                  dataItemKey="key"
                                  textField="text"
                                  value={this.state.tpmType}
                                  disabled={!editable}
                                  style={{ width: 500 }}
                                  autoComplete={this.props.autoComplete}
                                  id={phenomId.gen(["init","tpmType"],"")}
                                  onChange={this.updateProp} />

              <div className="p-row">
                {this.state.tpmType === "udp" && udpList.map(({text, key}, idx) => {
                  return <div className="p-col p-col-6 p-col-fixed" key={key}>
                            <PhenomInput label={text}
                                          value={this.state[key]}
                                          disabled={!editable}
                                          id={phenomId.gen(["init", key],"")}
                                          autoComplete={this.props.autoComplete}
                                          onChange={(e) => this.setState({ [key]: e.target.value })} />
                        </div>
                  })}

                {this.state.tpmType === "dds" && ddsList.map(({text, key}) => {
                  return <div className="p-col p-col-6" key={key}>
                            <PhenomInput label={text}
                                          value={this.state[key]}
                                          disabled={!editable}
                                          id={phenomId.gen(["init", key],"")}
                                          autoComplete={this.props.autoComplete}
                                          onChange={(e) => this.setState({ [key]: e.target.value })} />
                          </div>
                })}

                {this.state.tpmType === "amqp" &&
                  ["host", "port", "exchange", "login", "password"].map((kind, idx) => {
                    return <div className="p-col p-col-6" key={kind}>
                              <PhenomInput label={splitCamelCaseWithSpace(kind)}
                                            value={this.state[kind]}
                                            disabled={!editable}
                                            id={phenomId.gen(["init", kind],"")}
                                            autoComplete={this.props.autoComplete}
                                            onChange={(e) => this.setState({ [kind]: e.target.value })} />
                            </div>
                  })}
              </div>
      </div>
    }
}


export const EditTransportChannelManager = withPageLayout(TransportChannelManager);
