import {CadetInput, CadetTextArea, LineLabel} from "../stateless";
import {NodeHistory2} from "../../edit/node-history";
import React from "react";
import PhenomId from "../../../requests/phenom-id";
import ChangeSetPicker from "../../widget/ChangeSetPicker";

export class MessageBasics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guid: false,
            name: this.props.name,
            description: this.props.description,
            id: this.props.id,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.guid !== prevProps.guid || this.props.name !== prevProps.name) {
            this.setState({
                name: this.props.name,
                description: this.props.description,
                guid: this.props.guid,
                id: this.props.id,
            });
        }
    }

    reportState = () => {
        return {
            ...this.state,
        };
    };

    reset = resetHistory => {
        this.setState(this.props, () => {
            if (resetHistory) {
                this.historyRef.updateHisotry();
            }
        });
    };

    render() {
        const phenomId = new PhenomId("message-basics",this.props.idCtx);
        return (
            <React.Fragment>
                <div className="flex-h">
                    <div className="flex-v" style={{flexGrow: 1}}>
                        <LineLabel text="MESSAGE" idCtx={phenomId.gen("","name")}/>
                        <CadetInput text={this.state.name} placeholder="enter message name"
                                    onChange={e => this.setState({name: e.target.value})}
                                    disabled={!this.props.editable}
                                    idCtx={phenomId.gen("","name")}/>
                        <LineLabel text="ID" idCtx={phenomId.gen("","id")}/>
                        <CadetInput text={this.state.id || ""} onChange={e => this.setState({id: e.target.value})}
                                    idCtx={phenomId.gen("","id")} placeholder="optional message identifier" diabled={!this.props.editable}/>
                    </div>
                    <div className="edit-side-bar">
                        <NodeHistory2 ref={ele => this.historyRef = ele} guid={this.state.guid} idCtx={phenomId.gen()}/>
                        <ChangeSetPicker id={phenomId.genPageId()} label="Change Set" />
                    </div>
                </div>
                <LineLabel text="Description" idCtx={phenomId.gen("","description")}/>
                <CadetTextArea text={this.state.description || ""} placeholder="enter message description"
                               onChange={e => this.setState({description: e.target.value})}
                               idCtx={phenomId.gen("","description")} disabled={!this.props.editable}/>
            </React.Fragment>
        );
    }
}
