import React from "react";
import $ from 'jquery';
import {clone, cloneDeep} from "lodash";
import {Path} from "./path";
import {Button} from "@progress/kendo-react-buttons";
import {Checkbox} from '@progress/kendo-react-inputs';
import {BasicAlert} from "../dialog/BasicAlert";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import {Notifications} from "../edit/notifications";
import {getNodeWithAddenda, getNodesOfType} from "../../requests/sml-requests";
import {DropdownSelect} from "./templates/basic_editor";
import {deGuidify} from "./util";
import {LineLabel, CadetLink, SelectChangeSetId} from "./stateless";
import PhenomId from "../../requests/phenom-id";
import {TypeAutoFill} from "./stateless"
import {Observables} from "./observables"
import ChangeSetPicker from "../widget/ChangeSetPicker";
import { getActiveChangeSetId } from "../../requests/actionCreators";
import { _ajax } from "../../requests/sml-requests";
import DeletionConfirm2 from "../dialog/DeletionConfirm2";
import { removeDeprecationIssues } from "../../requests/actionCreators";

export class FixMyModel extends React.Component {
    constructor(props) {
        super(props);
        FixMyModel.__singleton = this;

        this.fixRef = React.createRef();
        this.noticeRef = React.createRef();
        this.hashRef = {};
        this.phenomId = new PhenomId("fix-my-model",this.props.idCtx);
    }

    state = {
        visible: false,
        loading: false,
        saving: false,
        node: {},                   // charPath, aePath
        nodes: {},
        parentTypeGuid: "",         // aePath
        measurements: {},           // editType
        possibleMeasurements: {},   // editType
        observables: {},            // editType
        obsGlobal: "",              // editType
        obsOriginal: "",            // editType
        toBeFixed: "none",
        successMsg: "",
        errorMsg: "",
        selectAll: true,
        changeSetId: "0"
    }

    text = {
        none: {
            title: "",
            nodeType: "",
            columns: []
        },
        charPath: {
            title: "View Characteristic Path Edit",
            nodeType: "View Characteristics",
            columns: ["Characteristic", "View"]
        },
        aePath: {
            title: "Participant Path Edit",
            nodeType: "Participants",
            columns: ["Participant", "Assocation"]
        },
        editType: {
            title: "Entity Composition Type Edit",
            nodeType: "Compositions",
            columns: ["Composition", "Entity", "Change Observable To"]
        },
    }


    setup = (nodes) => {
        const hash = {};
        nodes.forEach(node => {
            hash[node.guid] = {...node};
            hash[node.guid].columns = [node.name || node.rolename,
                                       <CadetLink node={node.parent} newPage={true} style={{fontSize: 16}} />];
            hash[node.guid].checked = true;
        });

        return hash;
    }

    getCompAssocEntNodes = (guids) => {
        return $.ajax({
            url: "/index.php?r=/compassoc/get-comp-assoc-ent-nodes",
            method: "get",
            data: {
                guids
            }
        })
    }

    getObservables = () => {
        return $.ajax({
            url: "/index.php?r=/observable/model-nodes-of-type",
            method: "get",
            data: {
                coreAddenda: ["realizations"],
                realizationsFilter: ["xmi:type", "logical:Measurement"]
            }
        })
    }


    static async bulkEditType(obsGuid, compNodes, successMsg = "", errorMsg = "") {
        if (!FixMyModel.__singleton) new FixMyModel();
        FixMyModel.__singleton.__show();

        const guids = compNodes.map(node => node.guid);
        const res = await Promise.all([
            FixMyModel.__singleton.getObservables(),
            FixMyModel.__singleton.getCompAssocEntNodes(guids),
            getNodesOfType("logical:Measurement")
        ])

        const observables = deGuidify(JSON.parse(res[0]).nodes);
        const projectors = JSON.parse(res[1]);
        const measurements = deGuidify(JSON.parse(res[2]).nodes);
        const possibleMeasurements = deGuidify(observables[obsGuid].realizations);
        const nodes = FixMyModel.__singleton.setup(compNodes);
        Object.values(nodes).forEach(node => {
            node.columns.push(observables[obsGuid] ? <CadetLink node={observables[obsGuid]} newPage={true} style={{fontSize: 16}} /> : "");
            node.projectors = projectors[node.guid];
            if (node.projectors.length && node.projectors.every(view => view.xmiType === "platform:View")) node.highlight = true;
        })

        FixMyModel.__singleton.setState({
            loading: false,
            toBeFixed: "editType",
            nodes,
            measurements,
            possibleMeasurements,
            observables,
            obsGlobal: obsGuid,
            obsOriginal: obsGuid,
            successMsg,
            errorMsg,
        });

    }

    // platform:CharacteristicProjection
    static async bulkEditCharPath(charNodes, pathHead, pathPairs, successMsg, errorMsg) {
        if (!FixMyModel.__singleton) new FixMyModel();
        FixMyModel.__singleton.__show();

        const node = await getNodeWithAddenda(charNodes[0].guid, { coreAddenda: ["projectedCharacteristic", "measurement", "constraint"] });
        const nodes = FixMyModel.__singleton.setup(charNodes);

        FixMyModel.__singleton.setState({
            loading: false,
            toBeFixed: "charPath",
            node: JSON.parse(node),
            nodes,
            pathHead: cloneDeep(pathHead),
            pathPairs: cloneDeep(pathPairs),
            successMsg,
            errorMsg,
        });
    }

    // conceptual:AssociatedEntity
    static async bulkEditAePath(aeNodes, pathHead, pathPairs, successMsg, errorMsg) {
        if (!FixMyModel.__singleton) new FixMyModel();
        FixMyModel.__singleton.__show();

        const node = await getNodeWithAddenda(aeNodes[0].guid);
        const parentTypeGuid = pathPairs[0].parent.guid;
        const nodes = FixMyModel.__singleton.setup(aeNodes);

        FixMyModel.__singleton.setState({
            loading: false,
            toBeFixed: "aePath",
            node: JSON.parse(node),
            nodes,
            parentTypeGuid,
            pathPairs: cloneDeep(pathPairs),
            successMsg,
            errorMsg,
        });
    }

    __delete = () => {
        let nodesToBeDeleted = Object.values(this.state.nodes).filter(node => node.checked).map(node => node.guid);
        if (!nodesToBeDeleted.length) return;

        DeletionConfirm2.showMulti(nodesToBeDeleted, "Attributes", (e) => {
            // remove deleted nodes from redux's health report
            removeDeprecationIssues(e.deleted_guids);
            this.__close();
        }, true);
    }

    __save = () => {
        let nodesToBeUpdated = Object.values(this.state.nodes).filter(node => node.checked).map(node => node.guid);
        if (!nodesToBeUpdated.length) return;
        this.savingOn();
        let successMsg = this.state.successMsg.length ? this.state.successMsg : "All changes have been saved.";
        let errorMsg = this.state.errorMsg.length ? this.state.errorMsg : "Server responded with an unexpected status code.";
        let fixRef = this.fixRef.current;
        let noticeRef = this.noticeRef.current;
        let errors = [];
        let data = {};

        if (nodesToBeUpdated.length) {
            switch (this.state.toBeFixed) {
                case "charPath":
                    if (!fixRef.state.pathPairs.length) {
                        this.savingOff();
                        return noticeRef.note("A projected characteristic could not be set. Please make sure a valid path has been set.");
                    }
                    data = {
                        xmiType: "platform:CharacteristicProjection",
                        guids: nodesToBeUpdated,
                        path: fixRef.state.pathPairs.map(pathPair => pathPair.guid).join(" ")
                    }
                    break;
                case "aePath":
                    const lastHop = fixRef.state.pathPairs[fixRef.state.pathPairs.length - 1];
                    if (lastHop && lastHop.type.xmiType !== "conceptual:Observable") {
                        this.savingOff();
                        return noticeRef.note("The path must terminate on a composition of an observable type.");
                    }
                    data = {
                        xmiType: "conceptual:AssociatedEntity",
                        guids: nodesToBeUpdated,
                        path: fixRef.state.pathPairs.map(pathPair => pathPair.guid).join(" ")
                    }
                    break;
                case "editType":
                    if(this.state.obsGlobal === this.state.obsOriginal) errors = errors.concat("Please select a different Observable.");

                    data = {
                        xmiType: "conceptual:Composition",
                        guids: nodesToBeUpdated,
                        obsGuid: this.state.obsGlobal,
                    };

                    Object.values(this.hashRef).forEach(ref => {
                        if (ref.current === null) return;
                        const list = ref.current.generateListOfChars();

                        if (list.errors) return errors = errors.concat(list.errors);
                        if (data.charGuids === undefined) {
                            data.charGuids = list.charGuids;
                            data.measGuids = list.measGuids;
                        } else {

                            data.charGuids = data.charGuids.concat(list.charGuids);
                            data.measGuids = data.measGuids.concat(list.measGuids);
                        }
                    })
                    if (errors.length) {
                        this.savingOff();
                        return noticeRef.note(errors);
                    }
                    break;
            }
            data.changeSetId = getActiveChangeSetId();

            $.ajax({
                url: "/index.php?r=/edit/bulk-edit",
                method: "put",
                data
            }).then(res => {
                this.__close();
                res === "#true" ? BasicAlert.show(successMsg, "Process Complete") : BasicAlert.show(errorMsg, "Process Failed");
            })
        } else {
            this.__close();
            BasicAlert.show("Please select a node to be updated", "Process Incomplete");
        }
    }

    savingOn() {
        this.setState({saving: true});
    }

    savingOff() {
        this.setState({saving: false});
    }

    __show() {
        FixMyModel.__singleton.setState({
            visible: true,
            loading: true,
        });
    }

    // simulate unmount
    __close() {
        FixMyModel.__singleton.setState({
            visible: false,
            loading: false,
            saving: false,
            node: {},                   // charPath, aePath
            nodes: {},
            parentTypeGuid: "",         // aePath
            measurements: {},           // editType
            possibleMeasurements: {},   // editType
            observables: {},            // editType
            obsGlobal: "",              // editType
            obsOriginal: "",            // editType
            toBeFixed: "none",
            successMsg: "",
            errorMsg: "",
            selectAll: true,
        });
        FixMyModel.__singleton.fixRef = React.createRef();
        FixMyModel.__singleton.hashRef = {};
    }



    generateNewCharPath = (node) => {
        const fixRef = this.fixRef.current;
        return {
            ...node,
            measurement: node.measurement.guid,
            path: fixRef.state.pathPairs.map(pathPair => pathPair.guid).join(" "),
            constraint: node.constraint ? node.constraint.guid : null,
        }
    }

    generateNewAePath = (node) => {
        const fixRef = this.fixRef.current;
        return {
            ...node,
            path: fixRef.state.pathPairs.map(pathPair => pathPair.guid).join(" "),
        }
    }

    renderCharPath = () => {
        return <><Table text={this.text[this.state.toBeFixed]}
            nodes={this.state.nodes}
            selectAll={this.state.selectAll}
            handleCheckbox={this.handleCheckbox}
            idCtx={this.phenomId.gen("char-path")}/>

            <Path builder="charPath"
                disabled={false}
                pathPairs={this.state.pathPairs}
                pathHead={this.state.node.projectedCharacteristic}
                measurement={this.state.node.measurement}
                ref={this.fixRef}
                idCtx={this.phenomId.gen("char-path")}
                pathLabel={"New Path"}/></>
    }

    renderAePath = () => {
        return <><Table text={this.text[this.state.toBeFixed]}
            nodes={this.state.nodes}
            selectAll={this.state.selectAll}
            handleCheckbox={this.handleCheckbox}
            idCtx={this.phenomId.gen("ae-path")} />

            <Path builder="aePath"
                disabled={false}
                pathPairs={this.state.pathPairs}
                parentTypeGuid={this.state.parentTypeGuid}
                parentAEGuid={this.state.node.parent}
                ref={this.fixRef}
                idCtx={this.phenomId.gen("ae-path")} 
                pathLabel={"New Path"}/></>
    }

    renderEditType = () => {
        const phenomId = this.phenomId;
        const mHash = {};
        const views = {};
        const comps = {};
        {
            Object.values(this.state.nodes).forEach(comp => {
                if (!comp.checked || comp.projectors.length < 1) return null;
                comp.projectors.forEach(view => {
                    if (view.children.length < 1 || view.xmiType !== "platform:View") return null;

                    view.children.forEach(char => {
                        if (char.path.includes(comp.guid)) {
                            if(comps[comp.guid] === undefined) comps[comp.guid] = comp;
                            if(views[view.guid] === undefined) views[view.guid] = view;

                            if (mHash[char.measurement] === undefined) {
                                mHash[char.measurement] = { chars: {} };
                            }

                            mHash[char.measurement].chars[char.guid] = {
                                ...char,
                                viewGuid: view.guid,
                                compGuid: comp.guid
                            }
                        }
                    })
                })
            })
        }

        return <>
            <div className="flex-h">
                <div style={{width: "50%", padding: "10px 20px"}}>
                    <LineLabel text="Current Observable" idCtx={phenomId.gen("edit-type","current-observable")}/>
                    <label style={{padding: 10, fontWeight: 300}}>
                        <CadetLink node={this.state.observables[this.state.obsOriginal]} newPage={true} style={{fontSize: 16}} idCtx={phenomId.gen("","current-observable")}/>
                    </label>
                </div>
                <div style={{width: "50%", padding: "10px 20px"}}>
                    <LineLabel text="Assign new Observable" idCtx={phenomId.gen("","assign-new-observable")} style={{padding: "0px 0px 5px 0px"}}/>
                    {Object.values(this.state.observables).length &&
                      <TypeAutoFill
                        style={{width: "150px", fontSize: "12px"}}
                        data={Object.values(this.state.observables)}
                        textField="name"
                        value={this.state.observables[this.state.obsGlobal]}
                        idCtx={phenomId.gen("")}
                        showAll={false}
                        disabled={this.props.disabled}
                        onChange={(evt) => this.handleObsChange(evt.target.value.guid)}
                        addTypeIdent={false}/>}
                </div>
            </div>

            <Table text={this.text[this.state.toBeFixed]}
                nodes={this.state.nodes}
                selectAll={this.state.selectAll}
                handleCheckbox={this.handleCheckbox}
                idCtx={phenomId.gen("","to-be-fixed")}/>

            {Object.entries(mHash).map(([mGuid, mVals],mIdx) => {
                this.hashRef[mGuid] = React.createRef();
                return <MeasurementGroup ref={this.hashRef[mGuid]}
                    chars={mVals.chars}
                    comps={comps}
                    views={views}
                    measurementOriginal={mGuid}
                    measurements={this.state.measurements}
                    possibleMeasurements={this.state.possibleMeasurements}
                    idCtx={phenomId.gen(["edit-type",mIdx])}/>
            })}
        </>
    }

    handleCheckbox = (guid, bool) => {
        if (guid) {
            this.state.nodes[guid].checked = bool;
        } else {
            Object.values(this.state.nodes).forEach(node => node.checked = bool);
            this.state.selectAll = bool;
        }
        this.forceUpdate();
    }

    handleObsChange = (obsGuid) => {
        const {observables} = this.state;
        const possibleMeasurements = deGuidify(observables[obsGuid].realizations);

        Object.values(this.state.nodes).forEach(node => node.columns[node.columns.length - 1] = observables[obsGuid] ? observables[obsGuid].name : "")
        this.setState({obsGlobal: obsGuid, possibleMeasurements});
    }

    handleMeasChange = (measGuid, compGuid, viewGuid, charGuid) => {
        let {projectors} = this.state.nodes[compGuid];
        let view = projectors.find(view => view.guid === viewGuid);

        if (charGuid !== undefined) {
            let char = view.children.find(char => char.guid === charGuid);
            char.measurement = measGuid;
        } else {
            view.children.forEach(char => char.measurement = measGuid);
        }
        this.forceUpdate();
    }

    render() {
        let {toBeFixed} = this.state;
        let FixMe = null;
        const phenomId = this.phenomId;

        switch (toBeFixed) {
            case "charPath":
                FixMe = this.renderCharPath();
                break;
            case "aePath":
                FixMe = this.renderAePath();
                break;
            case "editType":
                FixMe = this.renderEditType();
                break;
        }

        return (<div id={phenomId.gen("","wrapper")}>
            {this.state.visible && <div className="react-modal" style={{padding: "45px 200px", zIndex: 15}}>
                <Notifications ref={this.noticeRef} style={{left: "auto", right: 100}} />
                <div style={{display:"flex", flexDirection:"column", height:"100%", background: "white"}}>

                    {/* header */}
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 20px", background: "#8aa5b2"}}>
                        <span style={{fontSize: 20, color: "#fff", fontWeight: "bold"}} idCtx={phenomId.gen("","to-be-fixed-span")}>
                            {this.text[toBeFixed].title}
                        </span>
                        <div className="flex-h" style={{bottomMargin: 10}}>
                            <Button icon="check" look="outline"
                                disabled={this.state.saving}
                                onClick={this.__save}
                                style={{fontSize: 18, marginRight: 10, color: "#fff"}}
                                idCtx={phenomId.gen("","save")}>
                                {this.state.saving ? "SAVING..." : "SAVE"}
                            </Button>
                            <Button icon="trash" look="outline" onClick={this.__delete} style={{fontSize: 18, marginRight: 10, color: "#fff"}}
                                    idCtx={phenomId.gen("","delete")}>DELETE</Button>
                            <Button icon="close" look="outline" onClick={this.__close} style={{fontSize: 18, marginRight: 10, color: "#fff"}}
                                    idCtx={phenomId.gen("","close")}>CLOSE</Button>
                            <div>
                                <LineLabel text="Choose a Change Set:" style={{fontSize:13}} idCtx={phenomId.gen("","choose-change-set")}/>
                                <ChangeSetPicker id={phenomId.gen("")} />
                            </div>
                        </div>
                    </div>

                    {/* content */}
                    {this.state.loading ? <img style={{display: "block", margin: "0 auto"}} src={loadingIcon} /> :
                        <><div style={{display: "flex", flexDirection: "column", minHeight: 500, padding: "20px", overflowY: "auto", background: "white"}}>
                            {FixMe}
                        </div></>}
                </div>
            </div>}
        </div>)
    }
}



function Table(props) {
  const phenomId = new PhenomId("table",props.idCtx);

    const handleSelectAll = () => {
        props.handleCheckbox(undefined, !props.selectAll);
    }
    console.log(props)
    return (
        <div style={{width: "75%", margin: "10px auto"}} id={phenomId.gen("","wrapper")}>
            <Button icon={!props.selectAll ? "check" : "close"}
                onClick={handleSelectAll} idCtx={phenomId.gen("","select-all")}>
                {!props.selectAll ? "Select All" : "Deselect All"}
            </Button>

            <div style={{border: "1px solid #ccc", marginTop: 10, boxSizing: "content-box"}}>
                <label style={{display: "block", padding: "5px 10px", background: "#ac8ab2", color: "#fafafa"}} idCtx={phenomId.gen("","node-type-label")}>
                    {`The selected ${props.text.nodeType} will be updated or deleted:`}</label>

                <div style={{maxHeight: 300, overflowY: "auto"}}>
                    <table style={{width: "100%"}}>
                        <tr>
                            <th style={{width: "10%", padding: "5px 10px", fontWeight: "bold", borderRight: "1px solid #ccc", textAlign: "center"}} idCtx={phenomId.gen("update","wrapper")}></th>
                            {props.text.columns.map((text, idx, arr) => {
                                const colStyle = {padding: "5px 10px", fontWeight: "bold"};
                                if (idx < arr.length - 1) colStyle.borderRight = "1px solid #ccc";
                                return (
                                    <th style={colStyle} id={phenomId.gen(["update",`header-${idx}`])}>{text}</th>
                                )
                            })}
                        </tr>
                        {Object.values(props.nodes).map((node, idx, arr) => {
                            const tdStyle = {padding: "5px 10px"};

                            const trStyle = {};
                            if (!node.checked) {
                                trStyle.background = "#eaeaea";
                                trStyle.textDecoration = "line-through";
                            }
                            if (node.highlight) {
                                trStyle.background = "#ffedce";
                            }

                            return (
                                <tr style={trStyle} id={phenomId.gen(["update",`row-${idx}`],"wrapper")}>
                                    <td style={{...tdStyle, textAlign: "center", borderRight: "1px solid #ccc"}}>
                                        <Checkbox checked={node.checked}
                                            onChange={(e) => props.handleCheckbox(node.guid, e.target.element.current.checked)}
                                            label=""
                                            idCtx={phenomId.gen(`row-${idx}`)}/>
                                    </td>

                                    {node.columns.map((text, cIdx, arr) => {
                                        const colStyle = {};
                                        if (cIdx < arr.length - 1) colStyle.borderRight = "1px solid #ccc";
                                        return (
                                            <td style={{...tdStyle, ...colStyle}} idCtx={phenomId.gen([`row-${idx}`,`data-${cIdx}`])}>{text}</td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>

                </div>
            </div>
        </div>
    )
}




class MeasurementGroup extends React.Component {

    state = {
        chars: []
    }

    componentDidMount() {
        this.setState({chars: this.props.chars})
    }

    componentDidUpdate(prevProps) {
        if (prevProps.chars !== this.props.chars) {
            this.setState({chars: this.props.chars})
        }
    }

    handleMeasChange = (mGuid, charGuid) => {
        if (charGuid !== undefined) {
            this.state.chars[charGuid].measurement = mGuid;
        } else {
            Object.values(this.state.chars).forEach(char => char.measurement = mGuid);
        }
        this.forceUpdate();
    }

    generateListOfChars = () => {
        let charGuids = [];
        let measGuids = [];
        let error = false;

        Object.values(this.state.chars).forEach(char => {
            if (!this.props.possibleMeasurements[char.measurement]) return error = true;
            charGuids.push(char.guid);
            measGuids.push(char.measurement);
        })
        if (error) return {errors: ["Measurement is required"]}
        return {
            charGuids,
            measGuids
        }
    }

    render() {
        const phenomId = new PhenomId("measurement-group",this.props.idCtx)
        const tableStyle = {
            contentTable: {width: "100%", maxHeight: 300, overflow: "auto", borderTop: "1px solid #ccc"},
            th: {padding: "5px 10px", fontWeight: "bold", borderRight: "1px solid #ccc"},
            thLast: {padding: "5px 10px", fontWeight: "bold"},
            td: {padding: "5px 10px", borderRight: "1px solid #ccc"},
            tdLast: {padding: "5px 10px"}
        }

        return (
            <div style={{width: "75%", margin: "10px auto 20px", border: "1px solid #ccc", boxSizing: "content-box"}} id={phenomId.gen("","wrapper")}>
                <label style={{display: "block", padding: "5px 10px", background: "#e18f00", color: "#fafafa"}}>
                    {`Update Measurement for View Characteristics`}</label>

                {/* INFO */}
                <div className="flex-h" style={{padding: 10}}>
                    <div style={{width: "50%"}}>
                        <label>Current Measurement:</label>
                        <div id={phenomId.gen("","current-measurement")}><CadetLink node={this.props.measurements[this.props.measurementOriginal]} newPage={true} style={{fontSize: 16}} /></div>
                    </div>

                    <div style={{width: "50%"}}>
                        <LineLabel text="Set all Measurements" id={phenomId.gen("","set-all-measurements")}/>
                        <DropdownSelect data={this.props.possibleMeasurements}
                            editable={true}
                            required={true}
                            onChange={(e) => this.handleMeasChange(e.target.value)}
                            id={phenomId.gen("","set-all-measurements")} />
                    </div>
                </div>

                {/* CONTENT */}
                <div style={tableStyle.contentTable}>
                    <table style={{width: "100%"}}>
                        <thead><tr>
                            <th style={tableStyle.th}>Characteristic</th>
                            <th style={tableStyle.th}>View</th>
                            <th style={tableStyle.th}>Composition</th>
                            <th style={tableStyle.thLast}>Assign new Measurement</th>
                        </tr></thead>
                        <tbody>
                            {Object.values(this.state.chars).map((char,cIdx) => {
                                // this is here bc if this component does not unmount, you will hit a NPE
                                const comp = this.props.comps[char.compGuid] || null;
                                const compName = comp ? comp.name : "";
                                const parent = comp ? comp.parent : null;
                                const view = comp ? this.props.views[char.viewGuid] : null;

                                return (<tr id={phenomId.gen(["chars",cIdx],"wrapper")}>
                                    <td style={tableStyle.td}><CadetLink node={char} newPage={true} style={{fontSize: 16}} id={phenomId.gen("","char")}/></td>
                                    <td style={tableStyle.td}><CadetLink node={view} newPage={true} style={{fontSize: 16}} id={phenomId.gen("","view")}/></td>
                                    <td style={tableStyle.td}><CadetLink node={parent} text={compName} newPage={true} style={{fontSize: 16}} id={phenomId.gen("","parent")}/></td>
                                    <td><DropdownSelect data={this.props.possibleMeasurements}
                                        selectedGuid={char.measurement}
                                        editable={true}
                                        required={true}
                                        onChange={(e) => this.handleMeasChange(e.target.value, char.guid)}
                                        id={phenomId.gen("","possible-measurements")} /></td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
