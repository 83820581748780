import React from 'react';
import {Notifications2} from "../edit/notifications";
import $ from 'jquery';
import {elementDetail, getNodeWithAddenda} from "../../requests/sml-requests";
import {LineLabel, PackageComboBox} from "./stateless";
import {NavLink} from "react-router-dom";
import {Tags} from "./tags";
import BasicEditor from "./templates/basic_editor";
import PhenomId from "../../requests/phenom-id";
import { withPageLayout } from "../edit/node-layout";
import { getActiveChangeSetId } from '../../requests/actionCreators';
import {createPhenomGuid} from "../util/util"
import NavTree from '../tree/NavTree';
import DeletionConfirm2 from '../dialog/DeletionConfirm2';


export class LandmarkManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            related_reference_points: [],
            editable: true,
        }
        this.original = {};
        this.noticeRef = React.createRef();
    }

    componentDidMount() {
        this.loadLandmark();
        this.loadRelatedRP(this.props.match.params.guid);
        window.addEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.guid !== prevProps.match.params.guid) {
            this.loadLandmark();
            this.loadRelatedRP(this.props.match.params.guid);
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    componentWillUnmount() {
      window.removeEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    loadLandmark = () => {
        getNodeWithAddenda(this.props.match.params.guid)
          .then(res => {
              const response = JSON.parse(res);

              if (this.props.updateTemplateNode) {
                this.props.updateTemplateNode(response);
              }

              this.setStateFromResponse(response);
          })
    }

    loadRelatedRP = (guid) => {
        elementDetail(guid).then((res) => {
            const response = JSON.parse(res);
            this.setStateFromResponse(response);
        });
    }

    isEdited = () => {
        return this.state.name !== this.original.name ||
               this.state.description !== this.original.description ||
               this.state?.parent != this.original?.parent;
    }

    setStateFromResponse = (response) => {
        this.original = {...this.original, ...response};
        this.setState({ ...response }, () => {
            this.setEditingStatus();
        });
    }

    handleSave = () => {
        const noticeRef = this.noticeRef.current;

        if(!this.isEdited()) {
            return Notifications2.parseErrors("No changes detected.");
        }

        const data = {
            name: this.state.name,
            guid: this.state.guid || createPhenomGuid(),
            xmiType: "logical:Landmark",
            description: this.state.description,
            changeSetId: getActiveChangeSetId(),
            parent: this.state?.parent || null,
        }

        $.ajax({
            url: "/index.php?r=/node/smm-save-nodes",
            method: "post",
            data
        }).then(res => {
            const response = JSON.parse(res);
            if (response.errors) {
                Notifications2.parseErrors(response);
            } else if (response.guid) {
                Notifications2.parseResponse(response);

                if (this.props.updateTemplateNode) {
                    this.props.updateTemplateNode(response);
                }

                this.setStateFromResponse(response);
                NavTree.addNodes([ response ]);
            } else {
                Notifications2.parseErrors("Something went wrong when trying to save your changes.");
            }
        })
    }

    handleReset = () => {
        this.setState({...this.original});
    }

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.name);
    }

    mutateOriginalParentListener = (e) => {
      // invalid
      if (!this.state.guid) {
        return;
      }

      const leaf = NavTree.getLeafNode(this.state.guid);
      if (!leaf) {
        return;
      }

      const newParentGuid = leaf.getParentGuid();
      if (this.state.parent !== newParentGuid) {
        this.original["parent"] = newParentGuid;
      }
    }

    render() {
        const phenomId = new PhenomId("landmark",this.props.idCtx);
        return(
            <BasicEditor guid={this.state.guid}
                         nodeTitle="LANDMARK"
                         nodeName={this.state.name}
                         nodeDescription={this.state.description}
                         editable={this.state.editable}
                         onNameChange={(name) => this.setState({name})}
                         onDescriptionChange={(description) => this.setState({description})}
                         saveBtn={this.handleSave}
                         resetBtn={this.handleReset}
                         noticeRef={this.noticeRef}
                         idCtx={phenomId.gen("")}>
                <div style={{ marginBottom: 10 }}>
                    <PackageComboBox id={phenomId.genPageId("parent")}
                                      label="Package"
                                      xmiType="face:LogicalDataModel"
                                      placeholder="<Default>"
                                      nodeGuid={this.state.guid}
                                      selectedGuid={this.state.parent}
                                      disabled={!this.state.editable}
                                      onChange={(parent) => this.setState({ parent: parent.guid })}
                                      onClickCancelIcon={() => this.setState({ parent: undefined })} />
                </div>

                <LineLabel text="Reference Point" idCtx={phenomId.gen("","reference-point")}/>
                <div style={{margin:"12px 0"}}>
                    {this.state.related_reference_points.map((rp, idx, arr) => {
                        return (
                            <NavLink
                                style={{marginRight: 5}}
                                key={rp.guid}
                                to={`/edit/details/reference_point/${rp.guid}`}
                                className="cadet-anchor"
                                idCtx={phenomId.gen(["reference-point",idx],"link")}>
                                {rp.name}{idx < arr.length - 1 ? ", " : ""}
                            </NavLink>
                        );
                    })}
                </div>

                {this.state.guid &&
                    <Tags guid={this.state.guid}
                        name={this.state.name}
                        disabled={!this.state.editable}
                        idCtx={phenomId.gen("init")} />
                }
            </BasicEditor>
        )
    }
}



export const EditLandmarkManager = withPageLayout(LandmarkManager);
