import React, {Component, useState} from "react";
import Draggable from "react-draggable";
import {DialogActionsBar} from "@progress/kendo-react-dialogs";
import styled from "@emotion/styled";
import {CadetInput, CadetTextArea} from "../../../util/stateless";
import AEPathBuilder, {InlinePathBuilderForward} from "../../../edit/edit_entity/ae-path-builder";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from "@progress/kendo-react-buttons";
import {deGuidify} from "../../../util/util";
import {cloneDeep} from "lodash";
import {Modal2} from "../../../util/Modal";
import { formatPathAttr, isDiagramNode } from "../util";
import PhenomId from "../../../../requests/phenom-id";
import { getNodeProjectors } from "../../../../requests/sml-requests";



const Container = styled.div`
    display: grid;
    gap: 10px;
    grid-template-columns: auto 1fr;
    padding: 10px;
`

const Label = styled.div`
    font-size: 12px;
`

function noop() {
}

export class AssociationModal extends Component {
    constructor(props) {
        super(props);

        this.poppedPathHops = {};
    }

    pathBuilderRef = React.createRef();
    phenomId = new PhenomId("assoc-modal");

    defaultBound = {
        "lowerBound": "1",
        "upperBound": "1",
        "sourceLowerBound": "1",
        "sourceUpperBound": "1",
    }

    defaultState = {
        visible: false,
        guid: null,
        rolename: "",
        description: "",
        sourceLowerBound: 1,
        sourceUpperBound: 1,
        lowerBound: 1,
        upperBound: 1,
        existingNames: [],
        pathPairs: [],
        projectors: [],
        x: 150,
        y: 100,
        projectedEntity: {guid: "", name: "", xmiType: ""},
        errMsgs: [],
    }

    editNameOnShow = true;
    state = {
       ...this.defaultState
    }


    componentDidUpdate() {
        if(this.editNameOnShow && this.nameRef) {
            this.nameRef.focus();
            this.nameRef.select();
            this.editNameOnShow = false;
        }
    }


    show(data = {}, projectedEntity, confirmFunc, cancelFunc) {
        const existingNames = [];
        if(data.children) data.children.forEach(child => { if(child.guid !== data.guid) existingNames.push(child.rolename) });
        
        this.setState({
            visible: true,
            guid: data.guid  || null,
            rolename: data.rolename || "",
            description: data.description || "",
            sourceLowerBound: data.sourceLowerBound || "1",
            sourceUpperBound: data.sourceUpperBound || "1",
            lowerBound: data.lowerBound || "1",
            upperBound: data.upperBound || "1",
            path: data.path,
            pathPairs: data.pathPairs && data.type ? this.connectifyPathPairs(cloneDeep(data.pathPairs), data.type) : [],
            projectedEntity: projectedEntity || {guid: "", name: "", xmiType: ""},
            existingNames,
            confirmFunc: confirmFunc || noop,
            cancelFunc: cancelFunc || noop,
        }, () => {
            if (isDiagramNode(data.guid)) {
                return;
            }
            
            getNodeProjectors(data.guid).then((res) => {
                const response = JSON.parse(res);
                this.setState({ projectors: response?.projectors || [] });
            })
        });
    }

    hide = () => {
        this.setState({visible: false},
            this.clearErrors);
    }

    reverseBound = (value) => {
        if(value === "*") return "-1";
        return value;
    }

    convertBound = (value) => {
        if(value === "-1") return "*";
        return value;
    }

    setLowerBound = (value, type) => {
        if(!value) value = ""; // value could be null and bypass the above default value.
        const reg = value.match(/\d*/);
        if (reg[0].length > 1) reg[0] = reg[0].replace(/^0+/, '');
        if(!reg[0]) return type === "target" ? this.defaultBound["lowerBound"] : this.defaultBound["sourceLowerBound"];
        return reg[0];
    }

    setUpperBound = (value="", type) => {
        if(!value) value = ""; // value could be null and bypass the above default value.
        const reg = value.match(/\*|\d*/);
        if (reg[0].length > 1) reg[0] = reg[0].replace(/^0+/, '');
        if (!reg[0]) return type === "target" ? this.defaultBound["upperBound"] : this.defaultBound["sourceUpperBound"];
        return this.reverseBound(reg[0]);
    }

    generateNode = () => {
        let pathPairs = [];
        if(this.pathBuilderRef.current) {
            pathPairs = this.pathBuilderRef.current.state.pathPairs;
        }

        return {
            guid: this.state.guid,
            xmiType: "conceptual:AssociatedEntity",
            rolename: this.state.rolename || "",
            description: this.state.description || "",
            sourceLowerBound: this.state.sourceLowerBound || "1",
            sourceUpperBound: this.state.sourceUpperBound || "1",
            lowerBound: this.state.lowerBound || "1",
            upperBound: this.state.upperBound || "1",
            path: pathPairs.map(pathPair => pathPair.guid).join(" "),
            pathPairs: pathPairs.map(pair => formatPathAttr(pair, pair.parent))
        }
    }

    confirm = () => {
        if (!this.validate()) {
            this.state.confirmFunc(this.generateNode());
            this.hide();
        }
    }

    close = () => {
        this.hide();
        this.state.cancelFunc();
    }

    validate = () => {
        const errMsgs = [];
        let validUpperBound = ["-1"];
        let errBoundMsg = (text1, text2) => `${text1} cannot be greater than ${text2}`;
        const {lowerBound, upperBound, sourceLowerBound, sourceUpperBound} = this.state;
        const lastHop = this.state.pathPairs[this.state.pathPairs.length - 1];

        // let hopErr = lastHop && lastHop.type.xmiType !== "conceptual:Observable"
        // if (hopErr) errMsgs.push("The path must terminate on a composition of an Observable type.");
        let nameErr = this.state.existingNames.includes(this.state.rolename);
        if(nameErr) errMsgs.push("Rolename exists already, choose a different name.");
        let tarErr = !validUpperBound.includes(upperBound) ? lowerBound > upperBound : false;
        if(tarErr) errMsgs.push(errBoundMsg("Target Lowerbound", "Target UpperBound"));
        let srcErr = !validUpperBound.includes(sourceUpperBound) ? sourceLowerBound > sourceUpperBound : false;
        if(srcErr) errMsgs.push(errBoundMsg("Source Lowerbound", "Source UpperBound"));

        if ( nameErr || tarErr || srcErr) this.setState({nameErr, tarErr, srcErr, errMsgs});
        return  nameErr || tarErr || srcErr;
    }

    connectifyPathPairs(pathPairs, parent_type_guid) {
        pathPairs.forEach((path_pair, idx) => {
            const prev_pair = idx === 0 ? null : pathPairs[idx - 1];
            const local_parent_type_guid = idx === 0 ? parent_type_guid : prev_pair.connector === "." ? prev_pair.type.guid : prev_pair.parent.guid;

            if (path_pair.parent.guid === local_parent_type_guid) {
                path_pair.connector = ".";
            } else {
                path_pair.connector = "->";
            }
        });
        return pathPairs;
    }

    popPathHop = () => {
        const poppedHop = this.state.pathPairs.pop();

        this.poppedPathHops[poppedHop.guid] = poppedHop;
        this.clearErrors();
        // this.forceUpdate();
    };

    pushPathHop = hop => {
        this.state.pathPairs.push(hop);
        this.clearErrors();
        // this.forceUpdate();
    };

    resetPath = () => {
        if (!this.state.path) {
            this.setState({pathPairs: []},
                this.clearErrors);
            return;
        }

        const pathGuids = this.state.path.trim().split(" ");
        const hopsMap = {...this.poppedPathHops, ...deGuidify(this.state.pathPairs)};
        const newPathPairs = [];

        pathGuids.forEach(pathGuid => newPathPairs.push(hopsMap[pathGuid]));
        this.setState({pathPairs: newPathPairs},
            this.clearErrors);
    };

    clearErrors = () => {
        this.setState({
            hopErr: false, 
            nameErr: false, 
            tarErr: false, 
            srcErr: false,
            errMsgs: [],
        });
    }

    render() {
        const { projectors } = this.state;
        const gridStyle = {width:300, background:"#ccc", border:"1px solid black"}
        const inputStyle = {margin:0, background:"#fff"}
        const inputBounds = {display:"flex"}
        const inputErr = "1px solid red";
        return (
            
            // this.state.visible ? <Draggable enableUserSelectHack={false} handle=".association-drag" defaultPosition={{x:this.state.x, y:this.state.y}}>

                <Modal2 show={this.state.visible} 
                        onExitedCallback={() => {
                            this.setState({...this.defaultState}, () => {
                                this.editNameOnShow = true;
                                this.clearErrors();
                            });
                        }}>
                    <div id={this.phenomId.genPageId()} className="association-drag" style={gridStyle}>
                        <Container>
                            <Label id={this.phenomId.genPageId("rolename-label")}>ROLENAME</Label>
                            <CadetInput id={this.phenomId.genPageId("rolename-input")}
                                        text={this.state.rolename} inputRef={el => this.nameRef = el}
                                        style={{...inputStyle, border: this.state.nameErr ? inputErr : null}}
                                        onChange={(e) => this.setState({rolename: e.target.value, nameErr: false, errMsgs:[]})} />

                            <Label id={this.phenomId.genPageId("description-label")}>DESCRIPTION</Label>
                            <CadetTextArea id={this.phenomId.genPageId("description-input")} style={inputStyle} text={this.state.description} onChange={(e) => this.setState({description: e.target.value})} />

                            <Label id={this.phenomId.genPageId("source-bounds-label")}>SOURCE BOUNDS</Label>
                            <div id={this.phenomId.genPageId("source-bounds-wrapper")} style={inputBounds}>
                                <DataList data={["0", "1", "2"]}
                                          value={this.state.sourceLowerBound}
                                          allowCustom={true} 
                                          error={this.state.srcErr}
                                          onChange={(e) => this.setState({ sourceLowerBound: this.setLowerBound(e.target.value, "source") },
                                                            this.clearErrors)} />
                                ..
                                <DataList data={["0", "1", "2", "*"]} 
                                        value={this.convertBound(this.state.sourceUpperBound)}
                                        allowCustom={true} 
                                        error={this.state.srcErr}
                                        onChange={(e) => this.setState({sourceUpperBound: this.setUpperBound(e.target.value, "source")},
                                                            this.clearErrors)} />
                            </div>

                            <Label id={this.phenomId.genPageId("target-bounds-label")}>TARGET BOUNDS</Label>
                            <div id={this.phenomId.genPageId("target-bounds-wrapper")} style={inputBounds}>
                                <DataList data={["0", "1", "2"]} 
                                        value={this.state.lowerBound}
                                        allowCustom={true} 
                                        error={this.state.tarErr}
                                        onChange={(e) => this.setState({lowerBound: this.setLowerBound(e.target.value, "target")},
                                                            this.clearErrors)} />
                                ..
                                <DataList data={["0", "1", "2", "*"]} 
                                        value={this.convertBound(this.state.upperBound)}
                                        allowCustom={true} 
                                        error={this.state.tarErr}
                                        onChange={(e) => this.setState({upperBound: this.setUpperBound(e.target.value, "target")},
                                                            this.clearErrors)} />
                            </div>

                            <Label id={this.phenomId.genPageId("path-label")}>Path</Label>
                            <div id={this.phenomId.genPageId("path-wrapper")} className="flex-v" style={{fontSize:13, padding:"5px", position:"relative", minHeight: 30, background:"white", border: this.state.hopErr ? "1px solid red" : null}}>
                                {!this.state.projectedEntity.guid.startsWith("DIAGRAM_") ? 
                                    <InlinePathBuilderForward 
                                        pathPairs={this.state.pathPairs}
                                        projectedEntity={this.state.projectedEntity}
                                        mainCompGuid={this.state.guid}
                                        editable={!projectors.length}
                                        ref={this.pathBuilderRef}
                                    />
                                    : 
                                    <span />}
                            </div>
                        </Container>

                        <div id={this.phenomId.genPageId("errors-wrapper")} style={{color:"red", fontSize:10, textAlign:"center", margin:"10px -10px"}}>
                            {this.state.errMsgs.map((err, idx) => <div id={this.phenomId.genPageId("error-msg", idx)}>{err}</div>)}
                        </div>
                        <div style={{background:"#f2f2f2"}}>
                            <DialogActionsBar>
                                <Button id={this.phenomId.genPageId("confirm-btn")} look="bare" onClick={this.confirm}>CONFIRM</Button>
                                <Button id={this.phenomId.genPageId("cancel-btn")} look="bare" onClick={this.close}>CANCEL</Button>
                            </DialogActionsBar>
                        </div>
                    </div>
                </Modal2>
            // </Draggable> : null
        );
    }
}


const DataList = ({id, data, value, onChange, allowCustom, style, error}) => {
    return <ComboBox
        id={id}
        data={data}
        value={value}
        onChange={onChange}
        allowCustom={allowCustom}
        clearButton={false}
        style={{flexShrink:1, margin:"0 5px", width:"43%", background:"#fff", border: error ? "1px solid red" : null, ...style}} />;
}; 