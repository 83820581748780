import React from "react";
import {nodeHistory} from "../../requests/sml-requests";
import PhenomId from "../../requests/phenom-id"
import { formatDate, isPhenomGuid } from "../util/util";
import { PhenomLabel } from "../util/stateless";

export class NodeHistory2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            creator: "loading...",
            creation: "loading...",
            last_editor: "loading...",
            last_edit: "loading...",
        };
        this.blank = {
            creator: "",
            created: "",
            last_editor: "",
            last_edit: "",
        };
        this.loading = {
            creator: "loading...",
            created: "loading...",
            last_editor: "loading...",
            last_edit: "loading...",
        };

    }

    componentDidMount() {
        if (this.props.guid && this.props.guid !== "new") {
            this.setState(this.loading);
            this.updateHisotry();
        } else {
            this.setState(this.blank);
        }
    }


    componentDidUpdate(prevProps) {
        if (this.props.guid !== prevProps.guid) {
            if (this.props.guid && this.props.guid !== "new" && !isPhenomGuid(this.props.guid)) {
                this.setState(this.loading);
                this.updateHisotry();
            } else {
                this.setState(this.blank);
            }
        }
    }

    updateHisotry() {
        nodeHistory(this.props.guid).then((data) => {
            const hist = JSON.parse(data);

            this.setState({
                creator: hist.creator,
                created: hist.creation.substring(0, 19),
                last_editor: hist.last_editor,
                last_edit: hist.last_edit.substring(0, 19),
            });
        });
    }

    render() {
        const phenomId = new PhenomId([this.props.idCtx,"node-history"])
        return <div className="node-history-wrapper">
                  <PhenomLabel id={phenomId.gen("init")} text="Last Modified By" />
            <span><p>{this.state.last_editor}</p><p>{formatDate(this.state.last_edit, true)}</p></span>
        </div>
    }
}
