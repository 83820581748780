import React from 'react'
import Portal from '../../../../dialog/Portal'
import { CSSTransition } from 'react-transition-group'
import { PhenomSelect } from '../../../../util/stateless'
import { splitXmiType, splitCamelCaseWithSpace, getShortenedStringRepresentationOfXmiType } from '../../../../util/util'
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Notifications2 } from '../../../../edit/notifications'
import { useState } from 'react'
import { useEffect } from 'react'
import PhenomId from '../../../../../requests/phenom-id'



export default class DataTypeModal extends React.Component {
  constructor(props) {
    super(props);
    this.phenomId = new PhenomId("data-type-modal")
  }

  state = {
    animateIn: true,
    srcMessagePort: null,
    dstMessagePort: null,

    type1: null,
    type2: null,
    saveCallback: null,
  }

  show = (srcNodeModel, dstNodeModel, saveCallback) => {
    this.setState({ srcNodeModel, dstNodeModel, saveCallback });
  }

  close = () => {
    this.setState({ animateIn: false }, () => {
      setTimeout(() => {
        this.setState({
          srcNodeModel: null,
          dstNodeModel: null,
          srcMessagePort: null,
          dstMessagePort: null,
          type1: null,
          type2: null,
          saveCallback: null,
          animateIn: true,
        })
      }, 300);
    })
  }

  save = () => {
    const { srcNodeModel, dstNodeModel, saveCallback, type1, type2 } = this.state;
    const xmiTypes = ["im:UoPInstance", "im:ComposedBlockInstance"]
    if ((xmiTypes.includes(srcNodeModel.getXmiType()) && !type1) || (xmiTypes.includes(dstNodeModel.getXmiType()) && !type2)) {
      return Notifications2.parseWarnings("Please select a connection type and try again.");
    }

    saveCallback && saveCallback(type1, type2);
    this.close()
  }

  render() {
    const { srcNodeModel, dstNodeModel, type1, type2 } = this.state;
    if (!srcNodeModel || !dstNodeModel) return null;

    const sectionStyle = {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // flexBasis: "calc(50% - 10px)",
      // width: "calc(50% - 10px)",
      gap: 10,
      fontSize: 14,
      padding: 5,
      overflow: "hidden",
    }
    
    return <Portal>
              <CSSTransition in={this.state.animateIn}
                              timeout={300}
                              appear={true}
                              mountOnEnter
                              unmountOnExit
                              classNames="fade">
                    <Dialog title="Create new connection" onClose={this.close}>
                      <div style={{ display: "flex",
                                    gap: 10,
                                    background: "white" }}>

                        {srcNodeModel.getXmiType() === "im:UoPInstance" &&
                        <div style={sectionStyle}>
                          <SourceUoPIDetail $app={this.props.$app}
                                            nodeModel={srcNodeModel}
                                            value={type1}
                                            phenomId={this.phenomId}
                                            onChange={(srcMessagePort) => this.setState({ type1: srcMessagePort?.guid })} />
                        </div> }

                        {dstNodeModel.getXmiType() === "im:UoPInstance" &&
                        <div style={sectionStyle}>
                          <DestinationUoPIDetail $app={this.props.$app}
                                                 nodeModel={dstNodeModel}
                                                 value={type2}
                                                 phenomId={this.phenomId}
                                                 onChange={(dstMessagePort) => this.setState({ type2: dstMessagePort?.guid })} />
                        </div> }

                        {srcNodeModel.getXmiType() === "im:ComposedBlockInstance" &&
                        <div style={sectionStyle}>
                          <ComposedBlockInstanceDetail $app={this.props.$app}
                                            nodeModel={srcNodeModel}
                                            value={type1}
                                            phenomId={this.phenomId}
                                            showInPorts={false}
                                            onChange={(e) => this.setState({ type1: e.target.value })} />
                        </div> }

                        {dstNodeModel.getXmiType() === "im:ComposedBlockInstance" &&
                        <div style={sectionStyle}>
                          <ComposedBlockInstanceDetail $app={this.props.$app}
                                                 nodeModel={dstNodeModel}
                                                 value={type2}
                                                 phenomId={this.phenomId}
                                                 showInPorts={true}
                                                 onChange={(e) => this.setState({ type2: e.target.value })} />
                        </div> }

                      </div>
                      <DialogActionsBar>
                          <button className="k-button k-primary" 
                                  autoFocus={true}
                                  id={this.phenomId.genPageId("save-btn")}
                                  onClick={this.save}>Save
                          </button>
                          <button className="k-button"
                                  id={this.phenomId.genPageId("cancel-btn")}
                                  onClick={() => this.close()}>Cancel
                          </button>
                      </DialogActionsBar>
                    </Dialog>
              </CSSTransition>
           </Portal>
  }
}




const SourceUoPIDetail = ({ nodeModel, value, onChange, phenomId }) => {
  const stormData = nodeModel.getStormData();
  const [msgPortList, setMsgPortList] = useState([]);

  useEffect(() => {
    const msgPorts = nodeModel.getAvailableMessagePorts(false).map(stormData => stormData.getData());
    msgPorts.length && msgPorts.unshift({
      guid: "",
      name: "--Select a message port--",
      xmiType: "uop:MessagePort",
    })

    setMsgPortList(msgPorts);
  }, [])

  if (!msgPortList.length) {
    return <div>No available data</div>
  }

  return <PhenomSelect label={`Select Message Port for ${stormData.getName() || getShortenedStringRepresentationOfXmiType(stormData.getXmiType())}`}
                       data={msgPortList}
                       value={value || ""}
                       dataItemKey="guid"
                       id={phenomId.genPageId("source")}
                       onChange={(e) => onChange(msgPortList.find(msg => msg.guid === e.target.value))}
                       labelProps={{ style: { textTransform: "none" } }} />
}


const DestinationUoPIDetail = ({ nodeModel, value, onChange, phenomId }) => {
  const stormData = nodeModel.getStormData();
  const [msgPortList, setMsgPortList] = useState([]);

  useEffect(() => {
    const msgPorts = nodeModel.getAvailableMessagePortsBasedOnInputEndPoints().map(stormData => stormData.getData());
    msgPorts.length && msgPorts.unshift({
      guid: "",
      name: "--Select a message port--",
      xmiType: "uop:MessagePort",
    })

    setMsgPortList(msgPorts);
  }, [])

  if (!msgPortList.length) {
    return <div>No available data</div>
  }

  return <PhenomSelect label={`Select Message Port for ${stormData.getName() || getShortenedStringRepresentationOfXmiType(stormData.getXmiType())}`}
                       data={msgPortList}
                       value={value || ""}
                       dataItemKey="guid"
                       id={phenomId.genPageId("destination")}
                       onChange={(e) => onChange(msgPortList.find(msg => msg.guid === e.target.value))}
                       labelProps={{ style: { textTransform: "none" } }} />
}



const ComposedBlockInstanceDetail = ({ $app, nodeModel, value, showInPorts, onChange, phenomId }) => {
  const stormData = nodeModel.getStormData();
  const [portOptions, setPortOptions] = useState([]);

  useEffect(() => {
    const ports = Object.values(nodeModel.getPorts()).filter((port) => {
      if (port.getName() === 'draw-tool' || port.countLinks() > 0) {
        return false;
      }
      return (showInPorts && port.getOptions().in) || (!showInPorts && !port.getOptions().in);
    }).map((port) => {
      const attr = port.getAttrData();


      let name = "";
      if (attr.getName()) {
        name = `${attr.getName()} | `;
      }

      if (attr.getAttr("realized_type") === "Templated") {
        name += `${attr.getAttr("realized_templateType")} (Templated)`;
      } else {
        const view = $app.findNode(attr.getAttr("dataType"));
        name += `${view?.name} (DataType)`;
      }

      return {
        guid: attr.getGuid(),
        name,
      }
    });

    ports.length && ports.unshift({
      guid: "",
      name: "--Select a port--",
    })
    
    // debugger
    setPortOptions(ports);
  }, [])

  if (!portOptions.length) {
    return <div>No available data</div>
  }

  return <PhenomSelect label={`Select Port for ${stormData.getName() || getShortenedStringRepresentationOfXmiType(stormData.getXmiType())}`}
                       data={portOptions}
                       value={value || ""}
                       dataItemKey="guid"
                       textField="name"
                       id={phenomId.genPageId("cbi_port")}
                       onChange={onChange}
                       labelProps={{ style: { textTransform: "none" } }} />
}
