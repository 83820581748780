import $ from "jquery";
import Circos from "circos";
import {FadingDirections, PhenomLabel} from "../util/stateless";
import {BasicAlert} from "../dialog/BasicAlert";
import React from "react";
import { connect } from "react-redux";
import {getSemanticsMulti} from "../../requests/sml-requests";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import PhenomId from "../../requests/phenom-id";
import NavTree from "../tree/NavTree";

class MeridianMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewTable: {},
            matchTable: {},
            selectedChar: null,
            selectedView: null,
            relatedChars: [],
            selection: [],
            showLogical: false,
            loading: false,
        };
        this.setState = this.setState.bind(this);
        this.phenomId = new PhenomId("meridian-map",this.props.idCtx);

        this.directionsRef = undefined;
        this.myMeridianMap = undefined;
        this.viewLayoutConfiguration = { //configuration for the view tracks on the outside
            innerRadius: (500 / 2 - 500 / 100 - 500 / 50),
            outerRadius: (500 / 2 - 500 / 100),
            cornerRadius: 10,
            gap: 0.04, // in radian
            labels: {
                display: false,
                position: 0,
                size: "14px",
                color: "#000000",
                radialOffset: 20,
            },
            ticks: {
                display: false
            },
            events: {
                "click": () => {
                    $(".circos-tooltip").fadeTo(500, 0);
                },
                "mouseover": function (d, _, __, event) {
                    const tooltip = $(".circos-tooltip");
                    tooltip.text(d.label);
                    tooltip.css("left", event.clientX);
                    tooltip.css("top", event.clientY);
                    tooltip.fadeTo(500, 0.9);
                },
                "mouseout": function () {
                    $(".circos-tooltip").fadeTo(500, 0);
                }
            }
        };
        this.attrConfiguration = {
            innerRadius: (500 / 2 - 500 / 100 - 500 / 13),
            outerRadius: (500 / 2 - 500 / 100 - 500 / 25),
            min: 0,
            max: 2,
            color: "BuPu",
            logScale: false,
            tooltipContent: function (datum) {
                return datum.label;
            },
            events: {
                "click": (data) => {
                    this.handleSelectChar(data.char);
                }
            }
        };
        this.trackConfiguration = {
            radius: .83,
            opacity: .2,
            tooltipContent: function (datum, _) {
                return datum.path;
            },
            color: function (datum) {
                if (datum.logical) {
                    return "#fd6a62";
                } else {
                    return "#489bb3";
                }
            },
            events: {
                "mouseover.demo": function (d, _, nodes) {
                    nodes.forEach(function (currentValue) {
                        if (d === currentValue.__data__) {
                            currentValue.attributes[2].nodeValue = 1;  //opacity
                        }
                    });
                },
                "mouseout": (d, _, nodes) => {
                    nodes.forEach(function (currentValue) {
                        if (d === currentValue.__data__) {
                            currentValue.attributes[2].nodeValue = .2;
                        }
                    });
                },
                "click": (track, _, nodes) => {
                    const { source, target } = track;
                    const { viewTable } = this.state;
                    const srcChar = source.char;
                    const dstChar = target.char;

                    if (!srcChar?.guid || !dstChar?.guid) {
                      return;
                    }

                    const selectedView = viewTable[srcChar.parent];
                    this.setState({ selectedChar: srcChar, selectedView, relatedChars: [dstChar] });
                }
            }
        };
        this.viewLayoutData = undefined;
        this.attrData = undefined;
        this.trackData = undefined;
    }

    componentDidMount() {
        NavTree.collapseNavTree(false);
        NavTree.assignPageFilters({type: ["face:PlatformDataModel", "platform:View"]});

        this.myMeridianMap = new Circos({
            container: `#${this.phenomId.gen("graph","wrapper")}`,
            width: 500,
            height: 500,
        });
        if (this.props.selection) {
            this.directionsRef.hide();
            this.fetchMeridianViews(this.props.selection, false);
        }
    }

    componentDidUpdate(_, prevState) {
        if (this.state.viewTable !== prevState.viewTable) {
            this.setupViewLayoutData();
            this.setupAttrData();
            this.setupTrackData();
            this.myMeridianMap.layout(this.viewLayoutData, this.viewLayoutConfiguration);
            this.myMeridianMap.heatmap("attrs", this.attrData, this.attrConfiguration);
            this.myMeridianMap.chords("identical-context", this.trackData, this.trackConfiguration);
            this.myMeridianMap.render();
            this.directionsRef.hide();
        }
        if (this.state.selection !== prevState.selection) {
            this.props.setMeridianSelection(this.state.selection);
            this.directionsRef.hide();
        }
        if (this.state.showLogical !== prevState.showLogical && !!Object.keys(this.state.viewTable).length) {
            this.setupTrackData();
            this.myMeridianMap.layout(this.viewLayoutData, this.viewLayoutConfiguration);
            this.myMeridianMap.heatmap("attrs", this.attrData, this.attrConfiguration);
            this.myMeridianMap.chords("identical-context", this.trackData, this.trackConfiguration);
            this.myMeridianMap.render();
        }
    }

    componentWillUnmount() {
      NavTree.clearPageFilters();
      this.myMeridianMap.tip.remove();
    }

    handleSelectChar = (selectedChar) => {
      const { viewTable, matchTable } = this.state;
      if (!selectedChar?.guid) return;

      const match_group = matchTable[selectedChar.path] || [];
      const selectedView = viewTable[selectedChar.parent];
      const relatedChars = match_group.filter(c => c.guid !== selectedChar.guid);

      this.setState({ selectedChar, selectedView, relatedChars });
    }

    setupViewLayoutData() {
        this.viewLayoutData = Object.values(this.state.viewTable).map((view) => {
            const length = view.children.length * 2 - 1;
            return {len: length, color: "#8dd3c7", label: view.name, id: view.guid};
        });
    }

    setupAttrData() {
        const { viewTable } = this.state;
        this.attrData = [];

        for (let view_guid in viewTable) {
          const view = viewTable[view_guid];
          
          let counter = 0;
  
          view.children.forEach((attr) => {
              this.attrData.push({
                  block_id: view.guid,
                  start: counter,
                  end: counter + 1,
                  value: counter / (view.children.length / 1.3),
                  label: `${view.name}:${attr.rolename}`,
                  char: attr,
              });
              counter += 2;
          })
        }
    }

    setupTrackData() {
        this.trackData = [];
        const { showLogical, viewTable, matchTable } = this.state;

        // create track data
        for (let match_group of Object.values(matchTable)) {
          for (let i = 0; i < match_group.length; i++) {
            const srcAttr = match_group[i];
            const dstAttr = match_group[(i + 1) % match_group.length];
            if (srcAttr === dstAttr) {
              // only one attr exist in the array
              continue;
            }

            const srcView = viewTable[srcAttr.parent];
            const dstView = viewTable[dstAttr.parent];
            if (!srcView || !dstView) {
              // something went wrong
              continue;
            }

            const srcIdx = srcView.children.findIndex(c => c.guid === srcAttr.guid);
            const dstIdx = dstView.children.findIndex(c => c.guid === dstAttr.guid);
            if (srcIdx === -1 || dstIdx === -1) {
              // something went wrong
              continue;
            }

            const isMatchedLogically = srcAttr.measurement_guid === dstAttr.measurement_guid;

            if (!showLogical || (showLogical && isMatchedLogically)) {
              this.trackData.push({
                  source: {
                      id: srcAttr.parent,
                      char: srcAttr,
                      start: srcIdx * 2,
                      end: srcIdx * 2 + 1,
                  },
                  target: {
                      id: dstAttr.parent,
                      char: dstAttr,
                      start: dstIdx * 2,
                      end: dstIdx * 2 + 1,
                  },
                  path: srcAttr.path_string,
                  logical: isMatchedLogically,
              });
            }
          }
        }
    }

        

    fetchMeridianViews = (selection, addition) => {

        let tempSelection = Array.from(this.state.selection);
        if (addition) {
            tempSelection = tempSelection.concat(selection).filter((val, idx, arr) => arr.indexOf(val) === idx);
        } else {
            tempSelection = selection;
        }

        //validation on URI length; display warning message if view count exceeds max length
        let maxURILength = 51;
        let viewCount = tempSelection.length;
        if (viewCount === 0) {
            BasicAlert.show("Select one or more views from the Nav Tree and click the refresh icon or drag views directly onto the page to visualize relationships.");
            return;
        } else if (viewCount >= maxURILength) {
            BasicAlert.show("You have exceeded the maximum selected nodes for this request. Try selecting " + (maxURILength - 1) + " or fewer nodes. Nodes selected: " + viewCount);
            return;
        }
        this.setState({selection: tempSelection});

        if (!this.props.expired) {
            this.setState({loading: true});
        }

        getSemanticsMulti(tempSelection).then(res => {
            const response = JSON.parse(res);
            const viewTable = {};
            const matchTable = {};

            if (!Array.isArray(response.nodes)) {
              return;
            }

            response.nodes.forEach(view => {
              viewTable[view.guid] = view;

              view.children.forEach(char => {
                if (!char.path) return;
                if (!matchTable[char.path]) matchTable[char.path] = [];
                matchTable[char.path].push(char);
              })
            })

            this.setState({ 
              viewTable,
              matchTable,
              loading: false,
            })
        })
    };

    onDragLeave = (event) => {
      event.preventDefault();
      const textTo = document.querySelector("#dragging-leaf-to");
      if (textTo) textTo.innerHTML = "";
      const textImg = document.querySelector("#dragging-leaf-img");
      if (textImg) textImg.style.backgroundPosition = "-32px 32px";
    }

    onDragOver = (event) => {
      event.preventDefault();
      const ghosty = document.querySelector("#dragging-leaf-ghosty");
      const ghostyImg = document.querySelector("#dragging-leaf-img");
      if (!ghosty) return;

      // Adjust ghosty text
      const textTo = document.querySelector("#dragging-leaf-to");
      if (textTo) {
        textTo.innerHTML = "Meridian Map";
      }

      const draggingType = ghosty.dataset['xmitype'];
      if (!draggingType) return;

      // On Drop is valid
      if (draggingType === "platform:View") {
        event.dataTransfer.dropEffect = "copy";
        if (ghostyImg) ghostyImg.style.backgroundPosition = "0 32px";
      } else {
        event.dataTransfer.dropEffect = "none";
        if (ghostyImg) ghostyImg.style.backgroundPosition = "-32px 32px";
      }
    }

    onDrop = (event) => {
      const rawTreeData = event.dataTransfer.getData("treeNodes");
      const treeNodes = rawTreeData && JSON.parse(rawTreeData);

      if (treeNodes && treeNodes.every(node => node.xmiType === "platform:View")) {
        this.fetchMeridianViews(treeNodes.map(ele => ele.guid), true);
      }
    }

    onRefresh = () => {
      this.setState({loading: true});
      const selection = window["treeRef"].getSelectedGuids();
      let cleanSelection = [];

      // append only views to selection (excludes packages)
      selection.forEach(item =>{
        let itemType = window.treeRef.findLeaf(item).getXmiType();

        if(itemType === "platform:View"){
          cleanSelection.push(item)
        }
      })

      this.setState({
          changedSelection: false,
          selectedChar: null,
          selection: [],
          viewTable: {},
          matchTable: {},
          relatedChars: [],
          showLogical: false,
          selectedView: null,
          relatedChars: [],
      });

      // select whatever is highlighted 
      this.fetchMeridianViews(cleanSelection, false);
    }


    render() {
        const { viewTable, selectedChar, selectedView, relatedChars } = this.state;
        const phenomId = this.phenomId;

        return (
            <div className="phenom-content-wrapper" id={phenomId.gen("","wrapper")}>
                <nav class="sub-menu-actions" aria-label="form actions" />

                <div className="phenom-content-scrollable">
                  <img style={{display: this.state.loading ? "block" : "none", top: 0, left: 0, width: 90}}
                      className="ring-o-loading" src={loadingIcon}/>
                  <FadingDirections ref={el => this.directionsRef = el}
                                    idCtx={phenomId.gen("")}
                                    text="Select one or more views from the Nav Tree and click the refresh icon or drag views directly onto the page to visualize relationships. Then, click on the ribbons to learn more about the attributes they connect or the views and attributes themselves to see details."/>
                  <button className='page-refresh' onClick={() => this.onRefresh()} disabled={this.props.expired} id={phenomId.gen("","page-refresh-button")}></button>
                  {/* The below styling conditional is a hacky way to move the svg which prevent node dropping to the side of the screen.
                      It should be improved at the first opportunity. */}
                  <div
                      // id="graph"
                      id={phenomId.gen("graph","wrapper")}
                      className="meridian-graph center"
                      onDragOver={this.onDragOver}
                      onDrop={this.onDrop}
                      onDragLeave={this.onDragLeave}
                      style={{
                          paddingLeft: this.state.selection.length ? "calc(50% - 250px)" : "",
                          // marginLeft: !this.state.selection.length ? -300 : "",
                          width: !this.state.selection.length ? "calc(100% + 300px)" : "100%"}}
                          >
                  </div>
                  <div className="flex-v" style={{position: "absolute", top: 15, right: 15}}>
                      <div className="flex-h">
                          <input
                              style={{marginRight: 10}}
                              // id="show-logical"
                              id={phenomId.gen("","show-logical-input")}
                              type="checkbox"
                              checked={this.state.showLogical}
                              onChange={e => this.setState({showLogical: e.target.checked})}
                          />
                          <label htmlFor="show-logical" id={phenomId.gen("","show-logical-label")}>Logical Matches Only</label>
                      </div>
                  </div>

                  {selectedChar && <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: "1rem" }}>
                    <div>
                      <PhenomLabel text="Path" />
                      <div>{ selectedChar.path_string }</div>
                    </div>
                    <div>
                      <PhenomLabel text="Selected Characteristic" />
                      <CharBox char={selectedChar} view={selectedView} />
                    </div>
                    <div>
                      <PhenomLabel text="Semantic Matches" />
                      <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "1rem" }}>
                        {relatedChars.map(char => {
                          return <CharBox char={char} view={viewTable[char.parent]} />
                        })}
                      </div>
                    </div>
                  </div>}
                </div>
            </div>
        );
    }
}


const CharBox = (props) => {
  const { char, view } = props;

  if (!char?.guid) {
    return null;
  }

  return <div style={{ display: "grid", gridTemplateColumns: "minmax(100px, 20%) auto", padding: "0.5rem", border: "1px solid #e5e5e5" }}>
    <label>View</label>
    <div>{view?.name || ""}</div>
    
    <label>Rolename</label>
    <div>{char.rolename}</div>

    <label>Description</label>
    <div>{char.description}</div>

    <label>Measurement</label>
    <div>{char.measurement_name}</div>
  </div>
}



const msp = (state) => ({
  expired: state.user.expired,
})

export default connect(msp)(MeridianMap);
