import React, { useState, useEffect, useRef } from "react";
import {Link} from "react-router-dom";
import {CadetInput, CadetTextArea, LineLabel} from "../stateless";
import { sortNodesByName } from "../util";
import EditTopButtons from "../../edit/edit-top-buttons";
import {Notifications} from "../../edit/notifications";
import {NodeHistory2} from "../../edit/node-history";
import loadingIcon from "../../../images/Palette Ring-1s-200px.gif";
import {Button} from "@progress/kendo-react-buttons";
import ReactTooltip from "react-tooltip";
import PhenomId from "../../../requests/phenom-id";
import ChangeSetPicker from "../../widget/ChangeSetPicker";




export default (props) => {
    const {
        editable=true,
        guid,
        nodeTitle,
        nodeName,
        nodeDescription,
        onNameChange,
        onDescriptionChange,
        saveBtn,
        resetBtn,
        noticeRef,
        historyRef,
        deleteBtn,
        idCtx,
    } = props;



    const phenomId = new PhenomId("basic-editor",idCtx);

    const nameInput = () => {
        if (onNameChange === undefined || onNameChange === null) {
            return <CadetInput text={nodeName} disabled={true} idCtx={phenomId.gen("init","node-name")}/>
        }

        return (
            <CadetInput text={nodeName}
                disabled={!editable}
                idCtx={phenomId.gen("init","node-name")}
                onChange={(e) => onNameChange(e.target.value)} />
        )
    }

    const descriptionInput = () => {
        if (onDescriptionChange === undefined || onDescriptionChange === null) {
            return (<>
                <LineLabel text="Description" idCtx={phenomId.gen("init","description")}/>
                <CadetTextArea text={nodeDescription} disabled={true} idCtx={phenomId.gen("init","description")}/>
            </>);
        }

        return (<>
            <LineLabel text="Description" idCtx={phenomId.gen("init","description")}/>
            <CadetTextArea text={nodeDescription}
                idCtx={phenomId.gen("init","description")}
                disabled={!editable}
                onChange={(e) => onDescriptionChange(e.target.value)} />
        </>)
    }

    return (
        <div className="subview-wrapper flex-v" style={{minHeight: 512}}>
            <NoticeInfo ref={noticeRef} />
            <EditTopButtons saveBtn={saveBtn} resetBtn={resetBtn} canEdit={editable} deleteBtn={deleteBtn}/>
            <div className="flex-h">
                <div className="flex-v" style={{flexGrow: 1}}>
                    {nodeTitle !== undefined &&
                        <LineLabel text={nodeTitle} idCtx={phenomId.gen("init","title")}/> }
                    {nodeName !== undefined &&
                        nameInput() }
                    {nodeDescription !== undefined &&
                        descriptionInput() }
                </div>
                <div className="edit-side-bar">
                    <HistoryInfo ref={historyRef} guid={guid} idCtx={phenomId.gen("init")}/>
                    <ChangeSetPicker id={phenomId.genPageId()} disabled={!editable} label="Change Set" />
                </div>
            </div>

            {props.children}
        </div>
    )
}



export const HistoryInfo = React.forwardRef((props, ref) => {
    return <NodeHistory2 ref={ref} guid={props.guid} idCtx={props.idCtx}/>
})

export const NoticeInfo = React.forwardRef((props, ref) => {
    return <Notifications ref={ref} />
})


//===================================================

export const colors = ["#489bb3", "#5e4fa2", "#f37a4c", "#7f9f51", "#96228f", "#b15050", "#238d5d", "#3857ae", "#9a4da5", "#ad3d5f", "#d34b19"];

// From CSS Tricks
export function LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }
    var num = parseInt(col,16);

    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}





export const Block = React.forwardRef((props, ref) => {
    const components = {
        commaLinks: CommaLinks,
        constraintTable: ConstraintTable,
        container: Container,
        collapsible: Collapsible,
        editor: Editor,
        input: Input,
        miniCard: MiniCard,
        multiSelect: MultiSelect,
        singleSelect: SingleSelect,
        textarea: TextArea,
    }

    if (components[props.component] === undefined) {
        return (
            <div className={`block`}>
                {`The component, ${props.component}, doesn't exist yet. Please add it.`}
            </div>
        );
    }

    let TagName = components[props.component];
    return <TagName ref={ref} {...props} />;
})



export const Editor = (props) => {
    let classes = ["subview-wrapper", props.classes].join(" ");
    let style = { ...props.style, }

    if(props.loading) {
        return (
            <div className={classes} style={style} id={props.idCtx+"-editor-wrapper"}>
                <img src={loadingIcon} />
            </div>
        );
    }

    return (
        <div className={classes} style={style} id={props.idCtx+"-editor-wrapper"}>
            <NoticeInfo ref={props.noticeRef} />
            <EditTopButtons ref={props.topBtnRef} saveBtn={props.saveBtn} resetBtn={props.resetBtn} canEdit={props.editable} deleteBtn={props.deleteBtn}/>
                {props.children}
        </div>
    )
}



export const Container = (props) => {
    let style = { position:"relative", ...props.style, }
    let classes = ["block"];

    if (props.size) {
        classes.push("cadet-col-" + props.size);
    }

    switch(props.type) {
        case "horizontal":
            classes.push("flex-h");
            break;
        case "vertical":
            classes.push("flex-v");
            break;
    }

    return (
        <div className={classes.join(" ")} style={style} id={props.idCtx+"-container-wrapper"}>
            {props.title &&
            <div className="block" style={{padding:"10px 0"}}>
                <LineLabel text={props.title} />
            </div>}

            {props.children}
        </div>
    )
}



export const Input = (props) => {
    let classes = [];
    let style = { width:"100%", ...props.style };

    if (props.size) {
        classes.push("cadet-col-" + props.size);
    }

    return (
        <div className={classes.join(" ")} style={style} id={props.idCtx+"-input-wrapper"}>
            {props.title &&
            <LineLabel text={props.title} idCtx={props.idCtx}/>}

            <CadetInput text={props.text === undefined ? "loading..." : props.text}
                        disabled={!props.editable}
                        onChange={props.onChange}
                        idCtx={props.idCtx}/>
        </div>
    )
}



export const TextArea = (props) => {
    let classes = [];
    let style = { ...props.style };

    if (props.size) {
        classes.push("cadet-col-" + props.size);
    }

    return (
        <div className={classes.join(" ")} style={style} id={props.idCtx+"-text-area-wrapper"}>
            {props.title &&
            <LineLabel text={props.title} idCtx={props.idCtx}/>}

            <CadetTextArea text={props.text === undefined ? "loading..." : props.text}
                        disabled={!props.editable}
                        onChange={props.onChange}
                        idCtx={props.idCtx}/>
        </div>
    )
}



export const CommaLinks = (props) => {
    let style = { marginBottom:15, ...props.style };





    let array;
    if (Array.isArray(props.data)) {
        array = [...props.data];
    } else if (typeof props.data === 'object') {
        array = Object.values(props.data);
    }

    return(
        <div style={style} id={props.idCtx+"-comma-links-wrapper"}>
            {array.map((ele, idx, arr) => {
                if(props.link) {
                    let link = props.link.endsWith("/") ? props.link : (props.link + "/");

                    return <Link style={{marginRight:5}}
                                key={ele.guid}
                                to={link + ele.guid}
                                className="cadet-anchor">
                                    {ele.name}{idx < arr.length - 1 ? ", " : ""}
                            </Link>
                } else {
                    return <span key={ele.guid}
                                 className="cadet-anchor">
                        {ele.name}{idx < arr.length - 1 ? ", " : ""}
                    </span>
                }
            })}
        </div>
    );
}



export const Collapsible = (props) => {
    let wrapperRef = useRef(null);
    let [state, setState] = useState({
        collapse: props.collapse,
        wrapperStyle: {
            minWidth: "300px",
            height: 0,
            ...props.style
        }
    })

    useEffect(() => {
        if (wrapperRef.current !== null) {
            let wrapperStyle = {...state.wrapperStyle};

            if (props.collapse) {
                wrapperStyle.height = 0;
            } else {
                wrapperStyle.height = wrapperRef.current.scrollHeight;
            }
            setState({...state, collapse: props.collapse, wrapperStyle})
        }
    }, [props.collapse, wrapperRef.current])

    return (
        <div className="collapsible" style={state.wrapperStyle} ref={wrapperRef} id={props.idCtx+"-collapsible-wrapper"}>
            {props.children}
        </div>
    )
}



// PropTypes
// data: object (hashmap of nodes)
// selectedGuid: string
// onChange: function
// editable: boolean
// required: boolean
// noValueName: string
export const DropdownSelect = (props) => {
    let style = { width:"100%", ...props.style };
    let errorStyle = {};

    let data = props.data || {};
    let guid = props.selectedGuid || "";

    if (props.required && props.error) {
        errorStyle = {border: "1px solid red"};
    }

    return (
        <select className="cadet-select" style={{...style, ...errorStyle}}
                id={props.idCtx+"-drop-down-select"}
                value={data[guid] ? guid : ""}
                disabled={!props.editable}
                onChange={props.onChange}>
                    <option value="" disabled={props.required}>
                        {props.noValueName ? props.noValueName :
                            props.required ? "Choose One" : "None"
                        }
                    </option>

                    {Object.values(data).sort((a,b) => sortNodesByName(a,b)).map((node, idx) => {
                        return (<option value={node.guid} key={node.guid} id={props.idCtx+`-select-${idx}-option`}>
                                    {node.name}
                                </option>); })}
        </select>
    )
}



// PropTypes
// title: string
// data: hashmap of nodes
// selectedGuid: string
// required: boolean
// collapse: boolean
// cardColor: integer (choose from an array)
// cardLink: string
// editable: boolean
// condensed: boolean
// saveState: function (used for componentWillUnmount)
// onChange: function (if additional functionality is needed)
export class SingleSelect extends React.Component {
    state = {
        data: {},
        selectedGuid: "",
        collapse: true,
        error: false,
    }

    componentDidMount() {
        this.setState({
            collapse: this.props.collapse,
            data: this.props.data,
            selectedGuid: this.props.selectedGuid
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.collapse !== this.props.collapse ||
           prevProps.editable !== this.props.editable) {

             if (this.props.editable) {
                 this.setState({collapse: this.props.collapse})
             } else {
                 this.setState({collapse: true})
             }
         }

        if(prevProps.data !== this.props.data ||
           prevProps.selectedGuid !== this.props.selectedGuid) {
                this.setState({data: this.props.data, selectedGuid: this.props.selectedGuid})
        }
    }

    componentWillUnmount() {
        if(this.props.saveState) {
            this.props.saveState(this.state.selectedGuid);
        }
    }

    toggleCollapse = () => {
        this.setState((prevState, props) => ({...prevState, collapse: !prevState.collapse}))
    }

    handleSelectionChange = (e) => {
        this.setState({ selectedGuid: e.target.value, error: false });

        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    // Use ref to retrieve selected node
    generateNode = () => {
        let errors = this.validateSelection();
        if (errors.length) return { errors };
        return this.state.data[this.state.selectedGuid] || null;
    }

    generateGuid = () => {
        let errors = this.validateSelection();
        if (errors.length) return { errors };
        return this.state.selectedGuid || null;
    }

    validateSelection = () => {
        let errors = [];

        if (this.props.required && !this.state.data[this.state.selectedGuid]) {
            this.setState({collapse: false, error: true});
            errors.push(`${this.props.title} is required`);
        }

        return errors;
    }

    resetData = () => {
        this.setState({ selectedGuid: this.props.selectedGuid });
    }

    render() {
        let errorStyle = null;

        if (this.state.error) {
            errorStyle = {border: "1px solid red"};
        }
        const phenomId = new PhenomId("single-select",this.props.idCtx);

        return (
            <Block component="container" type="vertical" title={this.props.title} style={{marginBottom:"10px"}} idCtx={phenomId.gen("","wrapper")}>
                    {this.props.condensed &&
                    <DropdownSelect data={this.state.data}
                                    selectedGuid={this.state.selectedGuid}
                                    onChange={this.handleSelectionChange}
                                    editable={this.props.editable}
                                    required={this.props.required}
                                    error={this.state.error}
                                    noValueName={this.props.noValueName}
                                    idCtx={phenomId.gen("","condensed")} />}

                    {!this.props.condensed && this.props.editable && <>
                    <div style={{width:"100%", marginBottom:10}}>
                        <Button icon="select-box" onClick={this.toggleCollapse} idCtx={phenomId.gen("","choose-title")}>
                            {this.state.collapse ? `Choose a ${this.props.title}` : "Close selection box"}</Button>
                    </div>

                    <Block component="container" type="horizontal" style={{marginBottom:10}} idCtx={phenomId.gen("editable","")}>
                        <Collapsible collapse={this.state.collapse} idCtx={phenomId.gen("","")}>
                            <select className="block-multi-select"
                                    style={errorStyle}
                                    value={this.state.data[this.state.selectedGuid] ? this.state.selectedGuid : ""}
                                    size={6}
                                    disabled={!this.props.editable}
                                    onChange={this.handleSelectionChange}
                                    idCtx={phenomId.gen("required","select")}>
                                <option value="" disabled={this.props.required} style={{padding:"5px 0"}} id={phenomId.gen(["required","default"],"option")}>
                                    {this.props.noValueName ? this.props.noValueName :
                                        this.props.required ? "Choose One" : "None"
                                    }
                                </option>
                                {Object.values(this.state.data).sort((a,b) => sortNodesByName(a,b)).map((node, idx) => {
                                    return (<option value={node.guid}
                                                    key={node.guid}
                                                    style={{padding:"5px 0"}}
                                                    id={phenomId.gen(["required",idx],"option")}>
                                                {node.name}
                                            </option>); })}
                            </select>
                        </Collapsible>
                    </Block></>}

                    {!this.props.condensed && this.state.data[this.state.selectedGuid] !== undefined &&
                    <MiniCard data={this.state.data[this.state.selectedGuid]}
                              cardColor={this.props.cardColor}
                              link={this.props.cardLink}
                              idCtx={phenomId.gen("init","selected")}/>}
            </Block>
        )
    }
}



// PropTypes
// title: string
// data: hashmap of nodes
// selectedGuids: array
// required: boolean
// collapse: boolean
// infoBox: object
// cardColor: integer (choose from an array)
// cardLink: string
// editable: boolean
// saveState: function (used in componentWillUnmount)
export class MultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.nodeRefs = {};
    }

    state = {
        data: {},
        selectedGuids: [],
        collapse: true,
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            selectedGuids: this.props.selectedGuids,
            collapse: this.props.collapse,
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.collapse !== this.props.collapse ||
           prevProps.data !== this.props.data ||
           prevProps.selectedGuids !== this.props.selectedGuids) {

            this.setState({
                data: this.props.data,
                selectedGuids: this.props.selectedGuids,
                collapse: this.props.collapse,
            })
        }
    }

    componentWillUnmount() {
        if(this.props.saveState) {
            this.props.saveState(this.state.selectedGuids || []);
        }
    }

    toggleCollapse = () => {
        this.setState((prevState, props) => ({collapse: !prevState.collapse}));
    }

    handleSelectionChange = async (e) => {
        let options = e.target.options;
        let selected = [];

        for (let i = 0; i < options.length; i++) {
            if(options[i].selected){
                const guid = options[i].value;
                selected.push(guid);
            }
        }

        this.setState({selectedGuids: selected});
    }

    generateNodes = () => {
        let errors = this.validateSelection();
        if (errors.length) return { errors };
        let nodes = this.state.selectedGuids.map(guid => this.state.data[guid]);
        return { nodes }
    }

    generateGuids = () => {
        let errors = this.validateSelection();
        if (errors.length) return { errors };
        return this.state.selectedGuids.join(" ");
    }

    validateSelection = () => {
        let errors = [];

        if (this.props.required && this.state.selectedGuids.length < 1) {
            this.setState({collapse: false, error: true});
            errors.push(`${this.props.title} is required`);
        }

        return errors;
    }

    selectionToggle = (e) => {
        e.preventDefault();
        if(e.target.value) {
            // remove if it exists
            if (this.state.selectedGuids.indexOf(e.target.value) > -1) {
                var newSelectedGuids = this.state.selectedGuids.filter(guid => guid !== e.target.value);

            // push if it doesn't exist (this does not work if there's only one item remaining)
            } else {
                var newSelectedGuids = [...this.state.selectedGuids, e.target.value];
            }

            this.setState({selectedGuids: newSelectedGuids, error: false});
        }
    }

    removeLastSelection = (e) => {
        if (this.state.selectedGuids.length === 1 && this.state.selectedGuids.indexOf(e.target.value) > -1) {
            e.preventDefault();
            this.setState({selectedGuids: []});
        }
    }

    resetData = () => {
        this.setState({ selectedGuids: [...this.props.selectedGuids] });
    }

    render() {
        let infoBox = null;
        const phenomId = new PhenomId("multi-select",this.props.idCtx);

        if (this.props.infoBox) {
            infoBox = <>
                <Button style={{marginRight:10}} icon="info" look="bare" data-tip data-for={this.props.id} idCtx={phenomId.gen("","info-box")}/>
                <ReactTooltip id={this.props.id}><span idCtx={phenomId.gen("","info-box-choose-wrapper")}>You can:
                    <ul style={{paddingLeft:20, margin:0}} >
                        <li idCtx={phenomId.gen("","info-box-choose-from-title")}>{`choose from existing ${this.props.title}`}</li>
                        {this.props.infoBox.ableToCreate && <>
                            <li>{`create new ${this.props.title}`}</li>
                            <li>or do a combination of both</li>
                        </>}
                    </ul>
                    </span></ReactTooltip>
            </>
        }

        let errorStyle = null;
        if (this.state.error) {
            errorStyle = {border: "1px solid red"}
        }

        return (
            <Block component="container" type="vertical" title={this.props.title} style={{marginBottom:10}} idCtx={phenomId.gen("","wrapper")}>
                {this.props.editable && <>
                <div style={{width:"100%", marginBottom:10}}>
                    {infoBox}
                    <Button icon="select-box" onClick={this.toggleCollapse} idCtx={phenomId.gen("","title-close")}>
                            {this.state.collapse ? `Choose from existing ${this.props.title}` : "Close selection box"}</Button>
                </div>

                <Block component="container" type="horizontal" style={{marginBottom:10}} idCtx={phenomId.gen("selections","wrapper")}>
                    <Collapsible collapse={this.state.collapse} idCtx={phenomId.gen()}>
                        <select className="block-multi-select"
                                style={errorStyle}
                                value={this.state.selectedGuids}
                                multiple={true}
                                size={6}
                                disabled={!this.props.editable}
                                onChange={this.selectionToggle}
                                idCtx={phenomId.gen("","select")}>
                            <option value="" disabled={this.props.required} style={{padding:"5px 0"}} id={phenomId.gen(["selections","default"],"option")}>
                                {this.props.noValueName ? this.props.noValueName :
                                    this.props.required ? "Choose at least one" : "None"
                                }
                            </option>
                            {Object.values(this.state.data).sort((a,b) => sortNodesByName(a,b)).map((node, idx) => {
                                return (<option value={node.guid}
                                                key={node.guid}
                                                onMouseDown={this.removeLastSelection}
                                                style={{padding:"5px 0"}}
                                                id={phenomId.gen(["selections",idx],"option")}>
                                            {node.name}
                                        </option>); })}
                        </select>
                    </Collapsible>
                </Block></>}

                <Block component="container" type="horizontal" style={{flexWrap:"wrap"}} idCtx={phenomId.gen(["init","selected"])}>
                    {this.state.selectedGuids.map((guid,gIdx) => {
                        this.nodeRefs[guid] = React.createRef();
                        return <MiniCard data={this.state.data[guid]}
                                    ref={this.nodeRefs[guid]}
                                    cardColor={this.props.cardColor}
                                    link={this.props.cardLink}
                                    idCtx={phenomId.gen(["selected",gIdx])}/>
                    })}
                </Block>
            </Block>
        )
    }
}



export const MiniCard = (props) => {
    let bgColor = colors[props.cardColor] ? colors[props.cardColor] : colors[0];
    let style = { position:"relative", backgroundColor: bgColor, width:"30%", padding:"5px 10px", margin:"0 3% 10px 0", ...props.style };

    let data = props.data;
    let name = "", description = "", type = "", link = "";
    let disabled = props.link === undefined;

    if (props.data) {
        name = data.name || data.rolename;
        description = data.description;
        type = data.xmiType.split(":")[1].replace(/([a-z0-9])([A-Z])/g, '$1 $2');
        link = !disabled ? props.link.endsWith("/") ? props.link + data.guid : `${props.link}/${data.guid}` : "";
    }
    const idCtx = props.idCtx+"-mini-card";

    return(
        <div style={style} id={idCtx+"-wrapper"}>
            <span style={{display:"inline-block", fontSize:13, color:"#fff", marginBottom:5}}>
                {type}
            </span>

            <label style={{display:"block", wordWrap:"wrap", color:"#fff", fontWeight:"bold", marginBottom:5}}>
                <Link class="block-link" to={link} disabled={disabled} id={idCtx+"-link"}>
                    {name}
                </Link>
            </label>

            <p style={{fontSize:13, color:"#eaeaea"}} id={idCtx+"-description"}>
                {description}
            </p>
        </div>
    )
}



export const ConstraintTable = (props) => {
    let constraint = props.constraint ? props.constraint : null;
    const idCtx = props.idCtx+"-constraint-table";

    let show = null;
    if (constraint) {
        show = <Block component="container" title="Constraint Added" style={{marginBottom:10, overflowX:"auto"}} id={idCtx+"-wrapper"}>
            <table className="block-constraint-table">
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-name"}>{constraint.name}</td>
                    </tr>
                    {!constraint.description || <tr>
                            <td>Description</td>
                            <td style={{fontSize: "90%"}} id={idCtx+"-description"}>{constraint.description}</td>
                        </tr>
                    }
                    {!constraint.expression || <tr>
                        <td>RegEx Expression</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-regex"}>{constraint.expression}</td>
                    </tr>}
                    {(constraint.upperBound || constraint.upperBound === 0) && <tr>
                        <td>Upper Bound</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-upper-bound"}>{constraint.upperBound}</td>
                    </tr>}
                    {(constraint.lowerBound || constraint.lowerBound === 0) && <tr>
                        <td>Lower Bound</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-lower-bound"}>{constraint.lowerBound}</td>
                    </tr>}
                    {constraint.upperBoundInclusive && <tr>
                        <td>Upper Bound Inclusive</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-upper-bound-inclusive"}>{constraint.upperBoundInclusive}</td>
                    </tr>}
                    {constraint.lowerBoundInclusive && <tr>
                        <td>Lower Bound Inclusive</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-lower-bound-inclusive"}>{constraint.lowerBoundInclusive}</td>
                    </tr>}
                </tbody>
            </table>
        </Block>
    }

    return ( show )
}
