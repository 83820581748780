import {CadetInput, FauxAutofill} from "../stateless";
import React from "react";
import {deGuidify} from "../util";
import $ from "jquery";
import PhenomId from "../../../requests/phenom-id";

export class MsgFieldContents extends React.Component {
    constructor(props) {
        super(props);
        const field = this.props.field;

        this.state = {
            collapsed: !!this.props.field.rolename,
            scope: field.scope,
            target: field.target,
            target_guid: false,
            scalar: false,
            type: field.type,
            offset: field.offset || 0,
            bitMask: field.mask,
            count: field.count,
            stride: field.stride,
            function: field.function,
            presence: field.presence,
            view: !!field.scope,
            views: [],
            chars: {},
            protocol_guid: this.props.field.protocol_guid
        };
        this.phenomId = new PhenomId("contents",this.props.idCtx);
    }

    componentDidMount() {
        if (!this.props.views.guid && this.state.scope) {
            this.setState({chars: deGuidify(this.props.views[this.state.scope].children)}, () => {
                if (this.state.target) {
                    const char = Object.values(this.state.chars).find(char => char.rolename === this.state.target);
                    this.setState({target_guid: char.guid});
                    if (char) {
                        const lastHopIdx = char.pathPairs.length - 1;
                        const uBound = char.pathPairs[lastHopIdx].upperBound;
                        this.setState({scalar: uBound > 1});
                    }
                }
            });
        }
        $.ajax("/index.php?r=/node/model-nodes-of-type", {
            method: "get",
            data: {
                type: "platform:CharacteristicProjection",
            },
        }).then((characteristics) => {
            const nestedGuids = JSON.parse(characteristics).nodes.filter((char) => {
                return char.viewType
            }).map((view) => {
                return view.viewType
            });
            const views = Object.values(this.props.views).filter((view) => {
                return !nestedGuids.includes(view.guid)
            });
            this.setState({views})
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const field = this.props.field;

        if (field !== prevProps.field) {
            this.setState({
                scope: field.scope,
                target: field.target,
                type: field.type,
                offset: field.offset,
                bitMask: field.mask,
                count: field.count,
                stride: field.stride,
                function: field.function,
                presence: field.presence,
                view: !!field.scope,
            });
        }

        if (!this.props.views.guid && this.state.scope && //if the view list has arrived and there is a scope and ...
            (
                (//or if the socpe has just changed
                (prevProps.views.guid && !this.props.views.guid) || //the views have just arrived
                this.state.scope !== prevState.scope || this.state.target !== prevState.target) //or if the target has just changed
            )) {
            const chars = this.props.views[this.state.scope].children;

            this.setState({chars: deGuidify(chars)});
            if (this.state.target) {
                const char = chars.find(char => char.rolename === this.state.target);

                if (char) {
                    const lastHopIdx = char.pathPairs.length - 1;
                    const uBound = char.pathPairs[lastHopIdx].upperBound;
                    const isScalar = uBound > 1;

                    this.setState({target_guid: char.guid});
                    if (!isScalar && this.props.value === false) {
                        this.props.setValue(true);
                    } else if (isScalar) {
                        this.props.setValue(false);
                    }
                    this.setState({
                        scalar: isScalar,
                        count: isScalar ? this.state.count : false,
                    });
                }
            }
        }

        if (prevState.scope && !this.state.scope) {
            this.setState({chars: {}});
        }
    }

    selectChar = e => {
        const name = e.target.value;
        if (name) {
            const char = Object.values(this.state.chars).find(char => char.rolename === name);
            const type = char.type;
            this.setState({target: name, type: type, target_guid: char.guid});
        }
    };

    discriminantField() {
        const phenomId = this.phenomId;
        if (this.state.scalar) {
            return (<section style={{width: "100%"}} id={phenomId.gen(["init","scalar"],"wrapper")}>
                <label style={{width: "50%"}}>
                    <div className="fixed-label">Count:</div>
                    <CadetInput text={this.props.discriminator} id={phenomId.gen("scalar","count-input")}/>
                </label>
                <label style={{width: "50%"}}>
                    <div className="fixed-label">Stride:</div>
                    <input value={this.state.stride} className="cadet-text-input" type="number"
                           onChange={this.changeState} id={phenomId.gen("scalar","stride-input")}/>
                </label>
            </section>);
        } else {
            return <label style={{width: "50%"}}>
                <div className="fixed-label">Value:</div>
                <CadetInput text={this.props.value} id={phenomId.gen("init","count-input")} onChange={this.props.setValue}/>
            </label>;
        }
    }

    changeState = e => {
        const key = e.target.id.match("function|stride|target|offset|bitMask")[0];
        const value = e.target.value;
        if (value !== undefined) {
            this.setState({[key]: value});
        }
    };

    toggleCollapse = (position = !this.state.collapsed) => {
        this.setState({collapsed: position});
    };

    toggleType = type => {
        this.setState({view: type === "view"});
    };

    determinePrimitive = () => {
        if (this.state.target) {
            let targetType;

            if (this.state.target.startsWith("platform:")) {
                targetType = this.state.target;
            } else {
                const scopedView = this.props.views[this.state.scope];
                if (!scopedView) return "Please Select A Target";
                targetType = scopedView.children.filter(child => child.rolename === this.state.target)[0].platformType.xmiType;
            }

            return targetType.split(":")[1];
        } else {
            return "Please Select A Target";
        }
    };

    reportState = () => {
        return {
            scope: this.state.view ? this.state.scope : "",
            target: this.state.view ? (this.state.target_guid ? this.state.target : false) : (this.state.target && this.state.target.startsWith("platform:") ? this.state.target : false),
            target_guid: this.state.target_guid,
            is_scalar: this.state.scalar,
            value: this.state.value,
            count: this.state.count,
            offset: this.state.offset,
            type: this.state.type,
            mask: this.state.bitMask,
            stride: this.state.stride,
            function: this.state.function,
            view: this.state.view,
            protocol_guid: this.state.protocol_guid
        };
    };

    selectScope = value => {
        this.setState({scope: value, target: false, target_guid: false, type: false});
    };

    render() {
        const phenomId = this.phenomId;
        return (
            <div
                style={{
                    width: "100%",
                    height: this.state.collapsed ? 0 : (this.state.presence === "always" ? (this.state.scalar ? 109 : 85) : 105),
                    opacity: this.state.collapsed ? 0 : 1,
                    overflowY: this.state.collapsed ? "hidden" : "visible",
                    padding: this.state.collapsed ? 0 : 10
                }}
                className="message-field-contents"
            id={phenomId.gen("","wrapper")}>
                {!this.state.view || <section style={{width: "100%"}} id={phenomId.gen("view","wrapper")}>
                    <label style={{width: "33%"}}>
                        <div className="fixed-label">View:</div>
                        <FauxAutofill
                            // id="scope"
                            idCtx={phenomId.gen("view","scope")}
                            style={{width: "100%", marginLeft: "11px", position: "relative"}}
                            inputWidth={"calc(100% - 30px)"}
                            onSelect={this.selectScope}
                            text={this.state.scope && this.props.views[this.state.scope] ? this.props.views[this.state.scope].name : ""}
                            options={Object.values(this.state.views).map((view) => {
                                return {value: view.guid, text: view.name};
                            })}
                        />
                    </label>
                    <label style={{width: "33%"}}>
                        <div>Target Characteristic:</div>
                        <select className="cadet-select" style={{width: "calc(100% - 165px)"}} value={this.state.target}
                                onChange={this.selectChar} id={phenomId.gen("char","select")}>
                            <option id={phenomId.gen(["char","default"],"option")}>--- Please Select A {Object.keys(this.state.chars).length ? "Target" : "View"} ---
                            </option>
                            {Object.values(this.state.chars).map((char, idx) => {
                                return <option value={char.rolename} key={idx} id={phenomId.gen(["char",idx],"option")}>{char.rolename}</option>;
                            })}
                        </select>
                    </label>
                    <label style={{width: "33%"}}>
                        <div>Primitive:</div>
                        <CadetInput text={this.determinePrimitive()} style={{paddingBottom: 3}} id={phenomId.gen("view","primitive")}/>
                    </label>
                </section>}
                {this.state.view || <section style={{width: "50%"}} id={phenomId.gen(["init","no-view"],"wrapper")}>
                    <label>
                        <div className="fixed-label">Primitive:</div>
                        <select className="cadet-select" value={this.state.target}
                                onChange={this.changeState} id={phenomId.gen("target","select")}>
                            <option disabled={true}>---Select A Primitive---</option>
                            <option value={"platform:Long"} id={phenomId.gen(["target","long"],"option")}>Long</option>
                            <option value={"platform:Short"} id={phenomId.gen(["target","short"],"option")}>Short</option>
                            <option value={"platform:ULong"} id={phenomId.gen(["target","ulong"],"option")}>Unsigned Long</option>
                            <option value={"platform:UShort"} id={phenomId.gen(["target","ushort"],"option")}>Unsigned Short</option>
                            <option value={"platform:Octet"} id={phenomId.gen(["target","octet"],"option")}>Octet</option>
                        </select>
                    </label>
                </section>}
                <section>
                    <label style={{width: "50%"}}>
                        <div className="fixed-label">Offset:</div>
                        <input className="cadet-text-input" type="number" value={this.state.offset} id="offset"
                               onChange={this.changeState} id={phenomId.gen("init","offset-input")}/></label>
                    <label style={{width: "50%"}}>
                        <div className="fixed-label">Mask:</div>
                        <CadetInput text={this.state.bitMask || ""} onChange={this.changeState} id={phenomId.gen("init","bitMask-input")}/></label>
                </section>
                {(this.state.presence === "always") || this.discriminantField()}
                {(!this.state.scalar || this.state.presence === "conditional") || <label style={{width: "50%"}}>
                    <div className="fixed-label">Stride:</div>
                    <input value={this.state.stride} className="cadet-text-input" type="number"
                           onChange={this.changeState} id={phenomId.gen("init","stride-input")}/></label>}
                <label style={{width: "50%"}}>
                    <div className="fixed-label">Function:</div>
                    <CadetInput text={this.state.function || ""} onChange={this.changeState} id={phenomId.gen("init","function-input")}/></label>
            </div>
        );
    }
}
