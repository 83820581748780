import React, {Component} from "react";
import {Notifications, Notifications2} from "../notifications";
import {CadetInput, LineLabel, PackageComboBox} from "../../util/stateless";
import {NodeHistory2} from "../node-history";
import {Grid, GridNoRecords} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {getNodesOfType, smmSaveNodes, getNodeWithAddenda} from "../../../requests/sml-requests";
import $ from "jquery";
import PhenomId from "../../../requests/phenom-id"
import { withPageLayout } from "../node-layout"
import DeletionConfirm2 from "../../dialog/DeletionConfirm2";
import ChangeSetPicker from "../../widget/ChangeSetPicker";
import { getActiveChangeSetId } from "../../../requests/actionCreators";
import NavTree from "../../tree/NavTree";


export class UOPInstanceManager extends Component {
    constructor(props) {
        super(props);

        this.noticeRef = undefined;
        this.original = undefined;
        this.emptyValues = {
            name: "",
            guid: null,
            configurationURI: "",
            children: [],
            realizes: "new",
            runningIn: {guid:"new"},
            associationNode: null
        };
    }

    state = {
        portableComps: {},
        msgPorts: [],
        runningIn: {guid:"new"},
        mainPrograms: [],
        associationNode: null,
        parent: null,
    };

    componentDidMount() {
        const req1 = this.retrievePortableComps();
        const req2 = getNodesOfType("ddm:MainProgram").then((res) => {
            const resp = JSON.parse(res);
            this.setState({
                mainPrograms: resp.nodes
            });
        });
        Promise.all([req1, req2]).then(() => {
            this.loadData(this.props.match.params.guid);
        });

        window.addEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    componentDidUpdate(prevProps, prevState) {
        const currGuid = this.props.match.params.guid;
        if (currGuid !== prevProps.match.params.guid) {
            this.loadData(currGuid);
        }
        if (!prevState.guid && this.state.guid && this.props.history) {
            this.props.history.replace(`/integration/details/uop_instance/${this.state.guid}/`);
        }
    }

    componentWillUnmount() {
      window.removeEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    uopiAddendum = {
      coreAddenda: ["runningIn", "childrenMULTI"],
      coreAddendaChildren: ["childrenMULTI", "connection"],
    }

    // This is the same logic in Yii.
    // moved here to utilize the new save method.
    formatUopiResponse = (response) => {
      response.children.forEach(child => {
        const messagePort = child.connection;
        child["name"] = messagePort["name"];
        child["messagingPattern"] = messagePort["messagingPattern"];

        switch (child["messagingPattern"]) {
          case 'pub-sub':
            child["dataType"] = messagePort["messageType"];
            break;
          case 'Client':
            child["dataType"] = child["xmiType"] === "imUoPOutputEndPoint" ? messagePort["requestType"] : messagePort["responseType"];
            break;
          case 'Server':
            child["dataType"] = child["xmiType"] === "UoPOutputEndPoint" ? messagePort["responseType"] : messagePort["requestType"];
            break;
          default:
            break;
        }
      })

      return response;
    }

    loadData = (guid) => {
        this.setState({...this.emptyValues});
        if (guid !== "new") {
          getNodeWithAddenda(guid, this.uopiAddendum)
            .then(res => this.formatUopiResponse(JSON.parse(res)))
            .then((response) => {
                const childrenGuids = response.children.map(e => e.connection.guid);
                let msgPorts = this.state.msgPorts.map(e => ({...e, selected: childrenGuids.includes(e.guid)}))
                msgPorts = msgPorts.map(e => {
                    let adaptedType;
                    if (e.messageExchangeType) {
                        adaptedType = e.messageExchangeType
                    } else {
                        adaptedType = e.messagingPattern
                    }
                    e.adaptedType = adaptedType
                    return e;
                })
                this.setStateFromResponse({
                  ...response,
                  msgPorts: msgPorts,
                });
                this.original = response;
            }).fail((err) => {
                err.status === 500 && this.props.renderPageNotFound && this.props.renderPageNotFound();
            });
        } else {
          let msgPorts = this.state.msgPorts.map(e => {
              let adaptedType;
              if (e.messageExchangeType) {
                  adaptedType = e.messageExchangeType
              } else {
                  adaptedType = e.messagingPattern
              }
              e.adaptedType = adaptedType
              return e;
          })
            this.setState({...this.emptyValues});
            this.original = this.emptyValues;
        }
    };

    setStateFromResponse = (resp) => {
        if (this.props.updateTemplateNode) {
            this.props.updateTemplateNode(resp);
        }

        this.setState({
            ...resp,
            runningIn: resp.runningIn ? resp.runningIn : {guid:"new"}
        });
    };

    handleReset = () => {
        this.setStateFromResponse(this.original);
    };

    retrievePortableComps = () => {
        return $.ajax({
            url: "/index.php?r=/view/model-nodes-of-type",
            method: "get",
            data: {
                type: [
                    "uop:PortableComponent",
                    "uop:PlatformSpecificComponent"
                ],
                coreAddenda: ["childrenMULTI"],
                coreAddendaChildren: ["allowedValueMULTI"]
            }
        }).then(res => {
            let resp = JSON.parse(res);

            resp.nodes.sort((x, y) => {
                if (x.name < y.name) return -1;
                if (x.name > y.name) return 1;
                return 0;
            });

            const portableComps = {};
            const msgPorts = [];
            resp.nodes.forEach(el => {
                const newEl = el;
                newEl.children.map(e => ({selected: this.state.children ? this.state.children.includes(e.connection) : false, ...e}));
                msgPorts.push(...newEl.children.filter(e => e.xmiType === "uop:MessagePort").map(e => ({selected: false, ...e})));
                portableComps[el.guid] = newEl;
            });
            this.setState({
                portableComps,
                msgPorts
            });
        });
    };

    update = (e) => {
        const target = e.target.id.match("name|configurationURI|realizes")[0];

        let msgPorts;
        if (target === "realizes") {
            msgPorts = this.state.msgPorts.forEach(e => e.selected = false);
        }

        this.setState({
            [target]: e.target.value,
            msgPorts: msgPorts ? msgPorts : this.state.msgPorts
        });
    };

    msgPortSelectionChange = event => {
        event.dataItem.selected = !event.dataItem.selected;
        this.forceUpdate();
    };

    msgPortHeaderSelectionChange = event => {
        const checked = event.syntheticEvent.target.checked;
        this.state.msgPorts.filter(e => e.parent === this.state.realizes).forEach(item => item.selected = checked);
        this.forceUpdate();
    };

    msgPortRowClick = event => {
        let last = this.lastSelectedIndex;
        const current = this.state.msgPorts.findIndex(dataItem => dataItem === event.dataItem);

        if (!event.nativeEvent.shiftKey) {
            this.lastSelectedIndex = last = current;
        }

        if (!event.nativeEvent.ctrlKey) {
            this.state.msgPorts.forEach(item => item.selected = false);
        }
        const select = !event.dataItem.selected;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            this.state.msgPorts[i].selected = select;
        }
        this.forceUpdate();
    };

    handleSave = async () => {
        const pcInstance = {
            xmiType: "im:UoPInstance",
            name: this.state.name,
            guid: this.state.guid || undefined,
            configurationURI: this.state.configurationURI,
            realizes: this.state.realizes,
            changeSetId: getActiveChangeSetId(),
            parent: this.state.parent || null,
        };

        if (this.state.guid === null) {
          let newChildren = [];
          this.state.msgPorts.filter(e => e.selected).forEach(port => {
              if (port.messagingPattern === "pub-sub") {
                  newChildren.push({
                      connection: port.guid,
                      xmiType: port.messageExchangeType === "InboundMessage" ? "im:UoPInputEndPoint" : "im:UoPOutputEndPoint"
                  });
              }
              else if (port.messagingPattern.match("Client|Server")) {
                  newChildren.push({
                      connection: port.guid,
                      xmiType: "im:UoPInputEndPoint"
                  }, {
                      connection: port.guid,
                      xmiType: "im:UoPOutputEndPoint"
                  });
              }
          });
          // if (!newChildren.length) return this.noticeRef.error("Cannot save UoP instance without any ports.");
          pcInstance.children = newChildren;
        }

        // Save UoP Instance
        const uopiRes = await smmSaveNodes({
          node: pcInstance,
          returnTypes: ["im:UoPInstance"],
          returnAddenda: {
            "im:UoPInstance": this.uopiAddendum,
          }
        });

        const uopiResponse = JSON.parse(uopiRes);

        if ("errors" in uopiResponse) {
          return Notifications2.parseErrors(uopiResponse.errors);
        }
        window["treeRef"].fetchData();

        const uopi = uopiResponse.nodes[0];
        this.formatUopiResponse(uopi);

        // Check UoPi's association node
        if (this.state.runningIn.guid === "new") {
          // Delete association node if runningIn's guid was switched to "new" and an association node currently exist
          if (this.state.associationNode) {
            await $.ajax({
              method: "post",
              url: "/index.php?r=/node/smm-delete-node",
              data: { guid: this.state.associationNode.guid, }
            })
          }

          this.setStateFromResponse({ ...uopi, associationNode: null, runningIn: { guid: "new" }, });
          Notifications2.parseResponse(uopi);
          if (this.props.closeDialog) this.props.closeDialog();
          return;
      }

      // Create the Association Node
      const assocRes = await smmSaveNodes({
          xmiType: "im:UoPInstanceToMainProgram",
          guid: this.state.associationNode ? this.state.associationNode.guid : null,
          UoP_Instance_Guid: uopi.guid,
          Main_Program_Guid: this.state.runningIn.guid,
          parent: uopi.parent,
      });

      const assocResponse = JSON.parse(assocRes);

      if ("errors" in assocResponse) {
          return Notifications2.parseErrors(assocResponse.errors);
      }

      this.setStateFromResponse({
          ...uopi,
          associationNode: assocResponse,
          runningIn: { guid: assocResponse.Main_Program_Guid }
      });
      Notifications2.parseLogs("All changes saved");
      if (this.props.closeDialog) this.props.closeDialog();
    };

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.name);
        return null;
    };

    mutateOriginalParentListener = (e) => {
      // invalid
      if (!this.state.guid) {
        return;
      }

      const leaf = NavTree.getLeafNode(this.state.guid);
      if (!leaf) {
        return;
      }

      const newParentGuid = leaf.getParentGuid();
      if (this.state.parent !== newParentGuid) {
        this.original["parent"] = newParentGuid;
      }
    }

    render() {
        const phenomId = new PhenomId("edit-uop-instance");
        return (<div className="subview-wrapper" style={{position: "relative"}}>
            <Notifications ref={el => this.noticeRef = el} />
            <div className="flex-h" id={phenomId.gen("details","wrapper")}>
                <div className="flex-v" style={{flexGrow: 1}}>
                    <LineLabel text="UoP Instance" idCtx={phenomId.gen("name","")}/>
                    <CadetInput text={this.state.name} idCtx={phenomId.gen("name")} onChange={this.update}
                        style={{marginBottom: 15}} />
                        {this.props?.idm || <PackageComboBox id={phenomId.genPageId("parent")}
                                                              label="Package"
                                                              xmiType="skayl:IntegrationModel"
                                                              placeholder="<Default>"
                                                              nodeGuid={this.state.guid}
                                                              selectedGuid={this.state.parent}
                                                              onChange={(parent) => this.setState({ parent: parent.guid })}
                                                              onClickCancelIcon={() => this.setState({ parent: undefined })} />}
                    <LineLabel text="Config URI" style={{marginTop: 15}} idCtx={phenomId.gen(["details","config-uri"],"")}/>
                    <CadetInput
                        text={this.state.configurationURI}
                        title={this.state.configurationURI}
                        idCtx={phenomId.gen("config-uri","configurationURI")}
                        onChange={this.update}
                        style={{marginBottom: 0}}
                    />
                    <LineLabel text="UoP" style={{marginTop: 15}} idCtx={phenomId.gen(["details","uop"],"")}/>
                    <select className="cadet-select" style={{width: "100%", marginBottom: 0}}
                        disabled={this.state.guid}
                        value={this.state.realizes}
                        id={phenomId.gen("uop","realizes")}
                        onChange={this.update}>
                        <option value="new" disabled>SELECT OPTION</option>
                        {Object.values(this.state.portableComps).map((opt, idx) => <option
                            value={opt.guid} key={idx} id={phenomId.gen(["uop",`${idx}`],"option")}>{opt.name}</option>)}
                    </select>
                </div>
                <div className="edit-side-bar" style={this.props.dialog ? {display: "none"} :{}}>
                    <NodeHistory2 ref={ele => this.historyRef = ele} guid={this.state.guid} idCtx={phenomId.genPageId()}/>
                    <ChangeSetPicker id={phenomId.genPageId()}
                                     label="Change Set" />
                </div>
              </div>
                <div className="flex-v" style={{flexGrow:1}}>
                    {this.state.guid && <>
                    <LineLabel text="End Points" style={{marginTop: 15, marginBottom: 15}} idCtx={phenomId.gen(["details","message-ports"],"")}/>
                    <Grid
                        key="existing"
                        id={phenomId.gen("message-ports","grid")}
                        data={this.state.msgPorts.filter(e => e.selected)}
                        className="editorTable default-table2"
                        style={{maxHeight: "325px"}}>
                        <GridNoRecords>
                            No Message Ports Found For The Selected Portable Component
                        </GridNoRecords>
                        <Column title="NAME" field="name" />
                        <Column title="TYPE" field="adaptedType" />
                    </Grid></>}
                    <LineLabel text="Used in" style={{marginTop: 15}} idCtx={phenomId.gen(["details","used-in"],"")}/>
                    <select className="cadet-select" style={{width: "100%", marginBottom: 0}}
                        value={this.state.runningIn.guid}
                        id={phenomId.gen("runningIn","select")}
                        onChange={(e) => {
                                    this.setState({runningIn:{guid:e.target.value
                                    }
                                    })}}>
                        <option value="new">NONE</option>
                        {this.state.mainPrograms.map((e, idx) => <option key={idx} value={e.guid} id={phenomId.gen(["runningIn",`${idx}`],"option")}>{e.name}</option>)}
                    </select>
                    <div className="flex-h" style={!this.props.dialog ? {display: "none"} :{}}>
                        <button className="bordered-button no-left-margin"
                                onClick={() => {this.handleSave();}}
                                id={phenomId.gen("message-ports","create")}>Create
                        </button>
                        <button className="bordered-button no-left-margin"
                                onClick={this.props.closeDialog}
                                id={phenomId.gen("message-ports","cancel")}>Cancel
                        </button>
                    </div>
                </div>
            </div>);
    }
}


export const EditUOPInstanceManager = withPageLayout(UOPInstanceManager);
