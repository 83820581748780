/**
 * INDEX
 * ------------------------------------------------------------
 * 00. State
 * 01. Life Cycle Methods
 * 02. Initial Setup
 * 03. Getter Methods
 * 04. Setter Methods
 * 05. Render Methods
 * ------------------------------------------------------------
 */

import React from 'react';
import { PhenomInput, PhenomLabel, PhenomSelect, PhenomToggle, PhenomComboBox } from '../util/stateless';
import { PhenomButtonLink, PhenomLink } from '../widget/PhenomLink';
import { constraintsToPrimitives, primitiveTypeList } from '../../global-constants';
import { cloneDeep } from 'lodash';
import { createPhenomGuid, deGuidify, getShortenedStringRepresentationOfXmiType, isPhenomGuid } from '../util/util';
import $ from 'jquery';
import { Button } from '@progress/kendo-react-buttons';
import ModalNodeBuilder from '../dialog/ModalNodeBuilder';
import { ConstraintManager2 } from './edit-constraint-manager';
import NavTree from '../tree/NavTree';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import PhenomId from "../../requests/phenom-id";
import ReactTooltip from 'react-tooltip';
import { _ajax } from '../../requests/sml-requests';
import { RadioButton } from '@progress/kendo-react-inputs';

class PlatformTypeLite extends React.Component {
    static defaultProps = {
      newNode: {
        name: "",
        xmiType: "platform:Boolean",
        description: "",
        realizes: "",
        parent: "",
        constraint: null,
        children: [],

        digits: 0,
        scale: 0,
        size: 0,
        length: 0,
        maxSize: 0,
        maxLength: 0,
        subModelId: undefined,
      },
    }

    // ------------------------------------
    // 00. State
    // ------------------------------------
    phenomId = new PhenomId("edit-platform-types", this.props.idCtx);
    domRef = React.createRef();
    primitiveTypes = primitiveTypeList.map(xmiType => getShortenedStringRepresentationOfXmiType(xmiType));
    restrictedTypes = ["Enumeration", "IDL Struct"];
    primitiveWithConstraints = [ ...constraintsToPrimitives["platform:RegularExpressionConstraint"], ...constraintsToPrimitives["platform:RealRangeConstraint"], ...constraintsToPrimitives["platform:IntegerRangeConstraint"]]

    defaultState = {
      ...this.props.newNode,
    }
    state = cloneDeep(this.defaultState);


    // ------------------------------------
    // 01. Life Cycle Methods
    // ------------------------------------
    componentDidMount() {
      const { destructureReference } = this.props;

      this.initNodeState();
      this.setState({showLinkerInputs: destructureReference ? true : false})
    }
  
    componentDidUpdate(prevProps, prevState) {
      // node change - init data
      if (prevProps.node !== this.props.node) {
        this.initNodeState();

      // update realization if measurement changes
      } else if (prevProps.measurement?.guid !== this.props.measurement?.guid) {
        this.setState({ realizes: this.props.measurement });
      }

      // fetch view family struct links upon first linker inputs expand
      if(prevState.showLinkerInputs !== this.state.showLinkerInputs && this.state.showLinkerInputs && !this.state.linkOptions?.length) {
        this.fetchLinkOptions();
      }

      // toggle linker input to existing upon save
      if(prevProps.destructureLink?.guid !== this.props.destructureLink?.guid && !isPhenomGuid(this.props.destructureLink?.guid)) {
        this.toggleLinkerInput(false);
      }
    }

    // ------------------------------------
    // 02. Initial Setup
    // ------------------------------------
    initNodeState = () => {
      const { node } = this.props;

      if (node && node.guid) {
        _ajax({
          url: "/index.php?r=/node/model-get-node/",
          method: "get",
          data: {
              guid: node.guid,
              coreAddenda: ["childrenMULTI", "realizes", "platformTypeMeasurementStatus", "constraint", "typers"],
              coreAddendaChildren: ["type"],
              realizesAddenda: ["measurementAxisMULTI", "measurementSystem", "realizationsMULTI", "childrenMULTI", "measurementSystemAxis"],
              realizesAddendaChildren: ["type"],
              typeAddenda: ["realizationsMULTI", "measurementAxisMULTI"],
              measurementAxisAddenda: ["realizationsMULTI", "measurementSystemAxis"],
              measurementSystemAddenda: ["measurementSystemAxisMULTI"],
          },
        }).then((node) => {
          const children = this.formatChildren(node);
          this.setState({
            ...cloneDeep(this.defaultState),
            ...node,
            name: node?.name || node?.rolename,
            guid: node?.guid || createPhenomGuid(),
            children: children,
          }, () => {
            // get link options after guid is set
            if (this.props.node.xmiType === "platform:IDLStruct") {
              this.fetchLinkOptions();
            }
          });
        });
      } else {
        this.setState({
          ...cloneDeep(this.defaultState),
          guid: createPhenomGuid(),
          realizes: this.props.measurement || "",
        });
      }
    }

    fetchConstraintOptions(cb) {
      $.ajax({
          url: "/index.php?r=/constraint/model-nodes-of-type",
      }).then((res) => {
          const response = deGuidify(JSON.parse(res).nodes);
          this.setState({constraintOptions: response}, cb);
      });
    }

    /**
     * Fetches and updates the view family struct link options and sets props 'destructureLink'.
     *
     * @returns {void} Updates the state/ props with the new `destructureLink` data.
     * 
     * Note: This function relies on `viewGuid` and platformType `guid`. The AJAX request is
     *       made only if `viewGuid` is available.
     */
    fetchLinkOptions = ( ) => {
      const { viewGuid, destructureLink, handleChangeDestructureLink } = this.props;
      const { guid } = this.state;

      if (!viewGuid || !guid) return;

      const data = {
        viewGuid: viewGuid,
        structGuid: guid,
      }

      _ajax({
        url: "/index.php?r=/detail/family-struct-links",
        method: "post",
        data: data
      }).then((response) => {
        const { data } = response;

        this.setState({
          linkOptions: data?.familyStructLinks || [],
        }, () => handleChangeDestructureLink(data?.familyStructLinks.find(link => link.name === destructureLink?.name) || destructureLink));
      });
    }


    // ------------------------------------
    // 03. Getter Methods
    // ------------------------------------
    /**
    * Handles formatting platform type children, only used for
    * IDL Structs currently to help format IDLComposition data.
    * 
    * @param {node} node Javascript object formatted as a model node.
    * @returns {Array} Updated children.
    */
    formatChildren = (node) => {
      const { xmiType, children } = node;

      switch (xmiType) {
        case "platform:IDLStruct":
          return this.updateIdlCompositions(node);

        default:
          return children;
      }
    }

    generateNode = () => {
      const node = {
        guid: this.state.guid,
      };

      // newNode is in default props
      for (let key in this.props.newNode) {
        node[key] = this.state[key];
      }

      node.realizes = node.realizes.guid;

      return node;
    }

    /**
    * Creates a new, blank 'DestructureLink' object with default values and Phenom GUID.
    *
    * @returns {Object} A new 'DestructureLink' object.
    *
    */
    createBlankDestructureLink = () => {
      const { guid } = this.state;
      const { destructureLink, viewParent } = this.props;

      return {
        name: "",
        guid: createPhenomGuid(),
        xmiType: "skayl:DestructureLinker",
        struct: destructureLink?.struct || guid || undefined,
        parent: viewParent,
      }
    }


    // ------------------------------------
    // 04. Setter Methods
    // ------------------------------------
    /**
    * Adds a key-value pair to the IDL Compositions used to fill out
    * the Axis / Attribute / VTU field.
    * 
    * @param {node} node Javascript object formatted as a model node.
    * @returns {Array} Updated IDL Compositions.
    */
    updateIdlCompositions = (node) => {
      const {children} = node;

      return children.map(idlComposition => ({
        ...idlComposition,
        attribute: NavTree.getLeafNode(idlComposition.type.realizes)?.getData() || false,
      }));
    };

    openConstraintModal = () => {
      const constraint = {
        ...ConstraintManager2.defaultProps.newNode,
        guid: createPhenomGuid(),
      }

      switch (this.state.xmiType) {
        case "platform:Char":
        case "platform:String":
        case "platform:WChar":
        case "platform:WString":
          constraint.xmiType = "platform:RegularExpressionConstraint";
          break;

        case "platform:Double": 
        case "platform:Float": 
        case "platform:LongDouble":
          constraint.xmiType = "platform:RealRangeConstraint";
          break;

        case "platform:Octet":
        case "platform:UShort":
        case "platform:ULong": 
        case "platform:Short":
        case "platform:Long": 
        case "platform:ULongLong": 
        case "platform:LongLong":
          constraint.xmiType = "platform:IntegerRangeConstraint";
          break;

        default:
          return;
      }

      ModalNodeBuilder.show({
        component: ConstraintManager2,
        node: constraint,
        isLocalSaveOnly: true,
        additionalProps: {
          lockXmiType: true,
        },
        onSaveCallback: (newConstraint) => {
          this.setState({ constraint: newConstraint })
        },
      })
    }

    handleChangeXmiType = (e) => {
      if (!e.target.value) return;

      // reconstruct the xmiType
      // it was was split on space
      const xmiType = `platform:${e.target.value.split(" ").join("")}`;
      this.setState({ xmiType });
    }

    /**
     * Sets the 'createNewDestructureLink' state based on the provided boolean value.
     *
     * @param {boolean} bool - Boolean value to set the new/existing linker input state.
     * @param {boolean} [clearLink=false] - Optional boolean value to clear or set blank destructure link based on bool.
     * @returns {void} Updates the component's state based on the provided 'bool' and 'changeLink' value.
     *
     */
    toggleLinkerInput( bool, changeLink=false ) {
      const { handleChangeDestructureLink } = this.props;

      this.setState({ newLinker: bool });

      // primarily used to clear the link upon new/existing change
      if (changeLink) {
        handleChangeDestructureLink(bool ? this.createBlankDestructureLink() : undefined);
      }
    }

    /**
     * Sets the 'showLinkerInputs' state based on the provided boolean value.
     *
     * @param {boolean} bool - Boolean value to set the new/existing linker input state.
     * @returns {void} Updates the component's state and destructuring props based on the provided 'bool' value.
     *
     */
    toggleShowLinkerInput( bool ) {
      const { handleChangeDestructureReference } = this.props;
      
      this.setState({showLinkerInputs: bool});
      
      // if user turns off showLinkerInput, clear destructuring
      if (!bool) {
        this.toggleLinkerInput(false, true);
        handleChangeDestructureReference(undefined);
      }
    }


    // ------------------------------------
    // 05. Render Methods
    // ------------------------------------
    renderNameCell = () => {
      const { editable } = this.props;
      const { guid, name, xmiType } = this.state;
      const isNew = isPhenomGuid(guid);
      const title = getShortenedStringRepresentationOfXmiType(xmiType);

      // Fields are editable for new nodes only
      // if you remove the restriction then make the appropriate edits for char page
      return <PhenomInput id={this.phenomId.gen("details","name")}
                          label={title}
                          value={name}
                          disabled={!editable || !isNew}
                          onChange={(e) => this.setState({ name: e.target.value })}>
              <div style={{ alignSelf: "center", padding: "0 5px" }}>
                <PhenomButtonLink node={this.props.node} /> 
              </div>
            </PhenomInput>
    }

    renderPrimitiveTypeCell = () => {
      const { editable } = this.props;
      const { guid } = this.state;

      const isNew = isPhenomGuid(guid);
      const adjustedXmiType = getShortenedStringRepresentationOfXmiType(this.state.xmiType);

      return <div>
              <PhenomSelect label="Primitive Type"
                            value={adjustedXmiType}
                            data={this.primitiveTypes}
                            dataDisabled={this.restrictedTypes}
                            disabled={!editable || !isNew}
                            onChange={this.handleChangeXmiType} />
        </div>
    }

    renderExtraBoundedFields = () => {
      const { editable } = this.props;
      const { guid, xmiType, digits, scale, size, maxSize, length, maxLength } = this.state;
      const isNew = isPhenomGuid(guid);

      switch (xmiType) {
        case "platform:Fixed":
          return <div className="p-row">
            <div className="p-col">
              <PhenomInput label="Digits"
                           value={digits}
                           type="number"
                           disabled={!editable || !isNew}
                           onChange={(e) => this.setState({ digits: e.target.value })} />
            </div>
            <div className="p-col">
              <PhenomInput label="Scale"
                           value={scale}
                           type="number"
                           disabled={!editable || !isNew}
                           onChange={(e) => this.setState({ scale: e.target.value })} />
            </div>
          </div>

        case "platform:IDLArray":
          return <PhenomInput label="Size"
                              value={size}
                              type="number"
                              disabled={!editable || !isNew}
                              onChange={(e) => this.setState({ size: e.target.value })} />

        case "platform:IDLSequence":
          return <PhenomInput label="Max Size"
                              value={maxSize}
                              type="number"
                              disabled={!editable || !isNew}
                              onChange={(e) => this.setState({ maxSize: e.target.value })} />

        case "platform:CharArray":
        case "platform:WCharArray":
          return <PhenomInput label="Length"
                              value={length}
                              type="number"
                              disabled={!editable || !isNew}
                              onChange={(e) => this.setState({ length: e.target.value })} />

        case "platform:BoundedString":
        case "platform:BoundedWString":
          return <PhenomInput label="Max Length"
                              value={maxLength}
                              type="number"
                              disabled={!editable || !isNew}
                              onChange={(e) => this.setState({ maxLength: e.target.value })} />

        default:
          return null;
      }
    }    

    renderConstraintSection = () => {
      const { editable } = this.props;
      const { guid, xmiType } = this.state;
      const isNew = isPhenomGuid(guid);

      if (!this.primitiveWithConstraints.includes(xmiType)) {
        return null;
      }

      return <div>
          <PhenomLabel text="Constraint" />
          <div>
            <Button icon="add" 
                    disabled={!editable || !isNew}
                    onClick={this.openConstraintModal}>
              Create New
            </Button>
          </div>

          { this.renderConstraintTable() }
        </div>
    }

    renderConstraintTable = () => {
      const { constraint } = this.state;

      // invalid
      if (!constraint?.guid) {
        return null;
      }

      const isExpression = constraint.xmiType === "platform:RegularExpressionConstraint";
      const isRealRange = constraint.xmiType === "platform:RealRangeConstraint";
      const isIntegerRange = constraint.xmiType === "platform:IntegerRangeConstraint";

      return <table style={{ fontSize: 14, width: "100%", marginTop: 10 }}>
        <tbody>
          <tr>
            <th>Name</th>
            <td>{ constraint.name }</td>
          </tr>

          {isExpression && constraint.expression &&
            <tr>
              <th style={{ width: "50%" }}>RegEx Expression</th>
              <td>{ constraint.expression }</td>
            </tr> }

          {(isRealRange || isIntegerRange) && constraint.upperBound &&
            <tr>
              <th>Upper Bound</th>
              <td>{ constraint.upperBound }</td>
              {isRealRange &&
                <td>{ constraint.upperBoundInclusive ? "Inclusive" : "Exclusive" }</td> }
            </tr> }

          {(isRealRange || isIntegerRange) && constraint.lowerBound &&
            <tr>
              <th>Lower Bound</th>
              <td>
                { constraint.lowerBound }
              </td>
              {isRealRange &&
                <td>{ constraint.lowerBoundInclusive ? "Inclusive" : "Exclusive" }</td> }
            </tr> }
        </tbody>
      </table>
    }

    renderEnumerationSection = () => {
      const { xmiType, children=[] } = this.state;

      if (xmiType !== "platform:Enumeration") {
        return null;
      }

      return <Grid data={children}>
        <GridNoRecords>
          No Data Available.
        </GridNoRecords>
        <GridColumn title="Name" field="name" />
        <GridColumn title="Type" field="xmiType" cell={(props) => <td> { getShortenedStringRepresentationOfXmiType(props.dataItem["xmiType"]) } </td>} />
      </Grid>
    }

    renderIdlCompositionCell = (trElement, props) => {
      const { destructureReference } = this.props;
      const classes = [];

      // grey background for members included in destructure reference
      if (destructureReference && destructureReference !== props.dataItem["guid"]) {
        classes.push("non-destructure-row");
      } else {
        classes.push("blank-row")
      }
      
      return React.cloneElement(trElement, {
        ...trElement.props,
        className: classes.join(""),
      }, trElement.props.children);
    };

    renderIdlStructSection = () => {
      const { xmiType, children=[], showLinkerInputs } = this.state;
      const { destructureReference, handleChangeDestructureReference} = this.props;

      if (xmiType !== "platform:IDLStruct") {
        return null;
      }

      return <div className="p-col">
        <div className="p-row" style={{gap: "2px"}}>
          <PhenomLabel text="IDL Compositions" style={{ width: "100%" }}>
              {/* Render tooltip if Projected Char has Destructure Reference */}
              {(destructureReference || showLinkerInputs) && <div style={{display: "flex"}}>
                  <span className="fas fa-info-circle"
                      style={{margin: "2px 5px 0 0"}}
                      data-tip
                      data-for="compInfo"
                      data-place="right"/>
                  <ReactTooltip id='compInfo'>
                          <span>In the parent View's template, this Characteristic can be represented either by the whole IDLStruct or by addressing individually an IDLStruct's member using the member reference notation {"(->)"}.<br/><br/>
                          In the table below, the members referenced in the template (either all of them in the former case or the selected one in the latter) will appear plain while the excluded members will be marked with a gray background.<br/><br/>
                          Please refer to the "Query and Template Preview" section from the parent View for more details.
                          </span>
                  </ReactTooltip>
              </div> }
          </PhenomLabel>

          <Grid data={children} rowRender={this.renderIdlCompositionCell} className="blank-grid">
            <GridNoRecords>
              No Data Available.
            </GridNoRecords>
            <GridColumn title="Name" field="rolename" />
            <GridColumn title="Axis / Attribute / VTU" cell={(props) => <td> <PhenomLink node={props.dataItem["attribute"]} newTab={true}/> </td>}/>
            <GridColumn title="Type" cell={(props) => <td> <PhenomLink node={props.dataItem["type"]} newTab={true}/> </td>} />
            <GridColumn title="Primitive" headerClassName="short-header" cell={(props) => <td style={{width: "15%"}}> { getShortenedStringRepresentationOfXmiType(props.dataItem["type"]["xmiType"]) } </td>} />
            {showLinkerInputs &&
              <GridColumn title="Destructure Reference" headerClassName="short-header" cell={props => (
                          <td style={{padding: 0, width: "15%"}}>
                            <ul style={{ margin:0, listStyle:"none", paddingLeft: "10px"}}>
                              <li>
                                <RadioButton value="" 
                                            label="" 
                                            checked={destructureReference === props.dataItem["guid"]}
                                            onClick={() => handleChangeDestructureReference(props.dataItem["guid"])} /> 
                              </li>
                            </ul>
                          </td>
                          )}/>}
          </Grid>
        </div>
        
        {showLinkerInputs && 
          <div className="p-col">
            <div className="p-col" style={{gap: "2px"}}>
                <div className="p-row"> 
                  { this.renderLinkerInputToggle() }
                  { this.renderLinkerInput() }
                  <div className="p-col p-col-5"/>
                </div>
              </div>
          </div>}
      </div>
    }

    renderLinkerInput() {
      const { destructureLink, handleChangeDestructureLink, editable } = this.props;
      const { linkOptions, newLinker } = this.state;

      return <div className="p-col p-col-5">
                {newLinker ? 
                  <PhenomInput
                      label="New Destructure Linker"
                      id={this.phenomId.gen("link-name")}
                      value={destructureLink?.name}
                      disabled={!editable}
                      onChange={(e) => handleChangeDestructureLink({
                        ...destructureLink,
                        name: e.target.value,
                      })}/>
                  :
                  <PhenomComboBox
                      id={this.phenomId.gen("link")}
                      label="Destructure Linker"
                      value={destructureLink}
                      disabled={!editable}
                      dataItemKey="guid"
                      data={linkOptions?.filter(link => link.guid !== destructureLink?.guid) || []}
                      onChange={handleChangeDestructureLink}
                      onClickCancelIcon={() => handleChangeDestructureLink(undefined)}/>
                }
             </div>
    }

    renderLinkerInputToggle() {
      const { newLinker } = this.state;
      const { editable } = this.props;

      return <div className="p-col p-col-1">
                <div className="p-row">
                  <div style={{width: "100%"}}>
                    <PhenomLabel text="Existing/New" />
                    <PhenomToggle data={["Existing", "New"]}
                                  checked={newLinker}
                                  disabled={!editable}
                                  onChange={(e) => {
                                    this.toggleLinkerInput(e.target.checked, true);
                                  }}
                                  id={this.phenomId.genPageId("new-toggle")} />
                  </div>
                </div>
             </div>
    }

    renderShowLinkerToggle = () => {
      const { editable } = this.props;
      const { showLinkerInputs } = this.state;

      return <div>
              <PhenomLabel text="Destructure Linker" />
              <PhenomToggle data={["OFF", "ON"]}
                            checked={showLinkerInputs}
                            disabled={!editable}
                            onChange={(e) => {
                              this.toggleShowLinkerInput(e.target.checked);
                            }}
                            id={this.phenomId.genPageId("enable-toggle")} />
        </div>
    }

    render() {
      return <div className="edit-form">
        <div className="p-row">

          <div className="p-col p-col-5">
            { this.renderNameCell() }
          </div>

          <div className="p-col p-col-5">
            { this.renderPrimitiveTypeCell() }
          </div>

          {/* Render destructure linker toggle next to primitive type cell */}
          {this.props.measurement?.measurementAxis?.length > 1 && 
            <div className="p-col p-col-1">
              { this.renderShowLinkerToggle() }
            </div>}
        </div>

        { this.renderExtraBoundedFields() }
        { this.renderConstraintSection() }
        { this.renderEnumerationSection() }
        { this.renderIdlStructSection() }
        
      </div>
    }
}


export default PlatformTypeLite;