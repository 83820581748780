import { AnchoredPath, ColorCollapsable } from "../util/stateless";
import {BasicConfirm} from "../dialog/BasicConfirm";
import {BasicAlert} from "../dialog/BasicAlert";
import React from "react";
import { fetchNodesByTags, _ajax } from "../../requests/sml-requests";
import {typeToPath} from "../../requests/type-to-path";
import {NavLink} from "react-router-dom";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import $ from "jquery";
import {FixMyModel} from "../util/fixMyModel";
import {Button} from "@progress/kendo-react-buttons";
import { SubMenuLeft } from "../edit/edit-top-buttons";
import PhenomLoadButton from "../widget/LoaderButton";
import {PhenomLink} from "../widget/PhenomLink";
import PhenomId from "../../requests/phenom-id";
import { connect } from "react-redux";
import { runHealthReport } from "../../requests/actionThunks";
import NavTree from "../tree/NavTree";
import { Notifications2 } from "../edit/notifications";

class HealthCheck extends React.Component {
    constructor(props) {
        super(props);
        this.reportNames = ["entity_issues", "attr_issues", "obs_issues", "name_issues", "circular_issues",
                            "traceability_issues", "chars_using_ent_pholder_issues", "chars_using_obs_pholder_issues", "ents_typing_pholder_issues", "chars_using_pholder_meas_issues",
                            "uniqueid_issues", "deprecation_issues", "pdm_enum_issues", "pdm_empty_views_issues", "gen_spec_issues",
                            "model_conformance_issues", "platform_type_measurement_issues", "chars_are_optional_issues", "im_context_issues", "conversion_issues", "bounds_issues", 
                            "nested_view_issues", "composed_block_issues"];
        this.state = {
            loading: false,
            checks: 0,
            issueCount: 0,
            runtime: 0,
            issues: {
                entity_issues: [],
                attr_issues: {},
                obs_issues: {},
                name_issues: [],
                circular_issues: [],
                traceability_issues: [],
                chars_using_ent_pholder_issues: [],
                chars_using_obs_pholder_issues: [],
                ents_typing_pholder_issues: [],
                chars_using_pholder_meas_issues: [],
                uniqueid_issues: [],
                deprecation_issues: [],
                deprecation_issues_paths: [],
                pdm_enum_issues: [],
                pdm_empty_views_issues: [],
                gen_spec_issues: [],
                model_conformance_issues: [],
                platform_type_measurement_issues: [],
                chars_are_optional_issues: [],
                im_context_issues: [],
                im_uopi_issues: [],
                conversion_issues: [],
                bounds_issues: [],
                nested_view_issues: [],
                composed_block_issues: [],
            },
            downloadLoc: "",
            includeTags: "",
            excludeTags: "",
            nodesFromTags: [],
            reportCompleted: false,
            changeSetId: "",
            selectedIssues: this.reportNames.reduce((obj, string) => ({ ...obj, [string]: true }), {}),
            prevSelectedIssues: [],
        };
        this.colors = ["#489bb3", "#5e4fa2", "#f37a4c", "#b5ce91", "#96228f",
                       "#b15050", "#38d68f", "#4073ff", "#ec69ff", "#ad3d5f",
                       "#4287f5", "#770000", "#afaf40", "#b5ce91", "#8aa5b2",
                       "#833B83", "#423b83", "#489bb3", "#fda64e", "#3A7C80",
                       "#FF99CC", "#ff1e47", "#800020"];
        this.reportToColor = Object.assign({}, ...this.reportNames.map((n, index) => ({[n]: this.colors[index]})));
        this.phenomId = new PhenomId("health-check",this.props.idCtx);
    }

    entity_issues() {
        return {
            issue_name: "entity_issues",
            color: this.reportToColor["entity_issues"],
            circle_caption: ["Non-unique Entities"],
            numerator: this.countNodes(this.state.issues.entity_issues) || 0,
            denomenator: this.state.issues.entity_count || 0,
            issues: this.state.issues.entity_issues,
            render_function: this.standardIssueList,
            collapsable_heading: ["Entity Uniqueness"],
        };
    }
    attr_issues() {
        return {
            issue_name: "attr_issues",
            color: this.reportToColor["attr_issues"],
            circle_caption: ["Entities With Same", "Attributes"],
            numerator: this.state.issues.attr_issues ? Object.keys(this.state.issues.attr_issues).length : 0,
            denomenator: this.state.issues.entity_count || 0,
            issues: this.state.issues.attr_issues,
            render_function: this.generateAttrIssue,
            collapsable_heading: ["Attribute Uniqueness"],
        };
    }
    obs_issues() {
        return {
            issue_name: "obs_issues",
            color: this.reportToColor["obs_issues"],
            circle_caption: ["Entities With Same", "Observables"],
            numerator: this.state.issues.obs_issues ? Object.keys(this.state.issues.obs_issues).length : 0,
            denomenator: this.state.issues.entity_count || 0,
            issues: this.state.issues.obs_issues,
            render_function: this.generateObsIssue,
            collapsable_heading: ["Observable Uniqueness"],
        };
    }
    name_issues() {
        return {
            issue_name: "name_issues",
            color: this.reportToColor["name_issues"],
            circle_caption: ["Name Collisions"],
            numerator: this.countNodes(this.state.issues.name_issues) || 0,
            denomenator: this.state.issues.entity_count + this.state.issues.view_count || 0,
            issues: this.state.issues.name_issues,
            render_function: this.standardIssueList,
            collapsable_heading: ["Name Collisions", "Duplicate names: "],
        };
    }
    circular_issues() {
        return {
            issue_name: "circular_issues",
            color: this.reportToColor["circular_issues"],
            circle_caption: ["Containment Issues"],
            numerator: this.countNodes(this.state.issues.circular_issues) || 0,
            denomenator: this.state.issues.entity_count || 0,
            issues: this.state.issues.circular_issues,
            render_function: this.standardIssueList,
            collapsable_heading: ["Circular Containment", "Cyclical path: "],
        };
    }
    traceability_issues() {
        return {
            issue_name: "traceability_issues",
            color: this.reportToColor["traceability_issues"],
            circle_caption: ["Non-traceable", "Paths"],
            numerator: this.state.issues?.traceability_issues?.length || 0,
            denomenator: this.state.issues.path_count || 0,
            issues: this.state.issues.traceability_issues,
            render_function: this.standardIssueList,
            collapsable_heading: ["Path Traceability", "Non-traceable path in: "],
        };
    }
    chars_using_ent_pholder_issues() {
        return {
            issue_name: "chars_using_ent_pholder_issues",
            color: this.reportToColor["chars_using_ent_pholder_issues"],
            circle_caption: ["Entity Placeholder", "Paths"],
            numerator: this.state.issues?.chars_using_ent_pholder_issues?.length || 0,
            denomenator: this.state.issues?.path_count || 0,
            issues: this.state.issues?.chars_using_ent_pholder_issues,
            render_function: this.standardIssueList,
            collapsable_heading: ["Paths using Placeholder Entity", "Path with Entity Placeholder used in: "],
        };
    }
    chars_using_obs_pholder_issues() {
        return {
            issue_name: "chars_using_obs_pholder_issues",
            color: this.reportToColor["chars_using_obs_pholder_issues"],
            circle_caption: ["Observable", "Placeholder", "Paths"],
            numerator: this.state.issues?.chars_using_obs_pholder_issues?.length || 0,
            denomenator: this.state.issues?.path_count || 0,
            issues: this.state.issues?.chars_using_obs_pholder_issues,
            render_function: this.standardIssueList,
            collapsable_heading: ["Paths projecting Placeholder Observable", "Path with Observable Placeholder projected in: "],
        };
    }
    ents_typing_pholder_issues() {
        return {
            issue_name: "ents_typing_pholder_issues",
            color: this.reportToColor["ents_typing_pholder_issues"],
            circle_caption: ["Entities With", "Placeholder", "Observable"],
            numerator: this.state.issues?.ents_typing_pholder_issues?.length || 0,
            denomenator: this.state.issues?.entity_count || 0,
            issues: this.state.issues?.ents_typing_pholder_issues,
            render_function: this.standardIssueList,
            collapsable_heading: ["Entities with Placeholder Observable", "Entity with Composition typing Observable Placeholder: "],
        };
    }
    chars_using_pholder_meas_issues() {
        return {
            issue_name: "chars_using_pholder_meas_issues",
            color: this.reportToColor["chars_using_pholder_meas_issues"],
            circle_caption: ["Measurement", "Placeholder", "Paths"],
            numerator: this.state.issues?.chars_using_pholder_meas_issues?.length || 0,
            denomenator: this.state?.issues?.path_count || 0,
            issues: this.state.issues?.chars_using_pholder_meas_issues,
            render_function: this.standardIssueList,
            collapsable_heading: ["Characteristics using Placeholder Measurement", "Characteristic using Placeholder Measurement: "],
        };
    }
    uniqueid_issues() {
        return {
            issue_name: "uniqueid_issues",
            color: this.reportToColor["uniqueid_issues"],
            circle_caption: ["Entities", "Without", "Identifier"],
            numerator: this.state.issues?.uniqueid_issues?.length || 0,
            denomenator: this.state.issues?.entity_count || 0,
            issues: this.state.issues?.uniqueid_issues,
            render_function: this.generateUniqueIdIssue,
            collapsable_heading: ["Entities without an Identifier"],
        };
    }
    deprecation_issues() {
        return {
            issue_name: "deprecation_issues",
            color: this.reportToColor["deprecation_issues"],
            circle_caption: ["Deprecation", "Issues"],
            numerator: this.state.issues.deprecation_issues && Object.values(this.state.issues.deprecation_issues).reduce((acc, cur) => cur ? acc + Object.keys(cur).length : acc, 0) + Object.values(this.state.issues.deprecation_issues_paths).reduce((acc, cur) => cur ? acc + Object.keys(cur).length : acc, 0) || 0,
            denomenator: this.state.issues?.path_count + this.state.issues?.message_port_count || 0,
            issues: this.state.issues?.deprecation_issues,
            render_function: this.generateDeprecationIssue,
            collapsable_heading: ["Deprecation Issues"],
        };
    }
    pdm_enum_issues() {
        return {
            issue_name: "pdm_enum_issues",
            color: this.reportToColor["pdm_enum_issues"],
            circle_caption: ["Platform Enums", "w/o matching", "Literals/Labels"],
            numerator: this.state.issues?.pdm_enum_issues && Object.values(this.state.issues.pdm_enum_issues).flat().length || 0,
            denomenator: this.state.issues?.pdm_enum_count || 0,
            issues: this.state.issues?.pdm_enum_issues,
            render_function: this.generatePDMEnumIssue,
            collapsable_heading: ["Platform Enumerations with improperly matched Literals"],
        };
    }
    pdm_empty_views_issues() {
        return {
            issue_name: "pdm_empty_views_issues",
            color: this.reportToColor["pdm_empty_views_issues"],
            circle_caption: ["Views", "without", "Characteristics"],
            numerator: this.state.issues?.pdm_empty_views_issues && Object.values(this.state.issues.pdm_empty_views_issues).flat().length || 0,
            denomenator: this.state.issues?.view_count || 0,
            issues: this.state.issues?.pdm_empty_views_issues,
            render_function: this.standardIssueList,
            collapsable_heading: ["Views without Characteristics", "View without a characteristic: "],
        };
    }
    gen_spec_issues() {
        return {
            issue_name: "gen_spec_issues",
            color: this.reportToColor["gen_spec_issues"],
            circle_caption: ["Specialization", "Issues"],
            numerator: this.state.issues?.gen_spec_issues?.length,
            denomenator: this.state.issues?.entity_count || 0,
            issues: this.state.issues?.gen_spec_issues,
            render_function: this.generateGenSpecIssue,
            collapsable_heading: ["Specialization Inconsistencies"],
        };
    }
    conversion_issues() {
        return {
            issue_name: "conversion_issues",
            color: this.reportToColor["conversion_issues"],
            circle_caption: ["Conversion", "Errors"],
            numerator: this.state.issues?.conversion_issues?.length,
            denomenator: this.state.issues?.conversion_count || 0,
            issues: this.state.issues?.conversion_issues,
            render_function: this.generateConversionIssue,
            collapsable_heading: ["Conversion Errors"],
        };
    }
    bounds_issues() {
        return {
            issue_name: "bounds_issues",
            color: this.reportToColor["bounds_issues"],
            circle_caption: ["Invalid bounds"],
            numerator: this.state.issues?.bounds_issues?.length,
            denomenator: this.state?.issues.bounds_count || 0,
            issues: this.state.issues?.bounds_issues,
            render_function: this.standardIssueList,
            collapsable_heading: ["Bounds Issues", "Bounds issues in: "],
        };
    }
    model_conformance_issues() {
        const conformanceSize = this.state.issues?.model_conformance_issues && Object.keys(this.state.issues.model_conformance_issues).length;
        const nonConformantCount = this.state.issues?.model_conformance_issues && Object.values(this.state.issues.model_conformance_issues).reduce((total, issue) => {
          const { errors } = issue;
          let referencesBadNodes = false;

          if (errors && errors.length > 0) {
              referencesBadNodes = errors.some(error => {
              return error.kind.split(' - ')[1] === "references bad node";
            })
          }

          return referencesBadNodes ? total + 1 : total;
        }, 0);

        return {
            issue_name: "model_conformance_issues",
            color: this.reportToColor["model_conformance_issues"],
            circle_caption: ["FACE™ SDM", "Conformance", "Issues"],
            numerator: nonConformantCount,
            denomenator: conformanceSize,
            headerCount: nonConformantCount,
            issues: this.state.issues?.model_conformance_issues,
            render_function: this.generateModelConformanceIssue,
            collapsable_heading: ["FACE™ SDM Conformance"],
        };
    }
    platform_type_measurement_issues() {
        return {
            issue_name: "platform_type_measurement_issues",
            color: this.reportToColor["platform_type_measurement_issues"],
            circle_caption: ["Platform Types", "not matching", "Measurements"],
            numerator: this.state.issues.platform_type_measurement_issues && Object.values(this.state.issues.platform_type_measurement_issues).flat().length || 0,
            denomenator: this.state.issues?.platform_type_count || 0,
            issues: this.state.issues?.platform_type_measurement_issues,
            render_function: this.generatePlatformTypeMeasurementIssue,
            collapsable_heading: ["Platform Types with inappropriate type or Measurement"],
        };
    }
    chars_are_optional_issues(){
      return {
          issue_name: "chars_are_optional_issues",
          color: this.reportToColor["chars_are_optional_issues"],
          circle_caption: ["Integration", "are", "Optional"],
          numerator: this.state.issues?.chars_are_optional_issues?.total || 0,
          denomenator: this.state.issues?.char_count || 0,
          issues: this.state.issues?.chars_are_optional_issues,
          render_function: this.standardIssueList,
          collapsable_heading: ["Optional Characteristics"],
          multi:true,
          multi_heading:{
                         reg:{
                           title:"Standard Characteristics.",
                           text:"Optional Characteristic:"},
                         nest:{
                           title:"Nesting Characteristics.",
                           text:"Nesting Optional Characteristic:"}},
          multi_keys:["reg","nest"],
      };
    }
    im_context_issues(){
      return {
          issue_name: "im_context_issues",
          color: this.reportToColor["im_context_issues"],
          circle_caption: ["Integration", "Context"],
          numerator: this.state.issues?.im_context_issues?.length + this.state.issues?.im_uopi_issues?.length,
          denomenator: (this.state.issues?.im_context_count || 0) + this.state.issues?.im_uopi_issues?.length,
          issues: this.state.issues?.im_context_issues,
          render_function: this.generateIntegrationContextIssue,
          collapsable_heading: ["Integration Context"],
      };
    }
    nested_view_issues() {
        return {
            issue_name: "nested_view_issues",
            color: this.reportToColor["nested_view_issues"],
            circle_caption: ["Nested Views with", "Unique Paths"],
            numerator: this.state.issues?.nested_view_issues?.length,
            denomenator: (this.state.issues?.nested_view_count || 0),
            issues: this.state.issues?.nested_view_issues,
            render_function: this.generateNestedViewIssue,
            collapsable_heading: ["Nested View Issues"],
        };
    }
    composed_block_issues() {
        return {
            issue_name: "composed_block_issues",
            color: this.reportToColor["composed_block_issues"],
            circle_caption: ["Composed Block", "Instance Issues"],
            numerator: this.state.issues?.composed_block_issues?.length,
            denomenator: (this.state.issues?.composed_block_count || 0),
            issues: this.state.issues?.composed_block_issues,
            render_function: this.generateComposedBlockIssue,
            collapsable_heading: ["Composed Block Instance Issues"],
        };
    }

    componentDidMount() {
        NavTree.collapseNavTree(true);
        NavTree.assignPresetPageFilters("data");

        // process redux report
        if (Object.keys(this.props.health_report).length) {
            this.processReport(this.props.health_report, false);
        } 
        // set checkboxes from user settings if no redux report
        else if (this.props.user.userIdentity?.settings?.health_checks?.length){
            this.initChecks(this.props.user.userIdentity.settings.health_checks);
        }
        
        // if (this.props.savedState) this.setState({...this.state, issues: this.props.savedState});
        // this.setTags();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.health_report !== this.props.health_report) {
          this.processReport(this.props.health_report, true);
        }
        // if (this.props.savedState !== prevProps.savedState) {
        //     this.setState({...this.state, issues: this.props.savedState});
        // }
        // if (this.props.includeTags !== prevProps.includeTags ||
        //     this.props.excludeTags !== prevProps.excludeTags) {
        //     this.setTags();
        // }
    }

    // used to set checkboxes from user settings
    initChecks = (settingsIssues) => {
        const {selectedIssues} = this.state;
        
        Object.keys(selectedIssues).forEach(key => {
            selectedIssues[key] = settingsIssues.includes(key);
        });

        this.setState({selectedIssues: selectedIssues});
    }

    statBox(num, msg, clr = "#fff", addon) {
        const box = {alignItems: "center", marginBottom: 80};
        const huge = {color: clr, fontSize: 100};
        const smaller = {fontSize: 18, marginTop: -25, color: "#fff"};
        const tiny = {fontSize: 10, color: "#fff"};

        const phenomId = this.phenomId;
        return (
            <div className='flex-v' style={box} id={phenomId.gen("stat-box","wrapper")}>
                <div className='flex-h' style={{alignItems: "baseline"}}>
                    <span style={huge}>{num}</span>
                    {!addon || <span style={tiny}>{addon}</span>}
                </div>
                <span style={smaller}>{msg}</span>
            </div>
        );
    }


    drawCircle(issue, idx, offset = 0) {

      if (this.state.reportCompleted) {
        let part = issue.numerator
        let total = issue.denomenator
        let msgs = issue.circle_caption
        part = total - part;
        const numerator = total - part;
        const denominator = total > 0 ? "/" + total : "";

            const canvas = document.getElementById(this.phenomId.gen(["health-issues", `canvas-${idx}`]));
            const context = canvas.getContext("2d");
            const pi = Math.PI;

            context.beginPath();
            context.arc(125, 125, 90, 0, 2 * pi, false);
            context.fillStyle = issue.color;
            context.fill();

            context.beginPath();
            context.arc(125, 125, 75, (3 * pi / 2), ((3 * pi / 2) + (2 * pi) * (((total - part) / total) || 0)), false);
            context.lineWidth = 30;
            context.strokeStyle = "#bcbec0";
            context.stroke();

            context.beginPath();
            context.arc(125, 125, 70, 0, 2 * pi, false);
            context.fillStyle = "white";
            context.fill();

            context.font = "30px Source Sans Pro";
            context.fillStyle = "#9a9a9a";
            context.textAlign = "center";
            context.fillText(numerator + denominator, canvas.width / 2, canvas.height / 2 + 5 - offset);

            msgs.forEach((msg, idx) => {
                context.font = "15px Source Sans Pro";
                context.fillStyle = "#9a9a9a";
                context.textAlign = "center";
                context.fillText(msg, canvas.width / 2, canvas.height / 2 + (25 + 15 * idx - offset));
            });
        }
    }

    addUidToAll = () => {
        const phenomId = this.phenomId;
        return BasicConfirm.show([`Please confirm you want to add Identifiers to all of the following entities: `,
            this.state.issues.uniqueid_issues.map((e, idx) => <li key={idx} id={phenomId.gen(["basic-confirm-unique-id-issues",idx],"li")}>{e.name}</li>)],
            () => {
                BasicAlert.show("Adding Identifiers. Please wait.", "Processing changes...", false);
                const guids = this.state.issues.uniqueid_issues.map(issue => issue.guid);
                $.ajax({
                  url: "/index.php?r=/node/smm-add-uid-children",
                  method: "post",
                  data: {
                    guids,
                  }
                }).then(res => {
                  const response = JSON.parse(res);
                  if(response.success) {
                    return BasicAlert.show("Finished adding Identifiers.\nWe recommend re-running the health check now.", "Process complete");
                  } else {
                    return BasicAlert.show("Something went wrong. Was unable to add Identifiers.", "Process failed");
                  }
                })

                // Promise.all(this.state.issues.uniqueid_issues.map(issue => {
                //     $.ajax({
                //         url: "/index.php?r=/node/smm-save-nodes",
                //         method: "post",
                //         data: {
                //             parent: issue.guid,
                //             rolename: "elementID",
                //             type: window["model_uid"],
                //             xmiType: "conceptual:Composition",
                //             subModelId: issue.subModelId,
                //         }
                //     });
                // }));
            });
    };

    copyDownSpecializedAttributes = (entGuid) => {
        const changeSetId = this.state.changeSetId;
        $.ajax({
            url: "/index.php?r=/entity/gen-spec-fixup-copy-down",
            method: "post",
            data: {
                entGuid, changeSetId
            }
        }).then(res => {
            if (res !== "#true") {
                let response;
                try {
                    response = JSON.parse(res);
                } catch (e) {
                    response = res;
                }

                if (response === "" || response.error) {
                    BasicAlert.show("Could not complete the requested action.\nYou may re-run the health check and attempt again.", "Process Failed");
                }
            } else {
                BasicAlert.show("Successfully added inherited attributes.\n You may re-run the health check to ensure the change.", "Process Complete");
            }
        });
    };

    // TODO: REMOVE
    setTags = () => {
        this.setState({
            includeTags: this.props.includeTags || "",
            excludeTags: this.props.excludeTags || "",
            nodesFromTags: []
        }, () => {
            if (this.state.includeTags.length || this.state.excludeTags.length) {
                fetchNodesByTags({
                    tags: this.state.includeTags.length ? this.state.includeTags : this.state.excludeTags,
                }).then(res => {
                    const response = JSON.parse(res);
                    this.setState({nodesFromTags: response.nodes})
                })
            }
        })
    }

    setChangeSetId = (e) => {
      this.setState({changeSetId: e.target.value});
    }


    bulkEditPathDep = (toBeFixed, nodes, pathHead, pathPairs) => {
        switch (toBeFixed) {
            case "Characteristic_Projection":
                FixMyModel.bulkEditCharPath(nodes, pathHead, pathPairs,
                    "Successfully saved new paths.\nYou may re-run the health check to ensure the change.",
                    "Could not complete the requested action.");
                break;
            case "Associated_Entity":
                FixMyModel.bulkEditAePath(nodes, pathHead, pathPairs,
                    "Successfully saved new paths.\nYou may re-run the health check to ensure the change.",
                    "Could not complete the requested action.");
        }
    };

    generateAttrIssue = (key,hIdx) => {
      let heading = "Attribute Uniqueness";
      let content;
      const phenomId = this.phenomId;
      if (this.state.issues.attr_issues && Object.keys(this.state.issues.attr_issues).length) {
              content = <table
                id={phenomId.gen(["health-issues",hIdx],"wrapper")}
                className="invisi-table">
                  {Object.keys(this.state.issues[key]).map((guid, idx) => {
                      const problem = this.state.issues[key][guid];
                      if (problem.issues.length) {
                          const innerContent = problem.full ? problem.issues : (problem.issues.length > 100 ? problem.issues.substring(0, 100) + "..." : problem.issues);
                          return <tr>
                              <td>{idx + 1}</td>
                              <td>
                                  <NavLink className="cadet-anchor"
                                      to={`/edit/details/entity/${guid}`} id={phenomId.gen([hIdx,`row-${idx}`],`link`)}>{problem.name}</NavLink> contains
                                  the following non-unique attributes: {innerContent}
                              </td>
                              <td>
                                  {!(problem.issues.length > 100) ||
                                      <button className={"form-button " + (problem.full ? "collapse-up" : "expand-down")}
                                          onClick={() => {
                                              problem["full"] = !problem["full"];
                                              this.setState({issues: this.state.issues});
                                          }}
                                          id={phenomId.gen([hIdx,`row-${idx}`],`expand-list-button`)}/>}
                              </td>
                          </tr>;
                      }
                  })}
              </table>;
            }
            return content;
    }

    generateObsIssue = (key,hIdx) => {
      let heading = "Attribute Uniqueness";
      let content;
      const phenomId = this.phenomId;
      if (this.state.issues.obs_issues && Object.keys(this.state.issues.obs_issues).length) {
              content = <table
                id={phenomId.gen(["health-issues",hIdx],"wrapper")}
                className="invisi-table">
                  {Object.keys(this.state.issues[key]).map((guid, idx) => {
                      const problem = this.state.issues[key][guid];
                      if (problem.issues.length) {
                          const innerContent = problem.full ? problem.issues : (problem.issues.length > 100 ? problem.issues.substring(0, 100) + "..." : problem.issues);
                          return <tr>
                              <td>{idx + 1}</td>
                              <td>
                                  <NavLink className="cadet-anchor"
                                      to={`/edit/details/entity/${guid}`} id={phenomId.gen([hIdx,`row-${idx}`],`link`)}>{problem.name}</NavLink> contains
                                  the following non-unique observables: {innerContent}
                              </td>
                              <td>
                                  {!(problem.issues.length > 100) ||
                                      <button className={"form-button " + (problem.full ? "collapse-up" : "expand-down")}
                                          onClick={() => {
                                              problem["full"] = !problem["full"];
                                              this.setState({issues: this.state.issues});
                                          }}
                                          id={phenomId.gen([hIdx,`row-${idx}`],`expand-list-button`)}/>}
                              </td>
                          </tr>;
                      }
                  })}
              </table>;
            }
            return content;
    }


    // unused?
    generateGeneralizationIssue = (key,hIdx) => {
      let heading = "Generalization Non-Compliance";
      let content;
      const phenomId = this.phenomId;
      if (this.state.issues.generalization_issues && Object.keys(this.state.issues.generalization_issues).length) {
          content = <table
            id={phenomId.gen(["health-issues",hIdx],"wrapper")}
            className="invisi-table">
              {Object.values(this.state.issues[key]).map((problem, idx) => {
                  const generalizedName = problem.generalized.name;
                  const generalizedGuid = problem.generalized.guid;
                  const specializedName = problem.specialized.name;
                  const specializedGuid = problem.specialized.guid;
                  const mismatchedFieldTypes = problem.mismatched_fields.map(field => field.type_name);

                  return <tr>
                      <td>{idx + 1}</td>
                      <td>
                          <NavLink className="cadet-anchor"
                              to={`/edit/details/entity/${specializedGuid}`}
                              id={phenomId.gen([hIdx,`row-${idx}`],`gen-link`)}>{specializedName}</NavLink> inherits
                          from <NavLink className="cadet-anchor"
                              to={`/edit/details/entity/${generalizedGuid}`}
                              id={phenomId.gen([hIdx,`row-${idx}`],`gen-inherits-from-link`)}>{generalizedName}</NavLink> but
                          is missing attribute(s) of
                          type: {mismatchedFieldTypes.map((type, idx, arr) => `${(idx === 0) ? "" : (idx === arr.length - 1) ? (arr.length > 2 ? ", and " : " and ") : ", "} ${type}`)}
                      </td>
                  </tr>;
              })}
          </table>;
      }
      return content;
    }


    generateUniqueIdIssue = (key,hIdx) => {
      let content;
      const phenomId = this.phenomId;
      if (this.state.issues.uniqueid_issues && Object.keys(this.state.issues.uniqueid_issues).length) {
          content = <div>
              <PhenomLoadButton className="btn btn-primary"
                  idCtx={phenomId.gen(["health-issues",hIdx],"add-uid-to-all-button")}
                  text="Add Id to all"
                  onClick={this.addUidToAll}
                  style={{float: "left", margin: 10}}
              />
              <table
                 id={phenomId.gen(["health-issues",hIdx],"wrapper")}
                 className="invisi-table">
                  {Object.values(this.state.issues[key]).map((problem, idx) => {
                      const {guid, name} = problem;
                      return <tr>
                          <td>{idx + 1}</td>
                          <td>
                              Entity <NavLink className="cadet-anchor"
                                  to={`/edit/details/entity/${guid}`}
                                  id={phenomId.gen([hIdx,`entity-${idx}`],"link")}>{name}</NavLink> does
                              not
                              contain an Identifier
                          </td>
                        </tr>;
                    })}
                </table>
            </div>;
        }
        return content;
    }

    generateDeprecationIssue = (key, hIdx) => {
        let content;
        const phenomId = this.phenomId;

        if (this.state.issues.deprecation_issues && Object.keys(this.state.issues.deprecation_issues).length) {
            const depPaths = "deprecation_issues_paths";
            content = <table
                id={phenomId.gen(["health-issues", hIdx], "wrapper")}
                className="invisi-table">
                <tbody>
                    {Object.keys(this.state.issues[key]).map((depRef, depidx) => {
                        phenomId.gen(hIdx, `dependent-${depidx}`);
                        //checks that the test return is not null
                        if (this.state.issues[key][depRef]) {
                            const links = Object.keys(this.state.issues[key][depRef]).map((guid, i) => {
                                const ref = typeToPath(this.state.issues[key][depRef][guid]["ref"]["xmi:type"]) !== "" ?
                                    <NavLink
                                        id={phenomId.gen([`dependent-${depidx}`, `depref-${i}`], "link")}
                                        className="cadet-anchor"
                                        style={{fontSize: "16px"}}
                                        to={`/edit/details/${typeToPath(this.state.issues[key][depRef][guid]["ref"]["xmi:type"])}/${this.state.issues[key][depRef][guid]["ref"]["xmi:id"]}`}>
                                        {this.state.issues[key][depRef][guid]["ref"]["rolename"] ? this.state.issues[key][depRef][guid]["ref"]["rolename"] : this.state.issues[key][depRef][guid]["ref"]["name"]}</NavLink> :
                                    <NavLink
                                        id={phenomId.gen([`dependent-${depidx}`, `depref-${i}`], "link")}
                                        className="cadet-anchor"
                                        style={{fontSize: "16px"}}
                                        to={`/edit/details/${typeToPath(this.state.issues[key][depRef][guid]["ref"]["parent"]["xmiType"])}/${this.state.issues[key][depRef][guid]["ref"]["parent"]["guid"]}`}>
                                        {this.state.issues[key][depRef][guid]["ref"]["parent"]["rolename"] ? this.state.issues[key][depRef][guid]["ref"]["parent"]["rolename"] : this.state.issues[key][depRef][guid]["ref"]["parent"]["name"]}</NavLink>;
                                const referencing = this.state.issues[key][depRef][guid]["referencing"].map((refr, refri) => {
                                    return typeToPath(refr["xmi:type"]) !== "" ? <NavLink
                                        id={phenomId.gen([`depref-${i}`, `referencing-${refri}`], "link")}
                                        className="cadet-anchor"
                                        style={{fontSize: "16px"}}
                                        to={`/edit/details/${typeToPath(refr["xmi:type"])}/${refr["xmi:id"]}`}>
                                        {refri !== 0 ? " ," : null}{refr["rolename"] ? refr["rolename"] : refr["name"]}</NavLink> :
                                        <NavLink
                                            id={phenomId.gen([`depref-${i}`, `referencing-${refri}`], "link")}
                                            className="cadet-anchor"
                                            style={{fontSize: "16px"}}
                                            to={`/edit/details/${typeToPath(refr["parent"]["xmiType"])}/${refr["parent"]["guid"]}`}>
                                            {refri !== 0 ? " ," : null}{refr["rolename"] ? refr["rolename"] : refr["name"]}</NavLink>;
                                });

                                return <tr key={depidx}>
                                    <td>{i + 1}</td>
                                    <td style={{
                                        fontSize: "16px",
                                        paddingTop: "10px"
                                    }}>{depRef} Error: {referencing} {referencing.length === 1 ? "has" : "have"} deprecated
                                  reference{referencing.length === 1 ? "" : "s"} to: {ref}</td>

                                    {this.state.issues[key][depRef][guid]["ref"]["xmi:type"] === "conceptual:Observable" ?
                                        <td><Button icon="wrench" onClick={() => FixMyModel.bulkEditType(
                                            this.state.issues[key][depRef][guid]["ref"]["xmi:id"],
                                            this.state.issues[key][depRef][guid]["referencing"])}
                                            id={phenomId.gen(`dependent-${depidx}`, "fix-model-button")} /></td>
                                        : <td></td>}
                                </tr>;
                            });
                            return <tr>
                                <td colSpan={2}>
                                    <table style={{width: "100%", paddingTop: "10px"}}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2}
                                                    style={{textDecorationLine: "underline"}}>{depRef} Referencing Errors:
                                      </td>
                                            </tr>
                                            {links}</tbody>
                                    </table>
                                </td>
                            </tr>;
                        }
                    })}
                    {Object.values(this.state.issues[depPaths]).some(value => value !== null && !(typeof value === 'object' && Object.keys(value).length === 0)) &&
                        <div>
                            <tr>
                                <td colSpan={3} style={{paddingTop: "10px", fontWeight: "bold"}}>Path Errors:</td>
                            </tr>

                            {Object.keys(this.state.issues[depPaths]).map((cpae, index) => {

                                return <tr>
                                    <td colSpan={2}>
                                        <table style={{width: "100%", paddingTop: "10px"}} id={phenomId.gen([hIdx, `dep-path-${index}`], "wrapper")}>
                                            <tbody>
                                                <tr>
                                                    {this.state.issues[depPaths][cpae] !== null && (typeof this.state.issues[depPaths][cpae] === 'object' && !Array.isArray(this.state.issues[depPaths][cpae]) && Object.keys(this.state.issues[depPaths][cpae]).length > 0) &&
                                                        <td colSpan={2}
                                                            style={{textDecorationLine: "underline"}}>
                                                            {cpae.replace("_", " ")} Path Issues:
                                                        </td>}
                                                </tr>
                                                {Object.keys(this.state.issues[depPaths][cpae] || {}).map((guid, i) => {
                                                    phenomId.gen([`dep-path-${index}`, `cpae-${i}`], "wrapper");
                                                    const chars = this.state.issues[depPaths][cpae][guid]["chars"].map((char, idx) =>
                                                        <NavLink
                                                            id={phenomId.gen([`cpae-${i}`, `char-${idx}`], "link")}
                                                            className="cadet-anchor"
                                                            style={{fontSize: "16px"}}
                                                            to={typeToPath(char["xmiType"]) != "" ? `/edit/details/characteristic/${char["guid"]}` : `/edit/details/entity/${char["parent"]["xmi:id"]}`}>{idx > 0 ? ", " : ""}{char["name"]}</NavLink>);
                                                    const pathHead = this.state.issues[depPaths][cpae][guid]["pathHead"];
                                                    const pathPairs = this.state.issues[depPaths][cpae][guid]["path"];
                                                    return <tr key={index}>
                                                        <td>{i + 1}</td>
                                                        <td style={{wordBreak: "break-word", fontSize: "16px", paddingTop: "10px"}}>
                                                            {pathHead ? "Characteristic" : "AssociatedEntity"}{chars.length > 1 ? "s" : ""} {chars} {chars.length > 1 ? "have" : "has"} deprecated
                                                    path: <AnchoredPath
                                                                id={phenomId.gen(`cpae-${i}`)}
                                                                pathHead={pathHead}
                                                                pathPairs={pathPairs}
                                                                hasNormalAnchor={true}
                                                                isHealthCheck={true} />
                                                        </td>
                                                        <td>
                                                            <Button icon="wrench"
                                                                onClick={() => this.bulkEditPathDep(cpae, this.state.issues[depPaths][cpae][guid]["chars"], pathHead, pathPairs)}
                                                                id={phenomId.gen(`cpae-${i}`, "bulk-edit-button")} />
                                                        </td>
                                                    </tr>;
                                                })}</tbody>
                                        </table>
                                    </td>
                                </tr>;
                            })}
                        </div>}
                </tbody>
            </table>;
        }
        return content;
    }

    generatePDMEnumIssue = (key, hIdx) => {
        let content;
        const phenomId = this.phenomId;
        if (this.state.issues.pdm_enum_issues && Object.keys(this.state.issues.pdm_enum_issues).length) {
            const problemDescriptions = {'mismatched': ' has mismatched PDM Literals with differing or duplicate names', 'missing-realize': ' has matching PDM Literals with missing or mismatched realizes attribute'};
            content = <div>
                <table
                    id={phenomId.gen(["health-issues", hIdx], "wrapper")}
                    className="invisi-table">
                    {Object.entries(this.state.issues.pdm_enum_issues).map(([problemType, problems], pIdx) => {
                        phenomId.gen([hIdx, `pdm-enum-issue-${pIdx}`])
                        const titleRow = <tr><td><strong>{problemType[0].toUpperCase() + problemType.slice(1)}</strong></td><td><strong>{' Enumerations'}</strong></td></tr>
                        const problemRows = problems.map((problem, idx) => {
                            const {guid, name} = problem;
                            return <tr id={phenomId.gen([`pdm-enum-issue-${pIdx}}`, `problem-${idx}`], "wrapper")}>
                                <td>{idx + 1}</td>
                                <td>
                                    Platform Enumeration <NavLink className="cadet-anchor"
                                        to={`/edit/details/platform_type/${guid}`}
                                        id={phenomId.gen(`problem-${idx}`, "link")}>{name}</NavLink> {problemDescriptions[problemType]}
                                </td>
                            </tr>;
                        });
                        return [titleRow, ...problemRows];
                    }).flat()}
                </table>
            </div>;
        }
        return content;
    }

    generatePlatformTypeMeasurementIssue = (key, hIdx) => {
        let content;
        const phenomId = this.phenomId;
        if (this.state.issues.platform_type_measurement_issues && Object.keys(this.state.issues.platform_type_measurement_issues).length) {
            const problemDescriptions = {'badEnum': 'Enumerations realizing non-enum Measurements:', 'badEnumMeas': 'Simple Types realizing enum Measurements:',
                                         'badStruct':'IDLStructs realizing non-struct Measurements:', 'badStructMeas':'Simple Types realizing struct Measurements:',
                                         'badAttrMeas':'Simple Types realizing Measurements with attributes:'};
            content = <div>
                <table
                    id={phenomId.gen(["health-issues", hIdx], "wrapper")}
                    className="invisi-table">
                    {Object.entries(this.state.issues.platform_type_measurement_issues).filter(([problemType, problems]) => {return problems.length}).map(([problemType, problems], pIdx) => {
                        phenomId.gen([hIdx, `pdm-enum-issue-${pIdx}`])
                        const titleRow = <tr><td></td><td><strong>{problemDescriptions[problemType]}</strong></td></tr>
                        const problemRows = problems.map((problem, idx) => {
                            const {guid, name, type, realizes} = problem;
                            return <tr id={phenomId.gen([`pdm-enum-issue-${pIdx}}`, `problem-${idx}`], "wrapper")}>
                                <td>{idx + 1}</td>
                                <td>
                                    <NavLink className="cadet-anchor" to={`/edit/details/platform_type/${guid}`} id={phenomId.gen(`problem-${idx}`, "link")}>{name}</NavLink>
                                </td>
                            </tr>;
                        });
                        return [titleRow, ...problemRows];
                    }).flat()}
                </table>
            </div>;
        }
        return content;
    }

    generateIntegrationContextIssue = (key, hIdx) => {
      const phenomId = this.phenomId;
      const sub_color = "#8aa5b2";
      const yellow_warning = '\u26A0\uFE0F';
      const red_error = '\u274C';
      const { im_context_issues, im_uopi_issues, } = this.state.issues;
      if (!im_context_issues) return;

      return <div id={phenomId.gen(["health-issues", hIdx], "wrapper")}>
        {!!im_context_issues?.length && im_context_issues.map((context, j) => {
          const { blocks_with_multiple_in_connections, connections_with_mismatched_datatypes } = context;

          return <div style={{ margin: "10px 0" }}>
              <div><label style={{ marginBottom: 0 }}>{context.name}</label> (Context)</div>
              {!!blocks_with_multiple_in_connections.length &&
                <ColorCollapsable
                  idCtx={phenomId.gen(["context-issues-inbound-connections", j])}
                  contentId={phenomId.gen(["context-issues-inbound-connections", j], "color-collapsable-content-wrapper")}
                  heading={`${red_error} Blocks with multiple inbound connections`}
                  color={sub_color}
                  vMargin={10}
                  default={true}
                  noToggle={false}
                  content={<ul>{blocks_with_multiple_in_connections.map((portNode, k) => (
                              <li key={portNode.guid}>{portNode.parent.name}.{portNode.rolename}</li>
                          ))}</ul>}
                />}

              {!!connections_with_mismatched_datatypes.length &&
                <ColorCollapsable
                  idCtx={phenomId.gen(["context-issues-mismatched-datatype", j])}
                  contentId={phenomId.gen(["context-issues-mismatched-datatype", j], "color-collapsable-content-wrapper")}
                  heading={`${red_error} Connections with inconsistent data type`}
                  color={sub_color}
                  vMargin={10}
                  default={true}
                  noToggle={false}
                  content={<ul>{connections_with_mismatched_datatypes.map((portNode) => (
                              <li key={portNode.guid}>{portNode.parent.name}.{portNode.rolename}</li>
                          ))}</ul>}
                />}
          </div>})}

        {!!im_uopi_issues.length && im_uopi_issues.map((uopi, j) => {
          const { endpoints_with_multiple_in_connections, endpoints_with_multiple_out_connections, endpoints_without_message_port } = uopi;

          return <div style={{ margin: "10px 0" }}>
              <div><PhenomLink node={uopi} /> (UoP Instance)</div>
              {!!endpoints_without_message_port.length &&
                <ColorCollapsable
                  idCtx={phenomId.gen(["uopi-issues-bad-message-port", j])}
                  contentId={phenomId.gen(["uopi-issues-bad-message-port", j], "color-collapsable-content-wrapper")}
                  heading={`${red_error} Connected message port node found`}
                  color={sub_color}
                  vMargin={10}
                  default={true}
                  noToggle={false}
                  content={<ul>{endpoints_without_message_port.map((endpoint) => (
                            <li key={endpoint.guid}><PhenomLink node={endpoint} /></li>
                          ))}</ul>}
                />}

              {!!endpoints_with_multiple_in_connections.length &&
                <ColorCollapsable
                  idCtx={phenomId.gen(["uopi-issues-inbound-connections", j])}
                  contentId={phenomId.gen(["uopi-issues-inbound-connections", j], "color-collapsable-content-wrapper")}
                  heading={`${red_error} Endpoints with multiple inbound connections`}
                  color={sub_color}
                  vMargin={10}
                  default={true}
                  noToggle={false}
                  content={<ul>{endpoints_with_multiple_in_connections.map((endpoint) => (
                            <li key={endpoint.guid}><PhenomLink node={endpoint} /></li>
                          ))}</ul>}
                />}

              {!!endpoints_with_multiple_out_connections.length &&
                <ColorCollapsable
                  idCtx={phenomId.gen(["uopi-issues-outbound-connections", j])}
                  contentId={phenomId.gen(["uopi-issues-outbound-connections", j], "color-collapsable-content-wrapper")}
                  heading={`${yellow_warning} Endpoints with multiple outbound connections`}
                  color={sub_color}
                  vMargin={10}
                  default={true}
                  noToggle={false}
                  content={<ul>{endpoints_with_multiple_out_connections.map((endpoint) => (
                            <li key={endpoint.guid}><PhenomLink node={endpoint} /></li>
                          ))}</ul>}
                />}
          </div>
        })}
      </div>
    }

    generateGenSpecIssue = (key, hIdx) => {
        let content;
        const phenomId = this.phenomId;
        if (this.state.issues.gen_spec_issues && this.state.issues.gen_spec_issues.length) {
            const gen_spec_error = this.state.issues.gen_spec_issues.at(0).error ? this.state.issues.gen_spec_issues.shift().error : false;
            const gen_spec_error_exists = gen_spec_error != false;
            content = <div
                id={phenomId.gen(["health-issues", hIdx], "wrapper")}
                style={{padding: "15px 10px"}}>
                {gen_spec_error_exists && <div key={"error"} style={{margin: "15px 0", display: "flex"}}>{gen_spec_error}</div>}
                <NavLink className="filled-button" style={{margin: 0}} to="/manage/gen-spec">SEE DETAILED REVIEW</NavLink>
                {this.state.issues.gen_spec_issues.map((problem, idx) => {
                    const {name, guid, specName, specGuid, missing_attrs} = problem;
                    return (
                        <div>
                            <div key={idx} style={{margin: "15px 0", borderBottom: "1px solid #ccc", display: "flex"}} id={phenomId.gen([hIdx, `problem-${idx}`], "wrapper")}>
                                <div style={{flexGrow: 1}}>
                                    <NavLink
                                        style={{fontSize: "100%"}}
                                        className="cadet-anchor"
                                        target="_blank"
                                        to={`/edit/details/entity/${guid}`}
                                        id={phenomId.gen(`problem-${idx}`, "entity-button")}>
                                        {name}
                                    </NavLink>
                                    &nbsp;should inherit these attributes from&nbsp;
                                    <NavLink
                                        style={{fontSize: "100%"}}
                                        className="cadet-anchor"
                                        target="_blank"
                                        to={`/edit/details/entity/${specGuid}`}
                                        id={phenomId.gen(`problem-${idx}`, "spec-entity-button")}>
                                        {specName}
                                    </NavLink>:
                                    <ul>
                                        {missing_attrs.map((attr, aIdx) =>  <li id={phenomId.gen([`problem-${idx}`, `missing-attr-${aIdx}`], "name")}> {attr.name}</li>)}
                                    </ul>
                                </div>
                                <i className="fa fa-plus-square"
                                    id={phenomId.gen(`problem-${idx}`, "copy-down-spec-attrs")}
                                    onClick={() => this.copyDownSpecializedAttributes(guid)}
                                    title={`Add listed compositions to ${name}`}
                                    style={{
                                        display: "inline-block",
                                        fontSize: "22px",
                                        verticalAlign: "middle",
                                        width: "11%",
                                        cursor: "pointer"
                                    }}/>
                            </div>
                        </div>);
                })}
            </div>;
        }

        return content;
    }



    generateModelConformanceIssue = (key, hIdx) => {
        const phenomId = this.phenomId;
        const green_check = '\u2705';
        const yellow_warning = '\u26A0\uFE0F';
        const red_error = '\u274C';
        let health_check_issue = this[key]();
        let content = <div>
            <table id={phenomId.gen(["health-issues", hIdx], "wrapper")} className="invisi-table">
                {this.state.issues.model_conformance_issues && this.state.issues.model_conformance_issues.map((issue, issueIdx) => {
                    const {sdm, errors} = issue;
                    let referencesBadNodes = false;

                    if (errors && errors.length > 0) {
                        errors.sort(function (a, b) {
                            const kindA = a.kind;
                            const descriptiveError = kindA.split(' - ');
                            if (descriptiveError.length > 1 && descriptiveError[1] == "references bad node") {
                                referencesBadNodes = true;
                                return -1;
                            }
                            else {
                                return 0;
                            }
                        });
                    }

                    const errorContent = !errors ||
                        <tr>
                            <td>Error no.</td>
                            <td>Error</td>
                        </tr> &&
                        errors.map((error, errorIdx) => {
                            return <tr id={phenomId.gen([`conformance-issue-${issueIdx}`, `error-${errorIdx}`], "wrapper")}>
                                <td>{errorIdx + 1}</td>
                                <td id={phenomId.gen(`error-${errorIdx}`, "message")}>
                                    {error.message}
                                </td>
                            </tr>
                        });
                    return <React.Fragment id={phenomId.gen([hIdx, `conformance-issue-${issueIdx}`], "wrapper")}>
                        <tr>
                            <td>SDM: {sdm}
                                {!errors && green_check} {errors && !referencesBadNodes && yellow_warning} {errors && referencesBadNodes && red_error}
                                {/* {!referencesBadNodes && <PhenomLoadButton className="btn btn-primary"
                                    text="Fix My Model"
                                    onClick={() => this.makeModelConformant(sdm)}
                                    style={{float: "left", margin: 10}}
                                />} */}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {errors && <ColorCollapsable
                                    id={phenomId.gen(["health-issues", hIdx])}
                                    heading={""}
                                    content={errorContent}
                                    color={health_check_issue.color}
                                    contentId={phenomId.gen(["health-issues", hIdx], "wrapper")}
                                    vMargin={10}
                                    default={true}
                                    noToggle={false}
                                    hasBtnActions={key === "uniqueid_issues"} />}
                            </td>
                        </tr>
                        <br></br>
                    </React.Fragment>
                })}
            </table>
        </div>;
        return content;
    }

    generateConversionIssue = (key, hIdx) => {
        let content;
        const phenomId = this.phenomId;
        if (this.state.issues.conversion_issues && this.state.issues.conversion_issues.length) {
            content = <div
                id={phenomId.gen(["health-issues", hIdx], "wrapper")}
                style={{padding: "15px 10px"}}>
                {this.state.issues.conversion_issues.map((problem, idx) => {
                    return (
                        <div>{`${idx}.`} <PhenomLink node={problem["node"]} idCtx={phenomId.gen()}/>
                          <ul>
                            {problem["errors"].map(error => <li>{error}</li>)}
                          </ul>
                        </div>);
                })}
            </div>;
        }

        return content;
    }

    generateNestedViewIssue = (issueKey, tableId, collapsable_heading, multi = false, multiKeys = [issueKey], multi_heading=false) => {
        const phenomId = this.phenomId;
        const tables = [];
  
        if (!this.state.issues[issueKey] || this.state.issues[issueKey].length === 0) return;
  
        multiKeys.forEach((key,siIdx) => {
            const issues = multi ? this.state.issues[issueKey][key] : this.state.issues[issueKey];
            const table = {idx:siIdx};

            table["rows"] = issues.map((issue, idx) => {
                let whereNestedCount = 0;
                let nodesWhereNested;

                nodesWhereNested = issue.this_nodes_nestings.map((nestingLocation, idx) => {
                    const nesting = <PhenomLink node={nestingLocation} idCtx={phenomId.gen()}/>
                    let nestingLink;

                    if(idx === issue.this_nodes_nestings.length - 1){
                        nestingLink = nesting;
                    } else {
                        nestingLink = <>{nesting} {", "}</>
                    }

                    return nestingLink;
                })

                return(
                  <tr id={phenomId.gen([tableId,`issue-${siIdx}`],"wrapper")}>
                    <td>{idx + 1}</td>
                    <td>
                    {<PhenomLink node={issue} idCtx={phenomId.gen()}/>} {"is Nested in: "} {nodesWhereNested} {"."}
                    </td>
                  </tr>)
            })
            table["title"] = <tr><td colSpan="3">{"The following views have distinct Paths in multiple View Nestings. "
                                                + "To fix this Issue, make sure that each View Nesting has the same Path in each Nesting."}</td></tr>
            tables.push(table)
        })
        return <table
          id={phenomId.gen(["health-issues",tableId],"wrapper")}
          className="invisi-table">
          {tables.map(table => <>
                                {table.title || null}
                                {table.rows}
                              </>)}
          </table>;
      }

    generateComposedBlockIssue = (key, hIdx) => {
      const phenomId = this.phenomId;
      const sub_color = "#8aa5b2";
      const { composed_block_issues } = this.state.issues;

      return <div id={phenomId.gen(["health-issues", hIdx], "wrapper")}>
        {!!composed_block_issues?.length && composed_block_issues.map((instance, j) => {
          const { connections_with_mismatched_datatypes, instances_with_incorrect_datatypes, ports_with_differing_template_types } = instance;
          
          return <div style={{ margin: "10px 0" }}>
              <div><label style={{ marginBottom: 0 }}>{instance.name}</label> (Composed Block Instance)</div>
              {!!connections_with_mismatched_datatypes.length &&
                <ColorCollapsable
                  idCtx={phenomId.gen(["context-issues-mismatched-datatype", j])}
                  contentId={phenomId.gen(["context-issues-mismatched-datatype", j], "color-collapsable-content-wrapper")}
                  heading={`Connections with inconsistent data type`}
                  color={sub_color}
                  vMargin={10}
                  default={true}
                  noToggle={false}
                  content={<ul>{connections_with_mismatched_datatypes.map((portNode) => (
                              <li key={portNode.guid}>{portNode.parent.name} in <PhenomLink node={portNode.context}/> has a different data type than its connected node</li>
                          ))}</ul>}
                />}
              {!!instances_with_incorrect_datatypes.length &&
                <ColorCollapsable
                  idCtx={phenomId.gen(["instances-with-incorrect-datatypes", j])}
                  contentId={phenomId.gen(["instances-with-incorrect-datatypes", j], "color-collapsable-content-wrapper")}
                  heading={`Instances with incorrect data types`}
                  color={sub_color}
                  vMargin={20}
                  default={true}
                  noToggle={false}
                  content={<ul>{instances_with_incorrect_datatypes.map((portNode) => (
                              <li key={portNode.guid}>{portNode.parent.name} in <PhenomLink node={portNode.context}/> has a different data type from its definition</li>
                          ))}</ul>}
                />}
              {!!ports_with_differing_template_types.length &&
                <ColorCollapsable
                  idCtx={phenomId.gen(["ports-with-differing-template-types", j])}
                  contentId={phenomId.gen(["ports-with-differing-template-types", j], "color-collapsable-content-wrapper")}
                  heading={`Ports with inconsistent template types`}
                  color={sub_color}
                  vMargin={20}
                  default={true}
                  noToggle={false}
                  content={<ul>{ports_with_differing_template_types.map((portNode) => (
                              <li key={portNode.guid}>{portNode.parent.name} in <PhenomLink node={portNode.context}/> has a different data type from other templated ports of template type "{portNode.templateName}" </li>
                          ))}</ul>}
                />}
          </div>
        })}
      </div>;
    }

    standardIssueList = (issueKey, tableId, collapsable_heading, multi = false, multiKeys = [issueKey], multi_heading=false) => {
      const issueString = collapsable_heading[1] || collapsable_heading[0];
      const phenomId = this.phenomId;
      const tables = [];

      if (!this.state.issues[issueKey] || this.state.issues[issueKey].length === 0) return;

      multiKeys.forEach((key,siIdx) => {
          const issues = multi ? this.state.issues[issueKey][key] : this.state.issues[issueKey];
          const table = {idx:siIdx};

          table["rows"] = issues.map((issue, idx) => {
              return(
                <tr id={phenomId.gen([tableId,`issue-${siIdx}`],"wrapper")}>
                  <td>{idx + 1}</td>
                  <td>
                  {multi ? multi_heading[key]["text"] : issueString} {this.linkList(issue)}
                  </td>
                </tr>)
          })
          table["title"] = multi && <tr><td colSpan="3">{multi_heading[key]["title"]}</td></tr>
          tables.push(table)
      })
      return <table
        id={phenomId.gen(["health-issues",tableId],"wrapper")}
        className="invisi-table">
        {tables.map(table => <>
                              {table.title || null}
                              {table.rows}
                            </>)}
        </table>;
    }

    handleCheckBox = (issue) => {
        const { selectedIssues } = this.state;

        if (selectedIssues.hasOwnProperty(issue)) {
            this.setState(prevState => ({
                selectedIssues: {
                    ...prevState.selectedIssues,
                    [issue]: !selectedIssues[issue]
                }
            }));
        }
    }

    hexToRGBA = (hex, alpha = 1) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
      
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      }

    generateCollapsable(key, idx) {
      const phenomId = this.phenomId;
      const { selectedIssues, prevSelectedIssues } = this.state;
      let issue = this[key]();
      const searchedIssue = !prevSelectedIssues.length ? true : prevSelectedIssues.includes(key);
      let content = issue.render_function(key, idx, issue.collapsable_heading , issue.multi || false, issue.multi_keys || [key], issue.multi_heading || false);
      let heading = issue.collapsable_heading[0];

        let headerCount = Number.isInteger(issue.headerCount) ? issue.headerCount : issue.numerator;
        let newHeading = searchedIssue ? `${issue.collapsable_heading[0]} (${headerCount})` : `${issue.collapsable_heading[0]}`;
        return <ColorCollapsable
            idCtx={phenomId.gen(["health-issues",idx])}
            heading={this.state.checks ? newHeading : heading}
            content={content}
            color={this.hexToRGBA(issue.color, searchedIssue ? 1 : 0.25)}
            contentidCtx={phenomId.gen(["health-issues",idx],"")}
            contentId={phenomId.gen(["health-issues", idx], "wrapper")}
            vMargin={10}
            default={true}
            noToggle={!issue.numerator}
            hasBtnActions={key === "uniqueid_issues"}
            hasCheckBox={true}
            onCheck={() => this.handleCheckBox(key)}
            checkBoxValue={selectedIssues[key]} />;
    }


    sortByName = (a, b) => {
        let name1 = "", name2 = "";

        if (a.entity && b.entity) {
            name1 = a.entity.name;
            name2 = b.entity.name;
        }

        if (a.capability && b.capability) {
            name1 = a.capability.rolename;
            name2 = b.capability.rolename;
        }

        if (a.rolename && b.rolename) {
            name1 = a.rolename;
            name2 = b.rolename;
        }

        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
    };

    //Helper functions for a 'standard' node list: {guid:name, guid:name}
    countNodes(issueList) {
        return issueList?.reduce((acc, x) => acc + Object.keys(x).length, 0);
    }

    linkList(issueList) {
        const phenomId = this.phenomId;

        phenomId.gen("link-list")
        return Object.keys(issueList).map((key, idx, arr) => { return (<>
                    <PhenomLink
                        node={issueList[key]}
                        idCtx={phenomId.gen(["link-list",`view-guid-${idx}`],"")}
                        newTab/>
                    {idx < arr.length - 1 ? ", " : "."}
               </>);});
    }

    runReport = () => {
        this.setState({ loading: true })
        const { useAndTags, includeTags, excludeTags } = NavTree.getTagFilters();
        const { selectedIssues } = this.state
        let issues = [];

        // gather checked issues to run
        for (let [key, bool] of Object.entries(selectedIssues)) {
            if (bool === true) {
                issues.push(key);
            }
        }

        if (!issues.length) {
            this.setState({ loading: false });
            return;
        }

        // convert Sets to Array
        runHealthReport(useAndTags, [...includeTags], [...excludeTags], issues);
    }

    downloadReport = () => {
        const downloadName = this.state.downloadLoc.replace(/tmp_\d+\//, "").replace(/\.\w+$/, e => `_${new Date().toDateString().replace(/\s/g, "_")}${e}`);
        window.open(`${process.env.NODE_ENV === "development" ? "http://localhost" : ""}/index.php?r=/generate/download-file&file=${this.state.downloadLoc}&newName=${downloadName}`);
    }

    processReport(issues, userRanChecks=false) {
        const { selectedIssues } = this.state;
        const prevSelectedIssues = issues.prevSearched || [];

        if (!Object.keys(issues).length) {
          return
        }

        Object.keys(issues).forEach(key => {
            if (!issues[key] && issues[key] !== 0) issues[key] = [];
        });

        // filter out data due to certain backend tests returning data for multiple issues
        if (!prevSelectedIssues.includes("attr_issues") && Object.keys(issues).includes("attr_issues")) issues.attr_issues = undefined;
        if (!prevSelectedIssues.includes("obs_issues") && Object.keys(issues).includes("obs_issues")) issues.obs_issues = undefined;
        if (!prevSelectedIssues.includes("chars_using_ent_pholder_issues") && Object.keys(issues).includes("chars_using_ent_pholder_issues")) issues.chars_using_ent_pholder_issues = undefined;
        if (!prevSelectedIssues.includes("chars_using_obs_pholder_issues") && Object.keys(issues).includes("chars_using_obs_pholder_issues")) issues.chars_using_obs_pholder_issues = undefined;
        if (!prevSelectedIssues.includes("ents_typing_pholder_issues") && Object.keys(issues).includes("ents_typing_pholder_issues")) issues.ents_typing_pholder_issues = undefined;
        if (!prevSelectedIssues.includes("chars_using_pholder_meas_issues") && Object.keys(issues).includes("chars_using_pholder_meas_issues")) issues.chars_using_pholder_meas_issues = undefined;
        
        if (userRanChecks && prevSelectedIssues?.length) {
            _ajax({
                url: "/index.php?r=/site/health-report",
                method: "post",
                data: {health_checks: [...prevSelectedIssues]}
            });
        }

        this.initChecks(prevSelectedIssues)

        this.setState({
            issues: issues,
            checks: Object.keys(issues).length,
            loading: false,
            runtime: issues.totalTime,
            downloadLoc: issues.download_loc,
            reportCompleted: true,
            prevSelectedIssues: prevSelectedIssues,
        }, () => {
            prevSelectedIssues.forEach((issue, idx) => {
                this.drawCircle(this[issue](), idx);
            })
        });
    }

    renderCircles() {
        const { prevSelectedIssues } = this.state;
        const phenomId = this.phenomId;
        let circleGroups = [];
        let issuesLength = prevSelectedIssues.length;
        for (var i = 0; i < issuesLength + 1; i += 2) {
            let newRow = <div className="flex-h" style={{justifyContent: "space-around"}}>
                <canvas id={phenomId.gen(["health-issues",`canvas-${i}`])} width={250} height={250} />
                <canvas id={phenomId.gen(["health-issues",`canvas-${i + 1}`])}  width={250} height={250} />
            </div>
            circleGroups.push(newRow)
        }
        return circleGroups
    }

    render() {
        const phenomId = this.phenomId;
        const { loading, selectedIssues, prevSelectedIssues } = this.state;
        const cinc_works = this.props.userRole.indexOf('c') !== -1;

        return (
            <div className="phenom-content-wrapper" style={{backgroundColor: "#e8e9ea"}}>
                <span className='banner-span' style={{display: "none"}} />
                    <nav className="sub-menu-actions" aria-label='form actions'>
                    <SubMenuLeft>
                        {this.state.downloadLoc !== "" &&
                        <button id={phenomId.gen("init","download-report-button")}
                                onClick={this.downloadReport}>DOWNLOAD REPORT</button>}
                      </SubMenuLeft>
                    </nav>
                    {/* {this.state.nodesFromTags.length > 0 &&
                        <div style={{padding: "0 6px", color: "white", background: "rgb(211, 75, 25)", border: "1px solid #ccc"}}>
                            <span style={{marginRight: 8}}><strong style={{fontSize: 20}}>{this.state.includeTags.length ? this.state.includeTags.split(",").length :
                                this.state.excludeTags.length ? this.state.excludeTags.split(",").length : 0}</strong> tag(s) selected</span>
                            <span><strong style={{fontSize: 20}}>{this.state.nodesFromTags.length}</strong> node(s) {this.state.includeTags.length ? "included" : "excluded"}</span>
                        </div>
                    } */}
                <div className="phenom-content-scrollable">
                  <div className='flex-h' style={{width: "100%"}} id={phenomId.gen("health-issues","wrapper")}>
                      <div className='flex-v' style={{width: "20%", backgroundColor: "#748d99", paddingTop: 50}}>
                          {this.statBox(prevSelectedIssues.length, "Checks Executed")}
                          {this.statBox(this.reportNames.reduce((a,b) => this[b]().numerator ? a + this[b]().numerator : a, 0), "Issues Identified", "#52d5e2")}
                          {this.statBox(this.state.runtime, "Healthcheck Completed", undefined, "s")}
                      </div>
                      <div style={{width: "40%", backgroundColor: "#fff", paddingTop: 10, position: "relative"}}>
                          <img style={{display: loading ? "block" : "none"}} className="ring-o-loading"
                              src={loadingIcon} />
                          {!loading &&
                                this.renderCircles()}
                      </div>
                      <div style={{width: "40%"}}>
                          <div className="p-row" style={{gap: 0}}>
                            <div className="p-col p-col-6" style={{alignItems: "left", justifyContent: "center"}}>
                                <div className="p-row" style={{gap: 0}}>
                                <input
                                    style={{display: "flex", margin: "0 10px 0 15px"}}
                                    type="checkbox"
                                    onChange={(e) => this.setState(prevState => ({
                                        selectedIssues: Object.keys(prevState.selectedIssues).reduce((obj, key) => ({
                                            ...obj,
                                            [key]: e.target.checked
                                        }), {})
                                    }))}
                                    defaultChecked={true}
                                />
                                (Un)select all
                                </div>
                            </div>

                            <div className="p-col p-col-6">
                                <div className="p-row" style={{gap: 0}}>
                                    <button className="health-check-btn" 
                                            style={{width: "100%", padding: 9}}
                                            onClick={(() => this.runReport())}
                                            disabled={Object.values(selectedIssues).filter(value => value === true).length ? false : true}>
                                        <span className="fa fa-play" style={{paddingRight: "10px"}}/>
                                        RUN HEALTHCHECKS
                                    </button>
                                </div>
                            </div>
                          </div>
                          {this.reportNames.map((key, idx) => {
                              return this.generateCollapsable(key, idx)
                          })}
                      </div>
                  </div>
                </div>
            </div>
        );
    }
}


const msp = (state) => ({
  expired: state.user.expired,
  userRole: state.user.userRole,
  health_report: state.app.health_report,
  user: state.user,
})

const mdp = (dispatch) => ({
  runReport: () => dispatch(runHealthReport())
})

export default connect(msp, null)(HealthCheck);
