import React, { Component } from "react";
import Draggable from "react-draggable";
import { Button } from "@progress/kendo-react-buttons";
import $ from "jquery";

import { getDiagramFiles, getNodesOfType } from "../../../../requests/sml-requests";
import ModelPicker from "../../../edit/widgets/ModelPicker";
import { BasicAlert } from "../../../dialog/BasicAlert";
import blankDiagram from "../../../../images/blank_diagram.png";
import loadingIcon from "../../../../images/Palette Ring-1s-200px.gif";
import { Modal2 } from "../../../util/Modal";
import { cloneDeep } from "lodash";
import PhenomId from "../../../../requests/phenom-id";

import LoadPrompt from "./prompt_components/LoadPrompt";
import SavePrompt from "./prompt_components/SavePrompt";
import UnsavedPrompt from "./prompt_components/UnsavedPrompt";
import { isPhenomGuid } from "../../../util/util";



export class StormPrompt extends Component {
  constructor(props) {
    super(props);

    this.phenomId = new PhenomId("storm-prompt");
    this.dialogs = [
      {
        title: "Load Diagram",
        // preventClose: true,
        renderFunc: this.renderLoadSave,
      },
      {
        title: "Save Diagram",
        renderFunc: this.renderLoadSave,
      },
      {
        title: "Select Context",
        renderFunc: this.renderLoadContext,
      }
    ]
  }

  // ------------------------------------------------------------
  // State
  // ------------------------------------------------------------
  defaultState = {
    visible: false,
    loading: false,
    dialog: null,
    tabIdx: null,
    redirectToUrl: "",

    // unsaved prompt
    selectedTab: {},

    // Save & Load Diagram
    files: [],
    selectedFile: {},
    
    // Share Diagram
    shareIds: new Set(),
    reachableBranches: [],
    selectedShareBranch: {},

    // Context Nodes
    selectedContext: {},
    contextList: [],

    selectedGuids: new Set(),
    missingGuids: new Set(),
  }
  state = cloneDeep(this.defaultState)
  
  // ------------------------------------------------------------
  // Life Cycle Methods
  // ------------------------------------------------------------
  componentDidMount() {
    window.addEventListener('DIAGRAM_SAVE', this.saveImeditorListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dialog !== this.state.dialog) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('DIAGRAM_SAVE', this.saveImeditorListener);
  }


  // ------------------------------------------------------------
  // API methods
  // ------------------------------------------------------------
  fetchData = () => {
    if (this.props.stormType === "idm_editor") {
      this.fetchContextNodes();
    } else {
      this.fetchDiagramFiles();
      this.fetchShareDestinations();
    }
  }

  fetchDiagramFiles = () => {
    switch (this.props.stormType) {
      case "view_trace":
        var category = "t";
        break;
      case "scratchpad":
        var category = "s";
        break;
    }

    getDiagramFiles(category).then((res) => {
      let files = JSON.parse(res)
                      .map(ele => ({
                        ...ele,
                        fileId: ele.Diagram_ID,
                        fileName: ele.Name,
                      }))
                      .sort((a, b) => {
                        let textA = a.fileName.toUpperCase();
                        let textB = b.fileName.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                      });
      let fileData = files.find(f => f.fileId === this.state.selectedFile?.fileId);
      this.setState({
        files,
        selectedFile: fileData || {},
      })
    })
  }

  fetchShareDestinations = () => {
    $.ajax({
        url: "/index.php?r=/referencing-model/diagram-share-destinations",
        data: {withSubModels: false},
    }).then(res => {
        const reachableBranches = JSON.parse(res);
        this.setState({ reachableBranches });
    });
  }

  fetchContextNodes = () => {
    getNodesOfType("im:IntegrationContext").then(res => {
      this.setState({ contextList: JSON.parse(res).nodes });
    })
  }

  
  // ------------------------------------------------------------
  // Actions
  // ------------------------------------------------------------


  /**
   * Diagram_ID and fileId are the same.  
   *    > When fetching the list of diagrams, it has Diagram_ID
   *    > When saving a diagram data, it uses fileId
   * 
   * This is a mix of the old/new methods.  
   * 
   * @param {*} dialogType 
   * @param {*} redirectToUrl 
   */
  show = (dialogType, redirectToUrl) => {
    const { diagrams, activeIdx } = this.props;
    const tab = diagrams[activeIdx];
    
    this.setState({
      visible: true,
      dialog: dialogType,
      redirectToUrl,
      selectedFile: tab,
      selectedTab: tab,
    })

  }

  close = () => {
    this.setState({ visible: false }, () => {
      setTimeout(() => this.setState({ ...cloneDeep(this.defaultState) }), 300)
    });
  }

  loadDiagram = async () => {
    try {
      const { selectedFile } = this.state;
      if (!selectedFile.fileId) throw "invalid diagram id. failed to load diagram.";
  
      await this.setState({ loading: true });
      await this.props.$manager.loadDiagram(selectedFile.fileId, selectedFile.fileName);
      this.close();
      
    } catch (err) {
      console.error(err);
    }
  }

  // Used by view trace
  saveDiagram = async () => {
    try {
      const { redirectToUrl, selectedFile } = this.state;

      await this.setState({ loading: true });
      await this.props.$manager.saveDiagram(selectedFile.fileId, selectedFile.fileName);
      await this.setState({ loading: false });

      // if this url is present then it means the unsaved prompt is active (aka the user was trying to navigate away)
      if (!redirectToUrl) {
        this.close();
      } else {
        this.fetchData();
      }

    } catch (err) {
      console.error(err);
    }
  }

  deleteDiagram = async () => {
    const { selectedFile } = this.state;

    await this.props.$manager.deleteDiagram(selectedFile.fileId);

    if (!selectedFile.fileId || isPhenomGuid(selectedFile.fileId)) {
      return;
    }

    await this.setState({ 
      loading: true, 
      selectedFile: {},
    });
    
    await $.ajax({
      method: "post",
      url: "/index.php?r=/diagram/_diagram-delete",
      data: {
          index: selectedFile.fileId
      },
    })

    await this.fetchData();
    this.setState({ loading: false });
  }

  shareDiagrams = async () => {
    const { selectedFile, selectedShareBranch } = this.state;

    if (!selectedFile.fileId) {
      return;
    }

    $.ajax({
      url: "/index.php?r=/diagram/share-diagram",
      method: "post",
      data: {
          dstBranch: selectedShareBranch.id,
          diagramId: [selectedFile.fileId],
      }
    }).then((res) => {
      const response = JSON.parse(res);

      // success response returns as true
      if (response) {
        BasicAlert.show("Diagram successfully shared.", "SUCCESS", true);
      } else if (response?.errors) {
        BasicAlert.show(response.errors, "UNSUCCESSFUL", true);
      } else {
        BasicAlert.show("Something went wrong. Sharing was unsuccessful.", "UNSUCCESSFUL", true);
      }
    });
  }

  loadContext = () => {
    const { selectedContext } = this.state;
    if (!selectedContext.guid) return;

    this.props.$manager.loadImContext(selectedContext.guid);
    this.close();
  }

  deleteContext = () => {
    const { selectedContext } = this.state;
    if (!selectedContext.guid) return;
    this.props.$manager.deleteContext(selectedContext, () => {
      this.fetchContextNodes();
    });
  }

  handleContinueClick = async () => {
    if (!this.props.routerHistory || !this.state.redirectToUrl) return;
    await this.props.onClickContinue();
    this.close();
    this.props.routerHistory.push(this.state.redirectToUrl);
  }

  saveImeditorListener = () => {
    const { diagrams } = this.props;
  
    if (!this.state.visible) return;
    let unsavedList = diagrams.filter(item => item.ref?.getDiagramSaveStatus && !item.ref.getDiagramSaveStatus())

    if (unsavedList.length === 0){
      this.handleContinueClick();
    }
    else{
      this.setState({selectedTab: unsavedList[0]});
    }
  }
  

  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------
  selectFile = (file) => {
    // const shareIds = new Set(this.state.shareIds);
    // shareIds.has(file.fileId) ? shareIds.delete(file.fileId) : shareIds.add(file.fileId);
    this.setState({
      selectedFile: file,
      // shareIds,
    })
  }

  setShareDestination = (event) => {
    this.setState({ selectedShareBranch: event.target.value });
  }



  // ------------------------------------------------------------
  // Render Methods
  // ------------------------------------------------------------
  renderLoad = () => {
    const { loading, selectedFile, files, reachableBranches, shareIds, selectedShareBranch } = this.state;
    const { editable, stormType } = this.props;
    const isDisabled = !editable || loading;

    return <LoadPrompt selectedFile={selectedFile}
                      data={files}
                      dataGuidKey="fileId"
                      dataNameKey="fileName"
                      shareIds={shareIds}
                      stormType={stormType}
                      reachableBranches={reachableBranches}
                      selectedBranch={selectedShareBranch}
                      disabled={isDisabled}
                      phenomId={this.phenomId}
                      onSelect={this.selectFile}
                      onLoad={this.loadDiagram}
                      onDelete={this.deleteDiagram}
                      onShare={this.shareDiagrams}
                      onShareChange={this.setShareDestination} />
  }

  renderSave = () => {
    const { loading, selectedFile, files, redirectToUrl } = this.state;
    const { editable, stormType } = this.props;
    const isDisabled = !editable || loading;

    return <SavePrompt selectedFile={selectedFile}
                       data={files}
                       dataGuidKey="fileId"
                       dataNameKey="fileName"
                       disabled={isDisabled}
                       stormType={stormType}
                       phenomId={this.phenomId}
                       redirectToUrl={redirectToUrl}
                       onBackSelectDiagram={() => this.setState({ dialog: "unsaved_prompt" })}
                       onSelect={this.selectFile}
                       onSave={this.saveDiagram}
                        />
  }

  renderLoadContext = () => {
    const { loading, contextList, selectedContext } = this.state;
    const { editable, stormType } = this.props;
    const isDisabled = !editable || loading;

    return <LoadPrompt selectedFile={selectedContext}
                       data={contextList}
                       dataGuidKey="guid"
                       dataNameKey="name"
                       disabled={isDisabled}
                       stormType={stormType}
                       phenomId={this.phenomId}
                       onSelect={(c) => this.setState({ selectedContext: c })}
                       onLoad={this.loadContext}
                       onDelete={this.deleteContext} />
  }


  renderUnsavedPrompt = () => {
    const { loading, selectedTab } = this.state;
    const { stormType } = this.props;
    const { editable, diagrams } = this.props;
    const isDisabled = !editable || loading;

    return <UnsavedPrompt selectedTab={selectedTab}
                          data={diagrams}
                          dataGuidKey="fileId"
                          dataNameKey="fileName"
                          disabled={isDisabled}
                          stormType={stormType}
                          phenomId={this.phenomId}
                          onSelect={(file) => this.setState({ selectedTab: file, selectedFile: file})}
                          onSaveDiagram={async () => {
                            switch(stormType) {
                              case "idm_editor":
                                await selectedTab.ref?.saveDiagram();
                                this.forceUpdate();
                                break;

                              default:
                                this.setState({ dialog: "save" })
                            }
                          }}
                          onCommitDiagram={async () => {
                            switch(stormType) {
                              case "idm_editor":
                                await selectedTab.ref?.saveContext();
                                this.forceUpdate();
                                break;

                              default:
                                this.setState({ dialog: "commit" })
                            }
                          }}
                          onContinue={this.handleContinueClick}
                          onCancel={this.close} />
  }


  render () {
    const { dialog, visible } = this.state;

    switch (dialog) {
      case "save":
        var title = "Save Diagram";
        var renderFunc = this.renderSave;
        break;

      case "load":
        var title = "Load Diagram";
        var renderFunc = this.renderLoad;
        break;
      
      case "load_context":
        var title = "Load Context";
        var renderFunc = this.renderLoadContext;
        break;

      case "unsaved_prompt":
        var title = "Unsaved Changes";
        var renderFunc = this.renderUnsavedPrompt;
        break;
      
      default:
        return null;
    }

    return <Modal2 show={visible}>
            <div className="storm-prompt-container">
              <div className="storm-prompt-header">
                <h2>{title}</h2>
                {!dialog.preventClose &&
                  <Button 
                          id={this.phenomId.genPageId("close-btn")}
                          icon="close"
                          look="bare"
                          onClick={this.close} /> }
              </div>

              {renderFunc()}
            </div>
          </Modal2>
  }
}


