import {CadetInput} from "../util/stateless";
import {DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";
import { Checkbox, RadioButton } from '@progress/kendo-react-inputs';
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {getMoveImpact, smmSaveNodes, setEquality, modelRemoveNode, modelDeprecateNode} from "../../requests/sml-requests";
import {CadetLink} from "../util/stateless";
import {deGuidify, sortNodesByName} from "../util/util";
import {BasicAlert} from "./BasicAlert";
import ChangeSetPicker from "../widget/ChangeSetPicker";
import $ from "jquery";
import styled from "@emotion/styled";
import { getActiveChangeSetId } from "../../requests/actionCreators";
import NavTree from "../tree/NavTree";

class AttrMove extends Component {
    state = {
        node: {},
        newDstRoleName: "",
        newSrcRoleName: "",
        validMoves: {},
        destinationGuid: "",
        // impacts: [],
        existingSrcNames: [],
        existingDestNames: [],
        typeCollisions: {},
        destinationAttr: {},
        nameCollisionFound: false,
        canDelete: true,
        loadingMoves: true,
        loadingImpacts: true,
        visible: false,
        dstComposedInSrc: false,
        createNewSrcAttr: false,
        dstNameCollision: false,
        srcNameCollision: false,
        dstPublished: false,
    };

    text = {
        title: "Destination for Attribute Move",
    }

    constructor(props) {
        super(props);
        AttrMove.__singleton = this;
    }

    static async show(node, safeMode) {
        if (!AttrMove.__singleton) new AttrMove();

        const res = await Promise.all([
            AttrMove.__singleton.getNode(node.guid),
            AttrMove.__singleton.fetchValidMoves()
        ])

        const currentNode = JSON.parse(res[0]);
        const parentGuid = currentNode.parent.guid;
        const validMoves = JSON.parse(res[1]).nodes.filter(n => n.guid !== parentGuid && n.guid !== currentNode.type && n.deprecated === "false");

        AttrMove.__singleton.setState({
            node: JSON.parse(res[0]),
            validMoves: deGuidify(validMoves),
            safeMode,
            loadingMoves: false
        });

        AttrMove.__singleton.__show();
    }

    static hide() {
        AttrMove.__singleton.__hide();
    }

    __show() {
        // AttrMove.__singleton.fetchValidMoves();
        AttrMove.__singleton.setState({visible: true});
    }

    __hide() {
        AttrMove.__singleton.setState({
            node: {},
            newDstRoleName: "",
            newSrcRoleName: "",
            validMoves: {},
            destinationGuid: "",
            // impacts: [],
            existingSrcNames: [],
            existingDestNames: [],
            typeCollisions: {},
            destinationAttr: {},
            nameCollisionFound: false,
            canDelete: true,
            loadingMoves: true,
            loadingImpacts: true,
            visible: false,
            dstComposedInSrc: false,
            createNewSrcAttr: false,
            dstNameCollision: false,
            srcNameCollision: false,
            dstPublished: false,
        });
    }

    getNode = (guid) => {
        return $.ajax({
            url: "/index.php?r=/node/model-get-node",
            method: "get",
            data: { 
                guid, 
                coreAddenda: ["parent"],
                parentAddenda: ["childrenMULTI"] 
            }
        })
    }

    fetchValidMoves = () => {
        return $.ajax({
            url:"/index.php?r=/node/model-nodes-of-type",
            method:"get",
            data: {
                type: ["conceptual:Entity", "conceptual:Association"],
                coreAddenda: ["childrenMULTI"]
            }
        })
    }

    fetchImpact = () => {
        this.setState({loadingImpacts: true}, () => {
            getMoveImpact({
                moved_node: this.state.node.guid, 
                new_parent: this.state.destinationGuid
            }).then((res) => {
                const response = JSON.parse(res);

                this.setState({
                    // impacts: response.data,
                    dstPublished: response.published,
                    loadingImpacts: false,
                    canDelete: response.canDelete,
                    nameCollisionFound: response.nameCollisionFound,
                    attrCollision: response.attrCollision,
                    typeCollisions: deGuidify(response.typeCollisions),
                    dstComposedInSrc: response.dstComposedInSrc,
                    // existingDestNames: response.names.split(","),
                });
            });
        });
    };

    handleDestinationChange = (destGuid) => {
        if (destGuid === "") {
            this.setState({destinationGuid:"", existingDestNames:[], newDstRoleName:""});
        } else {
            const destParent = this.state.validMoves[destGuid];
            const existingDestNames = destParent.children.map(child => child.rolename);
            const srcParent = this.state.node.parent;
            const existingSrcNames = srcParent.children.map(child => child.rolename);

            this.setState({destinationGuid: destGuid, existingDestNames, existingSrcNames}, this.fetchImpact)
        }
    }


    confirmAction = async () => {
        const node = this.state.node;
        const destinationNode = this.state.validMoves[this.state.destinationGuid]
        let errors = [];
        

        if(this.validate()) {
            let removeMe = null;
            let equalityDst = null;

            if(this.state.canDelete) {
                removeMe = () => modelRemoveNode(node.guid, getActiveChangeSetId());
            } else {
                removeMe = () => modelDeprecateNode(node.guid, getActiveChangeSetId());
            }

            // Save Destination Attr
            if(this.state.destinationAttr.guid === undefined) {
                let resCreate = await smmSaveNodes({
                    guid: null,
                    rolename: this.state.newDstRoleName ? this.state.newDstRoleName: node.rolename,
                    xmiType: "conceptual:Composition",
                    type: node.type,
                    lowerBound: node.lowerBound,
                    upperBound: node.upperBound,
                    sourceLowerBound: node.sourceLowerBound,
                    sourceUpperBound: node.sourceUpperBound,
                    parent: this.state.destinationGuid,
                    subModelId: node.subModelId,
                    changeSetId: getActiveChangeSetId()
                })

                if (resCreate === undefined || resCreate === null) {
                    errors = errors.concat("Move action failed. Something went wrong.");
                } else {
                    const response = JSON.parse(resCreate);
                    if (response.errors) {
                        errors = errors.concat(response.errors);
                    } else if (response.guid) {
                        equalityDst = response.guid;
                    } else {
                        errors.push("Something went wrong. The Move action was unsuccessful.")
                    }
                }
            }

            // Compose Destination in Src
            if(this.state.createNewSrcAttr) {
                let resCreate = await smmSaveNodes({
                    guid: null,
                    rolename: this.state.newSrcRoleName ? this.state.newSrcRoleName: destinationNode.name.toLowerCase(),
                    xmiType: "conceptual:Composition",
                    type: this.state.destinationGuid,
                    parent: node.parent.guid,
                    subModelId: node.subModelId,
                    changeSetId: getActiveChangeSetId()
                })

                if (resCreate === undefined || resCreate === null) {
                    errors = errors.concat(`Composing ${destinationNode.name} in ${node.parent.name} has failed. Something went wrong.`);
                } else {
                    const response = JSON.parse(resCreate);
                    if (response.errors) {
                        errors = errors.concat(response.errors);
                    }
                }
            }

            // Check for errors from Save
            if (errors.length) {
                this.__hide();
                return BasicAlert.show(<ul>{errors.map(err => <li>{err}</li>)}</ul>, "Process Incomplete.");
            }

            // Set Equality
            if (!equalityDst) equalityDst = this.state.destinationAttr.guid;
            if (this.state.node.guid && equalityDst) await setEquality(this.state.node.guid, equalityDst);

            // Remove old node
            let resRemove = await removeMe();
            if (resRemove === "#false") {
                this.__hide();
                return BasicAlert.show("Something went wrong. Please contact Skayl customer support.", "Process Incomplete.");
            }

            // Action complete
            NavTree.reset();
            await this.props.history.push(`/edit/details/entity/${this.state.destinationGuid}/`);
            this.__hide();
        }
    }

    validate = () => {
        const dstNameCollision = this.validateDstName();
        const srcNameCollision = this.validateSrcName();

        const dst = this.state.destinationAttr.guid !== undefined || !dstNameCollision;
        const src = !this.state.createNewSrcAttr || !srcNameCollision;
        return dst && src;
    }

    validateDstName = () => {
        const dstName = this.state.newDstRoleName.trim().length ? this.state.newDstRoleName : this.state.node.rolename;
        const dstNameIncluded = this.state.destinationAttr.guid === undefined && this.state.existingDestNames.includes(dstName);
        this.setState({dstNameCollision: dstNameIncluded})
        return dstNameIncluded;
    }

    validateSrcName = () => {
        const srcName = this.state.newSrcRoleName.trim().length ? this.state.newSrcRoleName : this.state.validMoves[this.state.destinationGuid].name.toLowerCase();
        const srcNameIncluded = this.state.createNewSrcAttr && this.state.existingSrcNames.includes(srcName);
        this.setState({srcNameCollision: srcNameIncluded});
        return srcNameIncluded;
    }
    

    renderImpact = () => {
        const node = this.state.node;
        const parent = node.parent;
        const destination = this.state.validMoves[this.state.destinationGuid];

        if (!this.state.destinationGuid) {
            return false;
        } else if (this.state.loadingImpacts) {
            return <div>processing...</div>;
        } else if (this.state.dstPublished) {
            return <div>
                <div>The Entity you have chosen is a part of a <strong>published model.</strong></div>
                <div><strong>Please choose a different move destination.</strong></div>
            </div>
        } else {
            const dstName = this.state.newDstRoleName.trim().length ? this.state.newDstRoleName : this.state.node.rolename;
            const srcName = this.state.newSrcRoleName.trim().length ? this.state.newSrcRoleName : this.state.validMoves[this.state.destinationGuid].name.toLowerCase();
            const destinationAttr = this.state.destinationAttr;
            const typeCollisions = this.state.typeCollisions;
            const conflicts = [];

            if (Object.keys(typeCollisions).length) {
                conflicts.push(<>
                    <span><CadetLink node={destination} look="normal" newPage={true} /> already contains an attribute with type of <strong>{Object.values(typeCollisions)[0].typeName}</strong></span>
                </>);
            } 
            if(this.state.nameCollisionFound) {
                conflicts.push(<>
                    <span><CadetLink node={destination} look="normal" newPage={true}/> already contains an attribute with the name <strong>{node.rolename}</strong></span>
                </>)
            }
            if(!this.state.dstComposedInSrc) {
                conflicts.push(<>
                    <span><CadetLink node={destination} look="normal" newPage={true}/> is not composed in <strong>{parent.name}</strong></span>
                </>)
            }

            return (
                <div style={{fontSize:"90%"}}>
                    <div style={{marginBottom:10}}>
                        <span>You are proposing to move <strong>{node.rolename}</strong> from </span>
                        <span><CadetLink node={node.parent} look="normal" newPage={true}/> to </span>
                        <span><CadetLink node={destination} look="normal" newPage={true}/></span>
                    </div>

                    {!this.state.canDelete &&
                    <div style={{marginBottom:10, fontStyle:"italic"}}>
                        <span><strong>{parent.name}.{node.rolename}</strong> will be deprecated because it is referenced by other nodes. Run a health check to resolve these dependencies.</span></div>}
                    
                    {conflicts.length > 0 && 
                    <div style={{marginBottom: 10, padding:7, background:"#e3e3e3", border:"2px solid #ccc", maxHeight:500, overflowY:"auto"}}>
                        <label style={{color:"#ec8924", textShadow:"1px 1px 2px rgba(0,0,0,0.25)"}}>
                            CONFLICT:</label>
                        
                        <ul style={{paddingLeft:20, marginBottom:25}}>{conflicts.map(msg => <li>{msg}</li>)}</ul>

                        {Object.keys(this.state.typeCollisions).length > 0 &&
                        <Conflict>
                            <ConflictHeader>
                                Declare an existing attribute as the new identity of <strong>{parent.name}.{node.rolename}</strong>
                            </ConflictHeader>
                            {destinationAttr.guid === undefined &&
                                <div style={{padding:7, fontSize:13, fontStyle:"italic", textAlign:"center"}}>Selecting <strong>None</strong> will create a new node in <strong>{destination.name}</strong>. <br/>Generally this is not an advisable modeling practice.</div>}

                            <ul style={{padding:10, margin:0, listStyle:"none"}}>
                                <li style={{marginBottom:5}}>
                                <RadioButton value="" 
                                             label="None. I understand the statement above." 
                                             checked={destinationAttr.guid === undefined}
                                             onChange={(e) => this.setState({destinationAttr: {}})} /></li>
                                {Object.values(this.state.typeCollisions).map(attr => {
                                    return (<li style={{marginBottom:5}}>
                                            <RadioButton value={attr.guid} 
                                                         label={destination.name + "." + attr.rolename} 
                                                         checked={destinationAttr.guid === attr.guid}
                                                         onChange={(e) => this.setState({destinationAttr: this.state.typeCollisions[e.value]})} /></li>)})}
                            </ul>
                        </Conflict>}

                        {destinationAttr.guid === undefined && this.state.nameCollisionFound &&
                        <Conflict>
                            <ConflictHeader>
                                Change name of <strong>{node.rolename}</strong> to avoid name collision
                            </ConflictHeader>
                            <div style={{padding:10}}>
                                <div style={{marginBottom:5}}>Move <strong>{parent.name}.{node.rolename}</strong> into <strong>{destination.name}</strong> with the rolename:</div>
                                <CadetInput onChange={e => this.setState({newDstRoleName: e.target.value}, this.validateDstName)}
                                            text={this.state.newDstRoleName}
                                            style={{ width: 200,
                                                     height: 20,
                                                     margin:"5px 0 0",
                                                     backgroundColor: (this.state.dstNameCollision ? "#fbdfe4" : "#fff"),}}
                                            placeholder={`new rolename for ${node.rolename}`} />
                                {this.state.dstNameCollision &&
                                    <div style={{fontSize:"90%", fontStyle:"italic", color:"red", marginTop:10, textAlign:"center"}}>The name <strong>{dstName}</strong> already exists. Please choose a different name.</div>}
                            </div>
                        </Conflict>}

                        {!this.state.dstComposedInSrc &&
                        <Conflict>
                            <ConflictHeader>
                                Compose a new Composition in <strong>{parent.name}</strong> with type of <strong>{destination.name}</strong>
                            </ConflictHeader>
                            <div style={{padding:10}}>
                                <span style={{marginRight:5}}>
                                    Compose <strong>{destination.name}</strong> in <strong>{parent.name}</strong>?
                                </span>
                                <Checkbox label=""
                                            checked={this.state.createNewSrcAttr}
                                            onChange={() => this.setState({createNewSrcAttr: !this.state.createNewSrcAttr})} />

                                {this.state.createNewSrcAttr &&
                                <div style={{marginTop:5}}>
                                    <span>Use rolename: </span>
                                    <CadetInput onChange={e => this.setState({newSrcRoleName: e.target.value}, this.validateSrcName)}
                                                text={this.state.newSrcRoleName}
                                                style={{ width: 200,
                                                         height: 20,
                                                         margin:"5px 0 0 5px",
                                                         backgroundColor: (this.state.srcNameCollision ? "#fbdfe4" : "#fff"),}}
                                                placeholder={`default: ${destination.name.toLowerCase()}`} />
                                    {this.state.srcNameCollision &&
                                        <div style={{fontSize:"90%", fontStyle:"italic", color:"red", marginTop:10, textAlign:"center"}}>The name <strong>{srcName}</strong> already exists. Please choose a different name.</div>}
                                </div>}
                            </div>
                        </Conflict>}
                    </div>}

                    
                </div>
            );
        }
    };

    render() {
        const node = this.state.node;
        const parent = node.parent;

        return (<div>
            {this.state.visible && <div className="react-modal" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div style={{zIndex: 100, paddingBottom:0, background:"white", minWidth:500}}>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", padding:"5px 10px", background:"#ec8924", color:"white"}}>
                        {this.text.title}
                        <div style={{display:"flex", alignItems:"center"}}>
                            <ChangeSetPicker />
                            <Button icon="close" look="bare" onClick={this.__hide} />
                        </div>
                    </div>

                    {node.deprecated === "true" ?
                    <div style={{fontSize:"90%", padding:15}}>
                        <div><strong>{parent.name}.{node.rolename}</strong> is deprecated.</div>
                        <strong>Please choose a different attribute to move.</strong></div>
                    :
                    <div style={{padding:15}}>
                        <div>Where would you like to move the attribute <strong>{node.rolename}</strong>?</div>
                        <select className="cadet-select" style={{marginBottom:10}}
                                onChange={(e) => this.handleDestinationChange(e.target.value)}>
                            <option value=''>{this.state.loadingMoves ? "loading..." : Object.keys(this.state.validMoves).length ? "Select an Entity" : "No Possible Moves"}</option>
                            {Object.values(this.state.validMoves).sort((a,b) => sortNodesByName(a,b)).map((node) => {
                                return (<option value={node.guid}>{node.name}</option>);
                            })}
                        </select>
                            
                        {this.renderImpact()}
                    </div>}
    
                    <DialogActionsBar>
                        {this.state.destinationGuid && !this.state.dstPublished &&
                        <button className="k-button k-primary" 
                                disabled={this.state.disabled}
                                onClick={() => {
                                    this.confirmAction();
                                }}>Confirm</button>}
                        <button className="k-button k-primary" onClick={() => {
                            this.__hide();
                        }}>Cancel</button>
                    </DialogActionsBar>
                </div>
            </div>}
        </div>)
    }
}


export default withRouter(AttrMove);





const Conflict = styled.div`
    margin: 0 -7px;
`

const ConflictHeader = styled.div`
    background: #fbd6b1;
    padding: 5px;
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
`