import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import { FormatMenuHeaderCell } from "./stateless";
import React from "react";
import {orderBy} from "@progress/kendo-data-query";
import $ from 'jquery';
import PhenomId from "../../requests/phenom-id";


export class UnitTransformGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            nodes: [],
            sort: []
        };
    }

    componentDidMount() {
        if (this.props.nodes.length) {
            this.setState({ nodes: this.props.nodes });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.nodes && this.props.nodes !== prevProps.nodes) {
            this.setState({ nodes: this.props.nodes });
        }
    }

    loadTransformEquations() {
        this.setState({loading: true});
        $.ajax({
            url:"/index.php?r=/unit-transform/model-nodes-of-type",
            method: "get",
            data: {
                coreAddenda: ["dst"],
                Filter: {
                    includes: {src: this.props.guid}
                }
            }
        }).then(res => {
            const {nodes} = JSON.parse(res);

            this.setState({
                        transformEquations: nodes,
                        loading: false
                    })
                });
    }

    render() {
        const phenomId = new PhenomId("unit-transform-grid",this.props.idCtx);
        return (
            <Grid
                id={phenomId.gen("","wrapper")}
                data={orderBy(this.state.nodes, this.state.sort)}
                sortable
                sort={this.state.sort}
                onSortChange={(e) => {this.setState({sort: e.sort})}}
                className="editorTable"
                style={this.props.style}
                resizable>
                <GridNoRecords>
                    No data is available for this table.
                </GridNoRecords>
                <Column title={this.props.firstCol === "dst" ? "DESTINATION" : "SOURCE"} field={this.props.firstCol === "dst" ? "dst" : "src"}
                        headerCell={(props) => {
                            return <FormatMenuHeaderCell text={props.title} idCtx={phenomId.gen("","dst-or-src")}/>
                        }}/>



                <Column title="NAME" field="name"
                        headerCell={(props) => {
                            return <FormatMenuHeaderCell text={props.title} idCtx={phenomId.gen("","name")}/>
                        }}
                        cell={(props) => {
                            const {name} = props.dataItem;
                            return (<td id={phenomId.gen("","name")}>
                                {name}
                            </td>)
                        }}/>
                <Column title="EQUATION" field="equation"
                        headerCell={(props) => {
                            return <FormatMenuHeaderCell text={props.title} idCtx={phenomId.gen("","equation")}/>
                        }}
                        cell={(props) => {
                            const {equation} = props.dataItem;
                            return (<td id={phenomId.gen("","equation")}>
                                {equation}
                            </td>)
                        }}/>
            </Grid>
        );

    }
}
