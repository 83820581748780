import React from 'react';
import styled from "@emotion/styled";
import PhenomId from '../../../../requests/phenom-id';
import deprecated from "../../../../images/deprecated_hover.png";

import {
    isDiagramNode,
    isNestingChar,
    sortNodesByType,
    createBoundText,
} from "../util";

import {
  filterDataList,
  defaultBounds,
} from "../../../util/util";

import {
    Icon,
    StyledIcon,
    StyledFAIcon,
} from '../index';

import {
    NodeInput,
    AttributeCell,
    AttributeCellFixedSize,
    PortPlacement,

    AttributeRow,
    AttributeRowWrapper,
    SelectionOverlayRow,
} from './nodeDesign';



export const CellName = (props) => {
    const phenomId = new PhenomId(props.id);
    const {
        attr,
        actionUpdateChildName,
        actionDeleteChild,
        actionLockNode,
        actionKeypress,
    } = props;

    if(!props.attr) return null;

    return (<>
            {props.canDelete &&
                <button id={phenomId.genPageId("delete-btn")} className="row-del" tabIndex="-1" onClick={actionDeleteChild}>x</button>}
            <NodeInput id={phenomId.genPageId("input")}
                        type="text"
                        value={attr.rolename}
                        placeholder="enter new rolename"
                        style={{fontWeight:400}}
                        autoComplete="off"
                        onChange={actionUpdateChildName}
                        onFocus={actionLockNode}
                        onKeyPress={actionKeypress} />
        </>)
}



const dispTypeMap = {
    "conceptual:Entity": "(ENT)",
    "conceptual:Association": "(ASC)",
    "conceptual:Observable": "(OBS)"
};

export class CellType extends React.Component {
    cellRef = React.createRef();
    phenomId = new PhenomId(this.props.id);
    state = {
        inputValue: "",
        typeOptions: [],
        focusType: false,
    }

    componentDidMount() {
        this.setState({
            inputValue: this.props.text,
            typeOptions: this.props.typeOptions,
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.text !== this.props.text) {
            this.setState({
                inputValue: this.props.text,
            })
        }

        if(prevProps.typeOptions !== this.props.typeOptions) {
            this.setState({
                typeOptions: this.props.typeOptions,
            })
        }
    }

    componentWillUnmount() {
        if(this.state.focusType){
            this.props.$app.domSvgLayerOnTop();
        }
    }

    focusTypeList = () => {
        if(!this.state.focusType) {
            this.props.actionLockNode();
            this.props.$app.domModelLayerOnTop();
            this.setState({ focusType: true });
        }
    }

    blurTypeList = (e) => {
      this.props.$app.domSvgLayerOnTop();
      this.setState({ focusType: false });
    }

    handleTextChange = (e) => {
      const data = this.props.typeOptions;
      const text = e.target.value;
      let newDataList;
  
      newDataList = data.filter((ele) => {
        const name = ele.name ?? ele.rolename;
        const regex = new RegExp(text, 'i');
        return name.match(regex);
      });
  
      newDataList = newDataList.slice().sort(sortNodesByType);
  
      this.setState({
        inputValue: e.target.value,
        typeOptions: newDataList ? newDataList.sort() : data.sort(),
      });
    }

    renderListOptions = () => {
        const {
            attr,
            parentGuid,
        } = this.props;

        const {
            typeOptions
        } = this.state;


        const doNotIncludeObs = attr.xmiType === "conceptual:AssociatedEntity";

        return (
            typeOptions.map(option => {
                if(option.guid === parentGuid) return null;
                if(doNotIncludeObs && option.xmiType === "conceptual:Observable") return null;

                return <li key={option.guid}
                           style={{whiteSpace: "nowrap"}}
                           onMouseDown={() => this.props.actionUpdateType(option.guid, attr)}>
                                {`${dispTypeMap[option.xmiType]} ${option.name}`}
                       </li>
            })
        )
    }

    render() {
        const {
            attr,
        } = this.props;

        const {
            inputValue,
            focusType,
        } = this.state;

        if(!attr) return null;
        let isTypeEditable = isDiagramNode(attr.guid);

        // calculate the height of dropdown
        // limit the height so it doesn't go out of bounds
        if(this.cellRef.current) {
            const rect = this.cellRef.current.getBoundingClientRect();
            const containerRect = this.props.$app.containerDOM.getBoundingClientRect();
            const point = this.props.$app.engine.getRelativePoint(rect.x, rect.y);
            const zoom = this.props.$app.engine.getModel().getZoomLevel() / 100;

            const max_height = 200;
            const min_height = 40;
            const offset = 40;
            const list_bottom = max_height + rect.y + rect.height + offset;

            var dropdownStyle = {
                position: "fixed",
                fontSize: 12,
                fontWeight: 400,
                top: (point.y / zoom) + rect.height,
                left: (point.x / zoom),
                width: (rect.width / zoom),
                height: list_bottom > containerRect.bottom ? Math.max(max_height - (list_bottom - containerRect.bottom), min_height) : max_height,
                zIndex: 11,
                overflow: "scroll",
            }
        }

        return ( !isTypeEditable ?
                    <span>{inputValue}</span>
                : <div>
                      <NodeInput id={this.phenomId.genPageId("input")}
                                  type="text"
                                  ref={this.cellRef}
                                  value={inputValue}
                                  placeholder="Attribute type"
                                  autoComplete="off"
                                  onClick={this.focusTypeList}
                                  onFocus={this.focusTypeList}
                                  onBlur={this.blurTypeList}
                                  onChange={this.handleTextChange} />
                      {focusType &&
                      <ul id={this.phenomId.genPageId("list")}
                          className="faux-autofill"
                          style={dropdownStyle}>
                              {this.renderListOptions()}
                      </ul>}
                  </div>
        )
    }


}


// Hover effect controlled by css
export const DropZone = (props) => {
    return(<div className="drop-zone" data-drop-zone={props["data-drop-zone"]}>
        <div className="comp-placement top-left" data-placement="0" />
        <div className="comp-placement top" data-placement="1" />
        <div className="comp-placement top-right" data-placement="2" />
        <div className="comp-placement left" data-placement="7" />
        <div className="comp-placement right" data-placement="3" />
        <div className="comp-placement bottom-left" data-placement="6" />
        <div className="comp-placement bottom" data-placement="5" />
        <div className="comp-placement bottom-right" data-placement="4" />

        {Array.from(Array(11)).map((_, i) => {
            return <div className={`path-placement top-${i}`} data-placement={i} />
        })}
    </div>)
}





const SelectionRect = styled.div`
    position: absolute;
    background-color: rgba(0, 192, 255, 0.2);
    border: ${p => p.width || p.height ? "solid 2px rgb(0, 192, 255)" : null};
`

// note: React Storm comes with a SelectionBox
export const SelectionWindow = (props) => {
    const { rect } = props;

    return (
        <SelectionRect
            width={rect.width}
            height={rect.height}
            style={{
                top: rect.top,
                left: rect.left,
                width: rect.width,
                height: rect.height
            }}
        />
    )
}



export const EntityAttribute = React.memo(class EntComp extends React.Component {
  phenomId = new PhenomId(this.props.id);

  handleToggleDisplay = () => {
    this.props.actionToggle(this.props.attr.guid);
  }

  handleUpdateName = (e) => {
    this.props.actionUpdateChildName(e, this.props.attr);
  }

  handleDelete = async () => {
    const { $app, attr, parentModel } = this.props;
    await $app.deleteNodeChild(attr, parentModel);
  }

  handleLock = (e) => {
    this.props.parentModel.setLocked(true);
  }

  handleKeypress = (e) => {
    if(e.charCode === 13) {
      this.props.actionToggleEditing();
    }
  }

  handleSelectHop = () => {
    const { isSelectable, $app, attr} = this.props;
    return isSelectable && $app.selectHop(attr);
  }

  handleExpandType = () => {
    const { attr, typeNode, actionExpandType } = this.props;
    actionExpandType(attr, typeNode);
  }

  getMultiplicities = () => {
    const { attr } = this.props;
    const lower = attr.lowerBound;
    const upper = attr.upperBound;

    // if value is null then assume it is "1" (default value)
    if((lower && lower !== defaultBounds["lowerBound"]) ||
       (upper && upper !== defaultBounds["upperBound"])) {
        return `[${createBoundText(lower, upper)}]`;
       }
    return null;
  }

  render() {
    const { 
      attr,
      typeNode,
      typeOptions,
      parentData,
      hasOutPort,
      isEditing,
      isHidden,
      isTypeHidden,
      isUncommitted,
      isSelectable,
      isDeletable,
      $app,
    } = this.props;

    const typeName = typeNode?.name || "";
    const isTypeExpandable = typeNode.xmiType && typeNode.xmiType !== "conceptual:Observable";
    const multiplicities = attr.xmiType === "conceptual:Composition" ? this.getMultiplicities() : null;

    return (
      <AttributeRow id={this.phenomId.genPageId()}
                    className="attribute-row"
                    node={attr}
                    style={{zIndex: isSelectable ? 1 : null}}
                    onContextMenu={(e) => e.preventDefault()}
                    ref={el => this.ref = el}>

        <SelectionOverlayRow show={isSelectable}
                             data-drop-zone="entity-node"
                             onClick={this.handleSelectHop}
                              />

        <AttributeRowWrapper hide={hasOutPort}>

        {/* Column 0 */}
        {isEditing &&
        <AttributeCellFixedSize id={this.phenomId.genPageId("cell-hide")}
                                checkbox
                                style={{justifyContent:"center"}}>
          {attr.isPlaceholder 
            ? <div />
            : <input id={this.phenomId.genPageId("edit-hide-checkbox")}
                     type="checkbox"
                     tabIndex="-1"
                     style={{ width: 11, margin:0 }}
                     checked={!isHidden}
                     onChange={this.handleToggleDisplay} /> }
        </AttributeCellFixedSize> }

        {/* Column 1 */}
        <AttributeCell id={this.phenomId.genPageId("cell-rolename")}
                       node={attr}
                       data-tip={attr.description}
                       data-for="diagramTip"
                       isUncommitted={isUncommitted}>
            {isEditing 
              ? <CellName id={this.phenomId.genPageId("edit-rolename")}
                          attr={attr}
                          canDelete={isDeletable}
                          actionUpdateChildName={this.handleUpdateName}
                          actionDeleteChild={this.handleDelete}
                          actionLockNode={this.handleLock}
                          actionKeypress={this.handleKeypress} />
              : <span id={this.phenomId.genPageId("attr-rolename")}>{attr.rolename}</span> }
        </AttributeCell>

        {/* Column 2 */}
        <AttributeCell id={this.phenomId.genPageId("cell-type")}
                       node={attr}
                       hide={isTypeHidden && !isEditing}
                       data-tip={typeNode?.description || ""}
                       data-for="diagramTip">
          {isEditing
            ? <CellType id={this.phenomId.genPageId("edit-type")}
                        attr={attr}
                        parentGuid={parentData.guid}
                        typeNode={typeNode}
                        typeOptions={typeOptions}
                        text={typeNode?.name || ""}
                        $app={$app}
                        isEditing={isEditing}
                        actionLockNode={this.handleLock}
                        actionUpdateType={this.props.actionUpdateType} />
            : <div id={this.phenomId.genPageId("attr-type")} 
                   style={{display:"flex", alignItems:"center"}}>
                <Icon node={typeNode} />
                {isTypeExpandable ? 
                  <a id={this.phenomId.genPageId("attr-type-link")}
                     className="cadet-link"
                     onClick={this.handleExpandType}>{typeName}</a>
                  : <span id={this.phenomId.genPageId("attr-type-name")}>{typeName} {multiplicities}</span>}
              </div>}
        </AttributeCell>
      </AttributeRowWrapper>
    </AttributeRow>
    )
  }
})


export const ViewAttribute = React.memo(class ViewChar extends React.Component {
  phenomId = new PhenomId(this.props.id);

  handleToggleDisplay = () => {
    this.props.actionToggle(this.props.attr.guid);
  }

  handleUpdateName = (e) => {
    this.props.actionUpdateChildName(e, this.props.attr);
  }

  handleDelete = async () => {
    const { $app, attr, parentModel } = this.props;
    await $app.deleteNodeChild(attr, parentModel);
  }

  handleLock = (e) => {
    this.props.parentModel.setLocked(true);
  }

  handleKeypress = (e) => {
    if(e.charCode === 13) {
      this.props.actionToggleEditing();
    }
  }

  handleEyeClick = () => {
    const { attr, obsViewData, hasPath, actionExpandPath, actionExpandViewAttr } = this.props;

    if (isNestingChar(attr)) {
        actionExpandViewAttr(attr, obsViewData);
    } else {
        hasPath && actionExpandPath(attr);
    }
  }

  handlePencilClick = () => {
    const { attr, hasPath, actionEditChar } = this.props;
    hasPath && actionEditChar(attr);
  }

  handlePlusClick = () => {
    const { attr, parentModel, $app } = this.props;
    $app.startPathBuilder(parentModel, attr);
  }

  render() {
    const { 
      attr,
      obsViewData,
      hasPath,
      hasOutPort,
      isEditing,
      isHidden,
      isUncommitted,
      isDeletable,
      isParentComposite,
    } = this.props;

    const isPrivate = attr.attributeKind === "privatelyScoped";
    const isOptional = attr.optional === "true";

    return (
      <AttributeRow id={this.phenomId.genPageId()}
                    className="attribute-row"
                    node={attr}
                    onContextMenu={(e) => e.preventDefault()}
                    isNestedChar={isNestingChar(attr)}
                    ref={el => this.ref = el}>

        <AttributeRowWrapper>

        {/* Column 0 */}
        {isEditing &&
        <AttributeCellFixedSize id={this.phenomId.genPageId("cell-hide")}
                                checkbox
                                style={{justifyContent:"center"}}>
          {attr.isPlaceholder 
            ? <div />
            : <input id={this.phenomId.genPageId("edit-hide-checkbox")}
                     type="checkbox"
                     tabIndex="-1"
                     style={{ width: 11, margin:0 }}
                     checked={!isHidden}
                     onChange={this.handleToggleDisplay} /> }
        </AttributeCellFixedSize> }

        {/* Column 1 */}
        <AttributeCell id={this.phenomId.genPageId("cell-rolename")}
                       node={attr}
                       data-tip={attr.descriptionExtension}
                       data-for="diagramTip"
                       isUncommitted={isUncommitted}>
            {isEditing 
              ? <CellName id={this.phenomId.genPageId("edit-rolename")}
                          attr={attr}
                          canDelete={isDeletable}
                          actionUpdateChildName={this.handleUpdateName}
                          actionDeleteChild={this.handleDelete}
                          actionLockNode={this.handleLock}
                          actionKeypress={this.handleKeypress} />
              : <span id={this.phenomId.genPageId("attr-rolename")}>
                  {isOptional
                    ? `[${attr.rolename}]`
                    : attr.rolename
                  }</span>}
                              
        </AttributeCell>

        {/* Column 2 */}
        {/* Obs or View */}
        <AttributeCell id={this.phenomId.genPageId("cell-obs")}
                       data-tip={obsViewData?.description}
                       data-for="diagramTip">
            {obsViewData ? <span id={this.phenomId.genPageId("attr-obs")}>{obsViewData.name}</span>
                         : <div /> }
        </AttributeCell>

        {!isParentComposite && <>
          {/* Column 3 - Private */}
          <AttributeCellFixedSize id={this.phenomId.genPageId("cell-private-toggle")}
                                  char
                                  hide={attr.isPlaceholder}>
              <input type="checkbox"
                     checked={isPrivate}
                     disabled={!isNestingChar(attr)}
                     style={{width: 11, margin: 0}}
                     onChange={() => this.props.actionUpdateAttributeKind(attr, isPrivate ? "foreignReference" : "privatelyScoped")} /> </AttributeCellFixedSize>
        
          
          {/* Column 4 - expand path && deprecated path icon */}
          <AttributeCellFixedSize id={this.phenomId.genPageId("cell-expand-path")}
                                  char
                                  style={{display: "flex", justifyContent: "right"}}
                                  hide={attr.isPlaceholder}>
              {!attr.isPlaceholder &&
                <div>
                  {attr.isPathDeprecated &&
                    <img src={deprecated}
                          style={{width: 16, marginTop:"-2px"}}/>} 
                    <StyledFAIcon id={this.phenomId.genPageId("attr-expand-path-btn")}
                                  eye
                                  eyeSlash={hasOutPort}
                                  disabled={false}
                                  style={{marginBottom:"-2px", marginRight: "5px"}}
                                  onClick={this.handleEyeClick}  />
                  </div>} 
            </AttributeCellFixedSize>
          
          {/* Column 5 - edit char */}
          <AttributeCellFixedSize id={this.phenomId.genPageId("cell-edit-char")}
                                  char
                                  hide={attr.isPlaceholder}>
              <StyledFAIcon id={this.phenomId.genPageId("attr-edit-attr-btn")}
                            edit
                            disabled={!hasPath || isNestingChar(attr)}
                            onClick={this.handlePencilClick} /> </AttributeCellFixedSize>

          {/* Column 6 - create new path */}
          <AttributeCellFixedSize id={this.phenomId.genPageId("cell-start-new-path")}
                                  char>
              {!isNestingChar(attr) && !attr.isPlaceholder &&
              <StyledFAIcon id={this.phenomId.genPageId("attr-start-new-path-btn")}
                            plusCircle
                            disabled={false}
                            onClick={this.handlePlusClick} />} </AttributeCellFixedSize>
        </>}
      </AttributeRowWrapper>
    </AttributeRow>
    )
  }
})