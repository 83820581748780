import React from "react";
import {cloneDeep} from "lodash";
import {deGuidify} from "./util";
import ReactTooltip from "react-tooltip";
import {Button} from "@progress/kendo-react-buttons";
import {NodeHistory2} from "../edit/node-history";
import {Notifications2} from '../edit/notifications';
import NodeLayout, { withPageLayout, StyledGrid, StyledInput, StyledSidebar, StyledContent, StyledCardGroup } from '../edit/node-layout'
import PhenomId from "../../requests/phenom-id";

import {
    elementDetail,
    getNodeWithAddenda,
    getNodesOfType,
    smmSaveNodes
} from "../../requests/sml-requests";

import {
    CadetInput, 
    CadetTextArea, 
    LineLabel, 
    SingleSelect,
    Card,
    CommaLinks, 
} from "./stateless";
import { createNodeUrl } from "../../requests/type-to-path";
import { getActiveChangeSetId } from "../../requests/actionCreators";
import ChangeSetPicker from "../widget/ChangeSetPicker";
import NavTree from "../tree/NavTree";
import DeletionConfirm2 from "../dialog/DeletionConfirm2";

const labelStyle = {margin:"0 0 5px", lineHeight:"normal"};
const inputStyle = {margin:"0 0 15px"}


const defaultCSA = {
  guid: null,
  name: "",
  xmiType: "logical:CoordinateSystemAxis",
  description: "",
  parent: "",
  parents: {},
  related_coordinate_systems: [],
  related_measurement_system_axes: [],
  editable: true,
  subModelId: undefined,
}


export class CoordinateSystemAxisManager extends React.Component {
    constructor(props) {
        super(props);

        this.original = {};
        this.parentRef = React.createRef();
        this.phenomId = new PhenomId("edit-csa", this.props.idCtx);
    }

    loadingText = "Loading...";
    parentTip = ["Select a Parent Package"];

    state = {
      ...cloneDeep(defaultCSA),
      editable: true,
    }

    componentDidMount() {
        if (this.props.isComponent) {
            this.initNewNode(this.props.csa);
        } else if (this.props.match.params.guid === "new") {
            getNodesOfType("face:LogicalDataModel").then(res => {
              this.setState({ parents: deGuidify(JSON.parse(res).nodes) });
            });
            
            this.initNewNode();
        } else {
            this.initNode();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match?.params?.guid !== this.props.match?.params?.guid) {
            if (this.props.match.params.guid === "new") {
                getNodesOfType("face:LogicalDataModel").then(res => {
                  this.setState({ parents: deGuidify(JSON.parse(res).nodes) });
                });

                this.initNewNode();
            } else {
                this.initNode();
            }
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    componentWillUnmount() {
        if(this.props.saveState) {
            this.props.saveState(this.state)
        }
    }

    initNode = () => {
      elementDetail(this.props.match.params.guid).then(res => {
        const related_coordinate_systems = JSON.parse(res).related_coordinate_systems || [];
        const related_measurement_system_axes = JSON.parse(res).related_measurement_system_axes || [];
        this.setState({ related_coordinate_systems, related_measurement_system_axes });
      });

      getNodeWithAddenda(this.props.match.params.guid).then(res => {
        const csa = JSON.parse(res);
        this.original = cloneDeep(csa);

        if (this.props.updateTemplateNode) {
            this.props.updateTemplateNode(csa);
        }

        this.setState({ ...csa }, () => {
            this.setEditingStatus();
        });
      })
    }

    initNewNode = (preloaded = {}) => {
      this.setState({
        ...cloneDeep(defaultCSA),
        ...preloaded,
      }, () => {
        this.setEditingStatus();
      })
    }

    handleSave = async () => {
        let requestData = this.generateNode();
            requestData.changeSetId = getActiveChangeSetId();

        const res = await smmSaveNodes(requestData);
        const response = JSON.parse(res);
        Notifications2.parseResponse(response);

        if (response.guid) {
            NavTree.addNodes([ response ]);

            if (this.props.match.params.guid === "new") {
                return this.props.history.push( createNodeUrl(response) );
            }

            if (this.props.updateTemplateNode) {
                this.props.updateTemplateNode(response);
            }

            this.original = {...this.original, ...response};
            this.setState({ ...response });
        }
    }

    resetCoordinateSystemAxis = () => {
        if (this.props.match.params.guid === "new") {
            this.parentRef.current.resetData();
        }
        this.setState({...this.original});
    }


    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };


    generateNode = () => {
        return {
            name: this.state.name,
            guid: this.state.guid,
            xmiType: "logical:CoordinateSystemAxis",
            description: this.state.description,
            parent: this.state.parent || null,
        }
    }

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.name);
    }

    render() {
        const { guid, name, editable, deprecated } = this.state;
        const { isComponent } = this.props;

        return (
          <NodeLayout guid={guid}
                      name={name}
                      editable={editable}
                      deprecated={deprecated}
                      isComponent={isComponent}
                      idCtx={this.phenomId.genPageId()}
                      onSave={this.save}>

              <StyledGrid isComponent={isComponent}>
                  <StyledInput>
                      <LineLabel text="COORDINATE SYSTEM AXIS" style={labelStyle} idCtx={this.phenomId.genPageId("name")} />
                      <CadetInput text={name}
                          style={inputStyle}
                          disabled={!editable}
                          id={this.phenomId.genPageId("name")}
                          onChange={(e) => this.setState({name: e.target.value})} />

                      <LineLabel text="DESCRIPTION" style={labelStyle} idCtx={this.phenomId.genPageId("description")} />
                      <CadetTextArea text={this.state.description}
                          style={inputStyle}
                          disabled={!editable}
                          id={this.phenomId.genPageId("description")}
                          onChange={(e) => this.setState({description: e.target.value})} /></StyledInput>

                  {!isComponent && <>
                  <StyledSidebar>
                      <NodeHistory2 guid={this.props.match.params.guid} idCtx={this.phenomId.genPageId()} />
                      <ChangeSetPicker id={this.phenomId.genPageId()}
                                       disabled={!editable}
                                       label="Change Set" />
                  </StyledSidebar>

                  <StyledContent>
                      {this.props.match.params.guid === "new" &&
                      <div style={{position:"relative"}}>
                          <LineLabel text="Parent Package" style={labelStyle} idCtx={this.phenomId.genPageId("parent")} />

                          <div style={{marginBottom:10}}>
                            <Button icon="select-box" style={{marginRight:10}} id={this.phenomId.genPageId("parent", "toggle", "btn")} onClick={() => this.parentRef.current.toggleCollapse()}>Show/Hide selection box</Button>
                            <Button icon="info" look="bare" id={this.phenomId.genPageId("parent", "tip", "btn")} data-for="react-tip" data-tip={this.parentTip} /></div>
                          
                            <SingleSelect data={this.state.parents || {}}
                                  selectedGuid={this.state.parent || ""}
                                  required={false}
                                  buttonText="Select a Parent Package"
                                  optionNoneText="No Package Selected / Use Default"
                                  collapse={this.props.match.params.guid !== "new"}
                                  onChange={(e) => this.setState({parent: e.target.value})}
                                  editable={editable}
                                  idCtx={this.phenomId.genPageId("parent", "select")}
                                  ref={this.parentRef} />

                          {this.state.parents &&
                          <StyledCardGroup columns={3}>
                              <Card node={this.state.parents[this.state.parent]} idCtx={this.phenomId.genPageId("parent", "card")} /></StyledCardGroup>} </div>}

                      {this.props.match.params.guid !== "new" && this.state.related_coordinate_systems.length > 0 &&
                      <div> 
                          <LineLabel text="Coordinate System" style={labelStyle} idCtx={this.phenomId.genPageId("used", "by","cs")} />
                          <CommaLinks data={this.state.related_coordinate_systems || []} idCtx={this.phenomId.genPageId("used", "by", "cs")} /></div>}

                      {this.props.match.params.guid !== "new" && this.state.related_measurement_system_axes.length > 0 &&
                      <div>
                          <LineLabel text="Measurement System Axis" style={labelStyle} idCtx={this.phenomId.genPageId("used", "by","msa")} />
                          <CommaLinks data={this.state.related_measurement_system_axes || []} idCtx={this.phenomId.genPageId("used", "by", "msa")} /></div>}
                  </StyledContent>
                  </>}
              </StyledGrid>

              <ReactTooltip id='react-tip' type="info" getContent={(str) => <div>
                  You can:
                  <ul style={{paddingLeft:20, margin:0}}>
                      {(str || "").split(",").map((txt, i) => 
                          <li key={i}> {txt} </li>)}
                  </ul></div>} />
            </NodeLayout>
        )
    }
}



export const EditCoordinateSystemAxisManager = withPageLayout(CoordinateSystemAxisManager, { renderResetBtn: false });