import React from "react";
import {Notifications} from "../../edit/notifications";
import PhenomLoadButton from "../../widget/LoaderButton";
import $ from "jquery";
import PhenomId from "../../../requests/phenom-id";
import { connect } from "react-redux";


class AddPlaceholders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.noticeRef = null;

        this.importPlaceholders = this.importPlaceholders.bind(this);
    }

    importPlaceholders() {
        return $.post("/index.php?r=/referencing-model/add-placeholders")
                .then(res => {
                    if(res === "#true") {
                        this.noticeRef.info("Placeholders helper nodes added to project.")
                    } else {
                        this.noticeRef.parseErrors(res);
                    }
                });
    }

    render() {
        const phenomId = new PhenomId("add-placeholders",this.props.idCtx);
        const cinc_works = this.props.userRole.indexOf('c') != -1;
        return (
            <div className="phenom-content-scrollable subview-wrapper" id={phenomId.gen("","wrapper")}>
                <Notifications ref={ele => this.noticeRef = ele} />
                <div>
                    <div className="flex-v" style={{width: "85%"}}>
                        <p style={{marginBottom: 15}}>
                            Phenom can add a set of placeholder nodes to help you in your modeling efforts. These nodes will be most useful when you are not completely sure what observable or entity best represents the semantic you are trying to document or what measurement best represents the one used by a particular interface. In these instances, you will be able to use or project to a placeholder node, coming back later to revise your project.
                        <br></br><br></br>
                        You will be able to easily find parts of the project you still have to review by using the Health Check feature in the Generate menu to find all of the usages of placeholder nodes. Upon export, Phenom will remove from the exported artifact any placeholder nodes which are not being used by any other parts of the project.
                        <br></br><br></br>
                        Importing placeholders will create a new model which will be included in your project workspace. You will not be able to edit placeholder nodes, though you will be allowed to incorporate the generated model into other project workspaces.
                        <br></br><br></br>
                        For more information about placeholders, their usage in Phenom, and best-practice workflows, please review the related <a className="cadet-anchor" style={{fontSize: "100%"}}>knowledgebase article</a>.
                        <br></br><br></br>
                        NOTE: If your model already contains a placeholder model, repeating the process will generate placeholder content for any new observables for which that content is missing.
                    </p>
                    </div>
                    <PhenomLoadButton onClick={this.importPlaceholders} text="Import Placeholders" disabled={cinc_works} id={phenomId.gen("")}/>
                </div>
            </div>
        );
    }
}

const msp = (state) => ({
  userRole: state.user.userRole,
})

export default connect(msp)(AddPlaceholders);
