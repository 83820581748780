import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Button, Toolbar, ToolbarItem } from "@progress/kendo-react-buttons";
import { PhenomLabel } from '../../../util/stateless';
import { ProjectLeaf } from '../../../tree/leaf/ModelLeaf';




class PermissionDetail extends React.Component {
  containerRef = React.createRef();
  state = {
    permissions: [],
    external_users: [],
    editing: false,
    external_editing: false,
  }


  componentDidMount() {
    this.init();
    this.handleContainerResize();
    window.addEventListener("resize", this.handleContainerResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leaf !== this.props.leaf) {
      this.init();
    }
    this.handleContainerResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleContainerResize);
  }

  init() {
    const { leaf } = this.props;
    const permissions = [];
    const external_users = [];
    const editing = false;
    const external_editing = false;

    if (leaf) {
      const standard_permissions = leaf.getPermissions();

      for (let username in standard_permissions) {
        const role = leaf.getUserPermission(username);
        permissions.push({ username, role })
      }

      for (let username in leaf.getExternalPermissions()) {
        // show external users only
        if (standard_permissions[username]) {
          continue;
        }

        const role = leaf.getExternalPermission(username);
        external_users.push({ username, role })
      }

      const score = (role) => {
        return (/.*o.*/.test(role) ? 4 : 0) + 
               (/.*a.*/.test(role) ? 2 : 0) + 
               (/.*w.*/.test(role) ? 1 : 0) + 
               (/.*r.*/.test(role) ? 1 : 0)
      }

      permissions.sort((a, b) => {
        return score(a.role) < score(b.role);
      });

      external_users.sort((a, b) => {
        return score(a.role) < score(b.role);
      });
    }
    this.setState({ permissions, external_users, editing, external_editing })
  }

  toggleEdit = (e) => {
    const { leaf } = this.props;
    const { editing, permissions } = this.state;
    if (editing) {
      this.setState({ permissions: permissions.map((data) => { return { username: data.username, role: leaf.getUserPermission(data.username) }})});
    }
    this.setState({ editing: !editing });
  }

  toggleExternalEdit = (e) => {
    const { leaf } = this.props;
    const { external_editing, external_users } = this.state;
    if (external_editing) {
      this.setState({ external_users: external_users.map((data) => { return { username: data.username, role: leaf.getExternalPermission(data.username) }})});
    }
    this.setState({ external_editing: !external_editing });
  }

  isAdmin() {
    const { user, leaf } = this.props;
    return /.*a.*/.test(leaf.getUserPermission(user.username));
  }

  isOwner() {
    const { user, leaf } = this.props;
    return /.*o.*/.test(leaf.getUserPermission(user.username));
  }

  isEdited = () => {
    const { leaf } = this.props;

    const isStandardEdited = this.state.permissions.some(newPerm => {
      const prevRole = leaf.getUserPermission(newPerm.username);
      return prevRole !== newPerm.role;
    })

    const isExternalEdited = this.state.external_users.some(newPerm => {
      const prevRole = leaf.getExternalPermission(newPerm.username);
      return prevRole !== newPerm.role;
    })

    return isStandardEdited || isExternalEdited;
  }

  sortPerms = (perms) => {
    const ownerPerms = /.*o.*/.test(perms.role);
    const adminPerms = /.*a.*/.test(perms.role);
    const writePerms = /.*w.*/.test(perms.role);
    const readPerms = /.*r.*/.test(perms.role);

    let sorted = "";
    if (ownerPerms) sorted += "o";
    if (adminPerms) sorted += "a";
    if (writePerms) sorted += "w";
    if (readPerms) sorted += "r";
    return {
      username: perms.username,
      role: sorted
    };
  }

  serialize = () => {
    const { leaf } = this.props;
    const request = [];
    const addPermToRequest = (newPerm) => {
      if (leaf instanceof ProjectLeaf) {
        request.push({ modelId: leaf.getId(), ...this.sortPerms(newPerm) });
      } else {
        request.push({ subModelId: leaf.getId(), ...this.sortPerms(newPerm) });
      }
    }

    for (let newPerm of this.state.permissions) {
      const prevRole = leaf.getUserPermission(newPerm.username);
      if (prevRole !== newPerm.role) {
        addPermToRequest(newPerm);
      }
    }

    for (let newPerm of this.state.external_users) {
      const prevRole = leaf.getExternalPermission(newPerm.username);
      if (prevRole !== newPerm.role) {
        addPermToRequest(newPerm);
      }
    }

    return request;
  }

  /**
   * For dynamic height to work, the dom element requires a real number for height (a non-percentage number for height)
   *    - This looks at the parent's attribute to calculate the height
   *    - This triggers on window resize through event listeners
   */
  handleContainerResize = (e) => {
    if (!this.containerRef.current) return;

    const container = this.containerRef.current;
    const containerBox = container.getBoundingClientRect();
    const parentBox = container.parentElement.getBoundingClientRect();

    const newHeight = Math.max(parentBox.bottom - containerBox.top, 0);
    container.style.height = newHeight + "px";
  }

  handleEditPerms = (user, changedRole, external) => {
    const { permissions, external_users } = this.state;

    const permsList = external ? external_users : permissions;
    const permsData = permsList.find(data => data.username === user);
    permsData.role = permsData.role.includes(changedRole) ? permsData.role.replace(changedRole, "") : permsData.role + changedRole;

    if (!/.*r.*/.test(permsData.role) && /.*[oaw].*/.test(permsData.role)) {
      permsData.role += "r";
    }

    if (!/.*a.*/.test(permsData.role) && /.*o.*/.test(permsData.role)) {
      permsData.role += "a";
    }

    this.setState({ permissions, external_users });
  }

  renderStandardPerms = (_, props) => {
    const { user, leaf } = this.props;
    const { editing, permissions } = this.state;
    const data = props.dataItem;

    const permsData = permissions.find(perms => perms.username === data.username);
    const ownerPerms = /.*o.*/.test(permsData.role);
    const adminPerms = /.*a.*/.test(permsData.role);
    const writePerms = /.*w.*/.test(permsData.role);
    const readPerms = /.*r.*/.test(permsData.role);

    const currentUserRole = leaf.getUserPermission(user.username)
    const readDisabled = data.username === user.username || writePerms || adminPerms || ownerPerms;
    const writeDisabled = data.username === user.username || !/.*w.*/.test(currentUserRole);
    const adminDisabled = data.username === user.username || !/.*a.*/.test(currentUserRole) || ownerPerms;
    const ownerDisabled = data.username === user.username || !/.*o.*/.test(currentUserRole);

    return <tr id={`perm-${data.username}`}>
      <td>{ data.username }</td>
      <td style={{textAlign: "center", verticalAlign: "middle"}}>
        { editing ? 
          <input type="checkbox"
                 checked={readPerms}
                 onChange={(e) => this.handleEditPerms(data.username, "r", false)}
                 disabled={readDisabled} />
          : readPerms ? "✓" : "" }
      </td>
      <td style={{textAlign: "center", verticalAlign: "middle"}}>
        { editing ? 
          <input type="checkbox"
                 checked={writePerms}
                 onChange={(e) => this.handleEditPerms(data.username, "w", false)}
                 disabled={writeDisabled} />
          : writePerms ? "✓" : "" }
      </td>
      <td style={{textAlign: "center", verticalAlign: "middle"}}>
        { editing ? 
          <input type="checkbox"
                 checked={adminPerms}
                 onChange={(e) => this.handleEditPerms(data.username, "a", false)}
                 disabled={adminDisabled} />
          : adminPerms ? "✓" : "" }
      </td>
      <td style={{textAlign: "center", verticalAlign: "middle"}}>
        { editing ? 
          <input type="checkbox"
                 checked={ownerPerms}
                 onChange={(e) => this.handleEditPerms(data.username, "o", false)}
                 disabled={ownerDisabled} />
          : ownerPerms ? "✓" : "" }
      </td>
    </tr>
  }

  renderExternalPerms = (_, props) => {
    const { user, leaf } = this.props;
    const { external_editing, external_users } = this.state;
    const data = props.dataItem;
    const isDisabled = data.username === user.username || !this.isAdmin();

    const permsData = external_users.find(perms => perms.username === data.username);
    const ownerPerms = /.*o.*/.test(permsData.role);
    const adminPerms = /.*a.*/.test(permsData.role);
    const writePerms = /.*w.*/.test(permsData.role);
    const readPerms = /.*r.*/.test(permsData.role);


    return <tr id={`perm-${data.username}`}>
      <td>{ data.username }</td>
      <td style={{textAlign: "center", verticalAlign: "middle"}}>
        { external_editing ? 
          <input type="checkbox"
                 checked={readPerms}
                 onChange={ e => this.handleEditPerms(data.username, "r", true) }
                 disabled={isDisabled || !/.*r.*/.test(leaf.getExternalPermission(user.username))}></input>
          : readPerms ? "✓" : "" }
      </td>
      <td style={{textAlign: "center", verticalAlign: "middle"}}>
        { external_editing ? 
          <input type="checkbox"
                 checked={writePerms}
                 onChange={ e => this.handleEditPerms(data.username, "w", true) }
                 disabled={isDisabled || !/.*w.*/.test(leaf.getExternalPermission(user.username))}></input>
          : writePerms ? "✓" : "" }
      </td>
      <td style={{textAlign: "center", verticalAlign: "middle"}}>
        { external_editing ? 
          <input type="checkbox"
                 checked={adminPerms}
                 onChange={ e => this.handleEditPerms(data.username, "a", true) }
                 disabled={isDisabled || !/.*a.*/.test(leaf.getExternalPermission(user.username)) || ownerPerms}></input>
          : adminPerms ? "✓" : "" }
      </td>
      <td style={{textAlign: "center", verticalAlign: "middle"}}>{ ownerPerms ? "✓" : "" }</td>
    </tr>
  }

  render() {
    const { leaf, createPublishModel } = this.props;
    const { permissions, external_users, editing, external_editing } = this.state;

    // createPublishModel -> creating a new published model
    if (!leaf || leaf.isNewLeaf() || createPublishModel) return null;

    return <div style={{ display: "flex", gap: 10 }} ref={this.containerRef}>
      <div style={{ display: "flex", flex: 1, flexDirection: "column", overflow: "hidden" }}>
        <PhenomLabel text="Permission Manager" />
        <Grid data={permissions}
              rowRender={this.renderStandardPerms}
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}>
          <GridNoRecords>No data available</GridNoRecords>
          <GridColumn title='USER' field='username' />
          <GridColumn title='READ' field='read' width="75px" />
          <GridColumn title='WRITE' field='write' width="75px" />
          <GridColumn title='ADMIN' field='admin' width="75px" />
          <GridColumn title='OWNER' field='owner' width="75px" />
        </Grid>
        { (this.isAdmin() || this.isOwner()) && <Toolbar style={{ overflow: "visible" }}>
          <ToolbarItem>
            <Button onClick={ this.toggleEdit }>{ editing ? "Undo Changes" : "Modify Permissions"}</Button>
          </ToolbarItem>
        </Toolbar> }
      </div>

      {!!external_users.length &&
      <div style={{ display: "flex", flex: 1, flexDirection: "column", overflow: "hidden" }}>
        <PhenomLabel text="External Permission Manager" />
        <Grid data={external_users}
              rowRender={this.renderExternalPerms}
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}>
          <GridNoRecords>No data available</GridNoRecords>
          <GridColumn title='USER' field='username'/>
          <GridColumn title='READ' field='read' width="75px" />
          <GridColumn title='WRITE' field='write' width="75px" />
          <GridColumn title='ADMIN' field='admin' width="75px" />
          <GridColumn title='OWNER' field='owner' width="75px" />
        </Grid>
        { (this.isAdmin() || this.isOwner()) && <Toolbar>
          <ToolbarItem>
            <Button onClick={ this.toggleExternalEdit }>{ external_editing ? "Undo Changes" : "Modify External Permissions"}</Button>
          </ToolbarItem>
        </Toolbar> }
      </div> }
    </div>
  }
}


export default PermissionDetail;