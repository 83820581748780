import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'


/**
 * Used within react-router's switch statement
 *    if not authenticated then render Public Component, otherwise render Redirect
 * 
 * @param {object} props
 * @returns route component
 */
export const PublicRoute = ({ component: Component, path, exact }) => {
  const authenticated = useSelector(state => state.user.authenticated);
  return <Route exact={exact} path={path}
                render={props => (
                  authenticated && props.location.prevPath ? <Redirect to={props.location.prevPath} />
                    : authenticated ? <Redirect to="/dashboard" /> : <Component {...props} />
                )} />
}


/**
 * Used within react-router's switch statement
 *    if authenticated then render Private Component, otherwise render Redirect
 * 
 * @param {object} props
 * @returns route component
 */
export const ProtectedRoute = ({ component: Component, path, exact }) => {
  const authenticated = useSelector(state => state.user.authenticated);
  return <Route exact={exact} path={path}
                render={props => (
                  authenticated ? <Component {...props} /> : <Redirect to={{ pathname: "/", prevPath: props.location.pathname, }} />
                )} />
}


/**
 * Standalone route component
 *    if authenticated then render Private Component, otherwise render null
 * 
 * @param {object} props
 * @returns route component
 */
export const AuthRoute = ({ component: Component, path, exact }) => {
  const authenticated = useSelector(state => state.user.authenticated);
  if (!authenticated) return null;
  return <Route exact={exact} path={path}
                component={Component} />
}


export const SkaylRoute = ({ component: Component, path, exact }) => {
  const skaylAdmin = useSelector(state => state.user.skaylAdmin);
  if (!skaylAdmin) return null;
  return <Route exact={exact} path={path}
                component={Component} />
}

