import {Notifications, Notifications2} from "./notifications";
import {MessageBasics} from "../util/message/MessageBasics";
import {MsgConditionalContainer} from "../util/message/MsgConditionalContainer";
import {MsgField} from "../util/message/MsgField";
import React from "react";
import {arraysEqual, deGuidify} from "../util/util";
import {elementDetail, getNodeWithAddenda} from "../../requests/sml-requests";
import $ from "jquery";
import trashIcon from "../../images/trash2.png";
import LoaderButton from "../widget/LoaderButton";
import PhenomId from "../../requests/phenom-id";
import { withPageLayout } from "./node-layout";
import DeletionConfirm2 from "../../components/dialog/DeletionConfirm2";
import { createNodeUrl } from "../../requests/type-to-path";
import NavTree from "../tree/NavTree";


export class MessageManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            views: {"guid": {name: "loading..."}},
            name: "loading...",
            description: "loading...",
            id: false,
            guid: false,
            children: [],
            notes: [],
            editable: true,
            changeSetId: "",
        };

        this.conditionalFields = [];
        this.basicsRef = undefined;
        this.fieldRefs = {};
        this.openField = "";
        this.noticeRef = undefined;
        this.msgWasJustDeleted = undefined;
        this.msgSaved = undefined;
        this.base = {name: "", id: "", description: "", guid: false, children: []};
        this.originalRes = JSON.stringify(this.base);

        window["loadViewsIntoMessage"] = (guids) => {
            elementDetail(guids[0]).then((res) => {
                const response = JSON.parse(res);

                if (response.xmi_type === "platform:View") {
                    const newFields = Array.from(this.state.children);
                    response.children.forEach((field) => {
                        newFields.push({
                            guid: `tmp-${Math.random()}`,
                            presence: "always",
                            name: false,
                            rolename: field.name + "_field",
                            scope: field.parent_id,
                            target: field.name,
                            type: field.platformType,
                        });
                    });
                    this.conditionalFields = [];
                    this.setState({children: newFields});
                }
            });
        };
    }

    componentDidMount() {
        const guid = this.props.match.params.guid;

        if (guid !== "new") {
            this.loadMessageData(guid);
        } else {
            this.mountNewMessage();
        }
    }

    UNSAFE_componentWillUpdate(_) {
        this.conditionalFields = [];
    }

    mountNewMessage() {
        this.setState(this.base);

        $.ajax({
            url: "/index.php?r=/view/model-nodes-of-type",
            method: "get",
            data: {
                coreAddenda: ["children"],
                coreAddendaChildren: ["pathPairsMULTI", "platformType"],
            }
        }).then(res => {
            const response = JSON.parse(res);
            this.setState({views: deGuidify(response.nodes)});
        });
    }

    componentDidUpdate(prevProps, prevState) {
        for (let id in this.fieldRefs) { // before each render, cleans out the field reference collection and allows a fresh rebuild at render time
            if (!this.fieldRefs[id]) {
                delete this.fieldRefs[id];
            }
        }

        const currGuid = this.props.match.params.guid;
        const prevGuid = prevProps.match.params.guid;
        if (currGuid !== prevGuid) { // refreshes the content of the page when the guid in the url is updated

            // if (prevGuid !== 'new' || currGuid === 'new') {
            //     if (this.isChanged()) {
            //         if (!this.msgWasJustDeleted && !this.msgSaved) {
            //             this.confirmChanges();
            //         } else {
            //             this.msgWasJustDeleted = false;
            //             this.loadMessageData(currGuid);
            //         }
            //     } else {
            //         this.loadMessageData(currGuid);
            //     }
            // }
            if (currGuid !== "new") {
                this.loadMessageData(currGuid);
            } else {
                this.mountNewMessage();
            }
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    createFieldRef = (id, ele) => {
        const duplicate = Object.keys(this.fieldRefs).find(key => this.fieldRefs[key] === ele);

        if (duplicate) {
            delete this.fieldRefs[duplicate];
        }
        this.fieldRefs[id] = ele;
    };

    isChanged() {
        const originalMsg = JSON.parse(this.originalRes);
        return this.basicsRef.state.name !== originalMsg.name || this.basicsRef.state.description !== originalMsg.description ||
            //!arraysEqual(this.state.children, originalMsg.children) ||
            !arraysEqual(this.state.tags, originalMsg.tags) ||
            this.state.name !== originalMsg.name ||
            this.state.tag !== originalMsg.tag ||
            this.state.editor_id !== originalMsg.editor_id;
    }

    loadMessageData(guid) {
        getNodeWithAddenda(guid, {
          coreAddenda: ["children"],
          coreAddendaChildren: ["childrenMULTI"]
        }).then(res => {
            const response = JSON.parse(res);
            this.originalRes = res;
            this.setStateFromJson(response);
        });
    }

    effectChange = openFieldName => {
        this.openField = openFieldName;
        const allReports = this.gatherFieldReports();

        for (let report of allReports) {
            const field = this.state.children.find(field => field.guid === report.guid);

            if (!field.deleted) {
                for (let attr in report) {
                    field[attr] = report[attr];
                }
            }
        }

        this.conditionalFields = [];
        this.forceUpdate();
    };

    deleteMsgField = e => {
        e.preventDefault();
        e.dataTransfer.items[0].getAsString((guid) => {
            const field = this.state.children.find(field => field.guid === guid);
            field["deleted"] = true;
            this.effectChange();
        });
    };

    addMsgField = name => {
        const newFields = Array.from(this.state.children);
        newFields.push({guid: `tmp-${Math.random()}`, presence: (name ? "conditional" : "always"), name: name});
        this.conditionalFields = [];
        this.setState({children: newFields});
    };

    gatherFieldReports() {
        let allReports = [];

        for (let fieldID in this.fieldRefs) {
            const fieldRef = this.fieldRefs[fieldID];
            const report = fieldRef.reportState();

            if (Array.isArray(report)) {
                allReports = allReports.concat(report);
            } else {
                allReports.push(report);
            }
        }

        return allReports;
    }

    forceCollapsedState(state) {
        Object.values(this.fieldRefs).forEach(field => field.forceCollapsedState(state));
    }

    gatherDiscriminatorNames = () => {
        return this.gatherFieldReports().filter(report => report.presence === "always" && !report.deleted).map(report => report.rolename);
    };

    handleSave = (changeSetId = null) => {
        this.msgSaved = true;
        const msgReport = this.basicsRef.reportState();
        const fieldReports = this.gatherFieldReports();
        const msgState = {
            parent: this.state.parent || null,
            guid: this.state.guid || null,
            name: msgReport.name,
            description: msgReport.description || "",
            id: msgReport.id || "",
            xmiType: "message:Type",
            children: fieldReports.map(e => ({
                guid: e.guid.startsWith("tmp-0.") ? null : e.guid,
                target: e.target,
                rolename: e.rolename,
                presence: e.presence,
                xmiType: "message:Field",
                scope: e.scope || null,
                name: e.name || null,
                description: e.description || null,
                children: [{
                    guid: e.protocol_guid,
                    depends: e.depends || null,
                    mask: e.mask || null,
                    offset: e.offset,
                    value: e.value || null,
                    function: e.function || null,
                    stride: e.stride || null,
                    count: e.count || null,
                    xmiType: "message:FieldProtocol"
                }]
            })),
            changeSetId: changeSetId == "0" ? null : changeSetId
        };

        return $.ajax({
            url: "/index.php?r=/node/smm-save-nodes",
            method: "post",
            data: msgState,
        }).then(res => {
            const response = JSON.parse(res);

            if (response.errors) {
              Notifications2.parseErrors(response);
            } else if (response.guid || response.nodes) {
                NavTree.addNodes([ response ]);
                if (this.props.match.params.guid === "new") {
                    this.props.history.push( createNodeUrl(response) );
                } else {
                    this.loadMessageData(this.props.match.params.guid);
                    Notifications2.parseResponse(response);
                    window["treeRef"].fetchData();
                }
            } else {
              Notifications2.parseErrors("Something went wrong when trying to save your changes.");
            }
        });
    };

    handleReset = () => {
        if (this.originalRes) {
            this.setStateFromJson(JSON.parse(this.originalRes), () => {
                this.basicsRef.reset();
                this.setEditingStatus();
            });
        } else {
            this.basicsRef.reset();
            this.setState({children: []});
        }
    };

    handleDelete = () => {
      DeletionConfirm2.show(this.state.guid, this.state.name);
      return null;
    };

    setStateFromJson(data, followup) {
        data.children = data.children.map(child => {
            const protocol_guid = child.children[0].guid;

            const newChild = {
                ...child.children[0],
                protocol_guid,
                ...child
            };

            delete newChild.children;

            return newChild;
        });
        data.children.sort((a, b) => {
            return a.offset - b.offset;
        });
        this.conditionalFields = [];
        this.setState(data, followup);
    }

    // confirmChanges() {
    //     BasicConfirm.show("Changes were detected. Do you want to save your changes?",
    //         () => {
    //             this.save();
    //         },
    //         () => {
    //             this.loadMessageData(this.props.match.params.guid);
    //         },
    //         `Save changes?`,
    //     );
    // }

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };

    setChangeSetId = (e) => {
      this.setState({changeSetId: e.target.value});
    }

    render() {
        const phenomId = new PhenomId("edit-message",this.props.idCtx);
        return (
            <div className="subview-wrapper flex-v">
                <Notifications ref={el => this.noticeRef = el}/>
                {/* <EditTopButtons
                    saveBtn={() => this.save(this.state.changeSetId)}
                    resetBtn={this.resetMsg}
                    deleteBtn={this.state.guid ? this.deleteMsg : null}
                    canEdit={this.state.editable}
                    setChangeSetId={this.setChangeSetId}
                /> */}
                <MessageBasics
                    idCtx={phenomId.gen("details")}
                    ref={(ele) => this.basicsRef = ele}
                    guid={this.state.guid}
                    name={this.state.name}
                    description={this.state.description}
                    id={this.state.id}
                    editable={this.state.editable}
                />
                <div className="flex-h" style={{justifyContent: "space-between"}}>
                    <div style={{width: "85%"}}>
                        <div className="flex-h" style={{float: "right"}}>
                            <button className="cadet-anchor" style={{fontSize: "100%"}}
                                onClick={() => this.forceCollapsedState(false)}
                                id={phenomId.gen("details","expand-all-button")}>expand all
                            </button>
                            <span style={{margin: 5, color: "#409b9e"}}>|</span>
                            <button className="cadet-anchor" style={{fontSize: "100%"}}
                                onClick={() => this.forceCollapsedState(true)}
                                id={phenomId.gen("details","collapse-all-button")}>collapse all
                            </button>
                        </div>
                        {this.state.children.map((field, idx) => {
                            if (field.deleted) return;

                            if (field.presence === "always") {
                                return <MsgField
                                    id={phenomId.gen(["details",`${idx}`])}
                                    ref={(ele) => this.createFieldRef(field.guid, ele)}
                                    idx={idx} views={this.state.views}
                                    field={field}
                                    effectChange={this.effectChange}
                                    conditionalFields={this.conditionalFields}
                                    key={idx}
                                />;
                            } else if (field.presence === "conditional") {
                                if (this.conditionalFields.includes(field.name) || field.deleted) {
                                    return;
                                }
                                const children = this.state.children.filter(otherField => otherField.name === field.name && field.presence === "conditional" && !otherField.deleted);
                                this.conditionalFields.push(field.name);
                                return <MsgConditionalContainer
                                    id={phenomId.gen(["details",`${idx}`])}
                                    fields={children}
                                    open={this.openField === field.name}
                                    idx={idx}
                                    ref={(ele) => this.createFieldRef(field.name, ele)}
                                    views={this.state.views}
                                    msgFields={this.state.children.filter(field => field.presence === "always" && !field.deleted).map(field => field.rolename)}
                                    effectChange={this.effectChange}
                                    addMsgField={this.addMsgField}
                                    gatherDiscriminatorNames={this.gatherDiscriminatorNames}
                                    key={idx}
                                />;
                            }
                        })}
                        <button
                            // id="new-mdm-field"
                            id={phenomId.gen("details","add-field-button")}
                            onClick={() => this.addMsgField()}
                            title="Click to Add New Message Field"
                            onDrop={(e) => {
                                e.preventDefault();
                            }}
                        >+
                        </button>
                    </div>
                    <img
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.target.style.opacity = "1";
                        }}
                        id={phenomId.gen("details","delete-img")}
                        onDragLeave={e => e.target.style.opacity = ".6"}
                        onDrop={this.deleteMsgField}
                        src={trashIcon}
                        id='mdm-trash'
                        title="Drag Message Field Here to Delete">
                    </img>
                </div>
                <LoaderButton className="filled-button"
                    idCtx={phenomId.genPageId()}
                    text="SAVE"
                    onClick={() => this.save(this.state.changeSetId)}
                    style={{float: "right", margin: "15px 0"}}
                />
            </div>
        );
    }
}


export const EditMessageManager = withPageLayout(MessageManager);
